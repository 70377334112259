import React from "react";
import {BrowserRouter} from "react-router-dom";
import {MainMenu} from "./MainMenu";
import {Box, CssBaseline} from "@mui/material";
import {MainContent} from "./MainContent";
import {MainNav} from "./MainNav";
import {useUserPreferences} from "../common/hooks/useUserPreferences";

function AppRouter() {
    const {privacyMode} = useUserPreferences();

    return <>
        <BrowserRouter>
            <Box sx={{display: "flex"}} className={privacyMode ? "privacyModeOn" : ""}>
                <CssBaseline/>
                <MainMenu/>
                <MainNav/>
                <MainContent/>
            </Box>
        </BrowserRouter>
    </>
}

export default AppRouter;
