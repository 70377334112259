/* tslint:disable */
/* eslint-disable */
/**
 * VUT API for FIT
 *  Pekny den, Vazene kolegyne a kolegove!  Vsichni s novym informacnim systemem tak trochu bojujeme a spousta z nas se snazi implementovat nejakou vlastni sikovnou pomucku, ktera nam zprijemni zivot a praci, nyni nutne spjatou s novym informacnim systemem. Kazdeho takoveho cloveka pak dozajista nepotesila forma, detail ci vseobecna informativnost oficialni dokumentace. Ovsem nezoufejte dale!  K dispozici zde nyni vsichni mate (temer kompletni) OpenAPI specifikaci endpointu VUT API pro nasi Fakultu. OpenAPI specifikaci tedy muzete pouzit k automatickemu vygenerovani API klientu/knihoven a nebo ciste ke snazsi prochazeni skutecne dokumentace a porozumeni existujicich endpointu k implementaci vlastnich nastroju.  Specifikace aktualne neobsahuje uplne vsechny dostupne endpointy a nektere z uvedenych mohou mit neuplne ci zcela chybejici odpovedi serveru z duvodu nedostatecnych opravneni, ktere jsou mi v predmetech jen jakozto cvicicimu k dispozici. Dale take endpointy zpusobujici upravu dat nejsou aktualne ve specifikaci k dispozici. V pripade jakychkoliv dotazu, stiznosti ci napadu mi prosim napiste na mail.  Bohuzel pozadavky z prohlizece na produkcni API server nejsou k dispozici kvuli nastaveni CORS ciloveho serveru. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: dolejska@fit.vut.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from "./configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';

export const BASE_PATH = "https://api.vut.cz".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: AxiosRequestConfig;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}
