/* tslint:disable */
/* eslint-disable */
/**
 * VUT API for FIT
 *  Pekny den, Vazene kolegyne a kolegove!  Vsichni s novym informacnim systemem tak trochu bojujeme a spousta z nas se snazi implementovat nejakou vlastni sikovnou pomucku, ktera nam zprijemni zivot a praci, nyni nutne spjatou s novym informacnim systemem. Kazdeho takoveho cloveka pak dozajista nepotesila forma, detail ci vseobecna informativnost oficialni dokumentace. Ovsem nezoufejte dale!  K dispozici zde nyni vsichni mate (temer kompletni) OpenAPI specifikaci endpointu VUT API pro nasi Fakultu. OpenAPI specifikaci tedy muzete pouzit k automatickemu vygenerovani API klientu/knihoven a nebo ciste ke snazsi prochazeni skutecne dokumentace a porozumeni existujicich endpointu k implementaci vlastnich nastroju.  Specifikace aktualne neobsahuje uplne vsechny dostupne endpointy a nektere z uvedenych mohou mit neuplne ci zcela chybejici odpovedi serveru z duvodu nedostatecnych opravneni, ktere jsou mi v predmetech jen jakozto cvicicimu k dispozici. Dale take endpointy zpusobujici upravu dat nejsou aktualne ve specifikaci k dispozici. V pripade jakychkoliv dotazu, stiznosti ci napadu mi prosim napiste na mail.  Bohuzel pozadavky z prohlizece na produkcni API server nejsou k dispozici kvuli nastaveni CORS ciloveho serveru. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: dolejska@fit.vut.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CreateAktualniPredmetTerminZkouskaTerminElIndexV4Request
 */
export interface CreateAktualniPredmetTerminZkouskaTerminElIndexV4Request {
    /**
     * 
     * @type {boolean}
     * @memberof CreateAktualniPredmetTerminZkouskaTerminElIndexV4Request
     */
    'potvrzeni_registrace'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAktualniPredmetTerminZkouskaTerminElIndexV4Request
     */
    'zvysit_kapacitu'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateAuthLogin200Response
 */
export interface CreateAuthLogin200Response {
    /**
     * 
     * @type {number}
     * @memberof CreateAuthLogin200Response
     */
    'db_session_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateAuthLogin200Response
     */
    'db_session_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAuthLogin200Response
     */
    'format'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAuthLogin200Response
     */
    'http_session_token'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateAuthLogin200Response
     */
    'log_status_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateAuthLogin200Response
     */
    'message_cz'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAuthLogin200Response
     */
    'message_en'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateAuthLogin200Response
     */
    'per_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateAuthLogin200Response
     */
    'per_label'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAuthLogin200Response
     */
    'per_label_pr'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAuthLogin200Response
     */
    'per_login'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAuthLogin200Response
     */
    'readonly'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateAuthLoginRequest
 */
export interface CreateAuthLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAuthLoginRequest
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAuthLoginRequest
     */
    'username'?: string;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetAnotaceV3200Response
 */
export interface GetAktualniPredmetAnotaceV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetAnotaceV3200ResponseData}
     * @memberof GetAktualniPredmetAnotaceV3200Response
     */
    'data'?: GetAktualniPredmetAnotaceV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetAnotaceV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetAnotaceV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetAnotaceV3200ResponseData
 */
export interface GetAktualniPredmetAnotaceV3200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInner>}
     * @memberof GetAktualniPredmetAnotaceV3200ResponseData
     */
    'predmety'?: Array<GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInner
 */
export interface GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInner {
    /**
     * 
     * @type {Array<GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInnerAktualniPredmetyInner>}
     * @memberof GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInner
     */
    'aktualni_predmety'?: Array<GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInnerAktualniPredmetyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInner
     */
    'predmet_id'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInnerAktualniPredmetyInner
 */
export interface GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInnerAktualniPredmetyInner {
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'aktualni_informace'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'anotace'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'anotace_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'cil'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'hodnoceni'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'kontakt_vyucujici'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'kontrolovana_vyuka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'korekvizita'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'metody_vyucovani'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'odkazy'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'osnova'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'podminky_zapoctu'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'pouzivane_vybaveni'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'pracovni_staz'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'prerekvizity'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'proc_se_predmet_uci'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'rok'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'volitelne_casti'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetAnotaceV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'znalosti'?: string;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetElIndexV3200Response
 */
export interface GetAktualniPredmetElIndexV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetElIndexV3200ResponseData}
     * @memberof GetAktualniPredmetElIndexV3200Response
     */
    'data'?: GetAktualniPredmetElIndexV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetElIndexV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetElIndexV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetElIndexV3200ResponseData
 */
export interface GetAktualniPredmetElIndexV3200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetElIndexV3200ResponseDataStudentInner>}
     * @memberof GetAktualniPredmetElIndexV3200ResponseData
     */
    'student'?: Array<GetAktualniPredmetElIndexV3200ResponseDataStudentInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetElIndexV3200ResponseDataStudentInner
 */
export interface GetAktualniPredmetElIndexV3200ResponseDataStudentInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetElIndexV3200ResponseDataStudentInner
     */
    'absolvoval'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetElIndexV3200ResponseDataStudentInner
     */
    'aktivni_studium'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetElIndexV3200ResponseDataStudentInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetElIndexV3200ResponseDataStudentInner
     */
    'bodove_hodnoceni'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetElIndexV3200ResponseDataStudentInner
     */
    'con_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetElIndexV3200ResponseDataStudentInner
     */
    'datum_zapisu_vysledku'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetElIndexV3200ResponseDataStudentInner
     */
    'datum_zapoctu'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetElIndexV3200ResponseDataStudentInner
     */
    'klasifikoval_label_pr'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetElIndexV3200ResponseDataStudentInner
     */
    'klasifikoval_per_id'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetElIndexV3200ResponseDataStudentInner
     */
    'label_pr'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetElIndexV3200ResponseDataStudentInner
     */
    'login'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetElIndexV3200ResponseDataStudentInner
     */
    'pocet_pokusu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetElIndexV3200ResponseDataStudentInner
     */
    'povinnost'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetElIndexV3200ResponseDataStudentInner
     */
    'povinnost_student'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetElIndexV3200ResponseDataStudentInner
     */
    'student_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetElIndexV3200ResponseDataStudentInner
     */
    'studium_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetElIndexV3200ResponseDataStudentInner
     */
    'zapocet_udelil_label_pr'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetElIndexV3200ResponseDataStudentInner
     */
    'zapocet_udelil_per_id'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetElIndexV3200ResponseDataStudentInner
     */
    'zapocteno'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetElIndexV3200ResponseDataStudentInner
     */
    'znamka'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetElIndexV3200ResponseDataStudentInner
     */
    'znamka_znak'?: string;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetOboryV4200Response
 */
export interface GetAktualniPredmetOboryV4200Response {
    /**
     * 
     * @type {GetAktualniPredmetOboryV4200ResponseData}
     * @memberof GetAktualniPredmetOboryV4200Response
     */
    'data'?: GetAktualniPredmetOboryV4200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetOboryV4200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetOboryV4200Response
     */
    'rows_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetOboryV4200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetOboryV4200ResponseData
 */
export interface GetAktualniPredmetOboryV4200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetOboryV4200ResponseDataPredmetyInner>}
     * @memberof GetAktualniPredmetOboryV4200ResponseData
     */
    'predmety'?: Array<GetAktualniPredmetOboryV4200ResponseDataPredmetyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetOboryV4200ResponseDataPredmetyInner
 */
export interface GetAktualniPredmetOboryV4200ResponseDataPredmetyInner {
    /**
     * 
     * @type {Array<GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInner>}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInner
     */
    'aktualni_predmety'?: Array<GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInner
     */
    'predmet_id'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInner
 */
export interface GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'ap_povinnost'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'ap_povinnost_k_sp'?: string;
    /**
     * 
     * @type {Array<GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInner>}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'programy'?: Array<GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInner
 */
export interface GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInner
     */
    'delka_studia'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInner
     */
    'erasmus'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInner
     */
    'fakulta_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInner
     */
    'forma_studia_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInner
     */
    'kod_stud_prog'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInner
     */
    'lang_code'?: string;
    /**
     * 
     * @type {Array<GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInner>}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInner
     */
    'obory'?: Array<GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInner>;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInner
     */
    'prog_akreditace_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInner
     */
    'prog_akreditace_od'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInner
     */
    'prog_bez_specializace'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInner
     */
    'prog_forma'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInner
     */
    'prog_forma_zr'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInner
     */
    'prog_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInner
     */
    'prog_rok'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInner
     */
    'prog_typ'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInner
     */
    'prog_verze'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInner
     */
    'prog_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInner
     */
    'program_id'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInner
     */
    'program_lonsky_id'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInner
     */
    'titul'?: string;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInner
 */
export interface GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInner {
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInner
     */
    'akreditace_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInner
     */
    'akreditace_od'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInner
     */
    'bez_specializace'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInner
     */
    'kod_jkov'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInner
     */
    'kod_kkov'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInner
     */
    'kod_oboru'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInner
     */
    'nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInner
     */
    'obor_id'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInner
     */
    'obor_lonsky_id'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInner
     */
    'specializace'?: number;
    /**
     * 
     * @type {Array<GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInnerStupneInner>}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInner
     */
    'stupne'?: Array<GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInnerStupneInner>;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInner
     */
    'zamereni_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInner
     */
    'zkratka'?: string;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInnerStupneInner
 */
export interface GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInnerStupneInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInnerStupneInner
     */
    'cislo_stupne'?: number;
    /**
     * 
     * @type {Array<GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInnerStupneInnerRocnikyInner>}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInnerStupneInner
     */
    'rocniky'?: Array<GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInnerStupneInnerRocnikyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInnerStupneInner
     */
    'stupen_id'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInnerStupneInnerRocnikyInner
 */
export interface GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInnerStupneInnerRocnikyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInnerStupneInnerRocnikyInner
     */
    'cislo_rocniku'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInnerStupneInnerRocnikyInner
     */
    'rocnik_id'?: number;
    /**
     * 
     * @type {Array<GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInnerStupneInnerRocnikyInnerSemestryInner>}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInnerStupneInnerRocnikyInner
     */
    'semestry'?: Array<GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInnerStupneInnerRocnikyInnerSemestryInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInnerStupneInnerRocnikyInnerSemestryInner
 */
export interface GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInnerStupneInnerRocnikyInnerSemestryInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInnerStupneInnerRocnikyInnerSemestryInner
     */
    'pp_povinnost'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInnerStupneInnerRocnikyInnerSemestryInner
     */
    'pp_povinnost_k_sp'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInnerStupneInnerRocnikyInnerSemestryInner
     */
    'predmet_planu_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInnerStupneInnerRocnikyInnerSemestryInner
     */
    'semestr_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInnerStupneInnerRocnikyInnerSemestryInner
     */
    'typ_semestru_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetOboryV4200ResponseDataPredmetyInnerAktualniPredmetyInnerProgramyInnerOboryInnerStupneInnerRocnikyInnerSemestryInner
     */
    'typ_semestru_zkratka'?: string;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetPozadavkyVyucovaniV3200Response
 */
export interface GetAktualniPredmetPozadavkyVyucovaniV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetPozadavkyVyucovaniV3200ResponseData}
     * @memberof GetAktualniPredmetPozadavkyVyucovaniV3200Response
     */
    'data'?: GetAktualniPredmetPozadavkyVyucovaniV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPozadavkyVyucovaniV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyVyucovaniV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetPozadavkyVyucovaniV3200ResponseData
 */
export interface GetAktualniPredmetPozadavkyVyucovaniV3200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetPozadavkyVyucovaniV3200ResponseDataPozadavkyInner>}
     * @memberof GetAktualniPredmetPozadavkyVyucovaniV3200ResponseData
     */
    'pozadavky'?: Array<GetAktualniPredmetPozadavkyVyucovaniV3200ResponseDataPozadavkyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetPozadavkyVyucovaniV3200ResponseDataPozadavkyInner
 */
export interface GetAktualniPredmetPozadavkyVyucovaniV3200ResponseDataPozadavkyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyVyucovaniV3200ResponseDataPozadavkyInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyVyucovaniV3200ResponseDataPozadavkyInner
     */
    'delka'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyVyucovaniV3200ResponseDataPozadavkyInner
     */
    'kapacita'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPozadavkyVyucovaniV3200ResponseDataPozadavkyInner
     */
    'mistnosti'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyVyucovaniV3200ResponseDataPozadavkyInner
     */
    'osoba_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyVyucovaniV3200ResponseDataPozadavkyInner
     */
    'pocet'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPozadavkyVyucovaniV3200ResponseDataPozadavkyInner
     */
    'poznamka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPozadavkyVyucovaniV3200ResponseDataPozadavkyInner
     */
    'rozvrh_jednotka_popis'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPozadavkyVyucovaniV3200ResponseDataPozadavkyInner
     */
    'rozvrh_jednotka_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyVyucovaniV3200ResponseDataPozadavkyInner
     */
    'rozvrh_pozadavek_vyucovani_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyVyucovaniV3200ResponseDataPozadavkyInner
     */
    'rozvrhova_jednotka_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyVyucovaniV3200ResponseDataPozadavkyInner
     */
    'tyden_do'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyVyucovaniV3200ResponseDataPozadavkyInner
     */
    'tyden_od'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyVyucovaniV3200ResponseDataPozadavkyInner
     */
    'typ_rozvrhove_jednotky_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyVyucovaniV3200ResponseDataPozadavkyInner
     */
    'typ_tydne_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPozadavkyVyucovaniV3200ResponseDataPozadavkyInner
     */
    'typ_tydne_popis'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPozadavkyVyucovaniV3200ResponseDataPozadavkyInner
     */
    'typ_tydne_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyVyucovaniV3200ResponseDataPozadavkyInner
     */
    'zruseno'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetPozadavkyZkouskyV3200Response
 */
export interface GetAktualniPredmetPozadavkyZkouskyV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetPozadavkyZkouskyV3200ResponseData}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200Response
     */
    'data'?: GetAktualniPredmetPozadavkyZkouskyV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetPozadavkyZkouskyV3200ResponseData
 */
export interface GetAktualniPredmetPozadavkyZkouskyV3200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInner>}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseData
     */
    'pozadavky'?: Array<GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInner
 */
export interface GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInner
     */
    'delka_kola'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInner
     */
    'mistnosti'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInner
     */
    'osoba_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInner
     */
    'pocet_kol'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInner
     */
    'poradove_cislo'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInner
     */
    'poznamka'?: string;
    /**
     * 
     * @type {Array<GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInnerPreferenceInner>}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInner
     */
    'preference'?: Array<GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInnerPreferenceInner>;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInner
     */
    'rozsazeni'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInner
     */
    'rozsazeni_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInner
     */
    'rozvrh_pozadavek_zkouska_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInner
     */
    'zruseno'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInnerPreferenceInner
 */
export interface GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInnerPreferenceInner {
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInnerPreferenceInner
     */
    'cas_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInnerPreferenceInner
     */
    'cas_od'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInnerPreferenceInner
     */
    'datum_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInnerPreferenceInner
     */
    'datum_od'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInnerPreferenceInner
     */
    'den'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInnerPreferenceInner
     */
    'den_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInnerPreferenceInner
     */
    'den_zkratka'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInnerPreferenceInner
     */
    'navazovat'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInnerPreferenceInner
     */
    'pref_poznamka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInnerPreferenceInner
     */
    'pref_zruseno'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInnerPreferenceInner
     */
    'prestavka'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInnerPreferenceInner
     */
    'priorita'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInnerPreferenceInner
     */
    'priorita_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInnerPreferenceInner
     */
    'priorita_poradi'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInnerPreferenceInner
     */
    'rozvrh_pozadavek_zkouska_pref_id'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetPozadavkyZkouskyV3200ResponseDataPozadavkyInnerPreferenceInner
     */
    'soucasne'?: object;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetPraxeV3200Response
 */
export interface GetAktualniPredmetPraxeV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetPraxeV3200ResponseData}
     * @memberof GetAktualniPredmetPraxeV3200Response
     */
    'data'?: GetAktualniPredmetPraxeV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPraxeV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPraxeV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetPraxeV3200ResponseData
 */
export interface GetAktualniPredmetPraxeV3200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetPraxeV3200ResponseDataPredmetyInner>}
     * @memberof GetAktualniPredmetPraxeV3200ResponseData
     */
    'predmety'?: Array<GetAktualniPredmetPraxeV3200ResponseDataPredmetyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetPraxeV3200ResponseDataPredmetyInner
 */
export interface GetAktualniPredmetPraxeV3200ResponseDataPredmetyInner {
    /**
     * 
     * @type {Array<GetAktualniPredmetPraxeV3200ResponseDataPredmetyInnerAktualniPredmetyInner>}
     * @memberof GetAktualniPredmetPraxeV3200ResponseDataPredmetyInner
     */
    'aktualni_predmety'?: Array<GetAktualniPredmetPraxeV3200ResponseDataPredmetyInnerAktualniPredmetyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPraxeV3200ResponseDataPredmetyInner
     */
    'predmet_id'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetPraxeV3200ResponseDataPredmetyInnerAktualniPredmetyInner
 */
export interface GetAktualniPredmetPraxeV3200ResponseDataPredmetyInnerAktualniPredmetyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPraxeV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {Array<GetAktualniPredmetPraxeV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPraxeInner>}
     * @memberof GetAktualniPredmetPraxeV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'praxe'?: Array<GetAktualniPredmetPraxeV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPraxeInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetPraxeV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPraxeInner
 */
export interface GetAktualniPredmetPraxeV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPraxeInner {
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPraxeV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPraxeInner
     */
    'pozadavky'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPraxeV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPraxeInner
     */
    'praxe_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPraxeV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPraxeInner
     */
    'proj_praxe_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPraxeV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPraxeInner
     */
    'proj_praxe_stav_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPraxeV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPraxeInner
     */
    'stav_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPraxeV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPraxeInner
     */
    'ustav_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPraxeV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPraxeInner
     */
    'ustav_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPraxeV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPraxeInner
     */
    'ustav_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPraxeV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPraxeInner
     */
    'vedouci_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPraxeV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPraxeInner
     */
    'vedouci_label_pr'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPraxeV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPraxeInner
     */
    'vedouci_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPraxeV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPraxeInner
     */
    'zadani'?: string;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetPrerekvizityV3200Response
 */
export interface GetAktualniPredmetPrerekvizityV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetPrerekvizityV3200ResponseData}
     * @memberof GetAktualniPredmetPrerekvizityV3200Response
     */
    'data'?: GetAktualniPredmetPrerekvizityV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPrerekvizityV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPrerekvizityV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetPrerekvizityV3200ResponseData
 */
export interface GetAktualniPredmetPrerekvizityV3200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInner>}
     * @memberof GetAktualniPredmetPrerekvizityV3200ResponseData
     */
    'predmety'?: Array<GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInner
 */
export interface GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInner
     */
    'a_predmet_id'?: number;
    /**
     * 
     * @type {Array<GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInnerAktualniPredmetyInner>}
     * @memberof GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInner
     */
    'aktualni_predmety'?: Array<GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInnerAktualniPredmetyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInnerAktualniPredmetyInner
 */
export interface GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInnerAktualniPredmetyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'a_aktualni_predmet_id'?: number;
    /**
     * 
     * @type {Array<GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPrerekvizityInner>}
     * @memberof GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'prerekvizity'?: Array<GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPrerekvizityInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPrerekvizityInner
 */
export interface GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPrerekvizityInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPrerekvizityInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPrerekvizityInner
     */
    'ap_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPrerekvizityInner
     */
    'org_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPrerekvizityInner
     */
    'p_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPrerekvizityInner
     */
    'predmet_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPrerekvizityInner
     */
    'typ_popis'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPrerekvizityInner
     */
    'typ_semestru'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPrerekvizityInner
     */
    'typ_semestru_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPrerekvizityInner
     */
    'typ_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPrerekvizityInner
     */
    'ustav_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPrerekvizityInner
     */
    'ustav_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPrerekvizityV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPrerekvizityInner
     */
    'ustav_zkratka'?: string;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetPvskupinyV3200Response
 */
export interface GetAktualniPredmetPvskupinyV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetPvskupinyV3200ResponseData}
     * @memberof GetAktualniPredmetPvskupinyV3200Response
     */
    'data'?: GetAktualniPredmetPvskupinyV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPvskupinyV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPvskupinyV3200Response
     */
    'rows_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPvskupinyV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetPvskupinyV3200ResponseData
 */
export interface GetAktualniPredmetPvskupinyV3200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInner>}
     * @memberof GetAktualniPredmetPvskupinyV3200ResponseData
     */
    'predmety'?: Array<GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInner
 */
export interface GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInner {
    /**
     * 
     * @type {Array<GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInner>}
     * @memberof GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInner
     */
    'aktualni_predmety'?: Array<GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInner
     */
    'predmet_id'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInner
 */
export interface GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'ap_povinnost'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'ap_povinnost_k_sp'?: string;
    /**
     * 
     * @type {Array<GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPvSkupinyInner>}
     * @memberof GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'pv_skupiny'?: Array<GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPvSkupinyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'rok'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPvSkupinyInner
 */
export interface GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPvSkupinyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPvSkupinyInner
     */
    'cislo_rocniku'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPvSkupinyInner
     */
    'cislo_skupiny'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPvSkupinyInner
     */
    'cislo_stupne'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPvSkupinyInner
     */
    'obor_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPvSkupinyInner
     */
    'plan_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPvSkupinyInner
     */
    'pp_povinnost'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPvSkupinyInner
     */
    'pp_povinnost_k_sp'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPvSkupinyInner
     */
    'predmet_planu_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPvSkupinyInner
     */
    'program_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPvSkupinyInner
     */
    'rocnik_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPvSkupinyInner
     */
    'skupina_pv_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPvSkupinyInner
     */
    'stupen_id'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetRozvrhoveJednotkyV3200Response
 */
export interface GetAktualniPredmetRozvrhoveJednotkyV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetRozvrhoveJednotkyV3200ResponseData}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200Response
     */
    'data'?: GetAktualniPredmetRozvrhoveJednotkyV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetRozvrhoveJednotkyV3200ResponseData
 */
export interface GetAktualniPredmetRozvrhoveJednotkyV3200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInner>}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200ResponseData
     */
    'predmety'?: Array<GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInner
 */
export interface GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInner {
    /**
     * 
     * @type {Array<GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInner>}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInner
     */
    'aktualni_predmety'?: Array<GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInner
     */
    'predmet_id'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInner
 */
export interface GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'rok'?: number;
    /**
     * 
     * @type {Array<GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerRozvrhoveJednotkyInner>}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'rozvrhove_jednotky'?: Array<GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerRozvrhoveJednotkyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerRozvrhoveJednotkyInner
 */
export interface GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerRozvrhoveJednotkyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerRozvrhoveJednotkyInner
     */
    'akreditovana'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerRozvrhoveJednotkyInner
     */
    'aktualni_predmet_skupina_tymu_id'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerRozvrhoveJednotkyInner
     */
    'kontakt'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerRozvrhoveJednotkyInner
     */
    'max_pocet_studentu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerRozvrhoveJednotkyInner
     */
    'min_pocet_studentu'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerRozvrhoveJednotkyInner
     */
    'osnova'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerRozvrhoveJednotkyInner
     */
    'pocet_hodin'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerRozvrhoveJednotkyInner
     */
    'pocet_jednotek'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerRozvrhoveJednotkyInner
     */
    'pocet_vyucujicich_k_dispozici'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerRozvrhoveJednotkyInner
     */
    'povinnost'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerRozvrhoveJednotkyInner
     */
    'prirazeni_ke_skupine'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerRozvrhoveJednotkyInner
     */
    'registrace_vyucovani'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerRozvrhoveJednotkyInner
     */
    'registrace_vyuky_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerRozvrhoveJednotkyInner
     */
    'registrace_vyuky_od'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerRozvrhoveJednotkyInner
     */
    'rozvrhova_jednotka_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerRozvrhoveJednotkyInner
     */
    'trj_popis'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerRozvrhoveJednotkyInner
     */
    'trj_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetRozvrhoveJednotkyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerRozvrhoveJednotkyInner
     */
    'typ_rozvrhove_jednotky_id'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetSkupinyTymuV3200Response
 */
export interface GetAktualniPredmetSkupinyTymuV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetSkupinyTymuV3200ResponseData}
     * @memberof GetAktualniPredmetSkupinyTymuV3200Response
     */
    'data'?: GetAktualniPredmetSkupinyTymuV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetSkupinyTymuV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetSkupinyTymuV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetSkupinyTymuV3200ResponseData
 */
export interface GetAktualniPredmetSkupinyTymuV3200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInner>}
     * @memberof GetAktualniPredmetSkupinyTymuV3200ResponseData
     */
    'predmety'?: Array<GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInner
 */
export interface GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInner {
    /**
     * 
     * @type {Array<GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInnerAktualniPredmetyInner>}
     * @memberof GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInner
     */
    'aktualni_predmety'?: Array<GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInnerAktualniPredmetyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInner
     */
    'predmet_id'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInnerAktualniPredmetyInner
 */
export interface GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInnerAktualniPredmetyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {Array<GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInnerAktualniPredmetyInnerSkupinyTymuInner>}
     * @memberof GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'skupiny_tymu'?: Array<GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInnerAktualniPredmetyInnerSkupinyTymuInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInnerAktualniPredmetyInnerSkupinyTymuInner
 */
export interface GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInnerAktualniPredmetyInnerSkupinyTymuInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInnerAktualniPredmetyInnerSkupinyTymuInner
     */
    'aktualni_predmet_skupina_tymu_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInnerAktualniPredmetyInnerSkupinyTymuInner
     */
    'generovat_nazev'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInnerAktualniPredmetyInnerSkupinyTymuInner
     */
    'max_pocet_studentu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInnerAktualniPredmetyInnerSkupinyTymuInner
     */
    'max_pocet_tymu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInnerAktualniPredmetyInnerSkupinyTymuInner
     */
    'min_pocet_studentu'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInnerAktualniPredmetyInnerSkupinyTymuInner
     */
    'nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInnerAktualniPredmetyInnerSkupinyTymuInner
     */
    'odhlaseni_aktivity_minut'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInnerAktualniPredmetyInnerSkupinyTymuInner
     */
    'odhlaseni_vedoucim_minut'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInnerAktualniPredmetyInnerSkupinyTymuInner
     */
    'popis'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInnerAktualniPredmetyInnerSkupinyTymuInner
     */
    'prihlasovani_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInnerAktualniPredmetyInnerSkupinyTymuInner
     */
    'prihlasovani_od'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInnerAktualniPredmetyInnerSkupinyTymuInner
     */
    'zakladani_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInnerAktualniPredmetyInnerSkupinyTymuInner
     */
    'zakladani_od'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetSkupinyTymuV3200ResponseDataPredmetyInnerAktualniPredmetyInnerSkupinyTymuInner
     */
    'zmena_vedouciho'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetStudentiAnonZapsaniV3200Response
 */
export interface GetAktualniPredmetStudentiAnonZapsaniV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetStudentiAnonZapsaniV3200ResponseData}
     * @memberof GetAktualniPredmetStudentiAnonZapsaniV3200Response
     */
    'data'?: GetAktualniPredmetStudentiAnonZapsaniV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetStudentiAnonZapsaniV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetStudentiAnonZapsaniV3200Response
     */
    'rows_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetStudentiAnonZapsaniV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetStudentiAnonZapsaniV3200ResponseData
 */
export interface GetAktualniPredmetStudentiAnonZapsaniV3200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetStudentiAnonZapsaniV3200ResponseDataStudentiInner>}
     * @memberof GetAktualniPredmetStudentiAnonZapsaniV3200ResponseData
     */
    'studenti'?: Array<GetAktualniPredmetStudentiAnonZapsaniV3200ResponseDataStudentiInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetStudentiAnonZapsaniV3200ResponseDataStudentiInner
 */
export interface GetAktualniPredmetStudentiAnonZapsaniV3200ResponseDataStudentiInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetStudentiAnonZapsaniV3200ResponseDataStudentiInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetStudentiAnonZapsaniV3200ResponseDataStudentiInner
     */
    'povinnost'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetStudentiAnonZapsaniV3200ResponseDataStudentiInner
     */
    'povinnost_student'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetStudentiAnonZapsaniV3200ResponseDataStudentiInner
     */
    'student_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetStudentiAnonZapsaniV3200ResponseDataStudentiInner
     */
    'studium_id'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetStudentiZapsaniV3200Response
 */
export interface GetAktualniPredmetStudentiZapsaniV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetStudentiZapsaniV3200ResponseData}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200Response
     */
    'data'?: GetAktualniPredmetStudentiZapsaniV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200Response
     */
    'rows_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetStudentiZapsaniV3200ResponseData
 */
export interface GetAktualniPredmetStudentiZapsaniV3200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner>}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200ResponseData
     */
    'studenti'?: Array<GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner
 */
export interface GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner
     */
    'absolvoval'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner
     */
    'aktivni_studium'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner
     */
    'bodove_hodnoceni'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner
     */
    'con_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner
     */
    'datum_zapisu_vysledku'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner
     */
    'datum_zapoctu'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner
     */
    'el_index_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner
     */
    'klasifikoval_label_pr'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner
     */
    'klasifikoval_per_id'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner
     */
    'label_pr'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner
     */
    'login'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner
     */
    'pocet_pokusu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner
     */
    'povinnost'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner
     */
    'povinnost_student'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner
     */
    'student_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner
     */
    'studium_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner
     */
    'zapocet_udelil_label_pr'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner
     */
    'zapocet_udelil_per_id'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner
     */
    'zapocteno'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner
     */
    'znamka'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetStudentiZapsaniV3200ResponseDataStudentiInner
     */
    'znamka_znak'?: string;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetTerminElIndexV3200Response
 */
export interface GetAktualniPredmetTerminElIndexV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetTerminElIndexV3200ResponseData}
     * @memberof GetAktualniPredmetTerminElIndexV3200Response
     */
    'data'?: GetAktualniPredmetTerminElIndexV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminElIndexV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminElIndexV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetTerminElIndexV3200ResponseData
 */
export interface GetAktualniPredmetTerminElIndexV3200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner>}
     * @memberof GetAktualniPredmetTerminElIndexV3200ResponseData
     */
    'studenti'?: Array<GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner
 */
export interface GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner
     */
    'aktivni_studium'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner
     */
    'aktualni_predmet_tym_id'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner
     */
    'cas_registrace'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner
     */
    'datum'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner
     */
    'el_index_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner
     */
    'evidence_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner
     */
    'evidoval_jmenovka'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner
     */
    'evidoval_per_id'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner
     */
    'login'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner
     */
    'otazky'?: Array<any>;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner
     */
    'per_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner
     */
    'per_sex'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner
     */
    'pocet_bodu'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner
     */
    'poradove_cislo'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner
     */
    'potvrzeni_registrace'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner
     */
    'poznamky'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner
     */
    'registrace_terminu_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner
     */
    'studium_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner
     */
    'zdroj_vzniku_zaznamu'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner
     */
    'znamka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminElIndexV3200ResponseDataStudentiInner
     */
    'zprava_studentovi'?: string;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetTerminMistnostiV3200Response
 */
export interface GetAktualniPredmetTerminMistnostiV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetTerminMistnostiV3200ResponseData}
     * @memberof GetAktualniPredmetTerminMistnostiV3200Response
     */
    'data'?: GetAktualniPredmetTerminMistnostiV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminMistnostiV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminMistnostiV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetTerminMistnostiV3200ResponseData
 */
export interface GetAktualniPredmetTerminMistnostiV3200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetTerminMistnostiV3200ResponseDataMistnostiInner>}
     * @memberof GetAktualniPredmetTerminMistnostiV3200ResponseData
     */
    'mistnosti'?: Array<GetAktualniPredmetTerminMistnostiV3200ResponseDataMistnostiInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetTerminMistnostiV3200ResponseDataMistnostiInner
 */
export interface GetAktualniPredmetTerminMistnostiV3200ResponseDataMistnostiInner {
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminMistnostiV3200ResponseDataMistnostiInner
     */
    'mistnost'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminMistnostiV3200ResponseDataMistnostiInner
     */
    'mistnost_id'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetTerminMistnostiV4200Response
 */
export interface GetAktualniPredmetTerminMistnostiV4200Response {
    /**
     * 
     * @type {GetAktualniPredmetTerminMistnostiV4200ResponseData}
     * @memberof GetAktualniPredmetTerminMistnostiV4200Response
     */
    'data'?: GetAktualniPredmetTerminMistnostiV4200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminMistnostiV4200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminMistnostiV4200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetTerminMistnostiV4200ResponseData
 */
export interface GetAktualniPredmetTerminMistnostiV4200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetTerminMistnostiV4200ResponseDataTerminyInner>}
     * @memberof GetAktualniPredmetTerminMistnostiV4200ResponseData
     */
    'terminy'?: Array<GetAktualniPredmetTerminMistnostiV4200ResponseDataTerminyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetTerminMistnostiV4200ResponseDataTerminyInner
 */
export interface GetAktualniPredmetTerminMistnostiV4200ResponseDataTerminyInner {
    /**
     * 
     * @type {Array<GetAktualniPredmetTerminMistnostiV4200ResponseDataTerminyInnerMistnostiInner>}
     * @memberof GetAktualniPredmetTerminMistnostiV4200ResponseDataTerminyInner
     */
    'mistnosti'?: Array<GetAktualniPredmetTerminMistnostiV4200ResponseDataTerminyInnerMistnostiInner>;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminMistnostiV4200ResponseDataTerminyInner
     */
    'termin_id'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetTerminMistnostiV4200ResponseDataTerminyInnerMistnostiInner
 */
export interface GetAktualniPredmetTerminMistnostiV4200ResponseDataTerminyInnerMistnostiInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminMistnostiV4200ResponseDataTerminyInnerMistnostiInner
     */
    'mistnost_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminMistnostiV4200ResponseDataTerminyInnerMistnostiInner
     */
    'mistnost_label'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminMistnostiV4200ResponseDataTerminyInnerMistnostiInner
     */
    'pb_barva'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetTerminStudentiV3200Response
 */
export interface GetAktualniPredmetTerminStudentiV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetTerminElIndexV3200ResponseData}
     * @memberof GetAktualniPredmetTerminStudentiV3200Response
     */
    'data'?: GetAktualniPredmetTerminElIndexV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminStudentiV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminStudentiV3200Response
     */
    'rows_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminStudentiV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetTerminV3200Response
 */
export interface GetAktualniPredmetTerminV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetTerminV3200ResponseData}
     * @memberof GetAktualniPredmetTerminV3200Response
     */
    'data'?: GetAktualniPredmetTerminV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetTerminV3200ResponseData
 */
export interface GetAktualniPredmetTerminV3200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetTerminV3200ResponseDataPredmetyInner>}
     * @memberof GetAktualniPredmetTerminV3200ResponseData
     */
    'predmety'?: Array<GetAktualniPredmetTerminV3200ResponseDataPredmetyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetTerminV3200ResponseDataPredmetyInner
 */
export interface GetAktualniPredmetTerminV3200ResponseDataPredmetyInner {
    /**
     * 
     * @type {Array<GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInner>}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInner
     */
    'aktualni_predmety'?: Array<GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInner
     */
    'predmet_id'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInner
 */
export interface GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {Array<GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner>}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'zkousky'?: Array<GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
 */
export interface GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'body_do_el_indexu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'body_do_zapoctu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'body_navic'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'centralni_planovani_terminu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'max_pocet_bodu'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'min_bodu_k_pripusteni'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'min_pocet_bodu'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'povinnost'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'poznamky'?: string;
    /**
     * 
     * @type {Array<GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner>}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'terminy'?: Array<GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'typ_atributu'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'typ_atributu_popis'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'typ_atributu_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zkouska_projekt_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zkouska_projekt_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zkouska_projekt_poradove_cislo'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zprava'?: string;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
 */
export interface GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner {
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'akt_el_hodnoceni'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'el_hodnoceni_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'jen_se_zapoctem'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'konec_zkousky'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'lze_zapisovat'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'max_el_hodnoceni'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'max_pocet_studentu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'min_pocet_studentu'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'mistnost'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'multi_termin'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'pocet_otazek'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'pocet_studentu'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'poradi_pokusu'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'prihlasovat_automaticky'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'termin_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'termin_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'termin_poradove_cislo'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'termin_poznamky'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'typ_el_hodnoceni_id'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'typ_el_hodnoceni_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'typ_el_hodnoceni_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'viditelny_vsem'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'zacatek_zkousky'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'zapis_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'zapis_od'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'zkousejici_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'zkousejici_jmenovka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'zkouska_termin_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerTerminyInner
     */
    'zrusit_do'?: string;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetTerminyZkousekV3200Response
 */
export interface GetAktualniPredmetTerminyZkousekV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetTerminyZkousekV3200ResponseData}
     * @memberof GetAktualniPredmetTerminyZkousekV3200Response
     */
    'data'?: GetAktualniPredmetTerminyZkousekV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminyZkousekV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetTerminyZkousekV3200ResponseData
 */
export interface GetAktualniPredmetTerminyZkousekV3200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInner>}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseData
     */
    'terminy'?: Array<GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInner
 */
export interface GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInner {
    /**
     * 
     * @type {Array<GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInnerAktualniPredmetyInner>}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInner
     */
    'aktualni_predmety'?: Array<GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInnerAktualniPredmetyInner>;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInner
     */
    'konec_zkousky'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInner
     */
    'mistnost_id'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInner
     */
    'mistnost_label'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInner
     */
    'nazev'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInner
     */
    'pb_barva'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInner
     */
    'popis_zkousky'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInner
     */
    'poznamky'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInner
     */
    'termin_id'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInner
     */
    'typ_el_hodnoceni_id'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInner
     */
    'typ_el_hodnoceni_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInner
     */
    'typ_el_hodnoceni_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInner
     */
    'viditelny_vsem'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInner
     */
    'zacatek_zkousky'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInner
     */
    'zkousejici_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInner
     */
    'zkousejici_label_pr'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInner
     */
    'zp_typ_hodnoceni_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInner
     */
    'zp_typ_hodnoceni_popis'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInner
     */
    'zp_typ_hodnoceni_zkratka'?: string;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInnerAktualniPredmetyInner
 */
export interface GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInnerAktualniPredmetyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInnerAktualniPredmetyInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInnerAktualniPredmetyInner
     */
    'ap_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInnerAktualniPredmetyInner
     */
    'ap_rok'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInnerAktualniPredmetyInner
     */
    'ap_typ_semestru_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInnerAktualniPredmetyInner
     */
    'ap_typ_semestru_zkratka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInnerAktualniPredmetyInner
     */
    'p_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInnerAktualniPredmetyInner
     */
    'predmet_id'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetTerminyZkousekV4200Response
 */
export interface GetAktualniPredmetTerminyZkousekV4200Response {
    /**
     * 
     * @type {GetAktualniPredmetTerminyZkousekV4200ResponseData}
     * @memberof GetAktualniPredmetTerminyZkousekV4200Response
     */
    'data'?: GetAktualniPredmetTerminyZkousekV4200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV4200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminyZkousekV4200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetTerminyZkousekV4200ResponseData
 */
export interface GetAktualniPredmetTerminyZkousekV4200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetTerminyZkousekV4200ResponseDataTerminyInner>}
     * @memberof GetAktualniPredmetTerminyZkousekV4200ResponseData
     */
    'terminy'?: Array<GetAktualniPredmetTerminyZkousekV4200ResponseDataTerminyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetTerminyZkousekV4200ResponseDataTerminyInner
 */
export interface GetAktualniPredmetTerminyZkousekV4200ResponseDataTerminyInner {
    /**
     * 
     * @type {Array<GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInnerAktualniPredmetyInner>}
     * @memberof GetAktualniPredmetTerminyZkousekV4200ResponseDataTerminyInner
     */
    'aktualni_predmety'?: Array<GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInnerAktualniPredmetyInner>;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV4200ResponseDataTerminyInner
     */
    'konec_zkousky'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV4200ResponseDataTerminyInner
     */
    'nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV4200ResponseDataTerminyInner
     */
    'popis_zkousky'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV4200ResponseDataTerminyInner
     */
    'poznamky'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminyZkousekV4200ResponseDataTerminyInner
     */
    'termin_id'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetTerminyZkousekV4200ResponseDataTerminyInner
     */
    'typ_el_hodnoceni_id'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV4200ResponseDataTerminyInner
     */
    'typ_el_hodnoceni_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV4200ResponseDataTerminyInner
     */
    'typ_el_hodnoceni_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminyZkousekV4200ResponseDataTerminyInner
     */
    'viditelny_vsem'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV4200ResponseDataTerminyInner
     */
    'zacatek_zkousky'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminyZkousekV4200ResponseDataTerminyInner
     */
    'zkousejici_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV4200ResponseDataTerminyInner
     */
    'zkousejici_label_pr'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetTerminyZkousekV4200ResponseDataTerminyInner
     */
    'zp_typ_hodnoceni_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV4200ResponseDataTerminyInner
     */
    'zp_typ_hodnoceni_popis'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetTerminyZkousekV4200ResponseDataTerminyInner
     */
    'zp_typ_hodnoceni_zkratka'?: string;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetUvazkyV3200Response
 */
export interface GetAktualniPredmetUvazkyV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetUvazkyV3200ResponseData}
     * @memberof GetAktualniPredmetUvazkyV3200Response
     */
    'data'?: GetAktualniPredmetUvazkyV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetUvazkyV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetUvazkyV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetUvazkyV3200ResponseData
 */
export interface GetAktualniPredmetUvazkyV3200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner>}
     * @memberof GetAktualniPredmetUvazkyV3200ResponseData
     */
    'aktualni_predmety'?: Array<GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner
 */
export interface GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner
     */
    'aktualni_predmet_uvazek_druh_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner
     */
    'aktualni_predmet_uvazek_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner
     */
    'druh_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner
     */
    'druh_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner
     */
    'konstanta_b1'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner
     */
    'konstanta_b2'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner
     */
    'konstanta_bt'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner
     */
    'konstanta_bu'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner
     */
    'konstanta_bv'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner
     */
    'konstanta_k1'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner
     */
    'konstanta_k2'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner
     */
    'label_pr'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner
     */
    'per_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner
     */
    'pocet_hodin'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner
     */
    'pocet_skupin'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner
     */
    'pocet_studentu'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner
     */
    'poznamka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner
     */
    'typ_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner
     */
    'typ_uvazku_pro_zap_hodiny_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner
     */
    'typ_zkratka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner
     */
    'upd_ts'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner
     */
    'zh'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetUvazkyV3200ResponseDataAktualniPredmetyInner
     */
    'zkontrolovano'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetUzitiV3200Response
 */
export interface GetAktualniPredmetUzitiV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetUzitiV3200ResponseData}
     * @memberof GetAktualniPredmetUzitiV3200Response
     */
    'data'?: GetAktualniPredmetUzitiV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetUzitiV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetUzitiV3200Response
     */
    'rows_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetUzitiV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetUzitiV3200ResponseData
 */
export interface GetAktualniPredmetUzitiV3200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetUzitiV3200ResponseDataPredmetyInner>}
     * @memberof GetAktualniPredmetUzitiV3200ResponseData
     */
    'predmety'?: Array<GetAktualniPredmetUzitiV3200ResponseDataPredmetyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetUzitiV3200ResponseDataPredmetyInner
 */
export interface GetAktualniPredmetUzitiV3200ResponseDataPredmetyInner {
    /**
     * 
     * @type {Array<GetAktualniPredmetUzitiV3200ResponseDataPredmetyInnerAktualniPredmetyInner>}
     * @memberof GetAktualniPredmetUzitiV3200ResponseDataPredmetyInner
     */
    'aktualni_predmety'?: Array<GetAktualniPredmetUzitiV3200ResponseDataPredmetyInnerAktualniPredmetyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetUzitiV3200ResponseDataPredmetyInner
     */
    'predmet_id'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetUzitiV3200ResponseDataPredmetyInnerAktualniPredmetyInner
 */
export interface GetAktualniPredmetUzitiV3200ResponseDataPredmetyInnerAktualniPredmetyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetUzitiV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetUzitiV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'ap_povinnost'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetUzitiV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'ap_povinnost_k_sp'?: string;
    /**
     * 
     * @type {Array<GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPvSkupinyInner>}
     * @memberof GetAktualniPredmetUzitiV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'plan'?: Array<GetAktualniPredmetPvskupinyV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPvSkupinyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetUzitiV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'rok'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetV3200Response
 */
export interface GetAktualniPredmetV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetV3200ResponseData}
     * @memberof GetAktualniPredmetV3200Response
     */
    'data'?: GetAktualniPredmetV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetV3200ResponseData
 */
export interface GetAktualniPredmetV3200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetV3200ResponseDataPredmetyInner>}
     * @memberof GetAktualniPredmetV3200ResponseData
     */
    'predmety'?: Array<GetAktualniPredmetV3200ResponseDataPredmetyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetV3200ResponseDataPredmetyInner
 */
export interface GetAktualniPredmetV3200ResponseDataPredmetyInner {
    /**
     * 
     * @type {Array<GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner>}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInner
     */
    'aktualni_predmety'?: Array<GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInner
     */
    'fakulta_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInner
     */
    'fakulta_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInner
     */
    'fakulta_zkratka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInner
     */
    'jazyk_vyuky'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInner
     */
    'jazyk_vyuky_2'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInner
     */
    'predmet_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInner
     */
    'update_predmet'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInner
     */
    'zkratka'?: string;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner
 */
export interface GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'aktualni_pocet_studentu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'forma_zkousky_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'forma_zkousky_popis'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'forma_zkousky_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'kredity'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'max_pocet_studentu'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'nazev'?: string;
    /**
     * 
     * @type {Array<GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPravidlaKlasifikaceInner>}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'pravidla_klasifikace'?: Array<GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPravidlaKlasifikaceInner>;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'rok'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'spravujici_ustav_id'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'spravujici_ustav_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'spravujici_ustav_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'typ_semestru_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'typ_semestru_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'typ_ukonceni_predmetu_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'update_ap_predmet'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'ustav_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'ustav_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'ustav_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'ustav_zkratka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'zkratka_ukonceni_predmetu'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'zobrazit_www'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPravidlaKlasifikaceInner
 */
export interface GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPravidlaKlasifikaceInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPravidlaKlasifikaceInner
     */
    'bodova_skladba_predmetu_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPravidlaKlasifikaceInner
     */
    'pocet_bodu'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPravidlaKlasifikaceInner
     */
    'popis_typu_bodove_skladby'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPravidlaKlasifikaceInner
     */
    'typ_hodnoceni_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetV3200ResponseDataPredmetyInnerAktualniPredmetyInnerPravidlaKlasifikaceInner
     */
    'zkratka_typu_bodove_skladby'?: string;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetVyucovaniStudentiV3200Response
 */
export interface GetAktualniPredmetVyucovaniStudentiV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetVyucovaniStudentiV3200ResponseData}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200Response
     */
    'data'?: GetAktualniPredmetVyucovaniStudentiV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetVyucovaniStudentiV3200ResponseData
 */
export interface GetAktualniPredmetVyucovaniStudentiV3200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInner>}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseData
     */
    'predmety'?: Array<GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInner
 */
export interface GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInner {
    /**
     * 
     * @type {Array<GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInner>}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInner
     */
    'aktualni_predmety'?: Array<GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInner
     */
    'predmet_id'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInner
 */
export interface GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {Array<GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInner>}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'vyucovani'?: Array<GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInner
 */
export interface GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInner {
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInner
     */
    'cas_od'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInner
     */
    'den_zkratka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInner
     */
    'rj_popis'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInner
     */
    'rj_zkratka'?: string;
    /**
     * 
     * @type {Array<GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInnerStudentiInner>}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInner
     */
    'studenti'?: Array<GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInnerStudentiInner>;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInner
     */
    'typ_tydne'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInner
     */
    'vyucovani_id'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInnerStudentiInner
 */
export interface GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInnerStudentiInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInnerStudentiInner
     */
    'absolvoval'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInnerStudentiInner
     */
    'aktivni_studium'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInnerStudentiInner
     */
    'bodove_hodnoceni'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInnerStudentiInner
     */
    'el_index_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInnerStudentiInner
     */
    'family_names'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInnerStudentiInner
     */
    'first_names'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInnerStudentiInner
     */
    'login'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInnerStudentiInner
     */
    'per_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInnerStudentiInner
     */
    'per_sex'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInnerStudentiInner
     */
    'registrace_vyucovani_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInnerStudentiInner
     */
    'souhlas_studenta'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInnerStudentiInner
     */
    'studium_id'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInnerStudentiInner
     */
    'zapocteno'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInnerStudentiInner
     */
    'zdroj_vzniku_zaznamu'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInnerStudentiInner
     */
    'znamka'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInnerStudentiInner
     */
    'znamka_znak'?: string;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetVyucovaniV3200Response
 */
export interface GetAktualniPredmetVyucovaniV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetVyucovaniV3200ResponseData}
     * @memberof GetAktualniPredmetVyucovaniV3200Response
     */
    'data'?: GetAktualniPredmetVyucovaniV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniV3200Response
     */
    'rows_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetVyucovaniV3200ResponseData
 */
export interface GetAktualniPredmetVyucovaniV3200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner>}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseData
     */
    'vyucovani'?: Array<GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
 */
export interface GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner {
    /**
     * 
     * @type {Array<GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInnerBlokyInner>}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'bloky'?: Array<GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInnerBlokyInner>;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'p_fakulta_acronym'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'p_fakulta_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'smi_videt'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'typ_semestru_zkratka_x_cz'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'v_aktualni_predmet_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'v_ap_garant_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'v_ap_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'v_ap_rok'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'v_doplnujici_text'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'v_garant_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'v_max_pocet_studentu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'v_p_barva'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'v_p_html_barva'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'v_p_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'v_pocet_studentu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'v_povolit_registraci'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'v_trj_barva'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'v_trj_html_barva'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'v_trj_popis'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'v_trj_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'v_typ_rozvrhove_jednotky_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'v_typ_semestru_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'v_vyucovani_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner
     */
    'v_webinar_link'?: string;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInnerBlokyInner
 */
export interface GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInnerBlokyInner {
    /**
     * 
     * @type {Array<GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInnerBlokyInnerDnyInner>}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInnerBlokyInner
     */
    'dny'?: Array<GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInnerBlokyInnerDnyInner>;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInnerBlokyInner
     */
    'vb_cas_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInnerBlokyInner
     */
    'vb_cas_od'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInnerBlokyInner
     */
    'vb_celosemestralni'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInnerBlokyInner
     */
    'vb_datum_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInnerBlokyInner
     */
    'vb_datum_od'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInnerBlokyInner
     */
    'vb_den_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInnerBlokyInner
     */
    'vb_typ_tydne_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInnerBlokyInner
     */
    'vb_vyucovani_blok_id'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInnerBlokyInnerDnyInner
 */
export interface GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInnerBlokyInnerDnyInner {
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInnerBlokyInnerDnyInner
     */
    'vbd_datum'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInnerBlokyInnerDnyInner
     */
    'vbd_vyuc_blok_den_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInnerBlokyInnerDnyInner
     */
    'vbd_zruseno'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetVyucujiciTypV3200Response
 */
export interface GetAktualniPredmetVyucujiciTypV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetVyucujiciTypV3200ResponseData}
     * @memberof GetAktualniPredmetVyucujiciTypV3200Response
     */
    'data'?: GetAktualniPredmetVyucujiciTypV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucujiciTypV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucujiciTypV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetVyucujiciTypV3200ResponseData
 */
export interface GetAktualniPredmetVyucujiciTypV3200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInner>}
     * @memberof GetAktualniPredmetVyucujiciTypV3200ResponseData
     */
    'predmety'?: Array<GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInner
 */
export interface GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInner {
    /**
     * 
     * @type {Array<GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInner>}
     * @memberof GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInner
     */
    'aktualni_predmety'?: Array<GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInner
     */
    'predmet_id'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInner
 */
export interface GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {Array<GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucujiciInner>}
     * @memberof GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'vyucujici'?: Array<GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucujiciInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucujiciInner
 */
export interface GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucujiciInner {
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucujiciInner
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucujiciInner
     */
    'label_pr'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucujiciInner
     */
    'org_abbrv'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucujiciInner
     */
    'org_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucujiciInner
     */
    'org_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucujiciInner
     */
    'orgunit_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucujiciInner
     */
    'per_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucujiciInner
     */
    'per_uri'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucujiciInner
     */
    'typ_gar_vyuc'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucujiciInner
     */
    'typ_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucujiciInner
     */
    'typ_org_vztahu_osoby'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucujiciInner
     */
    'typ_registrace_vyucovani_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucujiciInner
     */
    'typ_registrace_vyucovani_popis'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucujiciInner
     */
    'typ_registrace_vyucovani_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucujiciInner
     */
    'typ_rozvrhove_jednotky_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucujiciInner
     */
    'typ_rozvrhove_jednotky_popis'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetVyucujiciTypV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucujiciInner
     */
    'typ_rozvrhove_jednotky_zkratka'?: string;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetZadaniStudentiV3200Response
 */
export interface GetAktualniPredmetZadaniStudentiV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetZadaniStudentiV3200ResponseData}
     * @memberof GetAktualniPredmetZadaniStudentiV3200Response
     */
    'data'?: GetAktualniPredmetZadaniStudentiV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniStudentiV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniStudentiV3200Response
     */
    'rows_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniStudentiV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetZadaniStudentiV3200ResponseData
 */
export interface GetAktualniPredmetZadaniStudentiV3200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner>}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseData
     */
    'studenti'?: Array<GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner
 */
export interface GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner
     */
    'aktivni_studium'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner
     */
    'aktualni_predmet_tym_id'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner
     */
    'datum'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner
     */
    'el_index_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner
     */
    'evidence_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner
     */
    'evidoval_jmenovka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner
     */
    'evidoval_per_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner
     */
    'family_names'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner
     */
    'first_names'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner
     */
    'login'?: string;
    /**
     * 
     * @type {Array<GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInnerOtazkyInner>}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner
     */
    'otazky'?: Array<GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInnerOtazkyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner
     */
    'per_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner
     */
    'per_sex'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner
     */
    'pocet_bodu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner
     */
    'poradove_cislo'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner
     */
    'potvrzeni_registrace'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner
     */
    'poznamky'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner
     */
    'registrace_upd_ts'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner
     */
    'registrace_zadani_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner
     */
    'studium_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner
     */
    'znamka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner
     */
    'zprava_studentovi'?: string;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInnerOtazkyInner
 */
export interface GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInnerOtazkyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInnerOtazkyInner
     */
    'otazka_pocet_bodu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInnerOtazkyInner
     */
    'otazka_poradi'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetZadaniV31200Response
 */
export interface GetAktualniPredmetZadaniV31200Response {
    /**
     * 
     * @type {GetAktualniPredmetZadaniV31200ResponseData}
     * @memberof GetAktualniPredmetZadaniV31200Response
     */
    'data'?: GetAktualniPredmetZadaniV31200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV31200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV31200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetZadaniV31200ResponseData
 */
export interface GetAktualniPredmetZadaniV31200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetZadaniV31200ResponseDataPredmetyInner>}
     * @memberof GetAktualniPredmetZadaniV31200ResponseData
     */
    'predmety'?: Array<GetAktualniPredmetZadaniV31200ResponseDataPredmetyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetZadaniV31200ResponseDataPredmetyInner
 */
export interface GetAktualniPredmetZadaniV31200ResponseDataPredmetyInner {
    /**
     * 
     * @type {Array<GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInner>}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInner
     */
    'aktualni_predmety'?: Array<GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInner
     */
    'predmet_id'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInner
 */
export interface GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {Array<GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner>}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'zkousky'?: Array<GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
 */
export interface GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'body_do_el_indexu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'body_do_zapoctu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'body_navic'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'centralni_planovani_terminu'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'datum_odevzdani'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'max_pocet_bodu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'max_pocet_souboru'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'max_velikost_souboru'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'min_bodu_k_pripusteni'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'min_pocet_bodu'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'povinnost'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'poznamky'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'soubory_aktualizace_po_terminu'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'soubory_reg_vyraz'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'soubory_verejne'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'typ_atributu'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'typ_atributu_popis'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'typ_atributu_zkratka'?: string;
    /**
     * 
     * @type {Array<GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner>}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zadani'?: Array<GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner>;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zapis_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zapis_od'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zkouska_projekt_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zkouska_projekt_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zkouska_projekt_poradove_cislo'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zprava'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zrusit_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zverejneni_zadani_od'?: string;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
 */
export interface GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner {
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'literatura'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'max_pocet_resitelu'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'max_pocet_tymu'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'min_pocet_tymu'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'pocet_otazek'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'pocet_resitelu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'pocet_tymu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'prihlasovat_automaticky'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'url'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'vedouci_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'vedouci_jmenovka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'zadani_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'zadani_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'zadani_poradove_cislo'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'zadani_poznamky'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV31200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'zadani_text'?: string;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetZadaniV3200Response
 */
export interface GetAktualniPredmetZadaniV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetZadaniV3200ResponseData}
     * @memberof GetAktualniPredmetZadaniV3200Response
     */
    'data'?: GetAktualniPredmetZadaniV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV3200Response
     */
    'rows_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetZadaniV3200ResponseData
 */
export interface GetAktualniPredmetZadaniV3200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetZadaniV3200ResponseDataPredmetyInner>}
     * @memberof GetAktualniPredmetZadaniV3200ResponseData
     */
    'predmety'?: Array<GetAktualniPredmetZadaniV3200ResponseDataPredmetyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetZadaniV3200ResponseDataPredmetyInner
 */
export interface GetAktualniPredmetZadaniV3200ResponseDataPredmetyInner {
    /**
     * 
     * @type {Array<GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInner>}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInner
     */
    'aktualni_predmety'?: Array<GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInner
     */
    'predmet_id'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInner
 */
export interface GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {Array<GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner>}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'zkousky'?: Array<GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
 */
export interface GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'body_do_el_indexu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'body_do_zapoctu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'body_navic'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'centralni_planovani_terminu'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'datum_odevzdani'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'max_pocet_bodu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'max_pocet_souboru'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'max_velikost_souboru'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'min_bodu_k_pripusteni'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'min_pocet_bodu'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'povinnost'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'poznamky'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'soubory_aktualizace_po_terminu'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'soubory_reg_vyraz'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'soubory_verejne'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'typ_atributu'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'typ_atributu_popis'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'typ_atributu_zkratka'?: string;
    /**
     * 
     * @type {Array<GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner>}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zadani'?: Array<GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner>;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zapis_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zapis_od'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zkouska_projekt_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zkouska_projekt_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zkouska_projekt_poradove_cislo'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zprava'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zrusit_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zverejneni_zadani_od'?: string;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
 */
export interface GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner {
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'literatura'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'max_pocet_resitelu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'pocet_otazek'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'pocet_resitelu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'prihlasovat_automaticky'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'url'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'vedouci_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'vedouci_jmenovka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'zadani_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'zadani_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'zadani_poradove_cislo'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'zadani_poznamky'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
     */
    'zadani_text'?: string;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetZkouskaProjektV3200Response
 */
export interface GetAktualniPredmetZkouskaProjektV3200Response {
    /**
     * 
     * @type {GetAktualniPredmetZkouskaProjektV3200ResponseData}
     * @memberof GetAktualniPredmetZkouskaProjektV3200Response
     */
    'data'?: GetAktualniPredmetZkouskaProjektV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZkouskaProjektV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZkouskaProjektV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetZkouskaProjektV3200ResponseData
 */
export interface GetAktualniPredmetZkouskaProjektV3200ResponseData {
    /**
     * 
     * @type {Array<GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInner>}
     * @memberof GetAktualniPredmetZkouskaProjektV3200ResponseData
     */
    'predmety'?: Array<GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInner
 */
export interface GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInner {
    /**
     * 
     * @type {Array<GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInner>}
     * @memberof GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInner
     */
    'aktualni_predmety'?: Array<GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInner
     */
    'predmet_id'?: number;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInner
 */
export interface GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {Array<GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner>}
     * @memberof GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInner
     */
    'zkousky'?: Array<GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner>;
}
/**
 * 
 * @export
 * @interface GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
 */
export interface GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner {
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'body_do_el_indexu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'body_do_zapoctu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'body_navic'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'centralni_planovani_terminu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'max_pocet_bodu'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'min_bodu_k_pripusteni'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'min_pocet_bodu'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'povinnost'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'poznamky'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'typ_atributu'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'typ_atributu_popis'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'typ_atributu_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zkouska_projekt_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zkouska_projekt_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zkouska_projekt_poradove_cislo'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktualniPredmetZkouskaProjektV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner
     */
    'zprava'?: string;
}
/**
 * 
 * @export
 * @interface GetCasovyPlanRokV3200Response
 */
export interface GetCasovyPlanRokV3200Response {
    /**
     * 
     * @type {GetCasovyPlanRokV3200ResponseData}
     * @memberof GetCasovyPlanRokV3200Response
     */
    'data'?: GetCasovyPlanRokV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetCasovyPlanRokV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCasovyPlanRokV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetCasovyPlanRokV3200ResponseData
 */
export interface GetCasovyPlanRokV3200ResponseData {
    /**
     * 
     * @type {Array<GetCasovyPlanRokV3200ResponseDataSemestryInner>}
     * @memberof GetCasovyPlanRokV3200ResponseData
     */
    'semestry'?: Array<GetCasovyPlanRokV3200ResponseDataSemestryInner>;
}
/**
 * 
 * @export
 * @interface GetCasovyPlanRokV3200ResponseDataSemestryInner
 */
export interface GetCasovyPlanRokV3200ResponseDataSemestryInner {
    /**
     * 
     * @type {number}
     * @memberof GetCasovyPlanRokV3200ResponseDataSemestryInner
     */
    'rok_platnosti'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetCasovyPlanRokV3200ResponseDataSemestryInner
     */
    'tyden_semestru_semestr_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetCasovyPlanRokV3200ResponseDataSemestryInner
     */
    'typ_semestru_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCasovyPlanRokV3200ResponseDataSemestryInner
     */
    'vyuka_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCasovyPlanRokV3200ResponseDataSemestryInner
     */
    'vyuka_od'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCasovyPlanRokV3200ResponseDataSemestryInner
     */
    'zkouskove_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCasovyPlanRokV3200ResponseDataSemestryInner
     */
    'zkouskove_od'?: string;
}
/**
 * 
 * @export
 * @interface GetKalendarStatniSvatkyV1200Response
 */
export interface GetKalendarStatniSvatkyV1200Response {
    /**
     * 
     * @type {GetKalendarStatniSvatkyV1200ResponseData}
     * @memberof GetKalendarStatniSvatkyV1200Response
     */
    'data'?: GetKalendarStatniSvatkyV1200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetKalendarStatniSvatkyV1200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetKalendarStatniSvatkyV1200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetKalendarStatniSvatkyV1200ResponseData
 */
export interface GetKalendarStatniSvatkyV1200ResponseData {
    /**
     * 
     * @type {Array<GetKalendarStatniSvatkyV1200ResponseDataSvatkyInner>}
     * @memberof GetKalendarStatniSvatkyV1200ResponseData
     */
    'svatky'?: Array<GetKalendarStatniSvatkyV1200ResponseDataSvatkyInner>;
}
/**
 * 
 * @export
 * @interface GetKalendarStatniSvatkyV1200ResponseDataSvatkyInner
 */
export interface GetKalendarStatniSvatkyV1200ResponseDataSvatkyInner {
    /**
     * 
     * @type {string}
     * @memberof GetKalendarStatniSvatkyV1200ResponseDataSvatkyInner
     */
    'datum'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetKalendarStatniSvatkyV1200ResponseDataSvatkyInner
     */
    'nazev'?: string;
}
/**
 * 
 * @export
 * @interface GetMapaArealuV3200Response
 */
export interface GetMapaArealuV3200Response {
    /**
     * 
     * @type {GetMapaArealuV3200ResponseData}
     * @memberof GetMapaArealuV3200Response
     */
    'data'?: GetMapaArealuV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetMapaArealuV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMapaArealuV3200Response
     */
    'rows_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMapaArealuV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetMapaArealuV3200ResponseData
 */
export interface GetMapaArealuV3200ResponseData {
    /**
     * 
     * @type {Array<GetMapaArealuV3200ResponseDataPatraInner>}
     * @memberof GetMapaArealuV3200ResponseData
     */
    'patra'?: Array<GetMapaArealuV3200ResponseDataPatraInner>;
}
/**
 * 
 * @export
 * @interface GetMapaArealuV3200ResponseDataPatraInner
 */
export interface GetMapaArealuV3200ResponseDataPatraInner {
    /**
     * 
     * @type {Array<GetMapaArealuV3200ResponseDataPatraInnerBudovyInner>}
     * @memberof GetMapaArealuV3200ResponseDataPatraInner
     */
    'budovy'?: Array<GetMapaArealuV3200ResponseDataPatraInnerBudovyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetMapaArealuV3200ResponseDataPatraInner
     */
    'patro'?: number;
}
/**
 * 
 * @export
 * @interface GetMapaArealuV3200ResponseDataPatraInnerBudovyInner
 */
export interface GetMapaArealuV3200ResponseDataPatraInnerBudovyInner {
    /**
     * 
     * @type {string}
     * @memberof GetMapaArealuV3200ResponseDataPatraInnerBudovyInner
     */
    'budova'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMapaArealuV3200ResponseDataPatraInnerBudovyInner
     */
    'budova_id'?: number;
    /**
     * 
     * @type {Array<GetMapaArealuV3200ResponseDataPatraInnerBudovyInnerMistnostiInner>}
     * @memberof GetMapaArealuV3200ResponseDataPatraInnerBudovyInner
     */
    'mistnosti'?: Array<GetMapaArealuV3200ResponseDataPatraInnerBudovyInnerMistnostiInner>;
}
/**
 * 
 * @export
 * @interface GetMapaArealuV3200ResponseDataPatraInnerBudovyInnerMistnostiInner
 */
export interface GetMapaArealuV3200ResponseDataPatraInnerBudovyInnerMistnostiInner {
    /**
     * 
     * @type {number}
     * @memberof GetMapaArealuV3200ResponseDataPatraInnerBudovyInnerMistnostiInner
     */
    'mistnost_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMapaArealuV3200ResponseDataPatraInnerBudovyInnerMistnostiInner
     */
    'mistnost_planek_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMapaArealuV3200ResponseDataPatraInnerBudovyInnerMistnostiInner
     */
    'mistnost_typ_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMapaArealuV3200ResponseDataPatraInnerBudovyInnerMistnostiInner
     */
    'mistnost_typ_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMapaArealuV3200ResponseDataPatraInnerBudovyInnerMistnostiInner
     */
    'nazev'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof GetMapaArealuV3200ResponseDataPatraInnerBudovyInnerMistnostiInner
     */
    'osoby'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof GetMapaArealuV3200ResponseDataPatraInnerBudovyInnerMistnostiInner
     */
    'svg_dvere'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMapaArealuV3200ResponseDataPatraInnerBudovyInnerMistnostiInner
     */
    'svg_souradnice'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMapaArealuV3200ResponseDataPatraInnerBudovyInnerMistnostiInner
     */
    'svg_tvar'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMapaArealuV3200ResponseDataPatraInnerBudovyInnerMistnostiInner
     */
    'typ_objektu'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMapaArealuV3200ResponseDataPatraInnerBudovyInnerMistnostiInner
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMapaArealuV3200ResponseDataPatraInnerBudovyInnerMistnostiInner
     */
    'zkratka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMapaArealuV3200ResponseDataPatraInnerBudovyInnerMistnostiInner
     */
    'zkratka_plan'?: string;
}
/**
 * 
 * @export
 * @interface GetMistnostRezervaceV3200Response
 */
export interface GetMistnostRezervaceV3200Response {
    /**
     * 
     * @type {GetMistnostRezervaceV3200ResponseData}
     * @memberof GetMistnostRezervaceV3200Response
     */
    'data'?: GetMistnostRezervaceV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostRezervaceV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostRezervaceV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetMistnostRezervaceV3200ResponseData
 */
export interface GetMistnostRezervaceV3200ResponseData {
    /**
     * 
     * @type {Array<GetMistnostRezervaceV3200ResponseDataRezervaceInner>}
     * @memberof GetMistnostRezervaceV3200ResponseData
     */
    'rezervace'?: Array<GetMistnostRezervaceV3200ResponseDataRezervaceInner>;
}
/**
 * 
 * @export
 * @interface GetMistnostRezervaceV3200ResponseDataRezervaceInner
 */
export interface GetMistnostRezervaceV3200ResponseDataRezervaceInner {
    /**
     * 
     * @type {string}
     * @memberof GetMistnostRezervaceV3200ResponseDataRezervaceInner
     */
    'cas_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostRezervaceV3200ResponseDataRezervaceInner
     */
    'cas_do_real'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostRezervaceV3200ResponseDataRezervaceInner
     */
    'cas_od'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostRezervaceV3200ResponseDataRezervaceInner
     */
    'datum'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostRezervaceV3200ResponseDataRezervaceInner
     */
    'mistnost_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostRezervaceV3200ResponseDataRezervaceInner
     */
    'mistnost_label'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostRezervaceV3200ResponseDataRezervaceInner
     */
    'misto_mistnost_label'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostRezervaceV3200ResponseDataRezervaceInner
     */
    'misto_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostRezervaceV3200ResponseDataRezervaceInner
     */
    'popis'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetMistnostRezervaceV3200ResponseDataRezervaceInner
     */
    'pracovni_misto_id'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostRezervaceV3200ResponseDataRezervaceInner
     */
    'rezervace_mistnosti_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostRezervaceV3200ResponseDataRezervaceInner
     */
    'verejna_kontaktni_osoba_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostRezervaceV3200ResponseDataRezervaceInner
     */
    'verejna_kontaktni_osoba_label_pr'?: string;
}
/**
 * 
 * @export
 * @interface GetMistnostTerminyV3200Response
 */
export interface GetMistnostTerminyV3200Response {
    /**
     * 
     * @type {GetMistnostTerminyV3200ResponseData}
     * @memberof GetMistnostTerminyV3200Response
     */
    'data'?: GetMistnostTerminyV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostTerminyV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostTerminyV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetMistnostTerminyV3200ResponseData
 */
export interface GetMistnostTerminyV3200ResponseData {
    /**
     * 
     * @type {Array<GetMistnostTerminyV3200ResponseDataTerminyInner>}
     * @memberof GetMistnostTerminyV3200ResponseData
     */
    'terminy'?: Array<GetMistnostTerminyV3200ResponseDataTerminyInner>;
}
/**
 * 
 * @export
 * @interface GetMistnostTerminyV3200ResponseDataTerminyInner
 */
export interface GetMistnostTerminyV3200ResponseDataTerminyInner {
    /**
     * 
     * @type {Array<GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInnerAktualniPredmetyInner>}
     * @memberof GetMistnostTerminyV3200ResponseDataTerminyInner
     */
    'aktualni_predmety'?: Array<GetAktualniPredmetTerminyZkousekV3200ResponseDataTerminyInnerAktualniPredmetyInner>;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostTerminyV3200ResponseDataTerminyInner
     */
    'konec_zkousky'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostTerminyV3200ResponseDataTerminyInner
     */
    'mistnost_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostTerminyV3200ResponseDataTerminyInner
     */
    'mistnost_label'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostTerminyV3200ResponseDataTerminyInner
     */
    'nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostTerminyV3200ResponseDataTerminyInner
     */
    'pb_barva'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostTerminyV3200ResponseDataTerminyInner
     */
    'popis_zkousky'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostTerminyV3200ResponseDataTerminyInner
     */
    'poznamky'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostTerminyV3200ResponseDataTerminyInner
     */
    'termin_id'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetMistnostTerminyV3200ResponseDataTerminyInner
     */
    'typ_el_hodnoceni_id'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostTerminyV3200ResponseDataTerminyInner
     */
    'typ_el_hodnoceni_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostTerminyV3200ResponseDataTerminyInner
     */
    'typ_el_hodnoceni_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostTerminyV3200ResponseDataTerminyInner
     */
    'viditelny_vsem'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostTerminyV3200ResponseDataTerminyInner
     */
    'zacatek_zkousky'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostTerminyV3200ResponseDataTerminyInner
     */
    'zkousejici_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostTerminyV3200ResponseDataTerminyInner
     */
    'zkousejici_label_pr'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostTerminyV3200ResponseDataTerminyInner
     */
    'zp_typ_hodnoceni_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostTerminyV3200ResponseDataTerminyInner
     */
    'zp_typ_hodnoceni_popis'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostTerminyV3200ResponseDataTerminyInner
     */
    'zp_typ_hodnoceni_zkratka'?: string;
}
/**
 * 
 * @export
 * @interface GetMistnostV3200Response
 */
export interface GetMistnostV3200Response {
    /**
     * 
     * @type {GetMistnostV3200ResponseData}
     * @memberof GetMistnostV3200Response
     */
    'data'?: GetMistnostV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetMistnostV3200ResponseData
 */
export interface GetMistnostV3200ResponseData {
    /**
     * 
     * @type {Array<GetMistnostV3200ResponseDataLokalityInner>}
     * @memberof GetMistnostV3200ResponseData
     */
    'lokality'?: Array<GetMistnostV3200ResponseDataLokalityInner>;
}
/**
 * 
 * @export
 * @interface GetMistnostV3200ResponseDataLokalityInner
 */
export interface GetMistnostV3200ResponseDataLokalityInner {
    /**
     * 
     * @type {Array<GetMistnostV3200ResponseDataLokalityInnerArealyInner>}
     * @memberof GetMistnostV3200ResponseDataLokalityInner
     */
    'arealy'?: Array<GetMistnostV3200ResponseDataLokalityInnerArealyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostV3200ResponseDataLokalityInner
     */
    'lokalita_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostV3200ResponseDataLokalityInner
     */
    'pl_aktualni'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostV3200ResponseDataLokalityInner
     */
    'pl_nazev'?: string;
}
/**
 * 
 * @export
 * @interface GetMistnostV3200ResponseDataLokalityInnerArealyInner
 */
export interface GetMistnostV3200ResponseDataLokalityInnerArealyInner {
    /**
     * 
     * @type {number}
     * @memberof GetMistnostV3200ResponseDataLokalityInnerArealyInner
     */
    'areal_id'?: number;
    /**
     * 
     * @type {Array<GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInner>}
     * @memberof GetMistnostV3200ResponseDataLokalityInnerArealyInner
     */
    'budovy'?: Array<GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostV3200ResponseDataLokalityInnerArealyInner
     */
    'pa_aktualni'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostV3200ResponseDataLokalityInnerArealyInner
     */
    'pa_nazev'?: string;
}
/**
 * 
 * @export
 * @interface GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInner
 */
export interface GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInner {
    /**
     * 
     * @type {number}
     * @memberof GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInner
     */
    'budova_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInner
     */
    'pb_aktualni'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInner
     */
    'pb_nazev'?: string;
    /**
     * 
     * @type {Array<GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInner>}
     * @memberof GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInner
     */
    'podlazi'?: Array<GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInner>;
}
/**
 * 
 * @export
 * @interface GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInner
 */
export interface GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInner {
    /**
     * 
     * @type {Array<GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInnerMistnostiInner>}
     * @memberof GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInner
     */
    'mistnosti'?: Array<GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInnerMistnostiInner>;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInner
     */
    'podlazi_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInner
     */
    'pp_aktualni'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInner
     */
    'pp_nazev'?: string;
}
/**
 * 
 * @export
 * @interface GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInnerMistnostiInner
 */
export interface GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInnerMistnostiInner {
    /**
     * 
     * @type {string}
     * @memberof GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInnerMistnostiInner
     */
    'klapka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInnerMistnostiInner
     */
    'label'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInnerMistnostiInner
     */
    'mistnost_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInnerMistnostiInner
     */
    'nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInnerMistnostiInner
     */
    'pocet_mist'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInnerMistnostiInner
     */
    'popis_web'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInnerMistnostiInner
     */
    'pro_vyuku'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostV3200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInnerMistnostiInner
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface GetMistnostiV4200Response
 */
export interface GetMistnostiV4200Response {
    /**
     * 
     * @type {GetMistnostiV4200ResponseData}
     * @memberof GetMistnostiV4200Response
     */
    'data'?: GetMistnostiV4200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostiV4200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostiV4200Response
     */
    'rows_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostiV4200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetMistnostiV4200ResponseData
 */
export interface GetMistnostiV4200ResponseData {
    /**
     * 
     * @type {Array<GetMistnostiV4200ResponseDataLokalityInner>}
     * @memberof GetMistnostiV4200ResponseData
     */
    'lokality'?: Array<GetMistnostiV4200ResponseDataLokalityInner>;
}
/**
 * 
 * @export
 * @interface GetMistnostiV4200ResponseDataLokalityInner
 */
export interface GetMistnostiV4200ResponseDataLokalityInner {
    /**
     * 
     * @type {Array<GetMistnostiV4200ResponseDataLokalityInnerArealyInner>}
     * @memberof GetMistnostiV4200ResponseDataLokalityInner
     */
    'arealy'?: Array<GetMistnostiV4200ResponseDataLokalityInnerArealyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostiV4200ResponseDataLokalityInner
     */
    'lokalita_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostiV4200ResponseDataLokalityInner
     */
    'pl_aktualni'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostiV4200ResponseDataLokalityInner
     */
    'pl_nazev'?: string;
}
/**
 * 
 * @export
 * @interface GetMistnostiV4200ResponseDataLokalityInnerArealyInner
 */
export interface GetMistnostiV4200ResponseDataLokalityInnerArealyInner {
    /**
     * 
     * @type {number}
     * @memberof GetMistnostiV4200ResponseDataLokalityInnerArealyInner
     */
    'areal_id'?: number;
    /**
     * 
     * @type {Array<GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInner>}
     * @memberof GetMistnostiV4200ResponseDataLokalityInnerArealyInner
     */
    'budovy'?: Array<GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostiV4200ResponseDataLokalityInnerArealyInner
     */
    'pa_aktualni'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostiV4200ResponseDataLokalityInnerArealyInner
     */
    'pa_nazev'?: string;
}
/**
 * 
 * @export
 * @interface GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInner
 */
export interface GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInner {
    /**
     * 
     * @type {number}
     * @memberof GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInner
     */
    'budova_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInner
     */
    'pb_aktualni'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInner
     */
    'pb_nazev'?: string;
    /**
     * 
     * @type {Array<GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInner>}
     * @memberof GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInner
     */
    'podlazi'?: Array<GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInner>;
}
/**
 * 
 * @export
 * @interface GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInner
 */
export interface GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInner {
    /**
     * 
     * @type {Array<GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInnerMistnostiInner>}
     * @memberof GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInner
     */
    'mistnosti'?: Array<GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInnerMistnostiInner>;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInner
     */
    'podlazi_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInner
     */
    'pp_aktualni'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInner
     */
    'pp_nazev'?: string;
}
/**
 * 
 * @export
 * @interface GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInnerMistnostiInner
 */
export interface GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInnerMistnostiInner {
    /**
     * 
     * @type {Array<any>}
     * @memberof GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInnerMistnostiInner
     */
    'klapky'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInnerMistnostiInner
     */
    'label'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInnerMistnostiInner
     */
    'mistnost_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInnerMistnostiInner
     */
    'nazev'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInnerMistnostiInner
     */
    'pocet_mist'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInnerMistnostiInner
     */
    'popis_web'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInnerMistnostiInner
     */
    'pro_vyuku'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMistnostiV4200ResponseDataLokalityInnerArealyInnerBudovyInnerPodlaziInnerMistnostiInner
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface GetOborPlanV3200Response
 */
export interface GetOborPlanV3200Response {
    /**
     * 
     * @type {GetOborPlanV3200ResponseData}
     * @memberof GetOborPlanV3200Response
     */
    'data'?: GetOborPlanV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200Response
     */
    'rows_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetOborPlanV3200ResponseData
 */
export interface GetOborPlanV3200ResponseData {
    /**
     * 
     * @type {Array<GetOborPlanV3200ResponseDataProgramyInner>}
     * @memberof GetOborPlanV3200ResponseData
     */
    'programy'?: Array<GetOborPlanV3200ResponseDataProgramyInner>;
}
/**
 * 
 * @export
 * @interface GetOborPlanV3200ResponseDataProgramyInner
 */
export interface GetOborPlanV3200ResponseDataProgramyInner {
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200ResponseDataProgramyInner
     */
    'bez_specializace'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanV3200ResponseDataProgramyInner
     */
    'forma'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanV3200ResponseDataProgramyInner
     */
    'forma_zr'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanV3200ResponseDataProgramyInner
     */
    'lang_code'?: string;
    /**
     * 
     * @type {Array<GetOborPlanV3200ResponseDataProgramyInnerOboryInner>}
     * @memberof GetOborPlanV3200ResponseDataProgramyInner
     */
    'obory'?: Array<GetOborPlanV3200ResponseDataProgramyInnerOboryInner>;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanV3200ResponseDataProgramyInner
     */
    'prog_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200ResponseDataProgramyInner
     */
    'prog_rok'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanV3200ResponseDataProgramyInner
     */
    'prog_upd_ts'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200ResponseDataProgramyInner
     */
    'prog_verze'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanV3200ResponseDataProgramyInner
     */
    'prog_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200ResponseDataProgramyInner
     */
    'program_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200ResponseDataProgramyInner
     */
    'program_lonsky_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanV3200ResponseDataProgramyInner
     */
    'typ'?: string;
}
/**
 * 
 * @export
 * @interface GetOborPlanV3200ResponseDataProgramyInnerOboryInner
 */
export interface GetOborPlanV3200ResponseDataProgramyInnerOboryInner {
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInner
     */
    'obor_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInner
     */
    'obor_lonsky_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInner
     */
    'obor_zkratka'?: string;
    /**
     * 
     * @type {Array<GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInner>}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInner
     */
    'plany'?: Array<GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInner>;
}
/**
 * 
 * @export
 * @interface GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInner
 */
export interface GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInner {
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInner
     */
    'hlavni_obor'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInner
     */
    'motto_oboru'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInner
     */
    'nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInner
     */
    'plan_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInner
     */
    'plan_lonsky_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInner
     */
    'popis_oboru'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInner
     */
    'popis_planu'?: string;
    /**
     * 
     * @type {Array<GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInner>}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInner
     */
    'stupne'?: Array<GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInner>;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInner
     */
    'zamereni_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInner
     */
    'zkratka'?: string;
}
/**
 * 
 * @export
 * @interface GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInner
 */
export interface GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInner {
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInner
     */
    'cislo_stupne'?: number;
    /**
     * 
     * @type {Array<GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInner>}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInner
     */
    'rocniky'?: Array<GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInner
     */
    'stupen_id'?: number;
}
/**
 * 
 * @export
 * @interface GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInner
 */
export interface GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInner {
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInner
     */
    'cislo_rocniku'?: number;
    /**
     * 
     * @type {Array<GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerPredmetyInner>}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInner
     */
    'predmety'?: Array<GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerPredmetyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInner
     */
    'rocnik_id'?: number;
}
/**
 * 
 * @export
 * @interface GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerPredmetyInner
 */
export interface GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerPredmetyInner {
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerPredmetyInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerPredmetyInner
     */
    'ap_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerPredmetyInner
     */
    'garant'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerPredmetyInner
     */
    'garant_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerPredmetyInner
     */
    'garant_uri'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerPredmetyInner
     */
    'kredity'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerPredmetyInner
     */
    'org_acronym'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerPredmetyInner
     */
    'otevren'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerPredmetyInner
     */
    'p_zkratka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerPredmetyInner
     */
    'povinnost_k_sp'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerPredmetyInner
     */
    'predmet_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerPredmetyInner
     */
    'predmet_planu_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerPredmetyInner
     */
    'rok'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerPredmetyInner
     */
    'sem_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerPredmetyInner
     */
    'skupina_pv_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerPredmetyInner
     */
    'skupina_pv_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerPredmetyInner
     */
    'typ_ukonceni_predmetu_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerPredmetyInner
     */
    'typ_ukonceni_zkr'?: string;
}
/**
 * 
 * @export
 * @interface GetOborPlanyV3200Response
 */
export interface GetOborPlanyV3200Response {
    /**
     * 
     * @type {GetOborPlanyV3200ResponseData}
     * @memberof GetOborPlanyV3200Response
     */
    'data'?: GetOborPlanyV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanyV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanyV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetOborPlanyV3200ResponseData
 */
export interface GetOborPlanyV3200ResponseData {
    /**
     * 
     * @type {Array<GetOborPlanyV3200ResponseDataProgramyInner>}
     * @memberof GetOborPlanyV3200ResponseData
     */
    'programy'?: Array<GetOborPlanyV3200ResponseDataProgramyInner>;
}
/**
 * 
 * @export
 * @interface GetOborPlanyV3200ResponseDataProgramyInner
 */
export interface GetOborPlanyV3200ResponseDataProgramyInner {
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanyV3200ResponseDataProgramyInner
     */
    'bez_specializace'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanyV3200ResponseDataProgramyInner
     */
    'forma'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanyV3200ResponseDataProgramyInner
     */
    'forma_studia_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanyV3200ResponseDataProgramyInner
     */
    'forma_zr'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanyV3200ResponseDataProgramyInner
     */
    'lang_code'?: string;
    /**
     * 
     * @type {Array<GetOborPlanyV3200ResponseDataProgramyInnerOboryInner>}
     * @memberof GetOborPlanyV3200ResponseDataProgramyInner
     */
    'obory'?: Array<GetOborPlanyV3200ResponseDataProgramyInnerOboryInner>;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanyV3200ResponseDataProgramyInner
     */
    'prog_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanyV3200ResponseDataProgramyInner
     */
    'prog_upd_ts'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanyV3200ResponseDataProgramyInner
     */
    'prog_verze'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanyV3200ResponseDataProgramyInner
     */
    'prog_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanyV3200ResponseDataProgramyInner
     */
    'program_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanyV3200ResponseDataProgramyInner
     */
    'program_lonsky_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanyV3200ResponseDataProgramyInner
     */
    'rok'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanyV3200ResponseDataProgramyInner
     */
    'typ'?: string;
}
/**
 * 
 * @export
 * @interface GetOborPlanyV3200ResponseDataProgramyInnerOboryInner
 */
export interface GetOborPlanyV3200ResponseDataProgramyInnerOboryInner {
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanyV3200ResponseDataProgramyInnerOboryInner
     */
    'obor_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanyV3200ResponseDataProgramyInnerOboryInner
     */
    'obor_lonsky_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanyV3200ResponseDataProgramyInnerOboryInner
     */
    'obor_zamereni_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanyV3200ResponseDataProgramyInnerOboryInner
     */
    'obor_zkratka'?: string;
    /**
     * 
     * @type {Array<GetOborPlanyV3200ResponseDataProgramyInnerOboryInnerPlanyInner>}
     * @memberof GetOborPlanyV3200ResponseDataProgramyInnerOboryInner
     */
    'plany'?: Array<GetOborPlanyV3200ResponseDataProgramyInnerOboryInnerPlanyInner>;
}
/**
 * 
 * @export
 * @interface GetOborPlanyV3200ResponseDataProgramyInnerOboryInnerPlanyInner
 */
export interface GetOborPlanyV3200ResponseDataProgramyInnerOboryInnerPlanyInner {
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanyV3200ResponseDataProgramyInnerOboryInnerPlanyInner
     */
    'hlavni_obor'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanyV3200ResponseDataProgramyInnerOboryInnerPlanyInner
     */
    'plan_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborPlanyV3200ResponseDataProgramyInnerOboryInnerPlanyInner
     */
    'plan_lonsky_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanyV3200ResponseDataProgramyInnerOboryInnerPlanyInner
     */
    'zamereni_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborPlanyV3200ResponseDataProgramyInnerOboryInnerPlanyInner
     */
    'zkratka'?: string;
}
/**
 * 
 * @export
 * @interface GetOborPvskupinyV3200Response
 */
export interface GetOborPvskupinyV3200Response {
    /**
     * 
     * @type {GetOborPvskupinyV3200ResponseData}
     * @memberof GetOborPvskupinyV3200Response
     */
    'data'?: GetOborPvskupinyV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetOborPvskupinyV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborPvskupinyV3200Response
     */
    'rows_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborPvskupinyV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetOborPvskupinyV3200ResponseData
 */
export interface GetOborPvskupinyV3200ResponseData {
    /**
     * 
     * @type {Array<GetOborPvskupinyV3200ResponseDataProgramyInner>}
     * @memberof GetOborPvskupinyV3200ResponseData
     */
    'programy'?: Array<GetOborPvskupinyV3200ResponseDataProgramyInner>;
}
/**
 * 
 * @export
 * @interface GetOborPvskupinyV3200ResponseDataProgramyInner
 */
export interface GetOborPvskupinyV3200ResponseDataProgramyInner {
    /**
     * 
     * @type {Array<GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInner>}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInner
     */
    'obory'?: Array<GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInner>;
    /**
     * 
     * @type {string}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInner
     */
    'prog_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInner
     */
    'prog_rok'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInner
     */
    'prog_upd_ts'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInner
     */
    'prog_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInner
     */
    'program_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInner
     */
    'program_lonsky_id'?: number;
}
/**
 * 
 * @export
 * @interface GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInner
 */
export interface GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInner {
    /**
     * 
     * @type {number}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInner
     */
    'obor_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInner
     */
    'obor_lonsky_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInner
     */
    'obor_zkratka'?: string;
    /**
     * 
     * @type {Array<GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInner>}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInner
     */
    'plany'?: Array<GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInner>;
}
/**
 * 
 * @export
 * @interface GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInner
 */
export interface GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInner {
    /**
     * 
     * @type {number}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInner
     */
    'plan_hlavni_obor'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInner
     */
    'plan_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInner
     */
    'plan_lonsky_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInner
     */
    'plan_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInner
     */
    'plan_zamereni_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInner
     */
    'plan_zkratka'?: string;
    /**
     * 
     * @type {Array<GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInner>}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInner
     */
    'stupne'?: Array<GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInner>;
}
/**
 * 
 * @export
 * @interface GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInner
 */
export interface GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInner {
    /**
     * 
     * @type {number}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInner
     */
    'cislo_stupne'?: number;
    /**
     * 
     * @type {Array<GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInner>}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInner
     */
    'rocniky'?: Array<GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInner
     */
    'stupen_id'?: number;
}
/**
 * 
 * @export
 * @interface GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInner
 */
export interface GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInner {
    /**
     * 
     * @type {number}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInner
     */
    'cislo_rocniku'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInner
     */
    'rocnik_id'?: number;
    /**
     * 
     * @type {Array<GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInner>}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInner
     */
    'semestry'?: Array<GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInner>;
}
/**
 * 
 * @export
 * @interface GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInner
 */
export interface GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInner {
    /**
     * 
     * @type {string}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInner
     */
    'sem_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInner
     */
    'semestr_id'?: number;
    /**
     * 
     * @type {Array<GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInnerSkupinyPvInner>}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInner
     */
    'skupiny_pv'?: Array<GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInnerSkupinyPvInner>;
    /**
     * 
     * @type {number}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInner
     */
    'typ_semestru_id'?: number;
}
/**
 * 
 * @export
 * @interface GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInnerSkupinyPvInner
 */
export interface GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInnerSkupinyPvInner {
    /**
     * 
     * @type {object}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInnerSkupinyPvInner
     */
    'cislo_skupiny'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInnerSkupinyPvInner
     */
    'kontrolovat_pocet_kreditu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInnerSkupinyPvInner
     */
    'kontrolovat_pocet_predmetu'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInnerSkupinyPvInner
     */
    'max_pocet_kreditu'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInnerSkupinyPvInner
     */
    'max_pocet_predmetu'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInnerSkupinyPvInner
     */
    'min_pocet_kreditu'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInnerSkupinyPvInner
     */
    'min_pocet_predmetu'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInnerSkupinyPvInner
     */
    'nazev'?: string;
    /**
     * 
     * @type {Array<GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInnerSkupinyPvInnerPredmetyInner>}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInnerSkupinyPvInner
     */
    'predmety'?: Array<GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInnerSkupinyPvInnerPredmetyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInnerSkupinyPvInner
     */
    'skupina_pv_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInnerSkupinyPvInner
     */
    'zkratka'?: string;
}
/**
 * 
 * @export
 * @interface GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInnerSkupinyPvInnerPredmetyInner
 */
export interface GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInnerSkupinyPvInnerPredmetyInner {
    /**
     * 
     * @type {number}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInnerSkupinyPvInnerPredmetyInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInnerSkupinyPvInnerPredmetyInner
     */
    'p_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInnerSkupinyPvInnerPredmetyInner
     */
    'predmet_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborPvskupinyV3200ResponseDataProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInnerSemestryInnerSkupinyPvInnerPredmetyInner
     */
    'predmet_planu_id'?: number;
}
/**
 * 
 * @export
 * @interface GetOborV3200Response
 */
export interface GetOborV3200Response {
    /**
     * 
     * @type {GetOborV3200ResponseData}
     * @memberof GetOborV3200Response
     */
    'data'?: GetOborV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetOborV3200ResponseData
 */
export interface GetOborV3200ResponseData {
    /**
     * 
     * @type {Array<GetOborV3200ResponseDataProgramyInner>}
     * @memberof GetOborV3200ResponseData
     */
    'programy'?: Array<GetOborV3200ResponseDataProgramyInner>;
}
/**
 * 
 * @export
 * @interface GetOborV3200ResponseDataProgramyInner
 */
export interface GetOborV3200ResponseDataProgramyInner {
    /**
     * 
     * @type {object}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'cena_pro_eu_studenty'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'cena_pro_ne_eu_studenty'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'cena_pro_zahr_studenty_mena'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'delka_studia'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'erasmus'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'fakulta_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'fakulta_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'fakulta_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'forma_studia_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'kod_stud_prog'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'lang_code'?: string;
    /**
     * 
     * @type {Array<GetOborV3200ResponseDataProgramyInnerOboryInner>}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'obory'?: Array<GetOborV3200ResponseDataProgramyInnerOboryInner>;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'prog_akreditace_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'prog_akreditace_od'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'prog_bez_specializace'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'prog_forma'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'prog_forma_zr'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'prog_garant_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'prog_garant_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'prog_garant_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'prog_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'prog_povinnosti'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'prog_praxe'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'prog_profesni_profil'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'prog_typ'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'prog_upd_ts'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'prog_verze'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'prog_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'program_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'program_lonsky_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'rok'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInner
     */
    'titul'?: string;
}
/**
 * 
 * @export
 * @interface GetOborV3200ResponseDataProgramyInnerOboryInner
 */
export interface GetOborV3200ResponseDataProgramyInnerOboryInner {
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'absolvent_profil'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'akreditace_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'akreditace_od'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'bez_specializace'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'cil'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'garant_id'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'garant_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'garant_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'kod_jkov'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'kod_kkov'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'kod_oboru'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'min_pocet_kreditu'?: number;
    /**
     * 
     * @type {Array<GetOborV3200ResponseDataProgramyInnerOboryInnerNavrhyPraciInner>}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'navrhy_praci'?: Array<GetOborV3200ResponseDataProgramyInnerOboryInnerNavrhyPraciInner>;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'obor_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'obor_lonsky_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'popis_oboru'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'popis_rocnik1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'popis_rocnik2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'popis_rocnik3'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'posledni_rok_prijimani'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'praxe'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'specializace'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'szz'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'upd_ts'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'zamereni_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'zkratka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInner
     */
    'znalosti'?: string;
}
/**
 * 
 * @export
 * @interface GetOborV3200ResponseDataProgramyInnerOboryInnerNavrhyPraciInner
 */
export interface GetOborV3200ResponseDataProgramyInnerOboryInnerNavrhyPraciInner {
    /**
     * 
     * @type {number}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInnerNavrhyPraciInner
     */
    'navrh_praci_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInnerNavrhyPraciInner
     */
    'np_jazyk'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInnerNavrhyPraciInner
     */
    'np_nazev'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetOborV3200ResponseDataProgramyInnerOboryInnerNavrhyPraciInner
     */
    'np_zav_prace_id'?: object;
}
/**
 * 
 * @export
 * @interface GetOborZavpraceVolneV3200Response
 */
export interface GetOborZavpraceVolneV3200Response {
    /**
     * 
     * @type {GetOborZavpraceVolneV3200ResponseData}
     * @memberof GetOborZavpraceVolneV3200Response
     */
    'data'?: GetOborZavpraceVolneV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetOborZavpraceVolneV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborZavpraceVolneV3200Response
     */
    'rows_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborZavpraceVolneV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetOborZavpraceVolneV3200ResponseData
 */
export interface GetOborZavpraceVolneV3200ResponseData {
    /**
     * 
     * @type {Array<GetOborZavpraceVolneV3200ResponseDataZavPraceInner>}
     * @memberof GetOborZavpraceVolneV3200ResponseData
     */
    'zav_prace'?: Array<GetOborZavpraceVolneV3200ResponseDataZavPraceInner>;
}
/**
 * 
 * @export
 * @interface GetOborZavpraceVolneV3200ResponseDataZavPraceInner
 */
export interface GetOborZavpraceVolneV3200ResponseDataZavPraceInner {
    /**
     * 
     * @type {object}
     * @memberof GetOborZavpraceVolneV3200ResponseDataZavPraceInner
     */
    'firma_id'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetOborZavpraceVolneV3200ResponseDataZavPraceInner
     */
    'firma_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborZavpraceVolneV3200ResponseDataZavPraceInner
     */
    'firma_zkratka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborZavpraceVolneV3200ResponseDataZavPraceInner
     */
    'prace_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborZavpraceVolneV3200ResponseDataZavPraceInner
     */
    'prace_typ_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborZavpraceVolneV3200ResponseDataZavPraceInner
     */
    'prace_typ_zkratka'?: string;
    /**
     * 
     * @type {Array<GetOborZavpraceVolneV3200ResponseDataZavPraceInnerPreferencniOboryInner>}
     * @memberof GetOborZavpraceVolneV3200ResponseDataZavPraceInner
     */
    'preferencni_obory'?: Array<GetOborZavpraceVolneV3200ResponseDataZavPraceInnerPreferencniOboryInner>;
    /**
     * 
     * @type {number}
     * @memberof GetOborZavpraceVolneV3200ResponseDataZavPraceInner
     */
    'rok'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborZavpraceVolneV3200ResponseDataZavPraceInner
     */
    'ustav_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborZavpraceVolneV3200ResponseDataZavPraceInner
     */
    'ustav_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborZavpraceVolneV3200ResponseDataZavPraceInner
     */
    'ustav_zkratka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborZavpraceVolneV3200ResponseDataZavPraceInner
     */
    'vedouci_alt_text'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborZavpraceVolneV3200ResponseDataZavPraceInner
     */
    'vedouci_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborZavpraceVolneV3200ResponseDataZavPraceInner
     */
    'vedouci_jmeno'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborZavpraceVolneV3200ResponseDataZavPraceInner
     */
    'vedouci_label_pr'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOborZavpraceVolneV3200ResponseDataZavPraceInner
     */
    'zav_prace_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborZavpraceVolneV3200ResponseDataZavPraceInner
     */
    'zav_prace_stav_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOborZavpraceVolneV3200ResponseDataZavPraceInner
     */
    'zav_prace_typ_id'?: number;
}
/**
 * 
 * @export
 * @interface GetOborZavpraceVolneV3200ResponseDataZavPraceInnerPreferencniOboryInner
 */
export interface GetOborZavpraceVolneV3200ResponseDataZavPraceInnerPreferencniOboryInner {
    /**
     * 
     * @type {number}
     * @memberof GetOborZavpraceVolneV3200ResponseDataZavPraceInnerPreferencniOboryInner
     */
    'obor_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOborZavpraceVolneV3200ResponseDataZavPraceInnerPreferencniOboryInner
     */
    'obor_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOborZavpraceVolneV3200ResponseDataZavPraceInnerPreferencniOboryInner
     */
    'obor_zkratka'?: string;
}
/**
 * 
 * @export
 * @interface GetOboryV3200Response
 */
export interface GetOboryV3200Response {
    /**
     * 
     * @type {GetOboryV3200ResponseData}
     * @memberof GetOboryV3200Response
     */
    'data'?: GetOboryV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOboryV3200Response
     */
    'rows_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOboryV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetOboryV3200ResponseData
 */
export interface GetOboryV3200ResponseData {
    /**
     * 
     * @type {Array<GetOboryV3200ResponseDataProgramyInner>}
     * @memberof GetOboryV3200ResponseData
     */
    'programy'?: Array<GetOboryV3200ResponseDataProgramyInner>;
}
/**
 * 
 * @export
 * @interface GetOboryV3200ResponseDataProgramyInner
 */
export interface GetOboryV3200ResponseDataProgramyInner {
    /**
     * 
     * @type {number}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'delka_studia'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'erasmus'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'fakulta_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'fakulta_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'fakulta_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'forma_studia_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'kod_stud_prog'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'lang_code'?: string;
    /**
     * 
     * @type {Array<GetOboryV3200ResponseDataProgramyInnerOboryInner>}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'obory'?: Array<GetOboryV3200ResponseDataProgramyInnerOboryInner>;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'prog_akreditace_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'prog_akreditace_od'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'prog_bez_specializace'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'prog_forma'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'prog_forma_zr'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'prog_garant_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'prog_garant_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'prog_garant_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'prog_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'prog_typ'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'prog_upd_ts'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'prog_verze'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'prog_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'program_id'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'program_lonsky_id'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'rok'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInner
     */
    'titul'?: string;
}
/**
 * 
 * @export
 * @interface GetOboryV3200ResponseDataProgramyInnerOboryInner
 */
export interface GetOboryV3200ResponseDataProgramyInnerOboryInner {
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInnerOboryInner
     */
    'akreditace_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInnerOboryInner
     */
    'akreditace_od'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOboryV3200ResponseDataProgramyInnerOboryInner
     */
    'bez_specializace'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetOboryV3200ResponseDataProgramyInnerOboryInner
     */
    'garant_id'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInnerOboryInner
     */
    'garant_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInnerOboryInner
     */
    'garant_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInnerOboryInner
     */
    'kod_jkov'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInnerOboryInner
     */
    'kod_kkov'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetOboryV3200ResponseDataProgramyInnerOboryInner
     */
    'kod_oboru'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetOboryV3200ResponseDataProgramyInnerOboryInner
     */
    'min_pocet_kreditu'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInnerOboryInner
     */
    'nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOboryV3200ResponseDataProgramyInnerOboryInner
     */
    'obor_id'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetOboryV3200ResponseDataProgramyInnerOboryInner
     */
    'obor_lonsky_id'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetOboryV3200ResponseDataProgramyInnerOboryInner
     */
    'posledni_rok_prijimani'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetOboryV3200ResponseDataProgramyInnerOboryInner
     */
    'povinne_kredity'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOboryV3200ResponseDataProgramyInnerOboryInner
     */
    'specializace'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInnerOboryInner
     */
    'upd_ts'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInnerOboryInner
     */
    'zamereni_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOboryV3200ResponseDataProgramyInnerOboryInner
     */
    'zkratka'?: string;
}
/**
 * 
 * @export
 * @interface GetOceneniExterniTypV3200Response
 */
export interface GetOceneniExterniTypV3200Response {
    /**
     * 
     * @type {GetOceneniExterniTypV3200ResponseData}
     * @memberof GetOceneniExterniTypV3200Response
     */
    'data'?: GetOceneniExterniTypV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetOceneniExterniTypV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOceneniExterniTypV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetOceneniExterniTypV3200ResponseData
 */
export interface GetOceneniExterniTypV3200ResponseData {
    /**
     * 
     * @type {Array<GetOceneniExterniTypV3200ResponseDataOceneniInner>}
     * @memberof GetOceneniExterniTypV3200ResponseData
     */
    'oceneni'?: Array<GetOceneniExterniTypV3200ResponseDataOceneniInner>;
}
/**
 * 
 * @export
 * @interface GetOceneniExterniTypV3200ResponseDataOceneniInner
 */
export interface GetOceneniExterniTypV3200ResponseDataOceneniInner {
    /**
     * 
     * @type {string}
     * @memberof GetOceneniExterniTypV3200ResponseDataOceneniInner
     */
    'datum_zverejneni'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOceneniExterniTypV3200ResponseDataOceneniInner
     */
    'jmenovka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOceneniExterniTypV3200ResponseDataOceneniInner
     */
    'nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOceneniExterniTypV3200ResponseDataOceneniInner
     */
    'o_poradi'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOceneniExterniTypV3200ResponseDataOceneniInner
     */
    'oceneni_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOceneniExterniTypV3200ResponseDataOceneniInner
     */
    'oceneni_typ'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOceneniExterniTypV3200ResponseDataOceneniInner
     */
    'oceneni_typ_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOceneniExterniTypV3200ResponseDataOceneniInner
     */
    'per_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOceneniExterniTypV3200ResponseDataOceneniInner
     */
    'rok_oceneni'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOceneniExterniTypV3200ResponseDataOceneniInner
     */
    'text_web'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOceneniExterniTypV3200ResponseDataOceneniInner
     */
    'tym_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOceneniExterniTypV3200ResponseDataOceneniInner
     */
    'upd_ts'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOceneniExterniTypV3200ResponseDataOceneniInner
     */
    'zduvod_udeleni'?: string;
}
/**
 * 
 * @export
 * @interface GetOceneniV3200Response
 */
export interface GetOceneniV3200Response {
    /**
     * 
     * @type {GetOceneniExterniTypV3200ResponseData}
     * @memberof GetOceneniV3200Response
     */
    'data'?: GetOceneniExterniTypV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetOceneniV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOceneniV3200Response
     */
    'rows_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOceneniV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetOrgOrgsV3200Response
 */
export interface GetOrgOrgsV3200Response {
    /**
     * 
     * @type {GetOrgOrgsV3200ResponseData}
     * @memberof GetOrgOrgsV3200Response
     */
    'data'?: GetOrgOrgsV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetOrgOrgsV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOrgOrgsV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetOrgOrgsV3200ResponseData
 */
export interface GetOrgOrgsV3200ResponseData {
    /**
     * 
     * @type {Array<GetOrgOrgsV3200ResponseDataOrgunityInner>}
     * @memberof GetOrgOrgsV3200ResponseData
     */
    'orgunity'?: Array<GetOrgOrgsV3200ResponseDataOrgunityInner>;
}
/**
 * 
 * @export
 * @interface GetOrgOrgsV3200ResponseDataOrgunityInner
 */
export interface GetOrgOrgsV3200ResponseDataOrgunityInner {
    /**
     * 
     * @type {Array<GetOrgOrgsV3200ResponseDataOrgunityInnerOrgunityInner>}
     * @memberof GetOrgOrgsV3200ResponseDataOrgunityInner
     */
    'orgunity'?: Array<GetOrgOrgsV3200ResponseDataOrgunityInnerOrgunityInner>;
    /**
     * 
     * @type {number}
     * @memberof GetOrgOrgsV3200ResponseDataOrgunityInner
     */
    'parent_orgunitid'?: number;
}
/**
 * 
 * @export
 * @interface GetOrgOrgsV3200ResponseDataOrgunityInnerOrgunityInner
 */
export interface GetOrgOrgsV3200ResponseDataOrgunityInnerOrgunityInner {
    /**
     * 
     * @type {string}
     * @memberof GetOrgOrgsV3200ResponseDataOrgunityInnerOrgunityInner
     */
    'adresa_3_cz'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgOrgsV3200ResponseDataOrgunityInnerOrgunityInner
     */
    'adresa_3_en'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgOrgsV3200ResponseDataOrgunityInnerOrgunityInner
     */
    'adresa_4_cz'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgOrgsV3200ResponseDataOrgunityInnerOrgunityInner
     */
    'adresa_4_en'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgOrgsV3200ResponseDataOrgunityInnerOrgunityInner
     */
    'con_telephone'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgOrgsV3200ResponseDataOrgunityInnerOrgunityInner
     */
    'label_pr'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgOrgsV3200ResponseDataOrgunityInnerOrgunityInner
     */
    'org_acronym'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgOrgsV3200ResponseDataOrgunityInnerOrgunityInner
     */
    'org_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgOrgsV3200ResponseDataOrgunityInnerOrgunityInner
     */
    'orgunit_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOrgOrgsV3200ResponseDataOrgunityInnerOrgunityInner
     */
    'orgunitid'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetOrgOrgsV3200ResponseDataOrgunityInnerOrgunityInner
     */
    'per_id'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetOrgOrgsV3200ResponseDataOrgunityInnerOrgunityInner
     */
    'per_uri'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgOrgsV3200ResponseDataOrgunityInnerOrgunityInner
     */
    'pers_org_role_full'?: string;
}
/**
 * 
 * @export
 * @interface GetOrgV3200Response
 */
export interface GetOrgV3200Response {
    /**
     * 
     * @type {GetOrgV3200ResponseData}
     * @memberof GetOrgV3200Response
     */
    'data'?: GetOrgV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetOrgV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOrgV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetOrgV3200ResponseData
 */
export interface GetOrgV3200ResponseData {
    /**
     * 
     * @type {Array<GetOrgV3200ResponseDataOrgunitInner>}
     * @memberof GetOrgV3200ResponseData
     */
    'orgunit'?: Array<GetOrgV3200ResponseDataOrgunitInner>;
}
/**
 * 
 * @export
 * @interface GetOrgV3200ResponseDataOrgunitInner
 */
export interface GetOrgV3200ResponseDataOrgunitInner {
    /**
     * 
     * @type {string}
     * @memberof GetOrgV3200ResponseDataOrgunitInner
     */
    'adresa_3_cz'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgV3200ResponseDataOrgunitInner
     */
    'adresa_3_en'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgV3200ResponseDataOrgunitInner
     */
    'adresa_4_cz'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgV3200ResponseDataOrgunitInner
     */
    'adresa_4_en'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgV3200ResponseDataOrgunitInner
     */
    'con_telephone'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgV3200ResponseDataOrgunitInner
     */
    'label_pr'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgV3200ResponseDataOrgunitInner
     */
    'org_acronym'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgV3200ResponseDataOrgunitInner
     */
    'org_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgV3200ResponseDataOrgunitInner
     */
    'orgunit_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOrgV3200ResponseDataOrgunitInner
     */
    'orgunitid'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOrgV3200ResponseDataOrgunitInner
     */
    'per_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOrgV3200ResponseDataOrgunitInner
     */
    'per_uri'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgV3200ResponseDataOrgunitInner
     */
    'pers_org_role_full'?: string;
    /**
     * 
     * @type {Array<GetOrgV3200ResponseDataOrgunitInnerTypyInner>}
     * @memberof GetOrgV3200ResponseDataOrgunitInner
     */
    'typy'?: Array<GetOrgV3200ResponseDataOrgunitInnerTypyInner>;
}
/**
 * 
 * @export
 * @interface GetOrgV3200ResponseDataOrgunitInnerTypyInner
 */
export interface GetOrgV3200ResponseDataOrgunitInnerTypyInner {
    /**
     * 
     * @type {string}
     * @memberof GetOrgV3200ResponseDataOrgunitInnerTypyInner
     */
    'org_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgV3200ResponseDataOrgunitInnerTypyInner
     */
    'org_type_full'?: string;
}
/**
 * 
 * @export
 * @interface GetOrgZamestnanciV4200Response
 */
export interface GetOrgZamestnanciV4200Response {
    /**
     * 
     * @type {GetOrgZamestnanciV4200ResponseData}
     * @memberof GetOrgZamestnanciV4200Response
     */
    'data'?: GetOrgZamestnanciV4200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetOrgZamestnanciV4200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOrgZamestnanciV4200Response
     */
    'rows_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOrgZamestnanciV4200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetOrgZamestnanciV4200ResponseData
 */
export interface GetOrgZamestnanciV4200ResponseData {
    /**
     * 
     * @type {Array<GetOrgZamestnanciV4200ResponseDataOrgunityInner>}
     * @memberof GetOrgZamestnanciV4200ResponseData
     */
    'orgunity'?: Array<GetOrgZamestnanciV4200ResponseDataOrgunityInner>;
}
/**
 * 
 * @export
 * @interface GetOrgZamestnanciV4200ResponseDataOrgunityInner
 */
export interface GetOrgZamestnanciV4200ResponseDataOrgunityInner {
    /**
     * 
     * @type {string}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInner
     */
    'org_acronym'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInner
     */
    'orgunit_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInner
     */
    'orgunitid'?: number;
    /**
     * 
     * @type {Array<GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInner>}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInner
     */
    'osoby'?: Array<GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInner>;
}
/**
 * 
 * @export
 * @interface GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInner
 */
export interface GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInner {
    /**
     * 
     * @type {string}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInner
     */
    'con_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInner
     */
    'con_room'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInner
     */
    'con_telephone'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInner
     */
    'con_workload'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInner
     */
    'jmenovka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInner
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInner
     */
    'label_pr'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInner
     */
    'orcid_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInner
     */
    'per_academic_title'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInner
     */
    'per_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInner
     */
    'per_uri'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInner
     */
    'researcher_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInner
     */
    'scopus_id'?: string;
    /**
     * 
     * @type {Array<GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInnerVztahyInner>}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInner
     */
    'vztahy'?: Array<GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInnerVztahyInner>;
}
/**
 * 
 * @export
 * @interface GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInnerVztahyInner
 */
export interface GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInnerVztahyInner {
    /**
     * 
     * @type {number}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInnerVztahyInner
     */
    'display_www'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInnerVztahyInner
     */
    'pers_org_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInnerVztahyInner
     */
    'pers_org_role'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInnerVztahyInner
     */
    'pers_org_role_full'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInnerVztahyInner
     */
    'po_workload'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInnerVztahyInner
     */
    'pozice'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInnerVztahyInner
     */
    'priorita_zobrazeni'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOrgZamestnanciV4200ResponseDataOrgunityInnerOsobyInnerVztahyInner
     */
    'work_level'?: number;
}
/**
 * 
 * @export
 * @interface GetOrgsVutV3200Response
 */
export interface GetOrgsVutV3200Response {
    /**
     * 
     * @type {GetOrgsVutV3200ResponseData}
     * @memberof GetOrgsVutV3200Response
     */
    'data'?: GetOrgsVutV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetOrgsVutV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOrgsVutV3200Response
     */
    'rows_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOrgsVutV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetOrgsVutV3200ResponseData
 */
export interface GetOrgsVutV3200ResponseData {
    /**
     * 
     * @type {Array<GetOrgOrgsV3200ResponseDataOrgunityInnerOrgunityInner>}
     * @memberof GetOrgsVutV3200ResponseData
     */
    'orgunit'?: Array<GetOrgOrgsV3200ResponseDataOrgunityInnerOrgunityInner>;
}
/**
 * 
 * @export
 * @interface GetOsobaV3200Response
 */
export interface GetOsobaV3200Response {
    /**
     * 
     * @type {GetOsobaV3200ResponseData}
     * @memberof GetOsobaV3200Response
     */
    'data'?: GetOsobaV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetOsobaV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOsobaV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetOsobaV3200ResponseData
 */
export interface GetOsobaV3200ResponseData {
    /**
     * 
     * @type {Array<GetOsobaV3200ResponseDataOsobyInner>}
     * @memberof GetOsobaV3200ResponseData
     */
    'osoby'?: Array<GetOsobaV3200ResponseDataOsobyInner>;
}
/**
 * 
 * @export
 * @interface GetOsobaV3200ResponseDataOsobyInner
 */
export interface GetOsobaV3200ResponseDataOsobyInner {
    /**
     * 
     * @type {number}
     * @memberof GetOsobaV3200ResponseDataOsobyInner
     */
    'jina_fakulta'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOsobaV3200ResponseDataOsobyInner
     */
    'per_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOsobaV3200ResponseDataOsobyInner
     */
    'per_org_role'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOsobaV3200ResponseDataOsobyInner
     */
    'pers_org_role_full'?: string;
}
/**
 * 
 * @export
 * @interface GetPredmetLiteraturaV3200Response
 */
export interface GetPredmetLiteraturaV3200Response {
    /**
     * 
     * @type {GetPredmetLiteraturaV3200ResponseData}
     * @memberof GetPredmetLiteraturaV3200Response
     */
    'data'?: GetPredmetLiteraturaV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetPredmetLiteraturaV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPredmetLiteraturaV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetPredmetLiteraturaV3200ResponseData
 */
export interface GetPredmetLiteraturaV3200ResponseData {
    /**
     * 
     * @type {Array<GetPredmetLiteraturaV3200ResponseDataPredmetyInner>}
     * @memberof GetPredmetLiteraturaV3200ResponseData
     */
    'predmety'?: Array<GetPredmetLiteraturaV3200ResponseDataPredmetyInner>;
}
/**
 * 
 * @export
 * @interface GetPredmetLiteraturaV3200ResponseDataPredmetyInner
 */
export interface GetPredmetLiteraturaV3200ResponseDataPredmetyInner {
    /**
     * 
     * @type {Array<GetPredmetLiteraturaV3200ResponseDataPredmetyInnerLiteraturaInner>}
     * @memberof GetPredmetLiteraturaV3200ResponseDataPredmetyInner
     */
    'literatura'?: Array<GetPredmetLiteraturaV3200ResponseDataPredmetyInnerLiteraturaInner>;
    /**
     * 
     * @type {number}
     * @memberof GetPredmetLiteraturaV3200ResponseDataPredmetyInner
     */
    'predmet_id'?: number;
}
/**
 * 
 * @export
 * @interface GetPredmetLiteraturaV3200ResponseDataPredmetyInnerLiteraturaInner
 */
export interface GetPredmetLiteraturaV3200ResponseDataPredmetyInnerLiteraturaInner {
    /**
     * 
     * @type {string}
     * @memberof GetPredmetLiteraturaV3200ResponseDataPredmetyInnerLiteraturaInner
     */
    'citace'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPredmetLiteraturaV3200ResponseDataPredmetyInnerLiteraturaInner
     */
    'jazyk'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPredmetLiteraturaV3200ResponseDataPredmetyInnerLiteraturaInner
     */
    'literatura_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPredmetLiteraturaV3200ResponseDataPredmetyInnerLiteraturaInner
     */
    'platnost'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPredmetLiteraturaV3200ResponseDataPredmetyInnerLiteraturaInner
     */
    'poradove_cislo'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetPredmetLiteraturaV3200ResponseDataPredmetyInnerLiteraturaInner
     */
    'pub_id'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetPredmetLiteraturaV3200ResponseDataPredmetyInnerLiteraturaInner
     */
    'rok_do'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetPredmetLiteraturaV3200ResponseDataPredmetyInnerLiteraturaInner
     */
    'rok_od'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPredmetLiteraturaV3200ResponseDataPredmetyInnerLiteraturaInner
     */
    'typ_literatury_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPredmetLiteraturaV3200ResponseDataPredmetyInnerLiteraturaInner
     */
    'typ_popis'?: string;
}
/**
 * 
 * @export
 * @interface GetPredmetyTerminyRokV3200Response
 */
export interface GetPredmetyTerminyRokV3200Response {
    /**
     * 
     * @type {GetPredmetyTerminyRokV3200ResponseData}
     * @memberof GetPredmetyTerminyRokV3200Response
     */
    'data'?: GetPredmetyTerminyRokV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetPredmetyTerminyRokV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPredmetyTerminyRokV3200Response
     */
    'rows_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPredmetyTerminyRokV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetPredmetyTerminyRokV3200ResponseData
 */
export interface GetPredmetyTerminyRokV3200ResponseData {
    /**
     * 
     * @type {Array<GetPredmetyTerminyRokV3200ResponseDataAktualniPredmetyInner>}
     * @memberof GetPredmetyTerminyRokV3200ResponseData
     */
    'aktualni_predmety'?: Array<GetPredmetyTerminyRokV3200ResponseDataAktualniPredmetyInner>;
}
/**
 * 
 * @export
 * @interface GetPredmetyTerminyRokV3200ResponseDataAktualniPredmetyInner
 */
export interface GetPredmetyTerminyRokV3200ResponseDataAktualniPredmetyInner {
    /**
     * 
     * @type {number}
     * @memberof GetPredmetyTerminyRokV3200ResponseDataAktualniPredmetyInner
     */
    'aktualni_pocet_studentu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPredmetyTerminyRokV3200ResponseDataAktualniPredmetyInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPredmetyTerminyRokV3200ResponseDataAktualniPredmetyInner
     */
    'garant_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPredmetyTerminyRokV3200ResponseDataAktualniPredmetyInner
     */
    'garant_jmenovka'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetPredmetyTerminyRokV3200ResponseDataAktualniPredmetyInner
     */
    'max_bodu_predmet'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetPredmetyTerminyRokV3200ResponseDataAktualniPredmetyInner
     */
    'max_bodu_zkouska'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetPredmetyTerminyRokV3200ResponseDataAktualniPredmetyInner
     */
    'max_bodu_zkouska_karta_predmetu'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetPredmetyTerminyRokV3200ResponseDataAktualniPredmetyInner
     */
    'min_bodu_zapocet'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetPredmetyTerminyRokV3200ResponseDataAktualniPredmetyInner
     */
    'nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPredmetyTerminyRokV3200ResponseDataAktualniPredmetyInner
     */
    'pocet_bodovanych_casti'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPredmetyTerminyRokV3200ResponseDataAktualniPredmetyInner
     */
    'pozadavek_zapoctu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPredmetyTerminyRokV3200ResponseDataAktualniPredmetyInner
     */
    'pozadavek_zkousky'?: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof GetPredmetyTerminyRokV3200ResponseDataAktualniPredmetyInner
     */
    'terminy_zkousky'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof GetPredmetyTerminyRokV3200ResponseDataAktualniPredmetyInner
     */
    'typ_semestru'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPredmetyTerminyRokV3200ResponseDataAktualniPredmetyInner
     */
    'ustav_zkratka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPredmetyTerminyRokV3200ResponseDataAktualniPredmetyInner
     */
    'zkratka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPredmetyTerminyRokV3200ResponseDataAktualniPredmetyInner
     */
    'zkratka_ukonceni_predmetu'?: string;
}
/**
 * 
 * @export
 * @interface GetPrednaskovaSkupinaStudijniSkupinaV3200Response
 */
export interface GetPrednaskovaSkupinaStudijniSkupinaV3200Response {
    /**
     * 
     * @type {GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseData}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200Response
     */
    'data'?: GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseData
 */
export interface GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseData {
    /**
     * 
     * @type {Array<GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInner>}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseData
     */
    'prednaskove_skupiny'?: Array<GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInner>;
}
/**
 * 
 * @export
 * @interface GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInner
 */
export interface GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInner {
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInner
     */
    'prednaskova_skupina_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInner
     */
    'ps_fakulta_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInner
     */
    'ps_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInner
     */
    'ps_specialni_skupina'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInner
     */
    'ps_ustav_id'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInner
     */
    'rok_platnosti'?: number;
    /**
     * 
     * @type {Array<GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInner>}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInner
     */
    'studijni_skupiny'?: Array<GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInner>;
}
/**
 * 
 * @export
 * @interface GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInner
 */
export interface GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInner {
    /**
     * 
     * @type {string}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInner
     */
    'cskupina'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInner
     */
    'cts_popis'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInner
     */
    'cts_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInner
     */
    'max_pocet_studentu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInner
     */
    'nabizet_pro_zapis'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInner
     */
    'pocet_studentu'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInner
     */
    'pocet_zapsanych_studentu'?: number;
    /**
     * 
     * @type {Array<GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInner>}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInner
     */
    'programy'?: Array<GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInner
     */
    'referentka_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInner
     */
    'specialni_skupina'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInner
     */
    'studijni_skupina_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInner
     */
    'typ_semestru_id'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInner
     */
    'ustav_id'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInner
     */
    'zkratka'?: string;
}
/**
 * 
 * @export
 * @interface GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInner
 */
export interface GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInner {
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInner
     */
    'forma_studia_id'?: number;
    /**
     * 
     * @type {Array<GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInner>}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInner
     */
    'obory'?: Array<GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInner>;
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInner
     */
    'program_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInner
     */
    'typ_studia_id'?: number;
}
/**
 * 
 * @export
 * @interface GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInner
 */
export interface GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInner {
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInner
     */
    'obor_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInner
     */
    'obor_lonsky_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInner
     */
    'obor_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInner
     */
    'obor_zkratka'?: string;
    /**
     * 
     * @type {Array<GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInnerPlanyInner>}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInner
     */
    'plany'?: Array<GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInnerPlanyInner>;
}
/**
 * 
 * @export
 * @interface GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInnerPlanyInner
 */
export interface GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInnerPlanyInner {
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInnerPlanyInner
     */
    'plan_hlavni_obor'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInnerPlanyInner
     */
    'plan_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInnerPlanyInner
     */
    'plan_lonsky_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInnerPlanyInner
     */
    'plan_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInnerPlanyInner
     */
    'plan_zamereni_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInnerPlanyInner
     */
    'plan_zkratka'?: string;
    /**
     * 
     * @type {Array<GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInnerPlanyInnerStupneInner>}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInnerPlanyInner
     */
    'stupne'?: Array<GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInnerPlanyInnerStupneInner>;
}
/**
 * 
 * @export
 * @interface GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInnerPlanyInnerStupneInner
 */
export interface GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInnerPlanyInnerStupneInner {
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInnerPlanyInnerStupneInner
     */
    'cislo_stupne'?: number;
    /**
     * 
     * @type {Array<GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInner>}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInnerPlanyInnerStupneInner
     */
    'rocniky'?: Array<GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInnerPlanyInnerStupneInner
     */
    'stupen_id'?: number;
}
/**
 * 
 * @export
 * @interface GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInner
 */
export interface GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInner {
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInner
     */
    'cislo_rocniku'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseDataPrednaskoveSkupinyInnerStudijniSkupinyInnerProgramyInnerOboryInnerPlanyInnerStupneInnerRocnikyInner
     */
    'rocnik_id'?: number;
}
/**
 * 
 * @export
 * @interface GetProgramOboryV3200Response
 */
export interface GetProgramOboryV3200Response {
    /**
     * 
     * @type {object}
     * @memberof GetProgramOboryV3200Response
     */
    'data'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetProgramOboryV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProgramOboryV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetProgramRadaV3200Response
 */
export interface GetProgramRadaV3200Response {
    /**
     * 
     * @type {GetProgramRadaV3200ResponseData}
     * @memberof GetProgramRadaV3200Response
     */
    'data'?: GetProgramRadaV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetProgramRadaV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProgramRadaV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetProgramRadaV3200ResponseData
 */
export interface GetProgramRadaV3200ResponseData {
    /**
     * 
     * @type {Array<GetProgramRadaV3200ResponseDataProgramyInner>}
     * @memberof GetProgramRadaV3200ResponseData
     */
    'programy'?: Array<GetProgramRadaV3200ResponseDataProgramyInner>;
}
/**
 * 
 * @export
 * @interface GetProgramRadaV3200ResponseDataProgramyInner
 */
export interface GetProgramRadaV3200ResponseDataProgramyInner {
    /**
     * 
     * @type {number}
     * @memberof GetProgramRadaV3200ResponseDataProgramyInner
     */
    'program_id'?: number;
    /**
     * 
     * @type {Array<GetProgramRadaV3200ResponseDataProgramyInnerRadaInner>}
     * @memberof GetProgramRadaV3200ResponseDataProgramyInner
     */
    'rada'?: Array<GetProgramRadaV3200ResponseDataProgramyInnerRadaInner>;
}
/**
 * 
 * @export
 * @interface GetProgramRadaV3200ResponseDataProgramyInnerRadaInner
 */
export interface GetProgramRadaV3200ResponseDataProgramyInnerRadaInner {
    /**
     * 
     * @type {number}
     * @memberof GetProgramRadaV3200ResponseDataProgramyInnerRadaInner
     */
    'clen_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProgramRadaV3200ResponseDataProgramyInnerRadaInner
     */
    'clen_label_pr'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramRadaV3200ResponseDataProgramyInnerRadaInner
     */
    'clen_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramRadaV3200ResponseDataProgramyInnerRadaInner
     */
    'domovske_pracoviste'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramRadaV3200ResponseDataProgramyInnerRadaInner
     */
    'popis'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProgramRadaV3200ResponseDataProgramyInnerRadaInner
     */
    'poradi'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProgramRadaV3200ResponseDataProgramyInnerRadaInner
     */
    'program_rada_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProgramRadaV3200ResponseDataProgramyInnerRadaInner
     */
    'typ_clena_rady_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProgramRadaV3200ResponseDataProgramyInnerRadaInner
     */
    'zamestnanec_vut'?: number;
}
/**
 * 
 * @export
 * @interface GetProgramV3200Response
 */
export interface GetProgramV3200Response {
    /**
     * 
     * @type {GetProgramV3200ResponseData}
     * @memberof GetProgramV3200Response
     */
    'data'?: GetProgramV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProgramV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetProgramV3200ResponseData
 */
export interface GetProgramV3200ResponseData {
    /**
     * 
     * @type {Array<GetProgramV3200ResponseDataProgramyInner>}
     * @memberof GetProgramV3200ResponseData
     */
    'programy'?: Array<GetProgramV3200ResponseDataProgramyInner>;
}
/**
 * 
 * @export
 * @interface GetProgramV3200ResponseDataProgramyInner
 */
export interface GetProgramV3200ResponseDataProgramyInner {
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'akreditace_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'akreditace_od'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'bez_specializace'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'cena_pro_eu_studenty'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'cena_pro_ne_eu_studenty'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'cena_pro_zahr_studenty_mena'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'charakt_profesi'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'cile_studia_profil_programu_v1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'cile_studia_v2'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'delka_studia'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'dostupnost_zdr_p'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'erasmus'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'fakulta_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'fakulta_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'fakulta_zkratka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'forma'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'forma_studia_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'forma_zr'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'garant_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'garant_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'garant_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'inf_zabezp'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'kod_stud_prog'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'koordinator_ects_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'koordinator_ects_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'koordinator_ects_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'lang_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'moznosti_dalsiho_studia'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'nazev'?: string;
    /**
     * 
     * @type {Array<GetProgramV3200ResponseDataProgramyInnerOblastiInner>}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'oblasti'?: Array<GetProgramV3200ResponseDataProgramyInnerOblastiInner>;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'podminky_splneni'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'pozadavky_na_prijeti'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'pravidla_planu'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'praxe'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'praxe_spoluprace'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'pristup_liter'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'profesni_profil'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'profil_absolventa'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'profil_programu_v2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'prog_povinnosti'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'prog_verze'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'program_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'program_lonsky_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'prubeh_studia'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'rok'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'titul'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'tvurci_cinnost'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'typ'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'upd_ts'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'vnitrni_predpisy'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'zkratka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'znalosti'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'zpusob_ukonceni_studia_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInner
     */
    'zpusob_ukonceni_studia_popis'?: string;
}
/**
 * 
 * @export
 * @interface GetProgramV3200ResponseDataProgramyInnerOblastiInner
 */
export interface GetProgramV3200ResponseDataProgramyInnerOblastiInner {
    /**
     * 
     * @type {number}
     * @memberof GetProgramV3200ResponseDataProgramyInnerOblastiInner
     */
    'oblast_vzdelavani_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProgramV3200ResponseDataProgramyInnerOblastiInner
     */
    'oblast_vzdelavani_tema_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInnerOblastiInner
     */
    'program_oblast_o_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProgramV3200ResponseDataProgramyInnerOblastiInner
     */
    'program_oblast_podil'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProgramV3200ResponseDataProgramyInnerOblastiInner
     */
    'program_oblast_t_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProgramV3200ResponseDataProgramyInnerOblastiInner
     */
    'program_oblast_vychozi_tema'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProgramV3200ResponseDataProgramyInnerOblastiInner
     */
    'program_oblast_vzdelav_tema_id'?: number;
}
/**
 * 
 * @export
 * @interface GetProgramyV3200Response
 */
export interface GetProgramyV3200Response {
    /**
     * 
     * @type {GetProgramyV3200ResponseData}
     * @memberof GetProgramyV3200Response
     */
    'data'?: GetProgramyV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetProgramyV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProgramyV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetProgramyV3200ResponseData
 */
export interface GetProgramyV3200ResponseData {
    /**
     * 
     * @type {Array<GetProgramyV3200ResponseDataProgramyInner>}
     * @memberof GetProgramyV3200ResponseData
     */
    'programy'?: Array<GetProgramyV3200ResponseDataProgramyInner>;
}
/**
 * 
 * @export
 * @interface GetProgramyV3200ResponseDataProgramyInner
 */
export interface GetProgramyV3200ResponseDataProgramyInner {
    /**
     * 
     * @type {string}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'akreditace_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'akreditace_od'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'bez_specializace'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'delka_studia'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'erasmus'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'fakulta_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'fakulta_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'fakulta_zkratka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'forma'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'forma_studia_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'forma_zr'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'garant_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'garant_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'garant_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'kod_stud_prog'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'lang_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'prog_verze'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'program_id'?: number;
    /**
     * 
     * @type {object}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'program_lonsky_id'?: object;
    /**
     * 
     * @type {number}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'rok'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'titul'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'typ'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'upd_ts'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProgramyV3200ResponseDataProgramyInner
     */
    'zkratka'?: string;
}
/**
 * 
 * @export
 * @interface GetPrukazInfoV1200Response
 */
export interface GetPrukazInfoV1200Response {
    /**
     * 
     * @type {GetPrukazInfoV1200ResponseData}
     * @memberof GetPrukazInfoV1200Response
     */
    'data'?: GetPrukazInfoV1200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetPrukazInfoV1200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPrukazInfoV1200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetPrukazInfoV1200ResponseData
 */
export interface GetPrukazInfoV1200ResponseData {
    /**
     * 
     * @type {Array<GetPrukazInfoV1200ResponseDataPrukazyInner>}
     * @memberof GetPrukazInfoV1200ResponseData
     */
    'prukazy'?: Array<GetPrukazInfoV1200ResponseDataPrukazyInner>;
}
/**
 * 
 * @export
 * @interface GetPrukazInfoV1200ResponseDataPrukazyInner
 */
export interface GetPrukazInfoV1200ResponseDataPrukazyInner {
    /**
     * 
     * @type {string}
     * @memberof GetPrukazInfoV1200ResponseDataPrukazyInner
     */
    'card_num_printed'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPrukazInfoV1200ResponseDataPrukazyInner
     */
    'card_number'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPrukazInfoV1200ResponseDataPrukazyInner
     */
    'ident_card_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPrukazInfoV1200ResponseDataPrukazyInner
     */
    'label_pr'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPrukazInfoV1200ResponseDataPrukazyInner
     */
    'login'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPrukazInfoV1200ResponseDataPrukazyInner
     */
    'per_id'?: number;
    /**
     * 
     * @type {Array<GetPrukazInfoV1200ResponseDataPrukazyInnerVztahyInner>}
     * @memberof GetPrukazInfoV1200ResponseDataPrukazyInner
     */
    'vztahy'?: Array<GetPrukazInfoV1200ResponseDataPrukazyInnerVztahyInner>;
}
/**
 * 
 * @export
 * @interface GetPrukazInfoV1200ResponseDataPrukazyInnerVztahyInner
 */
export interface GetPrukazInfoV1200ResponseDataPrukazyInnerVztahyInner {
    /**
     * 
     * @type {string}
     * @memberof GetPrukazInfoV1200ResponseDataPrukazyInnerVztahyInner
     */
    'org_acronym'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPrukazInfoV1200ResponseDataPrukazyInnerVztahyInner
     */
    'orgunitid'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPrukazInfoV1200ResponseDataPrukazyInnerVztahyInner
     */
    'root_org_acronym'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPrukazInfoV1200ResponseDataPrukazyInnerVztahyInner
     */
    'root_orgunitid'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPrukazInfoV1200ResponseDataPrukazyInnerVztahyInner
     */
    'student'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPrukazInfoV1200ResponseDataPrukazyInnerVztahyInner
     */
    'zamestnanec'?: number;
}
/**
 * 
 * @export
 * @interface GetRozvrhPozadavekVyucovaniNavaznostiV3200Response
 */
export interface GetRozvrhPozadavekVyucovaniNavaznostiV3200Response {
    /**
     * 
     * @type {GetRozvrhPozadavekVyucovaniNavaznostiV3200ResponseData}
     * @memberof GetRozvrhPozadavekVyucovaniNavaznostiV3200Response
     */
    'data'?: GetRozvrhPozadavekVyucovaniNavaznostiV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetRozvrhPozadavekVyucovaniNavaznostiV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetRozvrhPozadavekVyucovaniNavaznostiV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetRozvrhPozadavekVyucovaniNavaznostiV3200ResponseData
 */
export interface GetRozvrhPozadavekVyucovaniNavaznostiV3200ResponseData {
    /**
     * 
     * @type {Array<GetRozvrhPozadavekVyucovaniNavaznostiV3200ResponseDataNavaznostiInner>}
     * @memberof GetRozvrhPozadavekVyucovaniNavaznostiV3200ResponseData
     */
    'navaznosti'?: Array<GetRozvrhPozadavekVyucovaniNavaznostiV3200ResponseDataNavaznostiInner>;
}
/**
 * 
 * @export
 * @interface GetRozvrhPozadavekVyucovaniNavaznostiV3200ResponseDataNavaznostiInner
 */
export interface GetRozvrhPozadavekVyucovaniNavaznostiV3200ResponseDataNavaznostiInner {
    /**
     * 
     * @type {number}
     * @memberof GetRozvrhPozadavekVyucovaniNavaznostiV3200ResponseDataNavaznostiInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetRozvrhPozadavekVyucovaniNavaznostiV3200ResponseDataNavaznostiInner
     */
    'navaznost_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRozvrhPozadavekVyucovaniNavaznostiV3200ResponseDataNavaznostiInner
     */
    'poznamka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetRozvrhPozadavekVyucovaniNavaznostiV3200ResponseDataNavaznostiInner
     */
    'predmet_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetRozvrhPozadavekVyucovaniNavaznostiV3200ResponseDataNavaznostiInner
     */
    'rozvrh_jednotka_popis'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRozvrhPozadavekVyucovaniNavaznostiV3200ResponseDataNavaznostiInner
     */
    'rozvrh_jednotka_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetRozvrhPozadavekVyucovaniNavaznostiV3200ResponseDataNavaznostiInner
     */
    'rozvrh_poz_navaznost_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetRozvrhPozadavekVyucovaniNavaznostiV3200ResponseDataNavaznostiInner
     */
    'rozvrh_poz_priorita_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetRozvrhPozadavekVyucovaniNavaznostiV3200ResponseDataNavaznostiInner
     */
    'rozvrh_poz_priorita_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetRozvrhPozadavekVyucovaniNavaznostiV3200ResponseDataNavaznostiInner
     */
    'rozvrh_poz_priorita_poradi'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetRozvrhPozadavekVyucovaniNavaznostiV3200ResponseDataNavaznostiInner
     */
    'rozvrh_pozadavek_navaznost_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetRozvrhPozadavekVyucovaniNavaznostiV3200ResponseDataNavaznostiInner
     */
    'rozvrh_pozadavek_vyucovani_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetRozvrhPozadavekVyucovaniNavaznostiV3200ResponseDataNavaznostiInner
     */
    'rozvrhova_jednotka_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetRozvrhPozadavekVyucovaniNavaznostiV3200ResponseDataNavaznostiInner
     */
    'typ_rozvrhove_jednotky_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetRozvrhPozadavekVyucovaniNavaznostiV3200ResponseDataNavaznostiInner
     */
    'zruseno'?: number;
}
/**
 * 
 * @export
 * @interface GetRozvrhPozadavekVyucovaniPreferenceV3200Response
 */
export interface GetRozvrhPozadavekVyucovaniPreferenceV3200Response {
    /**
     * 
     * @type {GetRozvrhPozadavekVyucovaniPreferenceV3200ResponseData}
     * @memberof GetRozvrhPozadavekVyucovaniPreferenceV3200Response
     */
    'data'?: GetRozvrhPozadavekVyucovaniPreferenceV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetRozvrhPozadavekVyucovaniPreferenceV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetRozvrhPozadavekVyucovaniPreferenceV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetRozvrhPozadavekVyucovaniPreferenceV3200ResponseData
 */
export interface GetRozvrhPozadavekVyucovaniPreferenceV3200ResponseData {
    /**
     * 
     * @type {Array<GetRozvrhPozadavekVyucovaniPreferenceV3200ResponseDataPreferenceInner>}
     * @memberof GetRozvrhPozadavekVyucovaniPreferenceV3200ResponseData
     */
    'preference'?: Array<GetRozvrhPozadavekVyucovaniPreferenceV3200ResponseDataPreferenceInner>;
}
/**
 * 
 * @export
 * @interface GetRozvrhPozadavekVyucovaniPreferenceV3200ResponseDataPreferenceInner
 */
export interface GetRozvrhPozadavekVyucovaniPreferenceV3200ResponseDataPreferenceInner {
    /**
     * 
     * @type {string}
     * @memberof GetRozvrhPozadavekVyucovaniPreferenceV3200ResponseDataPreferenceInner
     */
    'cas_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRozvrhPozadavekVyucovaniPreferenceV3200ResponseDataPreferenceInner
     */
    'cas_od'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetRozvrhPozadavekVyucovaniPreferenceV3200ResponseDataPreferenceInner
     */
    'den_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetRozvrhPozadavekVyucovaniPreferenceV3200ResponseDataPreferenceInner
     */
    'navazovat'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetRozvrhPozadavekVyucovaniPreferenceV3200ResponseDataPreferenceInner
     */
    'poznamka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetRozvrhPozadavekVyucovaniPreferenceV3200ResponseDataPreferenceInner
     */
    'prestavka'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetRozvrhPozadavekVyucovaniPreferenceV3200ResponseDataPreferenceInner
     */
    'rozvrh_poz_priorita_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetRozvrhPozadavekVyucovaniPreferenceV3200ResponseDataPreferenceInner
     */
    'rozvrh_poz_priorita_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetRozvrhPozadavekVyucovaniPreferenceV3200ResponseDataPreferenceInner
     */
    'rozvrh_poz_priorita_poradi'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetRozvrhPozadavekVyucovaniPreferenceV3200ResponseDataPreferenceInner
     */
    'rozvrh_pozadavek_vyucovani_pref_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetRozvrhPozadavekVyucovaniPreferenceV3200ResponseDataPreferenceInner
     */
    'soucasne'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetRozvrhPozadavekVyucovaniPreferenceV3200ResponseDataPreferenceInner
     */
    'zruseno'?: number;
}
/**
 * 
 * @export
 * @interface GetSkupinyV3200Response
 */
export interface GetSkupinyV3200Response {
    /**
     * 
     * @type {GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseData}
     * @memberof GetSkupinyV3200Response
     */
    'data'?: GetPrednaskovaSkupinaStudijniSkupinaV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetSkupinyV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetSkupinyV3200Response
     */
    'rows_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetSkupinyV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetVyucBlokDenMistnostiV3200Response
 */
export interface GetVyucBlokDenMistnostiV3200Response {
    /**
     * 
     * @type {GetVyucBlokDenMistnostiV3200ResponseData}
     * @memberof GetVyucBlokDenMistnostiV3200Response
     */
    'data'?: GetVyucBlokDenMistnostiV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetVyucBlokDenMistnostiV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetVyucBlokDenMistnostiV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetVyucBlokDenMistnostiV3200ResponseData
 */
export interface GetVyucBlokDenMistnostiV3200ResponseData {
    /**
     * 
     * @type {Array<GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInner>}
     * @memberof GetVyucBlokDenMistnostiV3200ResponseData
     */
    'vyucovani'?: Array<GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInner>;
}
/**
 * 
 * @export
 * @interface GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInner
 */
export interface GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInner {
    /**
     * 
     * @type {Array<GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInnerBlokyInner>}
     * @memberof GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInner
     */
    'bloky'?: Array<GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInnerBlokyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInner
     */
    'vyucovani_id'?: number;
}
/**
 * 
 * @export
 * @interface GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInnerBlokyInner
 */
export interface GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInnerBlokyInner {
    /**
     * 
     * @type {Array<GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInnerBlokyInnerDnyInner>}
     * @memberof GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInnerBlokyInner
     */
    'dny'?: Array<GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInnerBlokyInnerDnyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInnerBlokyInner
     */
    'vyucovani_blok_id'?: number;
}
/**
 * 
 * @export
 * @interface GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInnerBlokyInnerDnyInner
 */
export interface GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInnerBlokyInnerDnyInner {
    /**
     * 
     * @type {Array<GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInnerBlokyInnerDnyInnerMistnostiInner>}
     * @memberof GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInnerBlokyInnerDnyInner
     */
    'mistnosti'?: Array<GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInnerBlokyInnerDnyInnerMistnostiInner>;
    /**
     * 
     * @type {number}
     * @memberof GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInnerBlokyInnerDnyInner
     */
    'vyuc_blok_den_id'?: number;
}
/**
 * 
 * @export
 * @interface GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInnerBlokyInnerDnyInnerMistnostiInner
 */
export interface GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInnerBlokyInnerDnyInnerMistnostiInner {
    /**
     * 
     * @type {number}
     * @memberof GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInnerBlokyInnerDnyInnerMistnostiInner
     */
    'mistnost_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInnerBlokyInnerDnyInnerMistnostiInner
     */
    'mistnost_label'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInnerBlokyInnerDnyInnerMistnostiInner
     */
    'nahradni'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInnerBlokyInnerDnyInnerMistnostiInner
     */
    'vyuc_blok_den_mistnost_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetVyucBlokDenMistnostiV3200ResponseDataVyucovaniInnerBlokyInnerDnyInnerMistnostiInner
     */
    'zruseno'?: number;
}
/**
 * 
 * @export
 * @interface GetVyucBlokDenVyucujiciV3200Response
 */
export interface GetVyucBlokDenVyucujiciV3200Response {
    /**
     * 
     * @type {GetVyucBlokDenVyucujiciV3200ResponseData}
     * @memberof GetVyucBlokDenVyucujiciV3200Response
     */
    'data'?: GetVyucBlokDenVyucujiciV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetVyucBlokDenVyucujiciV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetVyucBlokDenVyucujiciV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetVyucBlokDenVyucujiciV3200ResponseData
 */
export interface GetVyucBlokDenVyucujiciV3200ResponseData {
    /**
     * 
     * @type {Array<GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInner>}
     * @memberof GetVyucBlokDenVyucujiciV3200ResponseData
     */
    'vyucovani'?: Array<GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInner>;
}
/**
 * 
 * @export
 * @interface GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInner
 */
export interface GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInner {
    /**
     * 
     * @type {Array<GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInnerBlokyInner>}
     * @memberof GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInner
     */
    'bloky'?: Array<GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInnerBlokyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInner
     */
    'vyucovani_id'?: number;
}
/**
 * 
 * @export
 * @interface GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInnerBlokyInner
 */
export interface GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInnerBlokyInner {
    /**
     * 
     * @type {Array<GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInnerBlokyInnerDnyInner>}
     * @memberof GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInnerBlokyInner
     */
    'dny'?: Array<GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInnerBlokyInnerDnyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInnerBlokyInner
     */
    'vyucovani_blok_id'?: number;
}
/**
 * 
 * @export
 * @interface GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInnerBlokyInnerDnyInner
 */
export interface GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInnerBlokyInnerDnyInner {
    /**
     * 
     * @type {number}
     * @memberof GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInnerBlokyInnerDnyInner
     */
    'vyuc_blok_den_id'?: number;
    /**
     * 
     * @type {Array<GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInnerBlokyInnerDnyInnerVyucujiciInner>}
     * @memberof GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInnerBlokyInnerDnyInner
     */
    'vyucujici'?: Array<GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInnerBlokyInnerDnyInnerVyucujiciInner>;
}
/**
 * 
 * @export
 * @interface GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInnerBlokyInnerDnyInnerVyucujiciInner
 */
export interface GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInnerBlokyInnerDnyInnerVyucujiciInner {
    /**
     * 
     * @type {number}
     * @memberof GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInnerBlokyInnerDnyInnerVyucujiciInner
     */
    'asistujici'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInnerBlokyInnerDnyInnerVyucujiciInner
     */
    'osoba_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInnerBlokyInnerDnyInnerVyucujiciInner
     */
    'osoba_label'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInnerBlokyInnerDnyInnerVyucujiciInner
     */
    'suplujici'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInnerBlokyInnerDnyInnerVyucujiciInner
     */
    'vyuc_blok_den_vyucujici_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInnerBlokyInnerDnyInnerVyucujiciInner
     */
    'zruseno'?: number;
}
/**
 * 
 * @export
 * @interface GetVyucovaniBlokMistnostiV3200Response
 */
export interface GetVyucovaniBlokMistnostiV3200Response {
    /**
     * 
     * @type {GetVyucovaniBlokMistnostiV3200ResponseData}
     * @memberof GetVyucovaniBlokMistnostiV3200Response
     */
    'data'?: GetVyucovaniBlokMistnostiV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetVyucovaniBlokMistnostiV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetVyucovaniBlokMistnostiV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetVyucovaniBlokMistnostiV3200ResponseData
 */
export interface GetVyucovaniBlokMistnostiV3200ResponseData {
    /**
     * 
     * @type {Array<GetVyucovaniBlokMistnostiV3200ResponseDataVyucovaniInner>}
     * @memberof GetVyucovaniBlokMistnostiV3200ResponseData
     */
    'vyucovani'?: Array<GetVyucovaniBlokMistnostiV3200ResponseDataVyucovaniInner>;
}
/**
 * 
 * @export
 * @interface GetVyucovaniBlokMistnostiV3200ResponseDataVyucovaniInner
 */
export interface GetVyucovaniBlokMistnostiV3200ResponseDataVyucovaniInner {
    /**
     * 
     * @type {Array<GetVyucovaniBlokMistnostiV3200ResponseDataVyucovaniInnerBlokyInner>}
     * @memberof GetVyucovaniBlokMistnostiV3200ResponseDataVyucovaniInner
     */
    'bloky'?: Array<GetVyucovaniBlokMistnostiV3200ResponseDataVyucovaniInnerBlokyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetVyucovaniBlokMistnostiV3200ResponseDataVyucovaniInner
     */
    'vyucovani_id'?: number;
}
/**
 * 
 * @export
 * @interface GetVyucovaniBlokMistnostiV3200ResponseDataVyucovaniInnerBlokyInner
 */
export interface GetVyucovaniBlokMistnostiV3200ResponseDataVyucovaniInnerBlokyInner {
    /**
     * 
     * @type {Array<GetVyucovaniMistnostiV3200ResponseDataVyucovaniInnerMistnostiInner>}
     * @memberof GetVyucovaniBlokMistnostiV3200ResponseDataVyucovaniInnerBlokyInner
     */
    'mistnosti'?: Array<GetVyucovaniMistnostiV3200ResponseDataVyucovaniInnerMistnostiInner>;
    /**
     * 
     * @type {number}
     * @memberof GetVyucovaniBlokMistnostiV3200ResponseDataVyucovaniInnerBlokyInner
     */
    'vyucovani_blok_id'?: number;
}
/**
 * 
 * @export
 * @interface GetVyucovaniBlokVyucujiciV3200Response
 */
export interface GetVyucovaniBlokVyucujiciV3200Response {
    /**
     * 
     * @type {GetVyucovaniBlokVyucujiciV3200ResponseData}
     * @memberof GetVyucovaniBlokVyucujiciV3200Response
     */
    'data'?: GetVyucovaniBlokVyucujiciV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetVyucovaniBlokVyucujiciV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetVyucovaniBlokVyucujiciV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetVyucovaniBlokVyucujiciV3200ResponseData
 */
export interface GetVyucovaniBlokVyucujiciV3200ResponseData {
    /**
     * 
     * @type {Array<GetVyucovaniBlokVyucujiciV3200ResponseDataVyucovaniInner>}
     * @memberof GetVyucovaniBlokVyucujiciV3200ResponseData
     */
    'vyucovani'?: Array<GetVyucovaniBlokVyucujiciV3200ResponseDataVyucovaniInner>;
}
/**
 * 
 * @export
 * @interface GetVyucovaniBlokVyucujiciV3200ResponseDataVyucovaniInner
 */
export interface GetVyucovaniBlokVyucujiciV3200ResponseDataVyucovaniInner {
    /**
     * 
     * @type {Array<GetVyucovaniBlokVyucujiciV3200ResponseDataVyucovaniInnerBlokyInner>}
     * @memberof GetVyucovaniBlokVyucujiciV3200ResponseDataVyucovaniInner
     */
    'bloky'?: Array<GetVyucovaniBlokVyucujiciV3200ResponseDataVyucovaniInnerBlokyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetVyucovaniBlokVyucujiciV3200ResponseDataVyucovaniInner
     */
    'vyucovani_id'?: number;
}
/**
 * 
 * @export
 * @interface GetVyucovaniBlokVyucujiciV3200ResponseDataVyucovaniInnerBlokyInner
 */
export interface GetVyucovaniBlokVyucujiciV3200ResponseDataVyucovaniInnerBlokyInner {
    /**
     * 
     * @type {number}
     * @memberof GetVyucovaniBlokVyucujiciV3200ResponseDataVyucovaniInnerBlokyInner
     */
    'vyucovani_blok_id'?: number;
    /**
     * 
     * @type {Array<GetVyucovaniVyucujiciV3200ResponseDataVyucovaniInnerVyucujiciInner>}
     * @memberof GetVyucovaniBlokVyucujiciV3200ResponseDataVyucovaniInnerBlokyInner
     */
    'vyucujici'?: Array<GetVyucovaniVyucujiciV3200ResponseDataVyucovaniInnerVyucujiciInner>;
}
/**
 * 
 * @export
 * @interface GetVyucovaniMistnostiV3200Response
 */
export interface GetVyucovaniMistnostiV3200Response {
    /**
     * 
     * @type {GetVyucovaniMistnostiV3200ResponseData}
     * @memberof GetVyucovaniMistnostiV3200Response
     */
    'data'?: GetVyucovaniMistnostiV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetVyucovaniMistnostiV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetVyucovaniMistnostiV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetVyucovaniMistnostiV3200ResponseData
 */
export interface GetVyucovaniMistnostiV3200ResponseData {
    /**
     * 
     * @type {Array<GetVyucovaniMistnostiV3200ResponseDataVyucovaniInner>}
     * @memberof GetVyucovaniMistnostiV3200ResponseData
     */
    'vyucovani'?: Array<GetVyucovaniMistnostiV3200ResponseDataVyucovaniInner>;
}
/**
 * 
 * @export
 * @interface GetVyucovaniMistnostiV3200ResponseDataVyucovaniInner
 */
export interface GetVyucovaniMistnostiV3200ResponseDataVyucovaniInner {
    /**
     * 
     * @type {Array<GetVyucovaniMistnostiV3200ResponseDataVyucovaniInnerMistnostiInner>}
     * @memberof GetVyucovaniMistnostiV3200ResponseDataVyucovaniInner
     */
    'mistnosti'?: Array<GetVyucovaniMistnostiV3200ResponseDataVyucovaniInnerMistnostiInner>;
    /**
     * 
     * @type {number}
     * @memberof GetVyucovaniMistnostiV3200ResponseDataVyucovaniInner
     */
    'vyucovani_id'?: number;
}
/**
 * 
 * @export
 * @interface GetVyucovaniMistnostiV3200ResponseDataVyucovaniInnerMistnostiInner
 */
export interface GetVyucovaniMistnostiV3200ResponseDataVyucovaniInnerMistnostiInner {
    /**
     * 
     * @type {number}
     * @memberof GetVyucovaniMistnostiV3200ResponseDataVyucovaniInnerMistnostiInner
     */
    'mistnost_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetVyucovaniMistnostiV3200ResponseDataVyucovaniInnerMistnostiInner
     */
    'mistnost_label'?: string;
}
/**
 * 
 * @export
 * @interface GetVyucovaniSkupinyV3200Response
 */
export interface GetVyucovaniSkupinyV3200Response {
    /**
     * 
     * @type {GetVyucovaniSkupinyV3200ResponseData}
     * @memberof GetVyucovaniSkupinyV3200Response
     */
    'data'?: GetVyucovaniSkupinyV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetVyucovaniSkupinyV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetVyucovaniSkupinyV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetVyucovaniSkupinyV3200ResponseData
 */
export interface GetVyucovaniSkupinyV3200ResponseData {
    /**
     * 
     * @type {Array<GetVyucovaniSkupinyV3200ResponseDataVyucovaniInner>}
     * @memberof GetVyucovaniSkupinyV3200ResponseData
     */
    'vyucovani'?: Array<GetVyucovaniSkupinyV3200ResponseDataVyucovaniInner>;
}
/**
 * 
 * @export
 * @interface GetVyucovaniSkupinyV3200ResponseDataVyucovaniInner
 */
export interface GetVyucovaniSkupinyV3200ResponseDataVyucovaniInner {
    /**
     * 
     * @type {Array<GetVyucovaniSkupinyV3200ResponseDataVyucovaniInnerPrednaskoveSkupinyInner>}
     * @memberof GetVyucovaniSkupinyV3200ResponseDataVyucovaniInner
     */
    'prednaskove_skupiny'?: Array<GetVyucovaniSkupinyV3200ResponseDataVyucovaniInnerPrednaskoveSkupinyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetVyucovaniSkupinyV3200ResponseDataVyucovaniInner
     */
    'vyucovani_id'?: number;
}
/**
 * 
 * @export
 * @interface GetVyucovaniSkupinyV3200ResponseDataVyucovaniInnerPrednaskoveSkupinyInner
 */
export interface GetVyucovaniSkupinyV3200ResponseDataVyucovaniInnerPrednaskoveSkupinyInner {
    /**
     * 
     * @type {number}
     * @memberof GetVyucovaniSkupinyV3200ResponseDataVyucovaniInnerPrednaskoveSkupinyInner
     */
    'prednaskova_skupina_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetVyucovaniSkupinyV3200ResponseDataVyucovaniInnerPrednaskoveSkupinyInner
     */
    'ps_fakulta_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetVyucovaniSkupinyV3200ResponseDataVyucovaniInnerPrednaskoveSkupinyInner
     */
    'ps_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetVyucovaniSkupinyV3200ResponseDataVyucovaniInnerPrednaskoveSkupinyInner
     */
    'ps_specialni_skupina'?: number;
    /**
     * 
     * @type {Array<GetVyucovaniSkupinyV3200ResponseDataVyucovaniInnerPrednaskoveSkupinyInnerStudijniSkupinyInner>}
     * @memberof GetVyucovaniSkupinyV3200ResponseDataVyucovaniInnerPrednaskoveSkupinyInner
     */
    'studijni_skupiny'?: Array<GetVyucovaniSkupinyV3200ResponseDataVyucovaniInnerPrednaskoveSkupinyInnerStudijniSkupinyInner>;
}
/**
 * 
 * @export
 * @interface GetVyucovaniSkupinyV3200ResponseDataVyucovaniInnerPrednaskoveSkupinyInnerStudijniSkupinyInner
 */
export interface GetVyucovaniSkupinyV3200ResponseDataVyucovaniInnerPrednaskoveSkupinyInnerStudijniSkupinyInner {
    /**
     * 
     * @type {number}
     * @memberof GetVyucovaniSkupinyV3200ResponseDataVyucovaniInnerPrednaskoveSkupinyInnerStudijniSkupinyInner
     */
    'ss_specialni_skupina'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetVyucovaniSkupinyV3200ResponseDataVyucovaniInnerPrednaskoveSkupinyInnerStudijniSkupinyInner
     */
    'ss_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetVyucovaniSkupinyV3200ResponseDataVyucovaniInnerPrednaskoveSkupinyInnerStudijniSkupinyInner
     */
    'studijni_skupina_id'?: number;
}
/**
 * 
 * @export
 * @interface GetVyucovaniVyucujiciV3200Response
 */
export interface GetVyucovaniVyucujiciV3200Response {
    /**
     * 
     * @type {GetVyucovaniVyucujiciV3200ResponseData}
     * @memberof GetVyucovaniVyucujiciV3200Response
     */
    'data'?: GetVyucovaniVyucujiciV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetVyucovaniVyucujiciV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetVyucovaniVyucujiciV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetVyucovaniVyucujiciV3200ResponseData
 */
export interface GetVyucovaniVyucujiciV3200ResponseData {
    /**
     * 
     * @type {Array<GetVyucovaniVyucujiciV3200ResponseDataVyucovaniInner>}
     * @memberof GetVyucovaniVyucujiciV3200ResponseData
     */
    'vyucovani'?: Array<GetVyucovaniVyucujiciV3200ResponseDataVyucovaniInner>;
}
/**
 * 
 * @export
 * @interface GetVyucovaniVyucujiciV3200ResponseDataVyucovaniInner
 */
export interface GetVyucovaniVyucujiciV3200ResponseDataVyucovaniInner {
    /**
     * 
     * @type {number}
     * @memberof GetVyucovaniVyucujiciV3200ResponseDataVyucovaniInner
     */
    'vyucovani_id'?: number;
    /**
     * 
     * @type {Array<GetVyucovaniVyucujiciV3200ResponseDataVyucovaniInnerVyucujiciInner>}
     * @memberof GetVyucovaniVyucujiciV3200ResponseDataVyucovaniInner
     */
    'vyucujici'?: Array<GetVyucovaniVyucujiciV3200ResponseDataVyucovaniInnerVyucujiciInner>;
}
/**
 * 
 * @export
 * @interface GetVyucovaniVyucujiciV3200ResponseDataVyucovaniInnerVyucujiciInner
 */
export interface GetVyucovaniVyucujiciV3200ResponseDataVyucovaniInnerVyucujiciInner {
    /**
     * 
     * @type {number}
     * @memberof GetVyucovaniVyucujiciV3200ResponseDataVyucovaniInnerVyucujiciInner
     */
    'asistujici'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetVyucovaniVyucujiciV3200ResponseDataVyucovaniInnerVyucujiciInner
     */
    'osoba_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetVyucovaniVyucujiciV3200ResponseDataVyucovaniInnerVyucujiciInner
     */
    'osoba_label'?: string;
}
/**
 * 
 * @export
 * @interface GetZamestnanciV4200Response
 */
export interface GetZamestnanciV4200Response {
    /**
     * 
     * @type {GetZamestnanciV4200ResponseData}
     * @memberof GetZamestnanciV4200Response
     */
    'data'?: GetZamestnanciV4200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanciV4200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanciV4200Response
     */
    'rows_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanciV4200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetZamestnanciV4200ResponseData
 */
export interface GetZamestnanciV4200ResponseData {
    /**
     * 
     * @type {Array<GetZamestnanciV4200ResponseDataOsobyInner>}
     * @memberof GetZamestnanciV4200ResponseData
     */
    'osoby'?: Array<GetZamestnanciV4200ResponseDataOsobyInner>;
}
/**
 * 
 * @export
 * @interface GetZamestnanciV4200ResponseDataOsobyInner
 */
export interface GetZamestnanciV4200ResponseDataOsobyInner {
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'dodavatel_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'fax'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'foto_bez_prihlaseni'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'foto_po_prihlaseni'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'foto_web'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'jmeno_bez_titulu'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'jmenovka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'label_pr'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'mistnost_label'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'mistnost_label_adr'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'mistnost_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'mistnost_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'orcid'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'per_honorific_title'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'per_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'researcher_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'role_gdpr'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'scopus_authorid'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'skupina'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'tel_klapka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'telefon'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'telefon_klapka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'tituly_pred'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanciV4200ResponseDataOsobyInner
     */
    'tituly_za'?: string;
}
/**
 * 
 * @export
 * @interface GetZamestnanecAktivityV3200Response
 */
export interface GetZamestnanecAktivityV3200Response {
    /**
     * 
     * @type {GetZamestnanecAktivityV3200ResponseData}
     * @memberof GetZamestnanecAktivityV3200Response
     */
    'data'?: GetZamestnanecAktivityV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecAktivityV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecAktivityV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetZamestnanecAktivityV3200ResponseData
 */
export interface GetZamestnanecAktivityV3200ResponseData {
    /**
     * 
     * @type {Array<GetZamestnanecAktivityV3200ResponseDataOsobyInner>}
     * @memberof GetZamestnanecAktivityV3200ResponseData
     */
    'osoby'?: Array<GetZamestnanecAktivityV3200ResponseDataOsobyInner>;
}
/**
 * 
 * @export
 * @interface GetZamestnanecAktivityV3200ResponseDataOsobyInner
 */
export interface GetZamestnanecAktivityV3200ResponseDataOsobyInner {
    /**
     * 
     * @type {Array<GetZamestnanecAktivityV3200ResponseDataOsobyInnerAktivityInner>}
     * @memberof GetZamestnanecAktivityV3200ResponseDataOsobyInner
     */
    'aktivity'?: Array<GetZamestnanecAktivityV3200ResponseDataOsobyInnerAktivityInner>;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecAktivityV3200ResponseDataOsobyInner
     */
    'per_id'?: number;
}
/**
 * 
 * @export
 * @interface GetZamestnanecAktivityV3200ResponseDataOsobyInnerAktivityInner
 */
export interface GetZamestnanecAktivityV3200ResponseDataOsobyInnerAktivityInner {
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecAktivityV3200ResponseDataOsobyInnerAktivityInner
     */
    'citace'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecAktivityV3200ResponseDataOsobyInnerAktivityInner
     */
    'ma_detail'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecAktivityV3200ResponseDataOsobyInnerAktivityInner
     */
    'prod_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecAktivityV3200ResponseDataOsobyInnerAktivityInner
     */
    'prod_uri'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecAktivityV3200ResponseDataOsobyInnerAktivityInner
     */
    'result_vav_id'?: number;
}
/**
 * 
 * @export
 * @interface GetZamestnanecKontaktDoplnkoveEmailyV3200Response
 */
export interface GetZamestnanecKontaktDoplnkoveEmailyV3200Response {
    /**
     * 
     * @type {GetZamestnanecKontaktDoplnkoveEmailyV3200ResponseData}
     * @memberof GetZamestnanecKontaktDoplnkoveEmailyV3200Response
     */
    'data'?: GetZamestnanecKontaktDoplnkoveEmailyV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecKontaktDoplnkoveEmailyV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecKontaktDoplnkoveEmailyV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetZamestnanecKontaktDoplnkoveEmailyV3200ResponseData
 */
export interface GetZamestnanecKontaktDoplnkoveEmailyV3200ResponseData {
    /**
     * 
     * @type {Array<GetZamestnanecKontaktDoplnkoveEmailyV3200ResponseDataOsobyInner>}
     * @memberof GetZamestnanecKontaktDoplnkoveEmailyV3200ResponseData
     */
    'osoby'?: Array<GetZamestnanecKontaktDoplnkoveEmailyV3200ResponseDataOsobyInner>;
}
/**
 * 
 * @export
 * @interface GetZamestnanecKontaktDoplnkoveEmailyV3200ResponseDataOsobyInner
 */
export interface GetZamestnanecKontaktDoplnkoveEmailyV3200ResponseDataOsobyInner {
    /**
     * 
     * @type {Array<GetZamestnanecKontaktDoplnkoveEmailyV3200ResponseDataOsobyInnerKontaktyInner>}
     * @memberof GetZamestnanecKontaktDoplnkoveEmailyV3200ResponseDataOsobyInner
     */
    'kontakty'?: Array<GetZamestnanecKontaktDoplnkoveEmailyV3200ResponseDataOsobyInnerKontaktyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecKontaktDoplnkoveEmailyV3200ResponseDataOsobyInner
     */
    'per_id'?: number;
}
/**
 * 
 * @export
 * @interface GetZamestnanecKontaktDoplnkoveEmailyV3200ResponseDataOsobyInnerKontaktyInner
 */
export interface GetZamestnanecKontaktDoplnkoveEmailyV3200ResponseDataOsobyInnerKontaktyInner {
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecKontaktDoplnkoveEmailyV3200ResponseDataOsobyInnerKontaktyInner
     */
    'con_id'?: number;
    /**
     * 
     * @type {Array<GetZamestnanecKontaktDoplnkoveEmailyV3200ResponseDataOsobyInnerKontaktyInnerEmailyInner>}
     * @memberof GetZamestnanecKontaktDoplnkoveEmailyV3200ResponseDataOsobyInnerKontaktyInner
     */
    'emaily'?: Array<GetZamestnanecKontaktDoplnkoveEmailyV3200ResponseDataOsobyInnerKontaktyInnerEmailyInner>;
}
/**
 * 
 * @export
 * @interface GetZamestnanecKontaktDoplnkoveEmailyV3200ResponseDataOsobyInnerKontaktyInnerEmailyInner
 */
export interface GetZamestnanecKontaktDoplnkoveEmailyV3200ResponseDataOsobyInnerKontaktyInnerEmailyInner {
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecKontaktDoplnkoveEmailyV3200ResponseDataOsobyInnerKontaktyInnerEmailyInner
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface GetZamestnanecKontaktyV4403Response
 */
export interface GetZamestnanecKontaktyV4403Response {
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecKontaktyV4403Response
     */
    'format'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecKontaktyV4403Response
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface GetZamestnanecKonzultacniHodinyV3200Response
 */
export interface GetZamestnanecKonzultacniHodinyV3200Response {
    /**
     * 
     * @type {GetZamestnanecKonzultacniHodinyV3200ResponseData}
     * @memberof GetZamestnanecKonzultacniHodinyV3200Response
     */
    'data'?: GetZamestnanecKonzultacniHodinyV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecKonzultacniHodinyV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecKonzultacniHodinyV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetZamestnanecKonzultacniHodinyV3200ResponseData
 */
export interface GetZamestnanecKonzultacniHodinyV3200ResponseData {
    /**
     * 
     * @type {Array<GetZamestnanecKonzultacniHodinyV3200ResponseDataOsobyInner>}
     * @memberof GetZamestnanecKonzultacniHodinyV3200ResponseData
     */
    'osoby'?: Array<GetZamestnanecKonzultacniHodinyV3200ResponseDataOsobyInner>;
}
/**
 * 
 * @export
 * @interface GetZamestnanecKonzultacniHodinyV3200ResponseDataOsobyInner
 */
export interface GetZamestnanecKonzultacniHodinyV3200ResponseDataOsobyInner {
    /**
     * 
     * @type {Array<GetZamestnanecKonzultacniHodinyV3200ResponseDataOsobyInnerKonzultacniHodinyInner>}
     * @memberof GetZamestnanecKonzultacniHodinyV3200ResponseDataOsobyInner
     */
    'konzultacni_hodiny'?: Array<GetZamestnanecKonzultacniHodinyV3200ResponseDataOsobyInnerKonzultacniHodinyInner>;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecKonzultacniHodinyV3200ResponseDataOsobyInner
     */
    'per_id'?: number;
}
/**
 * 
 * @export
 * @interface GetZamestnanecKonzultacniHodinyV3200ResponseDataOsobyInnerKonzultacniHodinyInner
 */
export interface GetZamestnanecKonzultacniHodinyV3200ResponseDataOsobyInnerKonzultacniHodinyInner {
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecKonzultacniHodinyV3200ResponseDataOsobyInnerKonzultacniHodinyInner
     */
    'cas_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecKonzultacniHodinyV3200ResponseDataOsobyInnerKonzultacniHodinyInner
     */
    'cas_od'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecKonzultacniHodinyV3200ResponseDataOsobyInnerKonzultacniHodinyInner
     */
    'den'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecKonzultacniHodinyV3200ResponseDataOsobyInnerKonzultacniHodinyInner
     */
    'den_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecKonzultacniHodinyV3200ResponseDataOsobyInnerKonzultacniHodinyInner
     */
    'den_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecKonzultacniHodinyV3200ResponseDataOsobyInnerKonzultacniHodinyInner
     */
    'konzultacni_hodiny_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecKonzultacniHodinyV3200ResponseDataOsobyInnerKonzultacniHodinyInner
     */
    'mistnost_label'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecKonzultacniHodinyV3200ResponseDataOsobyInnerKonzultacniHodinyInner
     */
    'mistnost_label_adr'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecKonzultacniHodinyV3200ResponseDataOsobyInnerKonzultacniHodinyInner
     */
    'mistnost_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecKonzultacniHodinyV3200ResponseDataOsobyInnerKonzultacniHodinyInner
     */
    'pasp_mistnost_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecKonzultacniHodinyV3200ResponseDataOsobyInnerKonzultacniHodinyInner
     */
    'popis'?: string;
}
/**
 * 
 * @export
 * @interface GetZamestnanecPozadavkyOmezeniV3200Response
 */
export interface GetZamestnanecPozadavkyOmezeniV3200Response {
    /**
     * 
     * @type {GetZamestnanecPozadavkyOmezeniV3200ResponseData}
     * @memberof GetZamestnanecPozadavkyOmezeniV3200Response
     */
    'data'?: GetZamestnanecPozadavkyOmezeniV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPozadavkyOmezeniV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPozadavkyOmezeniV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetZamestnanecPozadavkyOmezeniV3200ResponseData
 */
export interface GetZamestnanecPozadavkyOmezeniV3200ResponseData {
    /**
     * 
     * @type {Array<GetZamestnanecPozadavkyOmezeniV3200ResponseDataPreferenceInner>}
     * @memberof GetZamestnanecPozadavkyOmezeniV3200ResponseData
     */
    'preference'?: Array<GetZamestnanecPozadavkyOmezeniV3200ResponseDataPreferenceInner>;
}
/**
 * 
 * @export
 * @interface GetZamestnanecPozadavkyOmezeniV3200ResponseDataPreferenceInner
 */
export interface GetZamestnanecPozadavkyOmezeniV3200ResponseDataPreferenceInner {
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPozadavkyOmezeniV3200ResponseDataPreferenceInner
     */
    'cas_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPozadavkyOmezeniV3200ResponseDataPreferenceInner
     */
    'cas_od'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPozadavkyOmezeniV3200ResponseDataPreferenceInner
     */
    'den'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPozadavkyOmezeniV3200ResponseDataPreferenceInner
     */
    'den_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPozadavkyOmezeniV3200ResponseDataPreferenceInner
     */
    'den_zkratka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPozadavkyOmezeniV3200ResponseDataPreferenceInner
     */
    'omezeni_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPozadavkyOmezeniV3200ResponseDataPreferenceInner
     */
    'opakovani'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPozadavkyOmezeniV3200ResponseDataPreferenceInner
     */
    'osoba_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPozadavkyOmezeniV3200ResponseDataPreferenceInner
     */
    'platnost_do'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPozadavkyOmezeniV3200ResponseDataPreferenceInner
     */
    'platnost_od'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPozadavkyOmezeniV3200ResponseDataPreferenceInner
     */
    'popis'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPozadavkyOmezeniV3200ResponseDataPreferenceInner
     */
    'rozvrh_omez_druh_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPozadavkyOmezeniV3200ResponseDataPreferenceInner
     */
    'rozvrh_omezeni_osoba_id'?: number;
}
/**
 * 
 * @export
 * @interface GetZamestnanecPredmetyGarantovaneV3200Response
 */
export interface GetZamestnanecPredmetyGarantovaneV3200Response {
    /**
     * 
     * @type {GetZamestnanecPredmetyGarantovaneV3200ResponseData}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200Response
     */
    'data'?: GetZamestnanecPredmetyGarantovaneV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetZamestnanecPredmetyGarantovaneV3200ResponseData
 */
export interface GetZamestnanecPredmetyGarantovaneV3200ResponseData {
    /**
     * 
     * @type {Array<GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInner>}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200ResponseData
     */
    'osoby'?: Array<GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInner>;
}
/**
 * 
 * @export
 * @interface GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInner
 */
export interface GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInner {
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInner
     */
    'garant_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInner
     */
    'garant_label'?: string;
    /**
     * 
     * @type {Array<GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInnerPredmetyInner>}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInner
     */
    'predmety'?: Array<GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInnerPredmetyInner>;
}
/**
 * 
 * @export
 * @interface GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInnerPredmetyInner
 */
export interface GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInnerPredmetyInner {
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'ap_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'fakulta_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'fakulta_zkratka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'jazyk_vyuky'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'jazyk_vyuky_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'nazev_fakulty'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'nazev_ustavu'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'otevren'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'popis_semestru'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'predmet_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'rok'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'typ_semestru_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'ustav_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'ustav_zkratka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'www_fakulta'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'www_ustav'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyGarantovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'zkratka'?: string;
}
/**
 * 
 * @export
 * @interface GetZamestnanecPredmetyVyucovaneV3200Response
 */
export interface GetZamestnanecPredmetyVyucovaneV3200Response {
    /**
     * 
     * @type {GetZamestnanecPredmetyVyucovaneV3200ResponseData}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200Response
     */
    'data'?: GetZamestnanecPredmetyVyucovaneV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetZamestnanecPredmetyVyucovaneV3200ResponseData
 */
export interface GetZamestnanecPredmetyVyucovaneV3200ResponseData {
    /**
     * 
     * @type {Array<GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInner>}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200ResponseData
     */
    'osoby'?: Array<GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInner>;
}
/**
 * 
 * @export
 * @interface GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInner
 */
export interface GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInner {
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInner
     */
    'label'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInner
     */
    'per_id'?: number;
    /**
     * 
     * @type {Array<GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInnerPredmetyInner>}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInner
     */
    'predmety'?: Array<GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInnerPredmetyInner>;
}
/**
 * 
 * @export
 * @interface GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInnerPredmetyInner
 */
export interface GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInnerPredmetyInner {
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'ap_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'fakulta_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'fakulta_zkratka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'jazyk_vyuky'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'jazyk_vyuky_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'nazev_fakulty'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'nazev_ustavu'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'otevren'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'popis'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'popis_semestru'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'predmet_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'rok'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'typ_semestru_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'ustav_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'ustav_zkratka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'www_fakulta'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'www_ustav'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV3200ResponseDataOsobyInnerPredmetyInner
     */
    'zkratka'?: string;
}
/**
 * 
 * @export
 * @interface GetZamestnanecPredmetyVyucovaneV4200Response
 */
export interface GetZamestnanecPredmetyVyucovaneV4200Response {
    /**
     * 
     * @type {GetZamestnanecPredmetyVyucovaneV4200ResponseData}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200Response
     */
    'data'?: GetZamestnanecPredmetyVyucovaneV4200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetZamestnanecPredmetyVyucovaneV4200ResponseData
 */
export interface GetZamestnanecPredmetyVyucovaneV4200ResponseData {
    /**
     * 
     * @type {Array<GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInner>}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseData
     */
    'osoby'?: Array<GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInner>;
}
/**
 * 
 * @export
 * @interface GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInner
 */
export interface GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInner {
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInner
     */
    'label'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInner
     */
    'per_id'?: number;
    /**
     * 
     * @type {Array<GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInner>}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInner
     */
    'predmety'?: Array<GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInner>;
}
/**
 * 
 * @export
 * @interface GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInner
 */
export interface GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInner {
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInner
     */
    'aktualni_predmet_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInner
     */
    'ap_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInner
     */
    'fakulta_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInner
     */
    'fakulta_zkratka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInner
     */
    'jazyk_vyuky'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInner
     */
    'jazyk_vyuky_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInner
     */
    'nazev_fakulty'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInner
     */
    'nazev_ustavu'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInner
     */
    'otevren'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInner
     */
    'popis_semestru'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInner
     */
    'predmet_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInner
     */
    'rok'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInner
     */
    'typ_semestru_id'?: number;
    /**
     * 
     * @type {Array<GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInnerTypyRozvrhovychJednotekInner>}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInner
     */
    'typy_rozvrhovych_jednotek'?: Array<GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInnerTypyRozvrhovychJednotekInner>;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInner
     */
    'ustav_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInner
     */
    'ustav_zkratka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInner
     */
    'www_fakulta'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInner
     */
    'www_ustav'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInner
     */
    'zkratka'?: string;
}
/**
 * 
 * @export
 * @interface GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInnerTypyRozvrhovychJednotekInner
 */
export interface GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInnerTypyRozvrhovychJednotekInner {
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInnerTypyRozvrhovychJednotekInner
     */
    'popis'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPredmetyVyucovaneV4200ResponseDataOsobyInnerPredmetyInnerTypyRozvrhovychJednotekInner
     */
    'typ_rozvrhove_jednotky_id'?: number;
}
/**
 * 
 * @export
 * @interface GetZamestnanecProduktyV3200Response
 */
export interface GetZamestnanecProduktyV3200Response {
    /**
     * 
     * @type {GetZamestnanecProduktyV3200ResponseData}
     * @memberof GetZamestnanecProduktyV3200Response
     */
    'data'?: GetZamestnanecProduktyV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecProduktyV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecProduktyV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetZamestnanecProduktyV3200ResponseData
 */
export interface GetZamestnanecProduktyV3200ResponseData {
    /**
     * 
     * @type {Array<GetZamestnanecProduktyV3200ResponseDataOsobyInner>}
     * @memberof GetZamestnanecProduktyV3200ResponseData
     */
    'osoby'?: Array<GetZamestnanecProduktyV3200ResponseDataOsobyInner>;
}
/**
 * 
 * @export
 * @interface GetZamestnanecProduktyV3200ResponseDataOsobyInner
 */
export interface GetZamestnanecProduktyV3200ResponseDataOsobyInner {
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecProduktyV3200ResponseDataOsobyInner
     */
    'per_id'?: number;
    /**
     * 
     * @type {Array<GetZamestnanecProduktyV3200ResponseDataOsobyInnerProduktyInner>}
     * @memberof GetZamestnanecProduktyV3200ResponseDataOsobyInner
     */
    'produkty'?: Array<GetZamestnanecProduktyV3200ResponseDataOsobyInnerProduktyInner>;
}
/**
 * 
 * @export
 * @interface GetZamestnanecProduktyV3200ResponseDataOsobyInnerProduktyInner
 */
export interface GetZamestnanecProduktyV3200ResponseDataOsobyInnerProduktyInner {
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecProduktyV3200ResponseDataOsobyInnerProduktyInner
     */
    'prod_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecProduktyV3200ResponseDataOsobyInnerProduktyInner
     */
    'prod_quotations'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecProduktyV3200ResponseDataOsobyInnerProduktyInner
     */
    'prod_type'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecProduktyV3200ResponseDataOsobyInnerProduktyInner
     */
    'result_vav_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecProduktyV3200ResponseDataOsobyInnerProduktyInner
     */
    'rok'?: number;
}
/**
 * 
 * @export
 * @interface GetZamestnanecPublikaceV3200Response
 */
export interface GetZamestnanecPublikaceV3200Response {
    /**
     * 
     * @type {GetZamestnanecPublikaceV3200ResponseData}
     * @memberof GetZamestnanecPublikaceV3200Response
     */
    'data'?: GetZamestnanecPublikaceV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPublikaceV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPublikaceV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetZamestnanecPublikaceV3200ResponseData
 */
export interface GetZamestnanecPublikaceV3200ResponseData {
    /**
     * 
     * @type {Array<GetZamestnanecPublikaceV3200ResponseDataOsobyInner>}
     * @memberof GetZamestnanecPublikaceV3200ResponseData
     */
    'osoby'?: Array<GetZamestnanecPublikaceV3200ResponseDataOsobyInner>;
}
/**
 * 
 * @export
 * @interface GetZamestnanecPublikaceV3200ResponseDataOsobyInner
 */
export interface GetZamestnanecPublikaceV3200ResponseDataOsobyInner {
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPublikaceV3200ResponseDataOsobyInner
     */
    'per_id'?: number;
    /**
     * 
     * @type {Array<GetZamestnanecPublikaceV3200ResponseDataOsobyInnerPublikaceInner>}
     * @memberof GetZamestnanecPublikaceV3200ResponseDataOsobyInner
     */
    'publikace'?: Array<GetZamestnanecPublikaceV3200ResponseDataOsobyInnerPublikaceInner>;
}
/**
 * 
 * @export
 * @interface GetZamestnanecPublikaceV3200ResponseDataOsobyInnerPublikaceInner
 */
export interface GetZamestnanecPublikaceV3200ResponseDataOsobyInnerPublikaceInner {
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPublikaceV3200ResponseDataOsobyInnerPublikaceInner
     */
    'dspace_kod'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPublikaceV3200ResponseDataOsobyInnerPublikaceInner
     */
    'ma_detail'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPublikaceV3200ResponseDataOsobyInnerPublikaceInner
     */
    'pub_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPublikaceV3200ResponseDataOsobyInnerPublikaceInner
     */
    'pub_quotation_html'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecPublikaceV3200ResponseDataOsobyInnerPublikaceInner
     */
    'pub_uri'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPublikaceV3200ResponseDataOsobyInnerPublikaceInner
     */
    'result_vav_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecPublikaceV3200ResponseDataOsobyInnerPublikaceInner
     */
    'rok'?: number;
}
/**
 * 
 * @export
 * @interface GetZamestnanecV4200Response
 */
export interface GetZamestnanecV4200Response {
    /**
     * 
     * @type {GetZamestnanciV4200ResponseData}
     * @memberof GetZamestnanecV4200Response
     */
    'data'?: GetZamestnanciV4200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecV4200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecV4200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetZamestnanecZivotopisStrukturovanyV3200Response
 */
export interface GetZamestnanecZivotopisStrukturovanyV3200Response {
    /**
     * 
     * @type {GetZamestnanecZivotopisStrukturovanyV3200ResponseData}
     * @memberof GetZamestnanecZivotopisStrukturovanyV3200Response
     */
    'data'?: GetZamestnanecZivotopisStrukturovanyV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecZivotopisStrukturovanyV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecZivotopisStrukturovanyV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetZamestnanecZivotopisStrukturovanyV3200ResponseData
 */
export interface GetZamestnanecZivotopisStrukturovanyV3200ResponseData {
    /**
     * 
     * @type {Array<GetZamestnanecZivotopisStrukturovanyV3200ResponseDataOsobyInner>}
     * @memberof GetZamestnanecZivotopisStrukturovanyV3200ResponseData
     */
    'osoby'?: Array<GetZamestnanecZivotopisStrukturovanyV3200ResponseDataOsobyInner>;
}
/**
 * 
 * @export
 * @interface GetZamestnanecZivotopisStrukturovanyV3200ResponseDataOsobyInner
 */
export interface GetZamestnanecZivotopisStrukturovanyV3200ResponseDataOsobyInner {
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecZivotopisStrukturovanyV3200ResponseDataOsobyInner
     */
    'per_id'?: number;
    /**
     * 
     * @type {Array<GetZamestnanecZivotopisStrukturovanyV3200ResponseDataOsobyInnerPolozkyInner>}
     * @memberof GetZamestnanecZivotopisStrukturovanyV3200ResponseDataOsobyInner
     */
    'polozky'?: Array<GetZamestnanecZivotopisStrukturovanyV3200ResponseDataOsobyInnerPolozkyInner>;
}
/**
 * 
 * @export
 * @interface GetZamestnanecZivotopisStrukturovanyV3200ResponseDataOsobyInnerPolozkyInner
 */
export interface GetZamestnanecZivotopisStrukturovanyV3200ResponseDataOsobyInnerPolozkyInner {
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecZivotopisStrukturovanyV3200ResponseDataOsobyInnerPolozkyInner
     */
    'from_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecZivotopisStrukturovanyV3200ResponseDataOsobyInnerPolozkyInner
     */
    'misto'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecZivotopisStrukturovanyV3200ResponseDataOsobyInnerPolozkyInner
     */
    'pers_specification_struct_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecZivotopisStrukturovanyV3200ResponseDataOsobyInnerPolozkyInner
     */
    'popis'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecZivotopisStrukturovanyV3200ResponseDataOsobyInnerPolozkyInner
     */
    'spec_type'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecZivotopisStrukturovanyV3200ResponseDataOsobyInnerPolozkyInner
     */
    'till_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecZivotopisStrukturovanyV3200ResponseDataOsobyInnerPolozkyInner
     */
    'uvazek'?: string;
}
/**
 * 
 * @export
 * @interface GetZamestnanecZivotopisTextovyV3200Response
 */
export interface GetZamestnanecZivotopisTextovyV3200Response {
    /**
     * 
     * @type {GetZamestnanecZivotopisTextovyV3200ResponseData}
     * @memberof GetZamestnanecZivotopisTextovyV3200Response
     */
    'data'?: GetZamestnanecZivotopisTextovyV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecZivotopisTextovyV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecZivotopisTextovyV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetZamestnanecZivotopisTextovyV3200ResponseData
 */
export interface GetZamestnanecZivotopisTextovyV3200ResponseData {
    /**
     * 
     * @type {Array<GetZamestnanecZivotopisTextovyV3200ResponseDataOsobyInner>}
     * @memberof GetZamestnanecZivotopisTextovyV3200ResponseData
     */
    'osoby'?: Array<GetZamestnanecZivotopisTextovyV3200ResponseDataOsobyInner>;
}
/**
 * 
 * @export
 * @interface GetZamestnanecZivotopisTextovyV3200ResponseDataOsobyInner
 */
export interface GetZamestnanecZivotopisTextovyV3200ResponseDataOsobyInner {
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecZivotopisTextovyV3200ResponseDataOsobyInner
     */
    'per_id'?: number;
    /**
     * 
     * @type {Array<GetZamestnanecZivotopisTextovyV3200ResponseDataOsobyInnerPolozkyInner>}
     * @memberof GetZamestnanecZivotopisTextovyV3200ResponseDataOsobyInner
     */
    'polozky'?: Array<GetZamestnanecZivotopisTextovyV3200ResponseDataOsobyInnerPolozkyInner>;
}
/**
 * 
 * @export
 * @interface GetZamestnanecZivotopisTextovyV3200ResponseDataOsobyInnerPolozkyInner
 */
export interface GetZamestnanecZivotopisTextovyV3200ResponseDataOsobyInnerPolozkyInner {
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecZivotopisTextovyV3200ResponseDataOsobyInnerPolozkyInner
     */
    'data_block_clob'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZamestnanecZivotopisTextovyV3200ResponseDataOsobyInnerPolozkyInner
     */
    'element'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecZivotopisTextovyV3200ResponseDataOsobyInnerPolozkyInner
     */
    'pers_specification_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecZivotopisTextovyV3200ResponseDataOsobyInnerPolozkyInner
     */
    'spec_type'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetZamestnanecZivotopisTextovyV3200ResponseDataOsobyInnerPolozkyInner
     */
    'structured'?: number;
}
/**
 * 
 * @export
 * @interface GetZavpracePrideleneV3200Response
 */
export interface GetZavpracePrideleneV3200Response {
    /**
     * 
     * @type {GetZavpracePrideleneV3200ResponseData}
     * @memberof GetZavpracePrideleneV3200Response
     */
    'data'?: GetZavpracePrideleneV3200ResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetZavpracePrideleneV3200Response
     */
    'format'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZavpracePrideleneV3200Response
     */
    'rows_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetZavpracePrideleneV3200Response
     */
    'rows_processed'?: number;
}
/**
 * 
 * @export
 * @interface GetZavpracePrideleneV3200ResponseData
 */
export interface GetZavpracePrideleneV3200ResponseData {
    /**
     * 
     * @type {Array<GetZavpracePrideleneV3200ResponseDataZavPraceInner>}
     * @memberof GetZavpracePrideleneV3200ResponseData
     */
    'zav_prace'?: Array<GetZavpracePrideleneV3200ResponseDataZavPraceInner>;
}
/**
 * 
 * @export
 * @interface GetZavpracePrideleneV3200ResponseDataZavPraceInner
 */
export interface GetZavpracePrideleneV3200ResponseDataZavPraceInner {
    /**
     * 
     * @type {object}
     * @memberof GetZavpracePrideleneV3200ResponseDataZavPraceInner
     */
    'firma_id'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetZavpracePrideleneV3200ResponseDataZavPraceInner
     */
    'firma_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZavpracePrideleneV3200ResponseDataZavPraceInner
     */
    'firma_zkratka'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZavpracePrideleneV3200ResponseDataZavPraceInner
     */
    'jazyk'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZavpracePrideleneV3200ResponseDataZavPraceInner
     */
    'prace_klicova_slova'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZavpracePrideleneV3200ResponseDataZavPraceInner
     */
    'prace_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZavpracePrideleneV3200ResponseDataZavPraceInner
     */
    'prace_typ_nazev'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZavpracePrideleneV3200ResponseDataZavPraceInner
     */
    'prace_typ_zkratka'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZavpracePrideleneV3200ResponseDataZavPraceInner
     */
    'rok'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetZavpracePrideleneV3200ResponseDataZavPraceInner
     */
    'student_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZavpracePrideleneV3200ResponseDataZavPraceInner
     */
    'student_label_pr'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZavpracePrideleneV3200ResponseDataZavPraceInner
     */
    'studium_id'?: number;
    /**
     * 
     * @type {Array<GetZavpracePrideleneV3200ResponseDataZavPraceInnerVedouciInner>}
     * @memberof GetZavpracePrideleneV3200ResponseDataZavPraceInner
     */
    'vedouci'?: Array<GetZavpracePrideleneV3200ResponseDataZavPraceInnerVedouciInner>;
    /**
     * 
     * @type {number}
     * @memberof GetZavpracePrideleneV3200ResponseDataZavPraceInner
     */
    'vytisteno'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetZavpracePrideleneV3200ResponseDataZavPraceInner
     */
    'zav_prace_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetZavpracePrideleneV3200ResponseDataZavPraceInner
     */
    'zav_prace_stav_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZavpracePrideleneV3200ResponseDataZavPraceInner
     */
    'zav_prace_stav_nazev'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZavpracePrideleneV3200ResponseDataZavPraceInner
     */
    'zav_prace_typ_id'?: number;
}
/**
 * 
 * @export
 * @interface GetZavpracePrideleneV3200ResponseDataZavPraceInnerVedouciInner
 */
export interface GetZavpracePrideleneV3200ResponseDataZavPraceInnerVedouciInner {
    /**
     * 
     * @type {string}
     * @memberof GetZavpracePrideleneV3200ResponseDataZavPraceInnerVedouciInner
     */
    'vedouci_alt_text'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetZavpracePrideleneV3200ResponseDataZavPraceInnerVedouciInner
     */
    'vedouci_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetZavpracePrideleneV3200ResponseDataZavPraceInnerVedouciInner
     */
    'vedouci_jmeno'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetZavpracePrideleneV3200ResponseDataZavPraceInnerVedouciInner
     */
    'vedouci_label_pr'?: string;
}
/**
 * 
 * @export
 * @interface PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request
 */
export interface PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request {
    /**
     * 
     * @type {string}
     * @memberof PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request
     */
    'datum'?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request
     */
    'otazka_1'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request
     */
    'otazka_2'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request
     */
    'otazka_n'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request
     */
    'pocet_bodu'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request
     */
    'poznamky'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request
     */
    'zprava_studentovi'?: string;
}

/**
 * AutentizaceApi - axios parameter creator
 * @export
 */
export const AutentizaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Prihlaseni uzivatelskym uctem
         * @param {CreateAuthLoginRequest} createAuthLoginRequest Request body content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthLogin: async (createAuthLoginRequest: CreateAuthLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAuthLoginRequest' is not null or undefined
            assertParamExists('createAuthLogin', 'createAuthLoginRequest', createAuthLoginRequest)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAuthLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AutentizaceApi - functional programming interface
 * @export
 */
export const AutentizaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AutentizaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Prihlaseni uzivatelskym uctem
         * @param {CreateAuthLoginRequest} createAuthLoginRequest Request body content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAuthLogin(createAuthLoginRequest: CreateAuthLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAuthLogin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAuthLogin(createAuthLoginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AutentizaceApi - factory interface
 * @export
 */
export const AutentizaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AutentizaceApiFp(configuration)
    return {
        /**
         * 
         * @summary Prihlaseni uzivatelskym uctem
         * @param {CreateAuthLoginRequest} createAuthLoginRequest Request body content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthLogin(createAuthLoginRequest: CreateAuthLoginRequest, options?: any): AxiosPromise<CreateAuthLogin200Response> {
            return localVarFp.createAuthLogin(createAuthLoginRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AutentizaceApi - object-oriented interface
 * @export
 * @class AutentizaceApi
 * @extends {BaseAPI}
 */
export class AutentizaceApi extends BaseAPI {
    /**
     * 
     * @summary Prihlaseni uzivatelskym uctem
     * @param {CreateAuthLoginRequest} createAuthLoginRequest Request body content
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutentizaceApi
     */
    public createAuthLogin(createAuthLoginRequest: CreateAuthLoginRequest, options?: AxiosRequestConfig) {
        return AutentizaceApiFp(this.configuration).createAuthLogin(createAuthLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HarmonogramApi - axios parameter creator
 * @export
 */
export const HarmonogramApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/casovy-plan/rok/__rok__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Casovy plan ak. roku
         * @param {string} rok 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCasovyPlanRokV3: async (rok: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rok' is not null or undefined
            assertParamExists('getCasovyPlanRokV3', 'rok', rok)
            const localVarPath = `/api/fit/casovy-plan/rok/{rok}/v3`
                .replace(`{${"rok"}}`, encodeURIComponent(String(rok)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/kalendar/statni-svatky/v1?lang=cs&datum_od=1.1.2021&datum_do=31.12.2021\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam svatku ak. roku
         * @param {string} [datumOd] 
         * @param {string} [datumDo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKalendarStatniSvatkyV1: async (datumOd?: string, datumDo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/kalendar/statni-svatky/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (datumOd !== undefined) {
                localVarQueryParameter['datum_od'] = datumOd;
            }

            if (datumDo !== undefined) {
                localVarQueryParameter['datum_do'] = datumDo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HarmonogramApi - functional programming interface
 * @export
 */
export const HarmonogramApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HarmonogramApiAxiosParamCreator(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/casovy-plan/rok/__rok__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Casovy plan ak. roku
         * @param {string} rok 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCasovyPlanRokV3(rok: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCasovyPlanRokV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCasovyPlanRokV3(rok, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/kalendar/statni-svatky/v1?lang=cs&datum_od=1.1.2021&datum_do=31.12.2021\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam svatku ak. roku
         * @param {string} [datumOd] 
         * @param {string} [datumDo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKalendarStatniSvatkyV1(datumOd?: string, datumDo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetKalendarStatniSvatkyV1200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKalendarStatniSvatkyV1(datumOd, datumDo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HarmonogramApi - factory interface
 * @export
 */
export const HarmonogramApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HarmonogramApiFp(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/casovy-plan/rok/__rok__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Casovy plan ak. roku
         * @param {string} rok 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCasovyPlanRokV3(rok: string, options?: any): AxiosPromise<GetCasovyPlanRokV3200Response> {
            return localVarFp.getCasovyPlanRokV3(rok, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/kalendar/statni-svatky/v1?lang=cs&datum_od=1.1.2021&datum_do=31.12.2021\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam svatku ak. roku
         * @param {string} [datumOd] 
         * @param {string} [datumDo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKalendarStatniSvatkyV1(datumOd?: string, datumDo?: string, options?: any): AxiosPromise<GetKalendarStatniSvatkyV1200Response> {
            return localVarFp.getKalendarStatniSvatkyV1(datumOd, datumDo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HarmonogramApi - object-oriented interface
 * @export
 * @class HarmonogramApi
 * @extends {BaseAPI}
 */
export class HarmonogramApi extends BaseAPI {
    /**
     *  <a href=\"https://api.vut.cz/api/fit/casovy-plan/rok/__rok__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Casovy plan ak. roku
     * @param {string} rok 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HarmonogramApi
     */
    public getCasovyPlanRokV3(rok: string, options?: AxiosRequestConfig) {
        return HarmonogramApiFp(this.configuration).getCasovyPlanRokV3(rok, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/kalendar/statni-svatky/v1?lang=cs&datum_od=1.1.2021&datum_do=31.12.2021\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam svatku ak. roku
     * @param {string} [datumOd] 
     * @param {string} [datumDo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HarmonogramApi
     */
    public getKalendarStatniSvatkyV1(datumOd?: string, datumDo?: string, options?: AxiosRequestConfig) {
        return HarmonogramApiFp(this.configuration).getKalendarStatniSvatkyV1(datumOd, datumDo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KartaApi - axios parameter creator
 * @export
 */
export const KartaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/anotace/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Anotace (texty na karte)
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetAnotaceV3: async (aktualniPredmetId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetAnotaceV3', 'aktualniPredmetId', aktualniPredmetId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/anotace/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/obory/v4\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Ve kterych oborech je predmet zarazen?
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetOboryV4: async (aktualniPredmetId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetOboryV4', 'aktualniPredmetId', aktualniPredmetId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/obory/v4`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/prerekvizity/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Prerekvizity
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetPrerekvizityV3: async (aktualniPredmetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetPrerekvizityV3', 'aktualniPredmetId', aktualniPredmetId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/prerekvizity/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/pvskupiny/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary seznam povinne volitelnych skupin, do kterych je predmet zarazen
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetPvskupinyV3: async (aktualniPredmetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetPvskupinyV3', 'aktualniPredmetId', aktualniPredmetId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/pvskupiny/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/uziti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary seznam rocniku (a pv skupin), do kterych je predmet zarazen
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetUzitiV3: async (aktualniPredmetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetUzitiV3', 'aktualniPredmetId', aktualniPredmetId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/uziti/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail predmetu
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetV3: async (aktualniPredmetId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetV3', 'aktualniPredmetId', aktualniPredmetId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/vyucujici/typ/__typ__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam vyucujicich daneho typu
         * @param {string} aktualniPredmetId 
         * @param {number} typ 
         * @param {number} [perId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetVyucujiciTypV3: async (aktualniPredmetId: string, typ: number, perId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetVyucujiciTypV3', 'aktualniPredmetId', aktualniPredmetId)
            // verify required parameter 'typ' is not null or undefined
            assertParamExists('getAktualniPredmetVyucujiciTypV3', 'typ', typ)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/vyucujici/typ/{typ}/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)))
                .replace(`{${"typ"}}`, encodeURIComponent(String(typ)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (perId !== undefined) {
                localVarQueryParameter['per_id'] = perId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/vyucujici/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam vyucujicich
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {number} [perId] 
         * @param {number} [typId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetVyucujiciV3: async (aktualniPredmetId: string, lang?: string, perId?: number, typId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetVyucujiciV3', 'aktualniPredmetId', aktualniPredmetId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/vyucujici/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (perId !== undefined) {
                localVarQueryParameter['per_id'] = perId;
            }

            if (typId !== undefined) {
                localVarQueryParameter['typ_id'] = typId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/predmet/__predmet_id__/literatura/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Literatura
         * @param {string} predmetId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPredmetLiteraturaV3: async (predmetId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'predmetId' is not null or undefined
            assertParamExists('getPredmetLiteraturaV3', 'predmetId', predmetId)
            const localVarPath = `/api/fit/predmet/{predmet_id}/literatura/v3`
                .replace(`{${"predmet_id"}}`, encodeURIComponent(String(predmetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KartaApi - functional programming interface
 * @export
 */
export const KartaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KartaApiAxiosParamCreator(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/anotace/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Anotace (texty na karte)
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetAnotaceV3(aktualniPredmetId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetAnotaceV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetAnotaceV3(aktualniPredmetId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/obory/v4\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Ve kterych oborech je predmet zarazen?
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetOboryV4(aktualniPredmetId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetOboryV4200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetOboryV4(aktualniPredmetId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/prerekvizity/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Prerekvizity
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetPrerekvizityV3(aktualniPredmetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetPrerekvizityV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetPrerekvizityV3(aktualniPredmetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/pvskupiny/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary seznam povinne volitelnych skupin, do kterych je predmet zarazen
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetPvskupinyV3(aktualniPredmetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetPvskupinyV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetPvskupinyV3(aktualniPredmetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/uziti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary seznam rocniku (a pv skupin), do kterych je predmet zarazen
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetUzitiV3(aktualniPredmetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetUzitiV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetUzitiV3(aktualniPredmetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail predmetu
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetV3(aktualniPredmetId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetV3(aktualniPredmetId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/vyucujici/typ/__typ__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam vyucujicich daneho typu
         * @param {string} aktualniPredmetId 
         * @param {number} typ 
         * @param {number} [perId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetVyucujiciTypV3(aktualniPredmetId: string, typ: number, perId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetVyucujiciTypV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetVyucujiciTypV3(aktualniPredmetId, typ, perId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/vyucujici/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam vyucujicich
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {number} [perId] 
         * @param {number} [typId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetVyucujiciV3(aktualniPredmetId: string, lang?: string, perId?: number, typId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetVyucujiciTypV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetVyucujiciV3(aktualniPredmetId, lang, perId, typId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/predmet/__predmet_id__/literatura/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Literatura
         * @param {string} predmetId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPredmetLiteraturaV3(predmetId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPredmetLiteraturaV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPredmetLiteraturaV3(predmetId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KartaApi - factory interface
 * @export
 */
export const KartaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KartaApiFp(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/anotace/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Anotace (texty na karte)
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetAnotaceV3(aktualniPredmetId: string, lang?: string, options?: any): AxiosPromise<GetAktualniPredmetAnotaceV3200Response> {
            return localVarFp.getAktualniPredmetAnotaceV3(aktualniPredmetId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/obory/v4\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Ve kterych oborech je predmet zarazen?
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetOboryV4(aktualniPredmetId: string, lang?: string, options?: any): AxiosPromise<GetAktualniPredmetOboryV4200Response> {
            return localVarFp.getAktualniPredmetOboryV4(aktualniPredmetId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/prerekvizity/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Prerekvizity
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetPrerekvizityV3(aktualniPredmetId: string, options?: any): AxiosPromise<GetAktualniPredmetPrerekvizityV3200Response> {
            return localVarFp.getAktualniPredmetPrerekvizityV3(aktualniPredmetId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/pvskupiny/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary seznam povinne volitelnych skupin, do kterych je predmet zarazen
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetPvskupinyV3(aktualniPredmetId: string, options?: any): AxiosPromise<GetAktualniPredmetPvskupinyV3200Response> {
            return localVarFp.getAktualniPredmetPvskupinyV3(aktualniPredmetId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/uziti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary seznam rocniku (a pv skupin), do kterych je predmet zarazen
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetUzitiV3(aktualniPredmetId: string, options?: any): AxiosPromise<GetAktualniPredmetUzitiV3200Response> {
            return localVarFp.getAktualniPredmetUzitiV3(aktualniPredmetId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail predmetu
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetV3(aktualniPredmetId: string, lang?: string, options?: any): AxiosPromise<GetAktualniPredmetV3200Response> {
            return localVarFp.getAktualniPredmetV3(aktualniPredmetId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/vyucujici/typ/__typ__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam vyucujicich daneho typu
         * @param {string} aktualniPredmetId 
         * @param {number} typ 
         * @param {number} [perId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetVyucujiciTypV3(aktualniPredmetId: string, typ: number, perId?: number, options?: any): AxiosPromise<GetAktualniPredmetVyucujiciTypV3200Response> {
            return localVarFp.getAktualniPredmetVyucujiciTypV3(aktualniPredmetId, typ, perId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/vyucujici/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam vyucujicich
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {number} [perId] 
         * @param {number} [typId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetVyucujiciV3(aktualniPredmetId: string, lang?: string, perId?: number, typId?: number, options?: any): AxiosPromise<GetAktualniPredmetVyucujiciTypV3200Response> {
            return localVarFp.getAktualniPredmetVyucujiciV3(aktualniPredmetId, lang, perId, typId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/predmet/__predmet_id__/literatura/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Literatura
         * @param {string} predmetId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPredmetLiteraturaV3(predmetId: string, lang?: string, options?: any): AxiosPromise<GetPredmetLiteraturaV3200Response> {
            return localVarFp.getPredmetLiteraturaV3(predmetId, lang, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KartaApi - object-oriented interface
 * @export
 * @class KartaApi
 * @extends {BaseAPI}
 */
export class KartaApi extends BaseAPI {
    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/anotace/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Anotace (texty na karte)
     * @param {string} aktualniPredmetId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KartaApi
     */
    public getAktualniPredmetAnotaceV3(aktualniPredmetId: string, lang?: string, options?: AxiosRequestConfig) {
        return KartaApiFp(this.configuration).getAktualniPredmetAnotaceV3(aktualniPredmetId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/obory/v4\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Ve kterych oborech je predmet zarazen?
     * @param {string} aktualniPredmetId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KartaApi
     */
    public getAktualniPredmetOboryV4(aktualniPredmetId: string, lang?: string, options?: AxiosRequestConfig) {
        return KartaApiFp(this.configuration).getAktualniPredmetOboryV4(aktualniPredmetId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/prerekvizity/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Prerekvizity
     * @param {string} aktualniPredmetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KartaApi
     */
    public getAktualniPredmetPrerekvizityV3(aktualniPredmetId: string, options?: AxiosRequestConfig) {
        return KartaApiFp(this.configuration).getAktualniPredmetPrerekvizityV3(aktualniPredmetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/pvskupiny/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary seznam povinne volitelnych skupin, do kterych je predmet zarazen
     * @param {string} aktualniPredmetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KartaApi
     */
    public getAktualniPredmetPvskupinyV3(aktualniPredmetId: string, options?: AxiosRequestConfig) {
        return KartaApiFp(this.configuration).getAktualniPredmetPvskupinyV3(aktualniPredmetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/uziti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary seznam rocniku (a pv skupin), do kterych je predmet zarazen
     * @param {string} aktualniPredmetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KartaApi
     */
    public getAktualniPredmetUzitiV3(aktualniPredmetId: string, options?: AxiosRequestConfig) {
        return KartaApiFp(this.configuration).getAktualniPredmetUzitiV3(aktualniPredmetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Detail predmetu
     * @param {string} aktualniPredmetId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KartaApi
     */
    public getAktualniPredmetV3(aktualniPredmetId: string, lang?: string, options?: AxiosRequestConfig) {
        return KartaApiFp(this.configuration).getAktualniPredmetV3(aktualniPredmetId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/vyucujici/typ/__typ__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam vyucujicich daneho typu
     * @param {string} aktualniPredmetId 
     * @param {number} typ 
     * @param {number} [perId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KartaApi
     */
    public getAktualniPredmetVyucujiciTypV3(aktualniPredmetId: string, typ: number, perId?: number, options?: AxiosRequestConfig) {
        return KartaApiFp(this.configuration).getAktualniPredmetVyucujiciTypV3(aktualniPredmetId, typ, perId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/vyucujici/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam vyucujicich
     * @param {string} aktualniPredmetId 
     * @param {string} [lang] 
     * @param {number} [perId] 
     * @param {number} [typId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KartaApi
     */
    public getAktualniPredmetVyucujiciV3(aktualniPredmetId: string, lang?: string, perId?: number, typId?: number, options?: AxiosRequestConfig) {
        return KartaApiFp(this.configuration).getAktualniPredmetVyucujiciV3(aktualniPredmetId, lang, perId, typId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/predmet/__predmet_id__/literatura/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Literatura
     * @param {string} predmetId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KartaApi
     */
    public getPredmetLiteraturaV3(predmetId: string, lang?: string, options?: AxiosRequestConfig) {
        return KartaApiFp(this.configuration).getPredmetLiteraturaV3(predmetId, lang, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KartyOsobApi - axios parameter creator
 * @export
 */
export const KartyOsobApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/prukaz/info/v1?chip_hex=1B98A36C\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Prevod cisla cipu na osobu
         * @param {string} [chipHex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrukazInfoV1: async (chipHex?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/fit/prukaz/info/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (chipHex !== undefined) {
                localVarQueryParameter['chip_hex'] = chipHex;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KartyOsobApi - functional programming interface
 * @export
 */
export const KartyOsobApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KartyOsobApiAxiosParamCreator(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/prukaz/info/v1?chip_hex=1B98A36C\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Prevod cisla cipu na osobu
         * @param {string} [chipHex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrukazInfoV1(chipHex?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPrukazInfoV1200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrukazInfoV1(chipHex, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KartyOsobApi - factory interface
 * @export
 */
export const KartyOsobApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KartyOsobApiFp(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/prukaz/info/v1?chip_hex=1B98A36C\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Prevod cisla cipu na osobu
         * @param {string} [chipHex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrukazInfoV1(chipHex?: string, options?: any): AxiosPromise<GetPrukazInfoV1200Response> {
            return localVarFp.getPrukazInfoV1(chipHex, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KartyOsobApi - object-oriented interface
 * @export
 * @class KartyOsobApi
 * @extends {BaseAPI}
 */
export class KartyOsobApi extends BaseAPI {
    /**
     *  <a href=\"https://api.vut.cz/api/fit/prukaz/info/v1?chip_hex=1B98A36C\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Prevod cisla cipu na osobu
     * @param {string} [chipHex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KartyOsobApi
     */
    public getPrukazInfoV1(chipHex?: string, options?: AxiosRequestConfig) {
        return KartyOsobApiFp(this.configuration).getPrukazInfoV1(chipHex, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KontrolniPrehledyApi - axios parameter creator
 * @export
 */
export const KontrolniPrehledyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/predmety-terminy/rok/__rok__/v3?ustav=UJAZ&semestr=Z\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Pocty studentu na jednotlivych terminech zkousek
         * @param {string} rok 
         * @param {string} [ustav] zkratka
         * @param {string} [semestr] zkratka
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPredmetyTerminyRokV3: async (rok: string, ustav?: string, semestr?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rok' is not null or undefined
            assertParamExists('getPredmetyTerminyRokV3', 'rok', rok)
            const localVarPath = `/api/fit/predmety-terminy/rok/{rok}/v3`
                .replace(`{${"rok"}}`, encodeURIComponent(String(rok)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ustav !== undefined) {
                localVarQueryParameter['ustav'] = ustav;
            }

            if (semestr !== undefined) {
                localVarQueryParameter['semestr'] = semestr;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KontrolniPrehledyApi - functional programming interface
 * @export
 */
export const KontrolniPrehledyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KontrolniPrehledyApiAxiosParamCreator(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/predmety-terminy/rok/__rok__/v3?ustav=UJAZ&semestr=Z\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Pocty studentu na jednotlivych terminech zkousek
         * @param {string} rok 
         * @param {string} [ustav] zkratka
         * @param {string} [semestr] zkratka
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPredmetyTerminyRokV3(rok: string, ustav?: string, semestr?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPredmetyTerminyRokV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPredmetyTerminyRokV3(rok, ustav, semestr, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KontrolniPrehledyApi - factory interface
 * @export
 */
export const KontrolniPrehledyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KontrolniPrehledyApiFp(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/predmety-terminy/rok/__rok__/v3?ustav=UJAZ&semestr=Z\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Pocty studentu na jednotlivych terminech zkousek
         * @param {string} rok 
         * @param {string} [ustav] zkratka
         * @param {string} [semestr] zkratka
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPredmetyTerminyRokV3(rok: string, ustav?: string, semestr?: string, options?: any): AxiosPromise<GetPredmetyTerminyRokV3200Response> {
            return localVarFp.getPredmetyTerminyRokV3(rok, ustav, semestr, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KontrolniPrehledyApi - object-oriented interface
 * @export
 * @class KontrolniPrehledyApi
 * @extends {BaseAPI}
 */
export class KontrolniPrehledyApi extends BaseAPI {
    /**
     *  <a href=\"https://api.vut.cz/api/fit/predmety-terminy/rok/__rok__/v3?ustav=UJAZ&semestr=Z\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Pocty studentu na jednotlivych terminech zkousek
     * @param {string} rok 
     * @param {string} [ustav] zkratka
     * @param {string} [semestr] zkratka
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KontrolniPrehledyApi
     */
    public getPredmetyTerminyRokV3(rok: string, ustav?: string, semestr?: string, options?: AxiosRequestConfig) {
        return KontrolniPrehledyApiFp(this.configuration).getPredmetyTerminyRokV3(rok, ustav, semestr, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MistnostiApi - axios parameter creator
 * @export
 */
export const MistnostiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/mapa-arealu/v3?lang=cs&patro=1\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Mapa arealu
         * @param {string} [lang] 
         * @param {string} [patro] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMapaArealuV3: async (lang?: string, patro?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/fit/mapa-arealu/v3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (patro !== undefined) {
                localVarQueryParameter['patro'] = patro;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/mistnost/__mistnost_id__/rezervace/v3?lang=cs&datum_od=1.5.2022&datum_do=20.5.2022\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam jednorazovych rezervaci mistnosti
         * @param {string} mistnostId 
         * @param {string} [lang] 
         * @param {string} [datumOd] 
         * @param {string} [datumDo] 
         * @param {number} [rok] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMistnostRezervaceV3: async (mistnostId: string, lang?: string, datumOd?: string, datumDo?: string, rok?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mistnostId' is not null or undefined
            assertParamExists('getMistnostRezervaceV3', 'mistnostId', mistnostId)
            const localVarPath = `/api/fit/mistnost/{mistnost_id}/rezervace/v3`
                .replace(`{${"mistnost_id"}}`, encodeURIComponent(String(mistnostId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (datumOd !== undefined) {
                localVarQueryParameter['datum_od'] = datumOd;
            }

            if (datumDo !== undefined) {
                localVarQueryParameter['datum_do'] = datumDo;
            }

            if (rok !== undefined) {
                localVarQueryParameter['rok'] = rok;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/mistnost/__mistnost_id__/terminy/v3?lang=en&datum_od=1.1.2022&datum_do=1.2.2022\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Rozvrh mistnosti - terminy zkousek
         * @param {string} mistnostId 
         * @param {string} [lang] 
         * @param {string} [datumOd] 
         * @param {string} [datumDo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMistnostTerminyV3: async (mistnostId: string, lang?: string, datumOd?: string, datumDo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mistnostId' is not null or undefined
            assertParamExists('getMistnostTerminyV3', 'mistnostId', mistnostId)
            const localVarPath = `/api/fit/mistnost/{mistnost_id}/terminy/v3`
                .replace(`{${"mistnost_id"}}`, encodeURIComponent(String(mistnostId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (datumOd !== undefined) {
                localVarQueryParameter['datum_od'] = datumOd;
            }

            if (datumDo !== undefined) {
                localVarQueryParameter['datum_do'] = datumDo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/mistnost/__mistnost_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail mistnosti
         * @param {string} mistnostId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMistnostV3: async (mistnostId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mistnostId' is not null or undefined
            assertParamExists('getMistnostV3', 'mistnostId', mistnostId)
            const localVarPath = `/api/fit/mistnost/{mistnost_id}/v3`
                .replace(`{${"mistnost_id"}}`, encodeURIComponent(String(mistnostId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/mistnost/__mistnost_id__/vyucovani/v3?lang=cs&rok=2021&typ_semestru_id=2&datum_od=23.9.2021&datum_do=30.9.2021\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Rozvrh mistnosti - vyucovani
         * @param {string} mistnostId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {number} [typSemestruId] 
         * @param {string} [datumOd] 
         * @param {string} [datumDo] 
         * @param {string} [viditelne] 0/1/bez uvedeni nefiltrovano
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMistnostVyucovaniV3: async (mistnostId: string, lang?: string, rok?: number, typSemestruId?: number, datumOd?: string, datumDo?: string, viditelne?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mistnostId' is not null or undefined
            assertParamExists('getMistnostVyucovaniV3', 'mistnostId', mistnostId)
            const localVarPath = `/api/fit/mistnost/{mistnost_id}/vyucovani/v3`
                .replace(`{${"mistnost_id"}}`, encodeURIComponent(String(mistnostId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (rok !== undefined) {
                localVarQueryParameter['rok'] = rok;
            }

            if (typSemestruId !== undefined) {
                localVarQueryParameter['typ_semestru_id'] = typSemestruId;
            }

            if (datumOd !== undefined) {
                localVarQueryParameter['datum_od'] = datumOd;
            }

            if (datumDo !== undefined) {
                localVarQueryParameter['datum_do'] = datumDo;
            }

            if (viditelne !== undefined) {
                localVarQueryParameter['viditelne'] = viditelne;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/mistnosti/v4?lang=cs&rozvrhovane=1&aktualni=1\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam mistnosti
         * @param {string} [lang] 
         * @param {string} [rozvrhovane] 1
         * @param {string} [aktualni] 1
         * @param {number} [arealId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMistnostiV4: async (lang?: string, rozvrhovane?: string, aktualni?: string, arealId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/fit/mistnosti/v4`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (rozvrhovane !== undefined) {
                localVarQueryParameter['rozvrhovane'] = rozvrhovane;
            }

            if (aktualni !== undefined) {
                localVarQueryParameter['aktualni'] = aktualni;
            }

            if (arealId !== undefined) {
                localVarQueryParameter['areal_id'] = arealId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MistnostiApi - functional programming interface
 * @export
 */
export const MistnostiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MistnostiApiAxiosParamCreator(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/mapa-arealu/v3?lang=cs&patro=1\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Mapa arealu
         * @param {string} [lang] 
         * @param {string} [patro] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMapaArealuV3(lang?: string, patro?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMapaArealuV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMapaArealuV3(lang, patro, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/mistnost/__mistnost_id__/rezervace/v3?lang=cs&datum_od=1.5.2022&datum_do=20.5.2022\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam jednorazovych rezervaci mistnosti
         * @param {string} mistnostId 
         * @param {string} [lang] 
         * @param {string} [datumOd] 
         * @param {string} [datumDo] 
         * @param {number} [rok] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMistnostRezervaceV3(mistnostId: string, lang?: string, datumOd?: string, datumDo?: string, rok?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMistnostRezervaceV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMistnostRezervaceV3(mistnostId, lang, datumOd, datumDo, rok, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/mistnost/__mistnost_id__/terminy/v3?lang=en&datum_od=1.1.2022&datum_do=1.2.2022\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Rozvrh mistnosti - terminy zkousek
         * @param {string} mistnostId 
         * @param {string} [lang] 
         * @param {string} [datumOd] 
         * @param {string} [datumDo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMistnostTerminyV3(mistnostId: string, lang?: string, datumOd?: string, datumDo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMistnostTerminyV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMistnostTerminyV3(mistnostId, lang, datumOd, datumDo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/mistnost/__mistnost_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail mistnosti
         * @param {string} mistnostId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMistnostV3(mistnostId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMistnostV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMistnostV3(mistnostId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/mistnost/__mistnost_id__/vyucovani/v3?lang=cs&rok=2021&typ_semestru_id=2&datum_od=23.9.2021&datum_do=30.9.2021\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Rozvrh mistnosti - vyucovani
         * @param {string} mistnostId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {number} [typSemestruId] 
         * @param {string} [datumOd] 
         * @param {string} [datumDo] 
         * @param {string} [viditelne] 0/1/bez uvedeni nefiltrovano
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMistnostVyucovaniV3(mistnostId: string, lang?: string, rok?: number, typSemestruId?: number, datumOd?: string, datumDo?: string, viditelne?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetVyucovaniV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMistnostVyucovaniV3(mistnostId, lang, rok, typSemestruId, datumOd, datumDo, viditelne, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/mistnosti/v4?lang=cs&rozvrhovane=1&aktualni=1\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam mistnosti
         * @param {string} [lang] 
         * @param {string} [rozvrhovane] 1
         * @param {string} [aktualni] 1
         * @param {number} [arealId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMistnostiV4(lang?: string, rozvrhovane?: string, aktualni?: string, arealId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMistnostiV4200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMistnostiV4(lang, rozvrhovane, aktualni, arealId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MistnostiApi - factory interface
 * @export
 */
export const MistnostiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MistnostiApiFp(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/mapa-arealu/v3?lang=cs&patro=1\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Mapa arealu
         * @param {string} [lang] 
         * @param {string} [patro] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMapaArealuV3(lang?: string, patro?: string, options?: any): AxiosPromise<GetMapaArealuV3200Response> {
            return localVarFp.getMapaArealuV3(lang, patro, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/mistnost/__mistnost_id__/rezervace/v3?lang=cs&datum_od=1.5.2022&datum_do=20.5.2022\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam jednorazovych rezervaci mistnosti
         * @param {string} mistnostId 
         * @param {string} [lang] 
         * @param {string} [datumOd] 
         * @param {string} [datumDo] 
         * @param {number} [rok] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMistnostRezervaceV3(mistnostId: string, lang?: string, datumOd?: string, datumDo?: string, rok?: number, options?: any): AxiosPromise<GetMistnostRezervaceV3200Response> {
            return localVarFp.getMistnostRezervaceV3(mistnostId, lang, datumOd, datumDo, rok, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/mistnost/__mistnost_id__/terminy/v3?lang=en&datum_od=1.1.2022&datum_do=1.2.2022\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Rozvrh mistnosti - terminy zkousek
         * @param {string} mistnostId 
         * @param {string} [lang] 
         * @param {string} [datumOd] 
         * @param {string} [datumDo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMistnostTerminyV3(mistnostId: string, lang?: string, datumOd?: string, datumDo?: string, options?: any): AxiosPromise<GetMistnostTerminyV3200Response> {
            return localVarFp.getMistnostTerminyV3(mistnostId, lang, datumOd, datumDo, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/mistnost/__mistnost_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail mistnosti
         * @param {string} mistnostId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMistnostV3(mistnostId: string, lang?: string, options?: any): AxiosPromise<GetMistnostV3200Response> {
            return localVarFp.getMistnostV3(mistnostId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/mistnost/__mistnost_id__/vyucovani/v3?lang=cs&rok=2021&typ_semestru_id=2&datum_od=23.9.2021&datum_do=30.9.2021\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Rozvrh mistnosti - vyucovani
         * @param {string} mistnostId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {number} [typSemestruId] 
         * @param {string} [datumOd] 
         * @param {string} [datumDo] 
         * @param {string} [viditelne] 0/1/bez uvedeni nefiltrovano
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMistnostVyucovaniV3(mistnostId: string, lang?: string, rok?: number, typSemestruId?: number, datumOd?: string, datumDo?: string, viditelne?: string, options?: any): AxiosPromise<GetAktualniPredmetVyucovaniV3200Response> {
            return localVarFp.getMistnostVyucovaniV3(mistnostId, lang, rok, typSemestruId, datumOd, datumDo, viditelne, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/mistnosti/v4?lang=cs&rozvrhovane=1&aktualni=1\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam mistnosti
         * @param {string} [lang] 
         * @param {string} [rozvrhovane] 1
         * @param {string} [aktualni] 1
         * @param {number} [arealId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMistnostiV4(lang?: string, rozvrhovane?: string, aktualni?: string, arealId?: number, options?: any): AxiosPromise<GetMistnostiV4200Response> {
            return localVarFp.getMistnostiV4(lang, rozvrhovane, aktualni, arealId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MistnostiApi - object-oriented interface
 * @export
 * @class MistnostiApi
 * @extends {BaseAPI}
 */
export class MistnostiApi extends BaseAPI {
    /**
     *  <a href=\"https://api.vut.cz/api/fit/mapa-arealu/v3?lang=cs&patro=1\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Mapa arealu
     * @param {string} [lang] 
     * @param {string} [patro] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MistnostiApi
     */
    public getMapaArealuV3(lang?: string, patro?: string, options?: AxiosRequestConfig) {
        return MistnostiApiFp(this.configuration).getMapaArealuV3(lang, patro, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/mistnost/__mistnost_id__/rezervace/v3?lang=cs&datum_od=1.5.2022&datum_do=20.5.2022\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam jednorazovych rezervaci mistnosti
     * @param {string} mistnostId 
     * @param {string} [lang] 
     * @param {string} [datumOd] 
     * @param {string} [datumDo] 
     * @param {number} [rok] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MistnostiApi
     */
    public getMistnostRezervaceV3(mistnostId: string, lang?: string, datumOd?: string, datumDo?: string, rok?: number, options?: AxiosRequestConfig) {
        return MistnostiApiFp(this.configuration).getMistnostRezervaceV3(mistnostId, lang, datumOd, datumDo, rok, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/mistnost/__mistnost_id__/terminy/v3?lang=en&datum_od=1.1.2022&datum_do=1.2.2022\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Rozvrh mistnosti - terminy zkousek
     * @param {string} mistnostId 
     * @param {string} [lang] 
     * @param {string} [datumOd] 
     * @param {string} [datumDo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MistnostiApi
     */
    public getMistnostTerminyV3(mistnostId: string, lang?: string, datumOd?: string, datumDo?: string, options?: AxiosRequestConfig) {
        return MistnostiApiFp(this.configuration).getMistnostTerminyV3(mistnostId, lang, datumOd, datumDo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/mistnost/__mistnost_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Detail mistnosti
     * @param {string} mistnostId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MistnostiApi
     */
    public getMistnostV3(mistnostId: string, lang?: string, options?: AxiosRequestConfig) {
        return MistnostiApiFp(this.configuration).getMistnostV3(mistnostId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/mistnost/__mistnost_id__/vyucovani/v3?lang=cs&rok=2021&typ_semestru_id=2&datum_od=23.9.2021&datum_do=30.9.2021\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Rozvrh mistnosti - vyucovani
     * @param {string} mistnostId 
     * @param {string} [lang] 
     * @param {number} [rok] 
     * @param {number} [typSemestruId] 
     * @param {string} [datumOd] 
     * @param {string} [datumDo] 
     * @param {string} [viditelne] 0/1/bez uvedeni nefiltrovano
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MistnostiApi
     */
    public getMistnostVyucovaniV3(mistnostId: string, lang?: string, rok?: number, typSemestruId?: number, datumOd?: string, datumDo?: string, viditelne?: string, options?: AxiosRequestConfig) {
        return MistnostiApiFp(this.configuration).getMistnostVyucovaniV3(mistnostId, lang, rok, typSemestruId, datumOd, datumDo, viditelne, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/mistnosti/v4?lang=cs&rozvrhovane=1&aktualni=1\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam mistnosti
     * @param {string} [lang] 
     * @param {string} [rozvrhovane] 1
     * @param {string} [aktualni] 1
     * @param {number} [arealId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MistnostiApi
     */
    public getMistnostiV4(lang?: string, rozvrhovane?: string, aktualni?: string, arealId?: number, options?: AxiosRequestConfig) {
        return MistnostiApiFp(this.configuration).getMistnostiV4(lang, rozvrhovane, aktualni, arealId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OborySpecializaceApi - axios parameter creator
 * @export
 */
export const OborySpecializaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/obor/__obor_id__/plan/__plan_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail studijniho planu pro obor/specializaci
         * @param {string} oborId 
         * @param {string} planId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOborPlanV3: async (oborId: string, planId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oborId' is not null or undefined
            assertParamExists('getOborPlanV3', 'oborId', oborId)
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('getOborPlanV3', 'planId', planId)
            const localVarPath = `/api/fit/obor/{obor_id}/plan/{plan_id}/v3`
                .replace(`{${"obor_id"}}`, encodeURIComponent(String(oborId)))
                .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (rok !== undefined) {
                localVarQueryParameter['rok'] = rok;
            }

            if (erasmus !== undefined) {
                localVarQueryParameter['erasmus'] = erasmus;
            }

            if (typ !== undefined) {
                localVarQueryParameter['typ'] = typ;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/obor/__obor_id__/plany/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam studijnich planu pro obor/specializaci
         * @param {string} oborId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOborPlanyV3: async (oborId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oborId' is not null or undefined
            assertParamExists('getOborPlanyV3', 'oborId', oborId)
            const localVarPath = `/api/fit/obor/{obor_id}/plany/v3`
                .replace(`{${"obor_id"}}`, encodeURIComponent(String(oborId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/obor/__obor_id__/pvskupiny/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam skupin PV predmetu
         * @param {string} oborId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOborPvskupinyV3: async (oborId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oborId' is not null or undefined
            assertParamExists('getOborPvskupinyV3', 'oborId', oborId)
            const localVarPath = `/api/fit/obor/{obor_id}/pvskupiny/v3`
                .replace(`{${"obor_id"}}`, encodeURIComponent(String(oborId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (rok !== undefined) {
                localVarQueryParameter['rok'] = rok;
            }

            if (erasmus !== undefined) {
                localVarQueryParameter['erasmus'] = erasmus;
            }

            if (typ !== undefined) {
                localVarQueryParameter['typ'] = typ;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/obor/__obor_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail oboru/specializace
         * @param {string} oborId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOborV3: async (oborId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oborId' is not null or undefined
            assertParamExists('getOborV3', 'oborId', oborId)
            const localVarPath = `/api/fit/obor/{obor_id}/v3`
                .replace(`{${"obor_id"}}`, encodeURIComponent(String(oborId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/obory/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam oboru/specializaci ve vsech programech
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOboryV3: async (lang?: string, rok?: number, erasmus?: string, typ?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/fit/obory/v3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (rok !== undefined) {
                localVarQueryParameter['rok'] = rok;
            }

            if (erasmus !== undefined) {
                localVarQueryParameter['erasmus'] = erasmus;
            }

            if (typ !== undefined) {
                localVarQueryParameter['typ'] = typ;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/obor/__obor_id__/plan/__plan_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail studijniho planu daneho oboru/specializace ve studijnim programu
         * @param {string} programId 
         * @param {string} oborId 
         * @param {string} planId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramOborPlanV3: async (programId: string, oborId: string, planId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('getProgramOborPlanV3', 'programId', programId)
            // verify required parameter 'oborId' is not null or undefined
            assertParamExists('getProgramOborPlanV3', 'oborId', oborId)
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('getProgramOborPlanV3', 'planId', planId)
            const localVarPath = `/api/fit/program/{program_id}/obor/{obor_id}/plan/{plan_id}/v3`
                .replace(`{${"program_id"}}`, encodeURIComponent(String(programId)))
                .replace(`{${"obor_id"}}`, encodeURIComponent(String(oborId)))
                .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (rok !== undefined) {
                localVarQueryParameter['rok'] = rok;
            }

            if (erasmus !== undefined) {
                localVarQueryParameter['erasmus'] = erasmus;
            }

            if (typ !== undefined) {
                localVarQueryParameter['typ'] = typ;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/obor/__obor_id__/plany/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam studijnich planu pro danou specializaci studijniho programu
         * @param {string} programId 
         * @param {string} oborId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramOborPlanyV3: async (programId: string, oborId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('getProgramOborPlanyV3', 'programId', programId)
            // verify required parameter 'oborId' is not null or undefined
            assertParamExists('getProgramOborPlanyV3', 'oborId', oborId)
            const localVarPath = `/api/fit/program/{program_id}/obor/{obor_id}/plany/v3`
                .replace(`{${"program_id"}}`, encodeURIComponent(String(programId)))
                .replace(`{${"obor_id"}}`, encodeURIComponent(String(oborId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (rok !== undefined) {
                localVarQueryParameter['rok'] = rok;
            }

            if (erasmus !== undefined) {
                localVarQueryParameter['erasmus'] = erasmus;
            }

            if (typ !== undefined) {
                localVarQueryParameter['typ'] = typ;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/obor/__obor_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Informace k oboru/specializaci daneho studijniho programu
         * @param {string} programId 
         * @param {string} oborId 
         * @param {string} [lang] 
         * @param {string} [iPripravaAkreditace] 0/1
         * @param {string} [iNevykazovatDoMatriky] 0/1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramOborV3: async (programId: string, oborId: string, lang?: string, iPripravaAkreditace?: string, iNevykazovatDoMatriky?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('getProgramOborV3', 'programId', programId)
            // verify required parameter 'oborId' is not null or undefined
            assertParamExists('getProgramOborV3', 'oborId', oborId)
            const localVarPath = `/api/fit/program/{program_id}/obor/{obor_id}/v3`
                .replace(`{${"program_id"}}`, encodeURIComponent(String(programId)))
                .replace(`{${"obor_id"}}`, encodeURIComponent(String(oborId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (iPripravaAkreditace !== undefined) {
                localVarQueryParameter['i_priprava_akreditace'] = iPripravaAkreditace;
            }

            if (iNevykazovatDoMatriky !== undefined) {
                localVarQueryParameter['i_nevykazovat_do_matriky'] = iNevykazovatDoMatriky;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/obory/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam oboru/specializaci v programu
         * @param {string} programId 
         * @param {number} [typ] 
         * @param {string} [lang] en
         * @param {string} [erasmus] 1
         * @param {string} [rok] 2021&#x3D;2021/22
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramOboryV3: async (programId: string, typ?: number, lang?: string, erasmus?: string, rok?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('getProgramOboryV3', 'programId', programId)
            const localVarPath = `/api/fit/program/{program_id}/obory/v3`
                .replace(`{${"program_id"}}`, encodeURIComponent(String(programId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (typ !== undefined) {
                localVarQueryParameter['typ'] = typ;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (erasmus !== undefined) {
                localVarQueryParameter['erasmus'] = erasmus;
            }

            if (rok !== undefined) {
                localVarQueryParameter['rok'] = rok;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OborySpecializaceApi - functional programming interface
 * @export
 */
export const OborySpecializaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OborySpecializaceApiAxiosParamCreator(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/obor/__obor_id__/plan/__plan_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail studijniho planu pro obor/specializaci
         * @param {string} oborId 
         * @param {string} planId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOborPlanV3(oborId: string, planId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOborPlanV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOborPlanV3(oborId, planId, lang, rok, erasmus, typ, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/obor/__obor_id__/plany/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam studijnich planu pro obor/specializaci
         * @param {string} oborId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOborPlanyV3(oborId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOborPlanyV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOborPlanyV3(oborId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/obor/__obor_id__/pvskupiny/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam skupin PV predmetu
         * @param {string} oborId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOborPvskupinyV3(oborId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOborPvskupinyV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOborPvskupinyV3(oborId, lang, rok, erasmus, typ, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/obor/__obor_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail oboru/specializace
         * @param {string} oborId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOborV3(oborId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOborV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOborV3(oborId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/obory/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam oboru/specializaci ve vsech programech
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOboryV3(lang?: string, rok?: number, erasmus?: string, typ?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOboryV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOboryV3(lang, rok, erasmus, typ, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/obor/__obor_id__/plan/__plan_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail studijniho planu daneho oboru/specializace ve studijnim programu
         * @param {string} programId 
         * @param {string} oborId 
         * @param {string} planId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramOborPlanV3(programId: string, oborId: string, planId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOborPlanV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramOborPlanV3(programId, oborId, planId, lang, rok, erasmus, typ, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/obor/__obor_id__/plany/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam studijnich planu pro danou specializaci studijniho programu
         * @param {string} programId 
         * @param {string} oborId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramOborPlanyV3(programId: string, oborId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOborPlanyV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramOborPlanyV3(programId, oborId, lang, rok, erasmus, typ, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/obor/__obor_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Informace k oboru/specializaci daneho studijniho programu
         * @param {string} programId 
         * @param {string} oborId 
         * @param {string} [lang] 
         * @param {string} [iPripravaAkreditace] 0/1
         * @param {string} [iNevykazovatDoMatriky] 0/1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramOborV3(programId: string, oborId: string, lang?: string, iPripravaAkreditace?: string, iNevykazovatDoMatriky?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOborV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramOborV3(programId, oborId, lang, iPripravaAkreditace, iNevykazovatDoMatriky, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/obory/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam oboru/specializaci v programu
         * @param {string} programId 
         * @param {number} [typ] 
         * @param {string} [lang] en
         * @param {string} [erasmus] 1
         * @param {string} [rok] 2021&#x3D;2021/22
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramOboryV3(programId: string, typ?: number, lang?: string, erasmus?: string, rok?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProgramOboryV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramOboryV3(programId, typ, lang, erasmus, rok, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OborySpecializaceApi - factory interface
 * @export
 */
export const OborySpecializaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OborySpecializaceApiFp(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/obor/__obor_id__/plan/__plan_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail studijniho planu pro obor/specializaci
         * @param {string} oborId 
         * @param {string} planId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOborPlanV3(oborId: string, planId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options?: any): AxiosPromise<GetOborPlanV3200Response> {
            return localVarFp.getOborPlanV3(oborId, planId, lang, rok, erasmus, typ, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/obor/__obor_id__/plany/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam studijnich planu pro obor/specializaci
         * @param {string} oborId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOborPlanyV3(oborId: string, options?: any): AxiosPromise<GetOborPlanyV3200Response> {
            return localVarFp.getOborPlanyV3(oborId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/obor/__obor_id__/pvskupiny/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam skupin PV predmetu
         * @param {string} oborId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOborPvskupinyV3(oborId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options?: any): AxiosPromise<GetOborPvskupinyV3200Response> {
            return localVarFp.getOborPvskupinyV3(oborId, lang, rok, erasmus, typ, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/obor/__obor_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail oboru/specializace
         * @param {string} oborId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOborV3(oborId: string, lang?: string, options?: any): AxiosPromise<GetOborV3200Response> {
            return localVarFp.getOborV3(oborId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/obory/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam oboru/specializaci ve vsech programech
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOboryV3(lang?: string, rok?: number, erasmus?: string, typ?: string, options?: any): AxiosPromise<GetOboryV3200Response> {
            return localVarFp.getOboryV3(lang, rok, erasmus, typ, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/obor/__obor_id__/plan/__plan_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail studijniho planu daneho oboru/specializace ve studijnim programu
         * @param {string} programId 
         * @param {string} oborId 
         * @param {string} planId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramOborPlanV3(programId: string, oborId: string, planId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options?: any): AxiosPromise<GetOborPlanV3200Response> {
            return localVarFp.getProgramOborPlanV3(programId, oborId, planId, lang, rok, erasmus, typ, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/obor/__obor_id__/plany/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam studijnich planu pro danou specializaci studijniho programu
         * @param {string} programId 
         * @param {string} oborId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramOborPlanyV3(programId: string, oborId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options?: any): AxiosPromise<GetOborPlanyV3200Response> {
            return localVarFp.getProgramOborPlanyV3(programId, oborId, lang, rok, erasmus, typ, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/obor/__obor_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Informace k oboru/specializaci daneho studijniho programu
         * @param {string} programId 
         * @param {string} oborId 
         * @param {string} [lang] 
         * @param {string} [iPripravaAkreditace] 0/1
         * @param {string} [iNevykazovatDoMatriky] 0/1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramOborV3(programId: string, oborId: string, lang?: string, iPripravaAkreditace?: string, iNevykazovatDoMatriky?: string, options?: any): AxiosPromise<GetOborV3200Response> {
            return localVarFp.getProgramOborV3(programId, oborId, lang, iPripravaAkreditace, iNevykazovatDoMatriky, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/obory/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam oboru/specializaci v programu
         * @param {string} programId 
         * @param {number} [typ] 
         * @param {string} [lang] en
         * @param {string} [erasmus] 1
         * @param {string} [rok] 2021&#x3D;2021/22
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramOboryV3(programId: string, typ?: number, lang?: string, erasmus?: string, rok?: string, options?: any): AxiosPromise<GetProgramOboryV3200Response> {
            return localVarFp.getProgramOboryV3(programId, typ, lang, erasmus, rok, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OborySpecializaceApi - object-oriented interface
 * @export
 * @class OborySpecializaceApi
 * @extends {BaseAPI}
 */
export class OborySpecializaceApi extends BaseAPI {
    /**
     *  <a href=\"https://api.vut.cz/api/fit/obor/__obor_id__/plan/__plan_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Detail studijniho planu pro obor/specializaci
     * @param {string} oborId 
     * @param {string} planId 
     * @param {string} [lang] 
     * @param {number} [rok] 
     * @param {string} [erasmus] 1
     * @param {string} [typ] M/N/D/B
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OborySpecializaceApi
     */
    public getOborPlanV3(oborId: string, planId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options?: AxiosRequestConfig) {
        return OborySpecializaceApiFp(this.configuration).getOborPlanV3(oborId, planId, lang, rok, erasmus, typ, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/obor/__obor_id__/plany/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam studijnich planu pro obor/specializaci
     * @param {string} oborId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OborySpecializaceApi
     */
    public getOborPlanyV3(oborId: string, options?: AxiosRequestConfig) {
        return OborySpecializaceApiFp(this.configuration).getOborPlanyV3(oborId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/obor/__obor_id__/pvskupiny/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam skupin PV predmetu
     * @param {string} oborId 
     * @param {string} [lang] 
     * @param {number} [rok] 
     * @param {string} [erasmus] 1
     * @param {string} [typ] M/N/D/B
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OborySpecializaceApi
     */
    public getOborPvskupinyV3(oborId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options?: AxiosRequestConfig) {
        return OborySpecializaceApiFp(this.configuration).getOborPvskupinyV3(oborId, lang, rok, erasmus, typ, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/obor/__obor_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Detail oboru/specializace
     * @param {string} oborId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OborySpecializaceApi
     */
    public getOborV3(oborId: string, lang?: string, options?: AxiosRequestConfig) {
        return OborySpecializaceApiFp(this.configuration).getOborV3(oborId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/obory/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam oboru/specializaci ve vsech programech
     * @param {string} [lang] 
     * @param {number} [rok] 
     * @param {string} [erasmus] 1
     * @param {string} [typ] M/N/D/B
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OborySpecializaceApi
     */
    public getOboryV3(lang?: string, rok?: number, erasmus?: string, typ?: string, options?: AxiosRequestConfig) {
        return OborySpecializaceApiFp(this.configuration).getOboryV3(lang, rok, erasmus, typ, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/obor/__obor_id__/plan/__plan_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Detail studijniho planu daneho oboru/specializace ve studijnim programu
     * @param {string} programId 
     * @param {string} oborId 
     * @param {string} planId 
     * @param {string} [lang] 
     * @param {number} [rok] 
     * @param {string} [erasmus] 1
     * @param {string} [typ] M/N/D/B
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OborySpecializaceApi
     */
    public getProgramOborPlanV3(programId: string, oborId: string, planId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options?: AxiosRequestConfig) {
        return OborySpecializaceApiFp(this.configuration).getProgramOborPlanV3(programId, oborId, planId, lang, rok, erasmus, typ, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/obor/__obor_id__/plany/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam studijnich planu pro danou specializaci studijniho programu
     * @param {string} programId 
     * @param {string} oborId 
     * @param {string} [lang] 
     * @param {number} [rok] 
     * @param {string} [erasmus] 1
     * @param {string} [typ] M/N/D/B
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OborySpecializaceApi
     */
    public getProgramOborPlanyV3(programId: string, oborId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options?: AxiosRequestConfig) {
        return OborySpecializaceApiFp(this.configuration).getProgramOborPlanyV3(programId, oborId, lang, rok, erasmus, typ, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/obor/__obor_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Informace k oboru/specializaci daneho studijniho programu
     * @param {string} programId 
     * @param {string} oborId 
     * @param {string} [lang] 
     * @param {string} [iPripravaAkreditace] 0/1
     * @param {string} [iNevykazovatDoMatriky] 0/1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OborySpecializaceApi
     */
    public getProgramOborV3(programId: string, oborId: string, lang?: string, iPripravaAkreditace?: string, iNevykazovatDoMatriky?: string, options?: AxiosRequestConfig) {
        return OborySpecializaceApiFp(this.configuration).getProgramOborV3(programId, oborId, lang, iPripravaAkreditace, iNevykazovatDoMatriky, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/obory/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam oboru/specializaci v programu
     * @param {string} programId 
     * @param {number} [typ] 
     * @param {string} [lang] en
     * @param {string} [erasmus] 1
     * @param {string} [rok] 2021&#x3D;2021/22
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OborySpecializaceApi
     */
    public getProgramOboryV3(programId: string, typ?: number, lang?: string, erasmus?: string, rok?: string, options?: AxiosRequestConfig) {
        return OborySpecializaceApiFp(this.configuration).getProgramOboryV3(programId, typ, lang, erasmus, rok, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OceneniApi - axios parameter creator
 * @export
 */
export const OceneniApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/oceneni/externi/__externi__/typ/__o_poradi__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail oceneni
         * @param {string} externi 
         * @param {string} oPoradi 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOceneniExterniTypV3: async (externi: string, oPoradi: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externi' is not null or undefined
            assertParamExists('getOceneniExterniTypV3', 'externi', externi)
            // verify required parameter 'oPoradi' is not null or undefined
            assertParamExists('getOceneniExterniTypV3', 'oPoradi', oPoradi)
            const localVarPath = `/api/fit/oceneni/externi/{externi}/typ/{o_poradi}/v3`
                .replace(`{${"externi"}}`, encodeURIComponent(String(externi)))
                .replace(`{${"o_poradi"}}`, encodeURIComponent(String(oPoradi)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/oceneni/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam oceneni
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOceneniV3: async (lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/fit/oceneni/v3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OceneniApi - functional programming interface
 * @export
 */
export const OceneniApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OceneniApiAxiosParamCreator(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/oceneni/externi/__externi__/typ/__o_poradi__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail oceneni
         * @param {string} externi 
         * @param {string} oPoradi 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOceneniExterniTypV3(externi: string, oPoradi: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOceneniExterniTypV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOceneniExterniTypV3(externi, oPoradi, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/oceneni/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam oceneni
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOceneniV3(lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOceneniV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOceneniV3(lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OceneniApi - factory interface
 * @export
 */
export const OceneniApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OceneniApiFp(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/oceneni/externi/__externi__/typ/__o_poradi__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail oceneni
         * @param {string} externi 
         * @param {string} oPoradi 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOceneniExterniTypV3(externi: string, oPoradi: string, lang?: string, options?: any): AxiosPromise<GetOceneniExterniTypV3200Response> {
            return localVarFp.getOceneniExterniTypV3(externi, oPoradi, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/oceneni/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam oceneni
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOceneniV3(lang?: string, options?: any): AxiosPromise<GetOceneniV3200Response> {
            return localVarFp.getOceneniV3(lang, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OceneniApi - object-oriented interface
 * @export
 * @class OceneniApi
 * @extends {BaseAPI}
 */
export class OceneniApi extends BaseAPI {
    /**
     *  <a href=\"https://api.vut.cz/api/fit/oceneni/externi/__externi__/typ/__o_poradi__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Detail oceneni
     * @param {string} externi 
     * @param {string} oPoradi 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OceneniApi
     */
    public getOceneniExterniTypV3(externi: string, oPoradi: string, lang?: string, options?: AxiosRequestConfig) {
        return OceneniApiFp(this.configuration).getOceneniExterniTypV3(externi, oPoradi, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/oceneni/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam oceneni
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OceneniApi
     */
    public getOceneniV3(lang?: string, options?: AxiosRequestConfig) {
        return OceneniApiFp(this.configuration).getOceneniV3(lang, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizacniJednotkyApi - axios parameter creator
 * @export
 */
export const OrganizacniJednotkyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/org/__parent_orgunitid__/orgs/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam podrizenych organizacnich jednotek dane organizacni jednotky
         * @param {string} parentOrgunitid 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgOrgsV3: async (parentOrgunitid: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentOrgunitid' is not null or undefined
            assertParamExists('getOrgOrgsV3', 'parentOrgunitid', parentOrgunitid)
            const localVarPath = `/api/fit/org/{parent_orgunitid}/orgs/v3`
                .replace(`{${"parent_orgunitid"}}`, encodeURIComponent(String(parentOrgunitid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/org/__orgunitid__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail dane organizacni jednotky
         * @param {string} orgunitid 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgV3: async (orgunitid: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgunitid' is not null or undefined
            assertParamExists('getOrgV3', 'orgunitid', orgunitid)
            const localVarPath = `/api/fit/org/{orgunitid}/v3`
                .replace(`{${"orgunitid"}}`, encodeURIComponent(String(orgunitid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/org/__orgunitid__/zamestnanci/v4\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam osob v dane org. jednotce
         * @param {string} orgunitid 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgZamestnanciV4: async (orgunitid: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgunitid' is not null or undefined
            assertParamExists('getOrgZamestnanciV4', 'orgunitid', orgunitid)
            const localVarPath = `/api/fit/org/{orgunitid}/zamestnanci/v4`
                .replace(`{${"orgunitid"}}`, encodeURIComponent(String(orgunitid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/orgs/vut/fit/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam organizacnich jednotek
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgsVutV3: async (lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/fit/orgs/vut/fit/v3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizacniJednotkyApi - functional programming interface
 * @export
 */
export const OrganizacniJednotkyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizacniJednotkyApiAxiosParamCreator(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/org/__parent_orgunitid__/orgs/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam podrizenych organizacnich jednotek dane organizacni jednotky
         * @param {string} parentOrgunitid 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrgOrgsV3(parentOrgunitid: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrgOrgsV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrgOrgsV3(parentOrgunitid, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/org/__orgunitid__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail dane organizacni jednotky
         * @param {string} orgunitid 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrgV3(orgunitid: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrgV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrgV3(orgunitid, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/org/__orgunitid__/zamestnanci/v4\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam osob v dane org. jednotce
         * @param {string} orgunitid 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrgZamestnanciV4(orgunitid: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrgZamestnanciV4200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrgZamestnanciV4(orgunitid, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/orgs/vut/fit/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam organizacnich jednotek
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrgsVutV3(lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrgsVutV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrgsVutV3(lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizacniJednotkyApi - factory interface
 * @export
 */
export const OrganizacniJednotkyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizacniJednotkyApiFp(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/org/__parent_orgunitid__/orgs/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam podrizenych organizacnich jednotek dane organizacni jednotky
         * @param {string} parentOrgunitid 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgOrgsV3(parentOrgunitid: string, lang?: string, options?: any): AxiosPromise<GetOrgOrgsV3200Response> {
            return localVarFp.getOrgOrgsV3(parentOrgunitid, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/org/__orgunitid__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail dane organizacni jednotky
         * @param {string} orgunitid 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgV3(orgunitid: string, lang?: string, options?: any): AxiosPromise<GetOrgV3200Response> {
            return localVarFp.getOrgV3(orgunitid, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/org/__orgunitid__/zamestnanci/v4\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam osob v dane org. jednotce
         * @param {string} orgunitid 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgZamestnanciV4(orgunitid: string, lang?: string, options?: any): AxiosPromise<GetOrgZamestnanciV4200Response> {
            return localVarFp.getOrgZamestnanciV4(orgunitid, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/orgs/vut/fit/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam organizacnich jednotek
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgsVutV3(lang?: string, options?: any): AxiosPromise<GetOrgsVutV3200Response> {
            return localVarFp.getOrgsVutV3(lang, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizacniJednotkyApi - object-oriented interface
 * @export
 * @class OrganizacniJednotkyApi
 * @extends {BaseAPI}
 */
export class OrganizacniJednotkyApi extends BaseAPI {
    /**
     *  <a href=\"https://api.vut.cz/api/fit/org/__parent_orgunitid__/orgs/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam podrizenych organizacnich jednotek dane organizacni jednotky
     * @param {string} parentOrgunitid 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizacniJednotkyApi
     */
    public getOrgOrgsV3(parentOrgunitid: string, lang?: string, options?: AxiosRequestConfig) {
        return OrganizacniJednotkyApiFp(this.configuration).getOrgOrgsV3(parentOrgunitid, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/org/__orgunitid__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Detail dane organizacni jednotky
     * @param {string} orgunitid 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizacniJednotkyApi
     */
    public getOrgV3(orgunitid: string, lang?: string, options?: AxiosRequestConfig) {
        return OrganizacniJednotkyApiFp(this.configuration).getOrgV3(orgunitid, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/org/__orgunitid__/zamestnanci/v4\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam osob v dane org. jednotce
     * @param {string} orgunitid 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizacniJednotkyApi
     */
    public getOrgZamestnanciV4(orgunitid: string, lang?: string, options?: AxiosRequestConfig) {
        return OrganizacniJednotkyApiFp(this.configuration).getOrgZamestnanciV4(orgunitid, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/orgs/vut/fit/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam organizacnich jednotek
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizacniJednotkyApi
     */
    public getOrgsVutV3(lang?: string, options?: AxiosRequestConfig) {
        return OrganizacniJednotkyApiFp(this.configuration).getOrgsVutV3(lang, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OsobyApi - axios parameter creator
 * @export
 */
export const OsobyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Neomezene fotografie
         * @param {number} perId 
         * @param {number} [destWidth] 
         * @param {number} [destHeight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArchivOsobaFotografiePasovaV3: async (perId: number, destWidth?: number, destHeight?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'perId' is not null or undefined
            assertParamExists('getArchivOsobaFotografiePasovaV3', 'perId', perId)
            const localVarPath = `/api/fit/archiv/osoba/{per_id}/fotografie/pasova/v3`
                .replace(`{${"per_id"}}`, encodeURIComponent(String(perId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (destWidth !== undefined) {
                localVarQueryParameter['dest_width'] = destWidth;
            }

            if (destHeight !== undefined) {
                localVarQueryParameter['dest_height'] = destHeight;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/osoba/__per_id__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Je osoba na VUT?
         * @param {string} perId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsobaV3: async (perId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'perId' is not null or undefined
            assertParamExists('getOsobaV3', 'perId', perId)
            const localVarPath = `/api/fit/osoba/{per_id}/v3`
                .replace(`{${"per_id"}}`, encodeURIComponent(String(perId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanci/v4?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam zamestnancu a doktorandu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanciV4: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/fit/zamestnanci/v4`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/aktivity/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam aktivit dane osoby
         * @param {string} perId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecAktivityV3: async (perId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'perId' is not null or undefined
            assertParamExists('getZamestnanecAktivityV3', 'perId', perId)
            const localVarPath = `/api/fit/zamestnanec/{per_id}/aktivity/v3`
                .replace(`{${"per_id"}}`, encodeURIComponent(String(perId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verejna fotografie osoby bez prihlaseni
         * @param {number} perId 
         * @param {boolean} [jenKvalitni] 
         * @param {number} [destWidth] 
         * @param {number} [destHeight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecFotografiePasovaVerejnaBezPrihlaseniV3: async (perId: number, jenKvalitni?: boolean, destWidth?: number, destHeight?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'perId' is not null or undefined
            assertParamExists('getZamestnanecFotografiePasovaVerejnaBezPrihlaseniV3', 'perId', perId)
            const localVarPath = `/api/fit/zamestnanec/{per_id}/fotografie/pasova/verejna-bez-prihlaseni/v3`
                .replace(`{${"per_id"}}`, encodeURIComponent(String(perId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (jenKvalitni !== undefined) {
                localVarQueryParameter['jen_kvalitni'] = jenKvalitni;
            }

            if (destWidth !== undefined) {
                localVarQueryParameter['dest_width'] = destWidth;
            }

            if (destHeight !== undefined) {
                localVarQueryParameter['dest_height'] = destHeight;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verejna fotografie osoby po prihlaseni
         * @param {number} perId 
         * @param {boolean} [jenKvalitni] 
         * @param {number} [destWidth] 
         * @param {number} [destHeight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecFotografiePasovaVerejnaPoPrihlaseniV3: async (perId: number, jenKvalitni?: boolean, destWidth?: number, destHeight?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'perId' is not null or undefined
            assertParamExists('getZamestnanecFotografiePasovaVerejnaPoPrihlaseniV3', 'perId', perId)
            const localVarPath = `/api/fit/zamestnanec/{per_id}/fotografie/pasova/verejna-po-prihlaseni/v3`
                .replace(`{${"per_id"}}`, encodeURIComponent(String(perId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (jenKvalitni !== undefined) {
                localVarQueryParameter['jen_kvalitni'] = jenKvalitni;
            }

            if (destWidth !== undefined) {
                localVarQueryParameter['dest_width'] = destWidth;
            }

            if (destHeight !== undefined) {
                localVarQueryParameter['dest_height'] = destHeight;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/kontakt/__kontakt_id__/doplnkove-emaily/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Funkcni e-maily zamestnance
         * @param {string} perId 
         * @param {string} kontaktId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecKontaktDoplnkoveEmailyV3: async (perId: string, kontaktId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'perId' is not null or undefined
            assertParamExists('getZamestnanecKontaktDoplnkoveEmailyV3', 'perId', perId)
            // verify required parameter 'kontaktId' is not null or undefined
            assertParamExists('getZamestnanecKontaktDoplnkoveEmailyV3', 'kontaktId', kontaktId)
            const localVarPath = `/api/fit/zamestnanec/{per_id}/kontakt/{kontakt_id}/doplnkove-emaily/v3`
                .replace(`{${"per_id"}}`, encodeURIComponent(String(perId)))
                .replace(`{${"kontakt_id"}}`, encodeURIComponent(String(kontaktId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/kontakty/v4?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam vztahu osoby k organizacnim jednotkam s kontakty
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecKontaktyV4: async (perId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'perId' is not null or undefined
            assertParamExists('getZamestnanecKontaktyV4', 'perId', perId)
            const localVarPath = `/api/fit/zamestnanec/{per_id}/kontakty/v4`
                .replace(`{${"per_id"}}`, encodeURIComponent(String(perId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/konzultacni-hodiny/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Konzultacni hodiny dane osoby
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecKonzultacniHodinyV3: async (perId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'perId' is not null or undefined
            assertParamExists('getZamestnanecKonzultacniHodinyV3', 'perId', perId)
            const localVarPath = `/api/fit/zamestnanec/{per_id}/konzultacni-hodiny/v3`
                .replace(`{${"per_id"}}`, encodeURIComponent(String(perId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/oceneni/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam oceneni dane osoby
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecOceneniV3: async (perId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'perId' is not null or undefined
            assertParamExists('getZamestnanecOceneniV3', 'perId', perId)
            const localVarPath = `/api/fit/zamestnanec/{per_id}/oceneni/v3`
                .replace(`{${"per_id"}}`, encodeURIComponent(String(perId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/patenty/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam patentu dane osoby
         * @param {string} perId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecPatentyV3: async (perId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'perId' is not null or undefined
            assertParamExists('getZamestnanecPatentyV3', 'perId', perId)
            const localVarPath = `/api/fit/zamestnanec/{per_id}/patenty/v3`
                .replace(`{${"per_id"}}`, encodeURIComponent(String(perId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__garant_id__/predmety/garantovane/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam garantovanych predmetu dane osoby
         * @param {string} garantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecPredmetyGarantovaneV3: async (garantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'garantId' is not null or undefined
            assertParamExists('getZamestnanecPredmetyGarantovaneV3', 'garantId', garantId)
            const localVarPath = `/api/fit/zamestnanec/{garant_id}/predmety/garantovane/v3`
                .replace(`{${"garant_id"}}`, encodeURIComponent(String(garantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/predmety/vyucovane/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam vyucovanych predmetu dane osoby
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecPredmetyVyucovaneV3: async (perId: string, lang?: string, rok?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'perId' is not null or undefined
            assertParamExists('getZamestnanecPredmetyVyucovaneV3', 'perId', perId)
            const localVarPath = `/api/fit/zamestnanec/{per_id}/predmety/vyucovane/v3`
                .replace(`{${"per_id"}}`, encodeURIComponent(String(perId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (rok !== undefined) {
                localVarQueryParameter['rok'] = rok;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/predmety/vyucovane/v4?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam vyucovanych predmetu dane osoby s typy rozvrhovych jednotek
         * @param {string} perId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecPredmetyVyucovaneV4: async (perId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'perId' is not null or undefined
            assertParamExists('getZamestnanecPredmetyVyucovaneV4', 'perId', perId)
            const localVarPath = `/api/fit/zamestnanec/{per_id}/predmety/vyucovane/v4`
                .replace(`{${"per_id"}}`, encodeURIComponent(String(perId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/produkty/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam produktu dane osoby
         * @param {string} perId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecProduktyV3: async (perId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'perId' is not null or undefined
            assertParamExists('getZamestnanecProduktyV3', 'perId', perId)
            const localVarPath = `/api/fit/zamestnanec/{per_id}/produkty/v3`
                .replace(`{${"per_id"}}`, encodeURIComponent(String(perId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/projekty/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam projektu dane osoby
         * @param {string} perId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecProjektyV3: async (perId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'perId' is not null or undefined
            assertParamExists('getZamestnanecProjektyV3', 'perId', perId)
            const localVarPath = `/api/fit/zamestnanec/{per_id}/projekty/v3`
                .replace(`{${"per_id"}}`, encodeURIComponent(String(perId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/publikace/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam publikaci dane osoby
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecPublikaceV3: async (perId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'perId' is not null or undefined
            assertParamExists('getZamestnanecPublikaceV3', 'perId', perId)
            const localVarPath = `/api/fit/zamestnanec/{per_id}/publikace/v3`
                .replace(`{${"per_id"}}`, encodeURIComponent(String(perId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/texty/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam textu dane osoby na web
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecTextyV3: async (perId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'perId' is not null or undefined
            assertParamExists('getZamestnanecTextyV3', 'perId', perId)
            const localVarPath = `/api/fit/zamestnanec/{per_id}/texty/v3`
                .replace(`{${"per_id"}}`, encodeURIComponent(String(perId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/v4?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail zamestnance/doktoranda
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecV4: async (perId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'perId' is not null or undefined
            assertParamExists('getZamestnanecV4', 'perId', perId)
            const localVarPath = `/api/fit/zamestnanec/{per_id}/v4`
                .replace(`{${"per_id"}}`, encodeURIComponent(String(perId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/zivotopis/strukturovany/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Strukturovana cast zivotopisu dane osoby
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecZivotopisStrukturovanyV3: async (perId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'perId' is not null or undefined
            assertParamExists('getZamestnanecZivotopisStrukturovanyV3', 'perId', perId)
            const localVarPath = `/api/fit/zamestnanec/{per_id}/zivotopis/strukturovany/v3`
                .replace(`{${"per_id"}}`, encodeURIComponent(String(perId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/zivotopis/textovy/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Textova cast zivotopisu dane osoby
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecZivotopisTextovyV3: async (perId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'perId' is not null or undefined
            assertParamExists('getZamestnanecZivotopisTextovyV3', 'perId', perId)
            const localVarPath = `/api/fit/zamestnanec/{per_id}/zivotopis/textovy/v3`
                .replace(`{${"per_id"}}`, encodeURIComponent(String(perId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OsobyApi - functional programming interface
 * @export
 */
export const OsobyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OsobyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Neomezene fotografie
         * @param {number} perId 
         * @param {number} [destWidth] 
         * @param {number} [destHeight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArchivOsobaFotografiePasovaV3(perId: number, destWidth?: number, destHeight?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArchivOsobaFotografiePasovaV3(perId, destWidth, destHeight, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/osoba/__per_id__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Je osoba na VUT?
         * @param {string} perId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOsobaV3(perId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOsobaV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOsobaV3(perId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanci/v4?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam zamestnancu a doktorandu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZamestnanciV4(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetZamestnanciV4200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZamestnanciV4(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/aktivity/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam aktivit dane osoby
         * @param {string} perId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZamestnanecAktivityV3(perId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetZamestnanecAktivityV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZamestnanecAktivityV3(perId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verejna fotografie osoby bez prihlaseni
         * @param {number} perId 
         * @param {boolean} [jenKvalitni] 
         * @param {number} [destWidth] 
         * @param {number} [destHeight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZamestnanecFotografiePasovaVerejnaBezPrihlaseniV3(perId: number, jenKvalitni?: boolean, destWidth?: number, destHeight?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZamestnanecFotografiePasovaVerejnaBezPrihlaseniV3(perId, jenKvalitni, destWidth, destHeight, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verejna fotografie osoby po prihlaseni
         * @param {number} perId 
         * @param {boolean} [jenKvalitni] 
         * @param {number} [destWidth] 
         * @param {number} [destHeight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZamestnanecFotografiePasovaVerejnaPoPrihlaseniV3(perId: number, jenKvalitni?: boolean, destWidth?: number, destHeight?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZamestnanecFotografiePasovaVerejnaPoPrihlaseniV3(perId, jenKvalitni, destWidth, destHeight, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/kontakt/__kontakt_id__/doplnkove-emaily/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Funkcni e-maily zamestnance
         * @param {string} perId 
         * @param {string} kontaktId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZamestnanecKontaktDoplnkoveEmailyV3(perId: string, kontaktId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetZamestnanecKontaktDoplnkoveEmailyV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZamestnanecKontaktDoplnkoveEmailyV3(perId, kontaktId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/kontakty/v4?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam vztahu osoby k organizacnim jednotkam s kontakty
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZamestnanecKontaktyV4(perId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZamestnanecKontaktyV4(perId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/konzultacni-hodiny/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Konzultacni hodiny dane osoby
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZamestnanecKonzultacniHodinyV3(perId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetZamestnanecKonzultacniHodinyV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZamestnanecKonzultacniHodinyV3(perId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/oceneni/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam oceneni dane osoby
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZamestnanecOceneniV3(perId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProgramOboryV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZamestnanecOceneniV3(perId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/patenty/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam patentu dane osoby
         * @param {string} perId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZamestnanecPatentyV3(perId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProgramOboryV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZamestnanecPatentyV3(perId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__garant_id__/predmety/garantovane/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam garantovanych predmetu dane osoby
         * @param {string} garantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZamestnanecPredmetyGarantovaneV3(garantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetZamestnanecPredmetyGarantovaneV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZamestnanecPredmetyGarantovaneV3(garantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/predmety/vyucovane/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam vyucovanych predmetu dane osoby
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZamestnanecPredmetyVyucovaneV3(perId: string, lang?: string, rok?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetZamestnanecPredmetyVyucovaneV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZamestnanecPredmetyVyucovaneV3(perId, lang, rok, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/predmety/vyucovane/v4?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam vyucovanych predmetu dane osoby s typy rozvrhovych jednotek
         * @param {string} perId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZamestnanecPredmetyVyucovaneV4(perId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetZamestnanecPredmetyVyucovaneV4200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZamestnanecPredmetyVyucovaneV4(perId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/produkty/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam produktu dane osoby
         * @param {string} perId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZamestnanecProduktyV3(perId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetZamestnanecProduktyV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZamestnanecProduktyV3(perId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/projekty/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam projektu dane osoby
         * @param {string} perId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZamestnanecProjektyV3(perId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProgramOboryV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZamestnanecProjektyV3(perId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/publikace/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam publikaci dane osoby
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZamestnanecPublikaceV3(perId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetZamestnanecPublikaceV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZamestnanecPublikaceV3(perId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/texty/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam textu dane osoby na web
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZamestnanecTextyV3(perId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProgramOboryV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZamestnanecTextyV3(perId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/v4?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail zamestnance/doktoranda
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZamestnanecV4(perId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetZamestnanecV4200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZamestnanecV4(perId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/zivotopis/strukturovany/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Strukturovana cast zivotopisu dane osoby
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZamestnanecZivotopisStrukturovanyV3(perId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetZamestnanecZivotopisStrukturovanyV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZamestnanecZivotopisStrukturovanyV3(perId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/zivotopis/textovy/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Textova cast zivotopisu dane osoby
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZamestnanecZivotopisTextovyV3(perId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetZamestnanecZivotopisTextovyV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZamestnanecZivotopisTextovyV3(perId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OsobyApi - factory interface
 * @export
 */
export const OsobyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OsobyApiFp(configuration)
    return {
        /**
         * 
         * @summary Neomezene fotografie
         * @param {number} perId 
         * @param {number} [destWidth] 
         * @param {number} [destHeight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArchivOsobaFotografiePasovaV3(perId: number, destWidth?: number, destHeight?: number, options?: any): AxiosPromise<File> {
            return localVarFp.getArchivOsobaFotografiePasovaV3(perId, destWidth, destHeight, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/osoba/__per_id__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Je osoba na VUT?
         * @param {string} perId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsobaV3(perId: string, options?: any): AxiosPromise<GetOsobaV3200Response> {
            return localVarFp.getOsobaV3(perId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanci/v4?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam zamestnancu a doktorandu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanciV4(options?: any): AxiosPromise<GetZamestnanciV4200Response> {
            return localVarFp.getZamestnanciV4(options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/aktivity/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam aktivit dane osoby
         * @param {string} perId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecAktivityV3(perId: string, options?: any): AxiosPromise<GetZamestnanecAktivityV3200Response> {
            return localVarFp.getZamestnanecAktivityV3(perId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verejna fotografie osoby bez prihlaseni
         * @param {number} perId 
         * @param {boolean} [jenKvalitni] 
         * @param {number} [destWidth] 
         * @param {number} [destHeight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecFotografiePasovaVerejnaBezPrihlaseniV3(perId: number, jenKvalitni?: boolean, destWidth?: number, destHeight?: number, options?: any): AxiosPromise<File> {
            return localVarFp.getZamestnanecFotografiePasovaVerejnaBezPrihlaseniV3(perId, jenKvalitni, destWidth, destHeight, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verejna fotografie osoby po prihlaseni
         * @param {number} perId 
         * @param {boolean} [jenKvalitni] 
         * @param {number} [destWidth] 
         * @param {number} [destHeight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecFotografiePasovaVerejnaPoPrihlaseniV3(perId: number, jenKvalitni?: boolean, destWidth?: number, destHeight?: number, options?: any): AxiosPromise<File> {
            return localVarFp.getZamestnanecFotografiePasovaVerejnaPoPrihlaseniV3(perId, jenKvalitni, destWidth, destHeight, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/kontakt/__kontakt_id__/doplnkove-emaily/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Funkcni e-maily zamestnance
         * @param {string} perId 
         * @param {string} kontaktId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecKontaktDoplnkoveEmailyV3(perId: string, kontaktId: string, options?: any): AxiosPromise<GetZamestnanecKontaktDoplnkoveEmailyV3200Response> {
            return localVarFp.getZamestnanecKontaktDoplnkoveEmailyV3(perId, kontaktId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/kontakty/v4?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam vztahu osoby k organizacnim jednotkam s kontakty
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecKontaktyV4(perId: string, lang?: string, options?: any): AxiosPromise<void> {
            return localVarFp.getZamestnanecKontaktyV4(perId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/konzultacni-hodiny/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Konzultacni hodiny dane osoby
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecKonzultacniHodinyV3(perId: string, lang?: string, options?: any): AxiosPromise<GetZamestnanecKonzultacniHodinyV3200Response> {
            return localVarFp.getZamestnanecKonzultacniHodinyV3(perId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/oceneni/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam oceneni dane osoby
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecOceneniV3(perId: string, lang?: string, options?: any): AxiosPromise<GetProgramOboryV3200Response> {
            return localVarFp.getZamestnanecOceneniV3(perId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/patenty/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam patentu dane osoby
         * @param {string} perId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecPatentyV3(perId: string, options?: any): AxiosPromise<GetProgramOboryV3200Response> {
            return localVarFp.getZamestnanecPatentyV3(perId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__garant_id__/predmety/garantovane/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam garantovanych predmetu dane osoby
         * @param {string} garantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecPredmetyGarantovaneV3(garantId: string, options?: any): AxiosPromise<GetZamestnanecPredmetyGarantovaneV3200Response> {
            return localVarFp.getZamestnanecPredmetyGarantovaneV3(garantId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/predmety/vyucovane/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam vyucovanych predmetu dane osoby
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecPredmetyVyucovaneV3(perId: string, lang?: string, rok?: number, options?: any): AxiosPromise<GetZamestnanecPredmetyVyucovaneV3200Response> {
            return localVarFp.getZamestnanecPredmetyVyucovaneV3(perId, lang, rok, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/predmety/vyucovane/v4?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam vyucovanych predmetu dane osoby s typy rozvrhovych jednotek
         * @param {string} perId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecPredmetyVyucovaneV4(perId: string, options?: any): AxiosPromise<GetZamestnanecPredmetyVyucovaneV4200Response> {
            return localVarFp.getZamestnanecPredmetyVyucovaneV4(perId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/produkty/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam produktu dane osoby
         * @param {string} perId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecProduktyV3(perId: string, options?: any): AxiosPromise<GetZamestnanecProduktyV3200Response> {
            return localVarFp.getZamestnanecProduktyV3(perId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/projekty/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam projektu dane osoby
         * @param {string} perId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecProjektyV3(perId: string, options?: any): AxiosPromise<GetProgramOboryV3200Response> {
            return localVarFp.getZamestnanecProjektyV3(perId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/publikace/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam publikaci dane osoby
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecPublikaceV3(perId: string, lang?: string, options?: any): AxiosPromise<GetZamestnanecPublikaceV3200Response> {
            return localVarFp.getZamestnanecPublikaceV3(perId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/texty/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam textu dane osoby na web
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecTextyV3(perId: string, lang?: string, options?: any): AxiosPromise<GetProgramOboryV3200Response> {
            return localVarFp.getZamestnanecTextyV3(perId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/v4?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail zamestnance/doktoranda
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecV4(perId: string, lang?: string, options?: any): AxiosPromise<GetZamestnanecV4200Response> {
            return localVarFp.getZamestnanecV4(perId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/zivotopis/strukturovany/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Strukturovana cast zivotopisu dane osoby
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecZivotopisStrukturovanyV3(perId: string, lang?: string, options?: any): AxiosPromise<GetZamestnanecZivotopisStrukturovanyV3200Response> {
            return localVarFp.getZamestnanecZivotopisStrukturovanyV3(perId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/zivotopis/textovy/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Textova cast zivotopisu dane osoby
         * @param {string} perId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecZivotopisTextovyV3(perId: string, lang?: string, options?: any): AxiosPromise<GetZamestnanecZivotopisTextovyV3200Response> {
            return localVarFp.getZamestnanecZivotopisTextovyV3(perId, lang, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OsobyApi - object-oriented interface
 * @export
 * @class OsobyApi
 * @extends {BaseAPI}
 */
export class OsobyApi extends BaseAPI {
    /**
     * 
     * @summary Neomezene fotografie
     * @param {number} perId 
     * @param {number} [destWidth] 
     * @param {number} [destHeight] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsobyApi
     */
    public getArchivOsobaFotografiePasovaV3(perId: number, destWidth?: number, destHeight?: number, options?: AxiosRequestConfig) {
        return OsobyApiFp(this.configuration).getArchivOsobaFotografiePasovaV3(perId, destWidth, destHeight, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/osoba/__per_id__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Je osoba na VUT?
     * @param {string} perId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsobyApi
     */
    public getOsobaV3(perId: string, options?: AxiosRequestConfig) {
        return OsobyApiFp(this.configuration).getOsobaV3(perId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/zamestnanci/v4?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam zamestnancu a doktorandu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsobyApi
     */
    public getZamestnanciV4(options?: AxiosRequestConfig) {
        return OsobyApiFp(this.configuration).getZamestnanciV4(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/aktivity/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam aktivit dane osoby
     * @param {string} perId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsobyApi
     */
    public getZamestnanecAktivityV3(perId: string, options?: AxiosRequestConfig) {
        return OsobyApiFp(this.configuration).getZamestnanecAktivityV3(perId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verejna fotografie osoby bez prihlaseni
     * @param {number} perId 
     * @param {boolean} [jenKvalitni] 
     * @param {number} [destWidth] 
     * @param {number} [destHeight] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsobyApi
     */
    public getZamestnanecFotografiePasovaVerejnaBezPrihlaseniV3(perId: number, jenKvalitni?: boolean, destWidth?: number, destHeight?: number, options?: AxiosRequestConfig) {
        return OsobyApiFp(this.configuration).getZamestnanecFotografiePasovaVerejnaBezPrihlaseniV3(perId, jenKvalitni, destWidth, destHeight, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verejna fotografie osoby po prihlaseni
     * @param {number} perId 
     * @param {boolean} [jenKvalitni] 
     * @param {number} [destWidth] 
     * @param {number} [destHeight] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsobyApi
     */
    public getZamestnanecFotografiePasovaVerejnaPoPrihlaseniV3(perId: number, jenKvalitni?: boolean, destWidth?: number, destHeight?: number, options?: AxiosRequestConfig) {
        return OsobyApiFp(this.configuration).getZamestnanecFotografiePasovaVerejnaPoPrihlaseniV3(perId, jenKvalitni, destWidth, destHeight, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/kontakt/__kontakt_id__/doplnkove-emaily/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Funkcni e-maily zamestnance
     * @param {string} perId 
     * @param {string} kontaktId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsobyApi
     */
    public getZamestnanecKontaktDoplnkoveEmailyV3(perId: string, kontaktId: string, options?: AxiosRequestConfig) {
        return OsobyApiFp(this.configuration).getZamestnanecKontaktDoplnkoveEmailyV3(perId, kontaktId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/kontakty/v4?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam vztahu osoby k organizacnim jednotkam s kontakty
     * @param {string} perId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsobyApi
     */
    public getZamestnanecKontaktyV4(perId: string, lang?: string, options?: AxiosRequestConfig) {
        return OsobyApiFp(this.configuration).getZamestnanecKontaktyV4(perId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/konzultacni-hodiny/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Konzultacni hodiny dane osoby
     * @param {string} perId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsobyApi
     */
    public getZamestnanecKonzultacniHodinyV3(perId: string, lang?: string, options?: AxiosRequestConfig) {
        return OsobyApiFp(this.configuration).getZamestnanecKonzultacniHodinyV3(perId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/oceneni/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam oceneni dane osoby
     * @param {string} perId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsobyApi
     */
    public getZamestnanecOceneniV3(perId: string, lang?: string, options?: AxiosRequestConfig) {
        return OsobyApiFp(this.configuration).getZamestnanecOceneniV3(perId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/patenty/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam patentu dane osoby
     * @param {string} perId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsobyApi
     */
    public getZamestnanecPatentyV3(perId: string, options?: AxiosRequestConfig) {
        return OsobyApiFp(this.configuration).getZamestnanecPatentyV3(perId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__garant_id__/predmety/garantovane/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam garantovanych predmetu dane osoby
     * @param {string} garantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsobyApi
     */
    public getZamestnanecPredmetyGarantovaneV3(garantId: string, options?: AxiosRequestConfig) {
        return OsobyApiFp(this.configuration).getZamestnanecPredmetyGarantovaneV3(garantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/predmety/vyucovane/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam vyucovanych predmetu dane osoby
     * @param {string} perId 
     * @param {string} [lang] 
     * @param {number} [rok] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsobyApi
     */
    public getZamestnanecPredmetyVyucovaneV3(perId: string, lang?: string, rok?: number, options?: AxiosRequestConfig) {
        return OsobyApiFp(this.configuration).getZamestnanecPredmetyVyucovaneV3(perId, lang, rok, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/predmety/vyucovane/v4?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam vyucovanych predmetu dane osoby s typy rozvrhovych jednotek
     * @param {string} perId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsobyApi
     */
    public getZamestnanecPredmetyVyucovaneV4(perId: string, options?: AxiosRequestConfig) {
        return OsobyApiFp(this.configuration).getZamestnanecPredmetyVyucovaneV4(perId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/produkty/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam produktu dane osoby
     * @param {string} perId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsobyApi
     */
    public getZamestnanecProduktyV3(perId: string, options?: AxiosRequestConfig) {
        return OsobyApiFp(this.configuration).getZamestnanecProduktyV3(perId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/projekty/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam projektu dane osoby
     * @param {string} perId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsobyApi
     */
    public getZamestnanecProjektyV3(perId: string, options?: AxiosRequestConfig) {
        return OsobyApiFp(this.configuration).getZamestnanecProjektyV3(perId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/publikace/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam publikaci dane osoby
     * @param {string} perId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsobyApi
     */
    public getZamestnanecPublikaceV3(perId: string, lang?: string, options?: AxiosRequestConfig) {
        return OsobyApiFp(this.configuration).getZamestnanecPublikaceV3(perId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/texty/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam textu dane osoby na web
     * @param {string} perId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsobyApi
     */
    public getZamestnanecTextyV3(perId: string, lang?: string, options?: AxiosRequestConfig) {
        return OsobyApiFp(this.configuration).getZamestnanecTextyV3(perId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/v4?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Detail zamestnance/doktoranda
     * @param {string} perId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsobyApi
     */
    public getZamestnanecV4(perId: string, lang?: string, options?: AxiosRequestConfig) {
        return OsobyApiFp(this.configuration).getZamestnanecV4(perId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/zivotopis/strukturovany/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Strukturovana cast zivotopisu dane osoby
     * @param {string} perId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsobyApi
     */
    public getZamestnanecZivotopisStrukturovanyV3(perId: string, lang?: string, options?: AxiosRequestConfig) {
        return OsobyApiFp(this.configuration).getZamestnanecZivotopisStrukturovanyV3(perId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__per_id__/zivotopis/textovy/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Textova cast zivotopisu dane osoby
     * @param {string} perId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsobyApi
     */
    public getZamestnanecZivotopisTextovyV3(perId: string, lang?: string, options?: AxiosRequestConfig) {
        return OsobyApiFp(this.configuration).getZamestnanecZivotopisTextovyV3(perId, lang, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OstatniApi - axios parameter creator
 * @export
 */
export const OstatniApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/obor/__obor_id__/zavprace_volne/v3?lang=cs&rok=2022\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Volna zadani zaverecnych praci
         * @param {string} oborId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOborZavpraceVolneV3: async (oborId: string, lang?: string, rok?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oborId' is not null or undefined
            assertParamExists('getOborZavpraceVolneV3', 'oborId', oborId)
            const localVarPath = `/api/fit/obor/{obor_id}/zavprace_volne/v3`
                .replace(`{${"obor_id"}}`, encodeURIComponent(String(oborId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (rok !== undefined) {
                localVarQueryParameter['rok'] = rok;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zavprace/pridelene/v3?rok=2022&lang=cs&zav_prace_typ_id=2\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Pridelena zadani zaverecnych praci
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZavpracePrideleneV3: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/fit/zavprace/pridelene/v3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zavprace_volne/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Volna zadani zaverecnych praci
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZavpraceVolneV3: async (lang?: string, rok?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/fit/zavprace_volne/v3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (rok !== undefined) {
                localVarQueryParameter['rok'] = rok;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OstatniApi - functional programming interface
 * @export
 */
export const OstatniApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OstatniApiAxiosParamCreator(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/obor/__obor_id__/zavprace_volne/v3?lang=cs&rok=2022\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Volna zadani zaverecnych praci
         * @param {string} oborId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOborZavpraceVolneV3(oborId: string, lang?: string, rok?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOborZavpraceVolneV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOborZavpraceVolneV3(oborId, lang, rok, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zavprace/pridelene/v3?rok=2022&lang=cs&zav_prace_typ_id=2\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Pridelena zadani zaverecnych praci
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZavpracePrideleneV3(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetZavpracePrideleneV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZavpracePrideleneV3(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zavprace_volne/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Volna zadani zaverecnych praci
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZavpraceVolneV3(lang?: string, rok?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOborZavpraceVolneV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZavpraceVolneV3(lang, rok, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OstatniApi - factory interface
 * @export
 */
export const OstatniApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OstatniApiFp(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/obor/__obor_id__/zavprace_volne/v3?lang=cs&rok=2022\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Volna zadani zaverecnych praci
         * @param {string} oborId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOborZavpraceVolneV3(oborId: string, lang?: string, rok?: number, options?: any): AxiosPromise<GetOborZavpraceVolneV3200Response> {
            return localVarFp.getOborZavpraceVolneV3(oborId, lang, rok, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zavprace/pridelene/v3?rok=2022&lang=cs&zav_prace_typ_id=2\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Pridelena zadani zaverecnych praci
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZavpracePrideleneV3(options?: any): AxiosPromise<GetZavpracePrideleneV3200Response> {
            return localVarFp.getZavpracePrideleneV3(options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zavprace_volne/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Volna zadani zaverecnych praci
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZavpraceVolneV3(lang?: string, rok?: number, options?: any): AxiosPromise<GetOborZavpraceVolneV3200Response> {
            return localVarFp.getZavpraceVolneV3(lang, rok, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OstatniApi - object-oriented interface
 * @export
 * @class OstatniApi
 * @extends {BaseAPI}
 */
export class OstatniApi extends BaseAPI {
    /**
     *  <a href=\"https://api.vut.cz/api/fit/obor/__obor_id__/zavprace_volne/v3?lang=cs&rok=2022\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Volna zadani zaverecnych praci
     * @param {string} oborId 
     * @param {string} [lang] 
     * @param {number} [rok] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OstatniApi
     */
    public getOborZavpraceVolneV3(oborId: string, lang?: string, rok?: number, options?: AxiosRequestConfig) {
        return OstatniApiFp(this.configuration).getOborZavpraceVolneV3(oborId, lang, rok, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/zavprace/pridelene/v3?rok=2022&lang=cs&zav_prace_typ_id=2\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Pridelena zadani zaverecnych praci
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OstatniApi
     */
    public getZavpracePrideleneV3(options?: AxiosRequestConfig) {
        return OstatniApiFp(this.configuration).getZavpracePrideleneV3(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/zavprace_volne/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Volna zadani zaverecnych praci
     * @param {string} [lang] 
     * @param {number} [rok] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OstatniApi
     */
    public getZavpraceVolneV3(lang?: string, rok?: number, options?: AxiosRequestConfig) {
        return OstatniApiFp(this.configuration).getZavpraceVolneV3(lang, rok, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PozadavkyNaRozvrhAZkouskyApi - axios parameter creator
 * @export
 */
export const PozadavkyNaRozvrhAZkouskyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/pozadavky/vyucovani/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam pozadavku na rozvrh
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetPozadavkyVyucovaniV3: async (aktualniPredmetId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetPozadavkyVyucovaniV3', 'aktualniPredmetId', aktualniPredmetId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/pozadavky/vyucovani/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/pozadavky/zkousky/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam pozadavku na zkousky
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetPozadavkyZkouskyV3: async (aktualniPredmetId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetPozadavkyZkouskyV3', 'aktualniPredmetId', aktualniPredmetId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/pozadavky/zkousky/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/rozvrh_pozadavek_vyucovani/__rozvrh_pozadavek_vyucovani_id__/navaznosti/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Navaznosti k pozadavku na rozvrh
         * @param {string} rozvrhPozadavekVyucovaniId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRozvrhPozadavekVyucovaniNavaznostiV3: async (rozvrhPozadavekVyucovaniId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rozvrhPozadavekVyucovaniId' is not null or undefined
            assertParamExists('getRozvrhPozadavekVyucovaniNavaznostiV3', 'rozvrhPozadavekVyucovaniId', rozvrhPozadavekVyucovaniId)
            const localVarPath = `/api/fit/rozvrh_pozadavek_vyucovani/{rozvrh_pozadavek_vyucovani_id}/navaznosti/v3`
                .replace(`{${"rozvrh_pozadavek_vyucovani_id"}}`, encodeURIComponent(String(rozvrhPozadavekVyucovaniId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/rozvrh_pozadavek_vyucovani/__rozvrh_pozadavek_vyucovani_pref_id__/preference/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Preference k pozadavku na rozvrh
         * @param {string} rozvrhPozadavekVyucovaniPrefId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRozvrhPozadavekVyucovaniPreferenceV3: async (rozvrhPozadavekVyucovaniPrefId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rozvrhPozadavekVyucovaniPrefId' is not null or undefined
            assertParamExists('getRozvrhPozadavekVyucovaniPreferenceV3', 'rozvrhPozadavekVyucovaniPrefId', rozvrhPozadavekVyucovaniPrefId)
            const localVarPath = `/api/fit/rozvrh_pozadavek_vyucovani/{rozvrh_pozadavek_vyucovani_pref_id}/preference/v3`
                .replace(`{${"rozvrh_pozadavek_vyucovani_pref_id"}}`, encodeURIComponent(String(rozvrhPozadavekVyucovaniPrefId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__osoba_id__/pozadavky/omezeni/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Nepritomnost osoby
         * @param {string} osobaId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecPozadavkyOmezeniV3: async (osobaId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'osobaId' is not null or undefined
            assertParamExists('getZamestnanecPozadavkyOmezeniV3', 'osobaId', osobaId)
            const localVarPath = `/api/fit/zamestnanec/{osoba_id}/pozadavky/omezeni/v3`
                .replace(`{${"osoba_id"}}`, encodeURIComponent(String(osobaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PozadavkyNaRozvrhAZkouskyApi - functional programming interface
 * @export
 */
export const PozadavkyNaRozvrhAZkouskyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PozadavkyNaRozvrhAZkouskyApiAxiosParamCreator(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/pozadavky/vyucovani/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam pozadavku na rozvrh
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetPozadavkyVyucovaniV3(aktualniPredmetId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetPozadavkyVyucovaniV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetPozadavkyVyucovaniV3(aktualniPredmetId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/pozadavky/zkousky/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam pozadavku na zkousky
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetPozadavkyZkouskyV3(aktualniPredmetId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetPozadavkyZkouskyV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetPozadavkyZkouskyV3(aktualniPredmetId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/rozvrh_pozadavek_vyucovani/__rozvrh_pozadavek_vyucovani_id__/navaznosti/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Navaznosti k pozadavku na rozvrh
         * @param {string} rozvrhPozadavekVyucovaniId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRozvrhPozadavekVyucovaniNavaznostiV3(rozvrhPozadavekVyucovaniId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRozvrhPozadavekVyucovaniNavaznostiV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRozvrhPozadavekVyucovaniNavaznostiV3(rozvrhPozadavekVyucovaniId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/rozvrh_pozadavek_vyucovani/__rozvrh_pozadavek_vyucovani_pref_id__/preference/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Preference k pozadavku na rozvrh
         * @param {string} rozvrhPozadavekVyucovaniPrefId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRozvrhPozadavekVyucovaniPreferenceV3(rozvrhPozadavekVyucovaniPrefId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRozvrhPozadavekVyucovaniPreferenceV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRozvrhPozadavekVyucovaniPreferenceV3(rozvrhPozadavekVyucovaniPrefId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__osoba_id__/pozadavky/omezeni/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Nepritomnost osoby
         * @param {string} osobaId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZamestnanecPozadavkyOmezeniV3(osobaId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetZamestnanecPozadavkyOmezeniV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZamestnanecPozadavkyOmezeniV3(osobaId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PozadavkyNaRozvrhAZkouskyApi - factory interface
 * @export
 */
export const PozadavkyNaRozvrhAZkouskyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PozadavkyNaRozvrhAZkouskyApiFp(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/pozadavky/vyucovani/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam pozadavku na rozvrh
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetPozadavkyVyucovaniV3(aktualniPredmetId: string, lang?: string, options?: any): AxiosPromise<GetAktualniPredmetPozadavkyVyucovaniV3200Response> {
            return localVarFp.getAktualniPredmetPozadavkyVyucovaniV3(aktualniPredmetId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/pozadavky/zkousky/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam pozadavku na zkousky
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetPozadavkyZkouskyV3(aktualniPredmetId: string, lang?: string, options?: any): AxiosPromise<GetAktualniPredmetPozadavkyZkouskyV3200Response> {
            return localVarFp.getAktualniPredmetPozadavkyZkouskyV3(aktualniPredmetId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/rozvrh_pozadavek_vyucovani/__rozvrh_pozadavek_vyucovani_id__/navaznosti/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Navaznosti k pozadavku na rozvrh
         * @param {string} rozvrhPozadavekVyucovaniId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRozvrhPozadavekVyucovaniNavaznostiV3(rozvrhPozadavekVyucovaniId: string, lang?: string, options?: any): AxiosPromise<GetRozvrhPozadavekVyucovaniNavaznostiV3200Response> {
            return localVarFp.getRozvrhPozadavekVyucovaniNavaznostiV3(rozvrhPozadavekVyucovaniId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/rozvrh_pozadavek_vyucovani/__rozvrh_pozadavek_vyucovani_pref_id__/preference/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Preference k pozadavku na rozvrh
         * @param {string} rozvrhPozadavekVyucovaniPrefId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRozvrhPozadavekVyucovaniPreferenceV3(rozvrhPozadavekVyucovaniPrefId: string, lang?: string, options?: any): AxiosPromise<GetRozvrhPozadavekVyucovaniPreferenceV3200Response> {
            return localVarFp.getRozvrhPozadavekVyucovaniPreferenceV3(rozvrhPozadavekVyucovaniPrefId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__osoba_id__/pozadavky/omezeni/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Nepritomnost osoby
         * @param {string} osobaId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZamestnanecPozadavkyOmezeniV3(osobaId: string, lang?: string, options?: any): AxiosPromise<GetZamestnanecPozadavkyOmezeniV3200Response> {
            return localVarFp.getZamestnanecPozadavkyOmezeniV3(osobaId, lang, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PozadavkyNaRozvrhAZkouskyApi - object-oriented interface
 * @export
 * @class PozadavkyNaRozvrhAZkouskyApi
 * @extends {BaseAPI}
 */
export class PozadavkyNaRozvrhAZkouskyApi extends BaseAPI {
    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/pozadavky/vyucovani/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam pozadavku na rozvrh
     * @param {string} aktualniPredmetId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PozadavkyNaRozvrhAZkouskyApi
     */
    public getAktualniPredmetPozadavkyVyucovaniV3(aktualniPredmetId: string, lang?: string, options?: AxiosRequestConfig) {
        return PozadavkyNaRozvrhAZkouskyApiFp(this.configuration).getAktualniPredmetPozadavkyVyucovaniV3(aktualniPredmetId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/pozadavky/zkousky/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam pozadavku na zkousky
     * @param {string} aktualniPredmetId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PozadavkyNaRozvrhAZkouskyApi
     */
    public getAktualniPredmetPozadavkyZkouskyV3(aktualniPredmetId: string, lang?: string, options?: AxiosRequestConfig) {
        return PozadavkyNaRozvrhAZkouskyApiFp(this.configuration).getAktualniPredmetPozadavkyZkouskyV3(aktualniPredmetId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/rozvrh_pozadavek_vyucovani/__rozvrh_pozadavek_vyucovani_id__/navaznosti/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Navaznosti k pozadavku na rozvrh
     * @param {string} rozvrhPozadavekVyucovaniId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PozadavkyNaRozvrhAZkouskyApi
     */
    public getRozvrhPozadavekVyucovaniNavaznostiV3(rozvrhPozadavekVyucovaniId: string, lang?: string, options?: AxiosRequestConfig) {
        return PozadavkyNaRozvrhAZkouskyApiFp(this.configuration).getRozvrhPozadavekVyucovaniNavaznostiV3(rozvrhPozadavekVyucovaniId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/rozvrh_pozadavek_vyucovani/__rozvrh_pozadavek_vyucovani_pref_id__/preference/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Preference k pozadavku na rozvrh
     * @param {string} rozvrhPozadavekVyucovaniPrefId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PozadavkyNaRozvrhAZkouskyApi
     */
    public getRozvrhPozadavekVyucovaniPreferenceV3(rozvrhPozadavekVyucovaniPrefId: string, lang?: string, options?: AxiosRequestConfig) {
        return PozadavkyNaRozvrhAZkouskyApiFp(this.configuration).getRozvrhPozadavekVyucovaniPreferenceV3(rozvrhPozadavekVyucovaniPrefId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/zamestnanec/__osoba_id__/pozadavky/omezeni/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Nepritomnost osoby
     * @param {string} osobaId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PozadavkyNaRozvrhAZkouskyApi
     */
    public getZamestnanecPozadavkyOmezeniV3(osobaId: string, lang?: string, options?: AxiosRequestConfig) {
        return PozadavkyNaRozvrhAZkouskyApiFp(this.configuration).getZamestnanecPozadavkyOmezeniV3(osobaId, lang, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PrednaskoveSkupinyApi - axios parameter creator
 * @export
 */
export const PrednaskoveSkupinyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/prednaskova_skupina/__prednaskova_skupina_id__/studijni_skupina/__studijni_skupina_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam studijnich skupin dane prednaskove skupiny
         * @param {string} prednaskovaSkupinaId 
         * @param {string} studijniSkupinaId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrednaskovaSkupinaStudijniSkupinaV3: async (prednaskovaSkupinaId: string, studijniSkupinaId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prednaskovaSkupinaId' is not null or undefined
            assertParamExists('getPrednaskovaSkupinaStudijniSkupinaV3', 'prednaskovaSkupinaId', prednaskovaSkupinaId)
            // verify required parameter 'studijniSkupinaId' is not null or undefined
            assertParamExists('getPrednaskovaSkupinaStudijniSkupinaV3', 'studijniSkupinaId', studijniSkupinaId)
            const localVarPath = `/api/fit/prednaskova_skupina/{prednaskova_skupina_id}/studijni_skupina/{studijni_skupina_id}/v3`
                .replace(`{${"prednaskova_skupina_id"}}`, encodeURIComponent(String(prednaskovaSkupinaId)))
                .replace(`{${"studijni_skupina_id"}}`, encodeURIComponent(String(studijniSkupinaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/prednaskova_skupina/__prednaskova_skupina_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail prednaskove skupiny
         * @param {string} prednaskovaSkupinaId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrednaskovaSkupinaV3: async (prednaskovaSkupinaId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prednaskovaSkupinaId' is not null or undefined
            assertParamExists('getPrednaskovaSkupinaV3', 'prednaskovaSkupinaId', prednaskovaSkupinaId)
            const localVarPath = `/api/fit/prednaskova_skupina/{prednaskova_skupina_id}/v3`
                .replace(`{${"prednaskova_skupina_id"}}`, encodeURIComponent(String(prednaskovaSkupinaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/skupiny/v3?lang=cs&rok=2021\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam prednaskovych skupin
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkupinyV3: async (lang?: string, rok?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/fit/skupiny/v3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (rok !== undefined) {
                localVarQueryParameter['rok'] = rok;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/studijni_skupina/__studijni_skupina_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail studijni skupiny
         * @param {string} studijniSkupinaId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudijniSkupinaV3: async (studijniSkupinaId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studijniSkupinaId' is not null or undefined
            assertParamExists('getStudijniSkupinaV3', 'studijniSkupinaId', studijniSkupinaId)
            const localVarPath = `/api/fit/studijni_skupina/{studijni_skupina_id}/v3`
                .replace(`{${"studijni_skupina_id"}}`, encodeURIComponent(String(studijniSkupinaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/studijni_skupina/__studijni_skupina_id__/vyucovani/v3?lang=cs&rok=2021&typ_semestru_id=2&datum_od=23.9.2021&datum_do=30.9.2021\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Rozvrh studijni skupiny
         * @param {string} studijniSkupinaId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {number} [typSemestruId] 
         * @param {string} [datumOd] 
         * @param {string} [datumDo] 
         * @param {string} [viditelne] 0/1/bez uvedeni nefiltrovano) - u mistnosti dalsi info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudijniSkupinaVyucovaniV3: async (studijniSkupinaId: string, lang?: string, rok?: number, typSemestruId?: number, datumOd?: string, datumDo?: string, viditelne?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studijniSkupinaId' is not null or undefined
            assertParamExists('getStudijniSkupinaVyucovaniV3', 'studijniSkupinaId', studijniSkupinaId)
            const localVarPath = `/api/fit/studijni_skupina/{studijni_skupina_id}/vyucovani/v3`
                .replace(`{${"studijni_skupina_id"}}`, encodeURIComponent(String(studijniSkupinaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (rok !== undefined) {
                localVarQueryParameter['rok'] = rok;
            }

            if (typSemestruId !== undefined) {
                localVarQueryParameter['typ_semestru_id'] = typSemestruId;
            }

            if (datumOd !== undefined) {
                localVarQueryParameter['datum_od'] = datumOd;
            }

            if (datumDo !== undefined) {
                localVarQueryParameter['datum_do'] = datumDo;
            }

            if (viditelne !== undefined) {
                localVarQueryParameter['viditelne'] = viditelne;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrednaskoveSkupinyApi - functional programming interface
 * @export
 */
export const PrednaskoveSkupinyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrednaskoveSkupinyApiAxiosParamCreator(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/prednaskova_skupina/__prednaskova_skupina_id__/studijni_skupina/__studijni_skupina_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam studijnich skupin dane prednaskove skupiny
         * @param {string} prednaskovaSkupinaId 
         * @param {string} studijniSkupinaId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrednaskovaSkupinaStudijniSkupinaV3(prednaskovaSkupinaId: string, studijniSkupinaId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPrednaskovaSkupinaStudijniSkupinaV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrednaskovaSkupinaStudijniSkupinaV3(prednaskovaSkupinaId, studijniSkupinaId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/prednaskova_skupina/__prednaskova_skupina_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail prednaskove skupiny
         * @param {string} prednaskovaSkupinaId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrednaskovaSkupinaV3(prednaskovaSkupinaId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPrednaskovaSkupinaStudijniSkupinaV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrednaskovaSkupinaV3(prednaskovaSkupinaId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/skupiny/v3?lang=cs&rok=2021\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam prednaskovych skupin
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSkupinyV3(lang?: string, rok?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSkupinyV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSkupinyV3(lang, rok, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/studijni_skupina/__studijni_skupina_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail studijni skupiny
         * @param {string} studijniSkupinaId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudijniSkupinaV3(studijniSkupinaId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPrednaskovaSkupinaStudijniSkupinaV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudijniSkupinaV3(studijniSkupinaId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/studijni_skupina/__studijni_skupina_id__/vyucovani/v3?lang=cs&rok=2021&typ_semestru_id=2&datum_od=23.9.2021&datum_do=30.9.2021\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Rozvrh studijni skupiny
         * @param {string} studijniSkupinaId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {number} [typSemestruId] 
         * @param {string} [datumOd] 
         * @param {string} [datumDo] 
         * @param {string} [viditelne] 0/1/bez uvedeni nefiltrovano) - u mistnosti dalsi info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudijniSkupinaVyucovaniV3(studijniSkupinaId: string, lang?: string, rok?: number, typSemestruId?: number, datumOd?: string, datumDo?: string, viditelne?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetVyucovaniV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudijniSkupinaVyucovaniV3(studijniSkupinaId, lang, rok, typSemestruId, datumOd, datumDo, viditelne, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrednaskoveSkupinyApi - factory interface
 * @export
 */
export const PrednaskoveSkupinyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrednaskoveSkupinyApiFp(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/prednaskova_skupina/__prednaskova_skupina_id__/studijni_skupina/__studijni_skupina_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam studijnich skupin dane prednaskove skupiny
         * @param {string} prednaskovaSkupinaId 
         * @param {string} studijniSkupinaId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrednaskovaSkupinaStudijniSkupinaV3(prednaskovaSkupinaId: string, studijniSkupinaId: string, lang?: string, options?: any): AxiosPromise<GetPrednaskovaSkupinaStudijniSkupinaV3200Response> {
            return localVarFp.getPrednaskovaSkupinaStudijniSkupinaV3(prednaskovaSkupinaId, studijniSkupinaId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/prednaskova_skupina/__prednaskova_skupina_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail prednaskove skupiny
         * @param {string} prednaskovaSkupinaId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrednaskovaSkupinaV3(prednaskovaSkupinaId: string, lang?: string, options?: any): AxiosPromise<GetPrednaskovaSkupinaStudijniSkupinaV3200Response> {
            return localVarFp.getPrednaskovaSkupinaV3(prednaskovaSkupinaId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/skupiny/v3?lang=cs&rok=2021\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam prednaskovych skupin
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkupinyV3(lang?: string, rok?: number, options?: any): AxiosPromise<GetSkupinyV3200Response> {
            return localVarFp.getSkupinyV3(lang, rok, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/studijni_skupina/__studijni_skupina_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail studijni skupiny
         * @param {string} studijniSkupinaId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudijniSkupinaV3(studijniSkupinaId: string, lang?: string, options?: any): AxiosPromise<GetPrednaskovaSkupinaStudijniSkupinaV3200Response> {
            return localVarFp.getStudijniSkupinaV3(studijniSkupinaId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/studijni_skupina/__studijni_skupina_id__/vyucovani/v3?lang=cs&rok=2021&typ_semestru_id=2&datum_od=23.9.2021&datum_do=30.9.2021\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Rozvrh studijni skupiny
         * @param {string} studijniSkupinaId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {number} [typSemestruId] 
         * @param {string} [datumOd] 
         * @param {string} [datumDo] 
         * @param {string} [viditelne] 0/1/bez uvedeni nefiltrovano) - u mistnosti dalsi info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudijniSkupinaVyucovaniV3(studijniSkupinaId: string, lang?: string, rok?: number, typSemestruId?: number, datumOd?: string, datumDo?: string, viditelne?: string, options?: any): AxiosPromise<GetAktualniPredmetVyucovaniV3200Response> {
            return localVarFp.getStudijniSkupinaVyucovaniV3(studijniSkupinaId, lang, rok, typSemestruId, datumOd, datumDo, viditelne, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PrednaskoveSkupinyApi - object-oriented interface
 * @export
 * @class PrednaskoveSkupinyApi
 * @extends {BaseAPI}
 */
export class PrednaskoveSkupinyApi extends BaseAPI {
    /**
     *  <a href=\"https://api.vut.cz/api/fit/prednaskova_skupina/__prednaskova_skupina_id__/studijni_skupina/__studijni_skupina_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam studijnich skupin dane prednaskove skupiny
     * @param {string} prednaskovaSkupinaId 
     * @param {string} studijniSkupinaId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrednaskoveSkupinyApi
     */
    public getPrednaskovaSkupinaStudijniSkupinaV3(prednaskovaSkupinaId: string, studijniSkupinaId: string, lang?: string, options?: AxiosRequestConfig) {
        return PrednaskoveSkupinyApiFp(this.configuration).getPrednaskovaSkupinaStudijniSkupinaV3(prednaskovaSkupinaId, studijniSkupinaId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/prednaskova_skupina/__prednaskova_skupina_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Detail prednaskove skupiny
     * @param {string} prednaskovaSkupinaId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrednaskoveSkupinyApi
     */
    public getPrednaskovaSkupinaV3(prednaskovaSkupinaId: string, lang?: string, options?: AxiosRequestConfig) {
        return PrednaskoveSkupinyApiFp(this.configuration).getPrednaskovaSkupinaV3(prednaskovaSkupinaId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/skupiny/v3?lang=cs&rok=2021\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam prednaskovych skupin
     * @param {string} [lang] 
     * @param {number} [rok] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrednaskoveSkupinyApi
     */
    public getSkupinyV3(lang?: string, rok?: number, options?: AxiosRequestConfig) {
        return PrednaskoveSkupinyApiFp(this.configuration).getSkupinyV3(lang, rok, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/studijni_skupina/__studijni_skupina_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Detail studijni skupiny
     * @param {string} studijniSkupinaId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrednaskoveSkupinyApi
     */
    public getStudijniSkupinaV3(studijniSkupinaId: string, lang?: string, options?: AxiosRequestConfig) {
        return PrednaskoveSkupinyApiFp(this.configuration).getStudijniSkupinaV3(studijniSkupinaId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/studijni_skupina/__studijni_skupina_id__/vyucovani/v3?lang=cs&rok=2021&typ_semestru_id=2&datum_od=23.9.2021&datum_do=30.9.2021\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Rozvrh studijni skupiny
     * @param {string} studijniSkupinaId 
     * @param {string} [lang] 
     * @param {number} [rok] 
     * @param {number} [typSemestruId] 
     * @param {string} [datumOd] 
     * @param {string} [datumDo] 
     * @param {string} [viditelne] 0/1/bez uvedeni nefiltrovano) - u mistnosti dalsi info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrednaskoveSkupinyApi
     */
    public getStudijniSkupinaVyucovaniV3(studijniSkupinaId: string, lang?: string, rok?: number, typSemestruId?: number, datumOd?: string, datumDo?: string, viditelne?: string, options?: AxiosRequestConfig) {
        return PrednaskoveSkupinyApiFp(this.configuration).getStudijniSkupinaVyucovaniV3(studijniSkupinaId, lang, rok, typSemestruId, datumOd, datumDo, viditelne, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjektovaPraxeApi - axios parameter creator
 * @export
 */
export const ProjektovaPraxeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/praxe/__proj_praxe_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail projektove praxe predmetu
         * @param {string} aktualniPredmetId 
         * @param {string} projPraxeId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetPraxeV3: async (aktualniPredmetId: string, projPraxeId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetPraxeV3', 'aktualniPredmetId', aktualniPredmetId)
            // verify required parameter 'projPraxeId' is not null or undefined
            assertParamExists('getAktualniPredmetPraxeV3', 'projPraxeId', projPraxeId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/praxe/{proj_praxe_id}/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)))
                .replace(`{${"proj_praxe_id"}}`, encodeURIComponent(String(projPraxeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjektovaPraxeApi - functional programming interface
 * @export
 */
export const ProjektovaPraxeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjektovaPraxeApiAxiosParamCreator(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/praxe/__proj_praxe_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail projektove praxe predmetu
         * @param {string} aktualniPredmetId 
         * @param {string} projPraxeId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetPraxeV3(aktualniPredmetId: string, projPraxeId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetPraxeV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetPraxeV3(aktualniPredmetId, projPraxeId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjektovaPraxeApi - factory interface
 * @export
 */
export const ProjektovaPraxeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjektovaPraxeApiFp(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/praxe/__proj_praxe_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail projektove praxe predmetu
         * @param {string} aktualniPredmetId 
         * @param {string} projPraxeId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetPraxeV3(aktualniPredmetId: string, projPraxeId: string, lang?: string, options?: any): AxiosPromise<GetAktualniPredmetPraxeV3200Response> {
            return localVarFp.getAktualniPredmetPraxeV3(aktualniPredmetId, projPraxeId, lang, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjektovaPraxeApi - object-oriented interface
 * @export
 * @class ProjektovaPraxeApi
 * @extends {BaseAPI}
 */
export class ProjektovaPraxeApi extends BaseAPI {
    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/praxe/__proj_praxe_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Detail projektove praxe predmetu
     * @param {string} aktualniPredmetId 
     * @param {string} projPraxeId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjektovaPraxeApi
     */
    public getAktualniPredmetPraxeV3(aktualniPredmetId: string, projPraxeId: string, lang?: string, options?: AxiosRequestConfig) {
        return ProjektovaPraxeApiFp(this.configuration).getAktualniPredmetPraxeV3(aktualniPredmetId, projPraxeId, lang, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RozvrhApi - axios parameter creator
 * @export
 */
export const RozvrhApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/rozvrhove-jednotky/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Rozvrhove jednotky (typy vyucovani, tedy rozvrhovych oken)
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetRozvrhoveJednotkyV3: async (aktualniPredmetId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetRozvrhoveJednotkyV3', 'aktualniPredmetId', aktualniPredmetId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/rozvrhove-jednotky/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/terminy-zkousek/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Terminy v predmetu
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {string} [datumOd] 
         * @param {string} [datumDo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetTerminyZkousekV3: async (aktualniPredmetId: string, lang?: string, datumOd?: string, datumDo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetTerminyZkousekV3', 'aktualniPredmetId', aktualniPredmetId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/terminy-zkousek/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (datumOd !== undefined) {
                localVarQueryParameter['datum_od'] = datumOd;
            }

            if (datumDo !== undefined) {
                localVarQueryParameter['datum_do'] = datumDo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/terminy-zkousek/v4?lang=cs&datum_od=1.1.2022&datum_do=1.2.2022\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Terminy v predmetu
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {string} [datumOd] 
         * @param {string} [datumDo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetTerminyZkousekV4: async (aktualniPredmetId: string, lang?: string, datumOd?: string, datumDo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetTerminyZkousekV4', 'aktualniPredmetId', aktualniPredmetId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/terminy-zkousek/v4`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (datumOd !== undefined) {
                localVarQueryParameter['datum_od'] = datumOd;
            }

            if (datumDo !== undefined) {
                localVarQueryParameter['datum_do'] = datumDo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/vyucovani/__vyucovani_id__/studenti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam studentu ve vyucovani
         * @param {string} aktualniPredmetId 
         * @param {string} vyucovaniId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetVyucovaniStudentiV3: async (aktualniPredmetId: string, vyucovaniId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetVyucovaniStudentiV3', 'aktualniPredmetId', aktualniPredmetId)
            // verify required parameter 'vyucovaniId' is not null or undefined
            assertParamExists('getAktualniPredmetVyucovaniStudentiV3', 'vyucovaniId', vyucovaniId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/vyucovani/{vyucovani_id}/studenti/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)))
                .replace(`{${"vyucovani_id"}}`, encodeURIComponent(String(vyucovaniId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/vyucovani/v3?lang=cs&rok=2021&typ_semestru_id=2&datum_od=23.9.2021&datum_do=30.9.2021\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Vyucovani (rozvrhova okna predmetu)
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {number} [typSemestruId] 
         * @param {string} [datumOd] 
         * @param {string} [datumDo] 
         * @param {string} [viditelne] 0/1/bez uvedeni nefiltrovano) - u mistnosti dalsi info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetVyucovaniV3: async (aktualniPredmetId: string, lang?: string, rok?: number, typSemestruId?: number, datumOd?: string, datumDo?: string, viditelne?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetVyucovaniV3', 'aktualniPredmetId', aktualniPredmetId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/vyucovani/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (rok !== undefined) {
                localVarQueryParameter['rok'] = rok;
            }

            if (typSemestruId !== undefined) {
                localVarQueryParameter['typ_semestru_id'] = typSemestruId;
            }

            if (datumOd !== undefined) {
                localVarQueryParameter['datum_od'] = datumOd;
            }

            if (datumDo !== undefined) {
                localVarQueryParameter['datum_do'] = datumDo;
            }

            if (viditelne !== undefined) {
                localVarQueryParameter['viditelne'] = viditelne;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/vyuc_blok_den/__vyuc_blok_den_id__/mistnosti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam mistnosti daneho bloku vyucovani v konkretni den
         * @param {string} vyucBlokDenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVyucBlokDenMistnostiV3: async (vyucBlokDenId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vyucBlokDenId' is not null or undefined
            assertParamExists('getVyucBlokDenMistnostiV3', 'vyucBlokDenId', vyucBlokDenId)
            const localVarPath = `/api/fit/vyuc_blok_den/{vyuc_blok_den_id}/mistnosti/v3`
                .replace(`{${"vyuc_blok_den_id"}}`, encodeURIComponent(String(vyucBlokDenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/vyuc_blok_den/__vyuc_blok_den_id__/vyucujici/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam vyucujicich daneho bloku vyucovani v konkretni den
         * @param {string} vyucBlokDenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVyucBlokDenVyucujiciV3: async (vyucBlokDenId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vyucBlokDenId' is not null or undefined
            assertParamExists('getVyucBlokDenVyucujiciV3', 'vyucBlokDenId', vyucBlokDenId)
            const localVarPath = `/api/fit/vyuc_blok_den/{vyuc_blok_den_id}/vyucujici/v3`
                .replace(`{${"vyuc_blok_den_id"}}`, encodeURIComponent(String(vyucBlokDenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/vyucovani_blok/__vyucovani_blok_id__/mistnosti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam mistnosti daneho bloku vyucovani
         * @param {string} vyucovaniBlokId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVyucovaniBlokMistnostiV3: async (vyucovaniBlokId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vyucovaniBlokId' is not null or undefined
            assertParamExists('getVyucovaniBlokMistnostiV3', 'vyucovaniBlokId', vyucovaniBlokId)
            const localVarPath = `/api/fit/vyucovani_blok/{vyucovani_blok_id}/mistnosti/v3`
                .replace(`{${"vyucovani_blok_id"}}`, encodeURIComponent(String(vyucovaniBlokId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/vyucovani_blok/__vyucovani_blok_id__/vyucujici/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam vyucujicich daneho bloku vyucovani
         * @param {string} vyucovaniBlokId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVyucovaniBlokVyucujiciV3: async (vyucovaniBlokId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vyucovaniBlokId' is not null or undefined
            assertParamExists('getVyucovaniBlokVyucujiciV3', 'vyucovaniBlokId', vyucovaniBlokId)
            const localVarPath = `/api/fit/vyucovani_blok/{vyucovani_blok_id}/vyucujici/v3`
                .replace(`{${"vyucovani_blok_id"}}`, encodeURIComponent(String(vyucovaniBlokId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/vyucovani/__vyucovani_id__/mistnosti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam mistnosti daneho vyucovani
         * @param {string} vyucovaniId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVyucovaniMistnostiV3: async (vyucovaniId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vyucovaniId' is not null or undefined
            assertParamExists('getVyucovaniMistnostiV3', 'vyucovaniId', vyucovaniId)
            const localVarPath = `/api/fit/vyucovani/{vyucovani_id}/mistnosti/v3`
                .replace(`{${"vyucovani_id"}}`, encodeURIComponent(String(vyucovaniId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/vyucovani/__vyucovani_id__/skupiny/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam prednaskovych skupin, pro ktere je dane vyucovani
         * @param {string} vyucovaniId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVyucovaniSkupinyV3: async (vyucovaniId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vyucovaniId' is not null or undefined
            assertParamExists('getVyucovaniSkupinyV3', 'vyucovaniId', vyucovaniId)
            const localVarPath = `/api/fit/vyucovani/{vyucovani_id}/skupiny/v3`
                .replace(`{${"vyucovani_id"}}`, encodeURIComponent(String(vyucovaniId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/vyucovani/__vyucovani_id__/vyucujici/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam vyucujicich daneho vyucovani
         * @param {string} vyucovaniId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVyucovaniVyucujiciV3: async (vyucovaniId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vyucovaniId' is not null or undefined
            assertParamExists('getVyucovaniVyucujiciV3', 'vyucovaniId', vyucovaniId)
            const localVarPath = `/api/fit/vyucovani/{vyucovani_id}/vyucujici/v3`
                .replace(`{${"vyucovani_id"}}`, encodeURIComponent(String(vyucovaniId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RozvrhApi - functional programming interface
 * @export
 */
export const RozvrhApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RozvrhApiAxiosParamCreator(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/rozvrhove-jednotky/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Rozvrhove jednotky (typy vyucovani, tedy rozvrhovych oken)
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetRozvrhoveJednotkyV3(aktualniPredmetId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetRozvrhoveJednotkyV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetRozvrhoveJednotkyV3(aktualniPredmetId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/terminy-zkousek/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Terminy v predmetu
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {string} [datumOd] 
         * @param {string} [datumDo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetTerminyZkousekV3(aktualniPredmetId: string, lang?: string, datumOd?: string, datumDo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetTerminyZkousekV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetTerminyZkousekV3(aktualniPredmetId, lang, datumOd, datumDo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/terminy-zkousek/v4?lang=cs&datum_od=1.1.2022&datum_do=1.2.2022\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Terminy v predmetu
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {string} [datumOd] 
         * @param {string} [datumDo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetTerminyZkousekV4(aktualniPredmetId: string, lang?: string, datumOd?: string, datumDo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetTerminyZkousekV4200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetTerminyZkousekV4(aktualniPredmetId, lang, datumOd, datumDo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/vyucovani/__vyucovani_id__/studenti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam studentu ve vyucovani
         * @param {string} aktualniPredmetId 
         * @param {string} vyucovaniId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetVyucovaniStudentiV3(aktualniPredmetId: string, vyucovaniId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetVyucovaniStudentiV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetVyucovaniStudentiV3(aktualniPredmetId, vyucovaniId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/vyucovani/v3?lang=cs&rok=2021&typ_semestru_id=2&datum_od=23.9.2021&datum_do=30.9.2021\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Vyucovani (rozvrhova okna predmetu)
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {number} [typSemestruId] 
         * @param {string} [datumOd] 
         * @param {string} [datumDo] 
         * @param {string} [viditelne] 0/1/bez uvedeni nefiltrovano) - u mistnosti dalsi info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetVyucovaniV3(aktualniPredmetId: string, lang?: string, rok?: number, typSemestruId?: number, datumOd?: string, datumDo?: string, viditelne?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetVyucovaniV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetVyucovaniV3(aktualniPredmetId, lang, rok, typSemestruId, datumOd, datumDo, viditelne, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/vyuc_blok_den/__vyuc_blok_den_id__/mistnosti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam mistnosti daneho bloku vyucovani v konkretni den
         * @param {string} vyucBlokDenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVyucBlokDenMistnostiV3(vyucBlokDenId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVyucBlokDenMistnostiV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVyucBlokDenMistnostiV3(vyucBlokDenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/vyuc_blok_den/__vyuc_blok_den_id__/vyucujici/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam vyucujicich daneho bloku vyucovani v konkretni den
         * @param {string} vyucBlokDenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVyucBlokDenVyucujiciV3(vyucBlokDenId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVyucBlokDenVyucujiciV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVyucBlokDenVyucujiciV3(vyucBlokDenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/vyucovani_blok/__vyucovani_blok_id__/mistnosti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam mistnosti daneho bloku vyucovani
         * @param {string} vyucovaniBlokId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVyucovaniBlokMistnostiV3(vyucovaniBlokId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVyucovaniBlokMistnostiV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVyucovaniBlokMistnostiV3(vyucovaniBlokId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/vyucovani_blok/__vyucovani_blok_id__/vyucujici/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam vyucujicich daneho bloku vyucovani
         * @param {string} vyucovaniBlokId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVyucovaniBlokVyucujiciV3(vyucovaniBlokId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVyucovaniBlokVyucujiciV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVyucovaniBlokVyucujiciV3(vyucovaniBlokId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/vyucovani/__vyucovani_id__/mistnosti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam mistnosti daneho vyucovani
         * @param {string} vyucovaniId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVyucovaniMistnostiV3(vyucovaniId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVyucovaniMistnostiV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVyucovaniMistnostiV3(vyucovaniId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/vyucovani/__vyucovani_id__/skupiny/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam prednaskovych skupin, pro ktere je dane vyucovani
         * @param {string} vyucovaniId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVyucovaniSkupinyV3(vyucovaniId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVyucovaniSkupinyV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVyucovaniSkupinyV3(vyucovaniId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/vyucovani/__vyucovani_id__/vyucujici/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam vyucujicich daneho vyucovani
         * @param {string} vyucovaniId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVyucovaniVyucujiciV3(vyucovaniId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVyucovaniVyucujiciV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVyucovaniVyucujiciV3(vyucovaniId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RozvrhApi - factory interface
 * @export
 */
export const RozvrhApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RozvrhApiFp(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/rozvrhove-jednotky/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Rozvrhove jednotky (typy vyucovani, tedy rozvrhovych oken)
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetRozvrhoveJednotkyV3(aktualniPredmetId: string, lang?: string, options?: any): AxiosPromise<GetAktualniPredmetRozvrhoveJednotkyV3200Response> {
            return localVarFp.getAktualniPredmetRozvrhoveJednotkyV3(aktualniPredmetId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/terminy-zkousek/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Terminy v predmetu
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {string} [datumOd] 
         * @param {string} [datumDo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetTerminyZkousekV3(aktualniPredmetId: string, lang?: string, datumOd?: string, datumDo?: string, options?: any): AxiosPromise<GetAktualniPredmetTerminyZkousekV3200Response> {
            return localVarFp.getAktualniPredmetTerminyZkousekV3(aktualniPredmetId, lang, datumOd, datumDo, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/terminy-zkousek/v4?lang=cs&datum_od=1.1.2022&datum_do=1.2.2022\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Terminy v predmetu
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {string} [datumOd] 
         * @param {string} [datumDo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetTerminyZkousekV4(aktualniPredmetId: string, lang?: string, datumOd?: string, datumDo?: string, options?: any): AxiosPromise<GetAktualniPredmetTerminyZkousekV4200Response> {
            return localVarFp.getAktualniPredmetTerminyZkousekV4(aktualniPredmetId, lang, datumOd, datumDo, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/vyucovani/__vyucovani_id__/studenti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam studentu ve vyucovani
         * @param {string} aktualniPredmetId 
         * @param {string} vyucovaniId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetVyucovaniStudentiV3(aktualniPredmetId: string, vyucovaniId: string, options?: any): AxiosPromise<GetAktualniPredmetVyucovaniStudentiV3200Response> {
            return localVarFp.getAktualniPredmetVyucovaniStudentiV3(aktualniPredmetId, vyucovaniId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/vyucovani/v3?lang=cs&rok=2021&typ_semestru_id=2&datum_od=23.9.2021&datum_do=30.9.2021\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Vyucovani (rozvrhova okna predmetu)
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {number} [typSemestruId] 
         * @param {string} [datumOd] 
         * @param {string} [datumDo] 
         * @param {string} [viditelne] 0/1/bez uvedeni nefiltrovano) - u mistnosti dalsi info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetVyucovaniV3(aktualniPredmetId: string, lang?: string, rok?: number, typSemestruId?: number, datumOd?: string, datumDo?: string, viditelne?: string, options?: any): AxiosPromise<GetAktualniPredmetVyucovaniV3200Response> {
            return localVarFp.getAktualniPredmetVyucovaniV3(aktualniPredmetId, lang, rok, typSemestruId, datumOd, datumDo, viditelne, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/vyuc_blok_den/__vyuc_blok_den_id__/mistnosti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam mistnosti daneho bloku vyucovani v konkretni den
         * @param {string} vyucBlokDenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVyucBlokDenMistnostiV3(vyucBlokDenId: string, options?: any): AxiosPromise<GetVyucBlokDenMistnostiV3200Response> {
            return localVarFp.getVyucBlokDenMistnostiV3(vyucBlokDenId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/vyuc_blok_den/__vyuc_blok_den_id__/vyucujici/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam vyucujicich daneho bloku vyucovani v konkretni den
         * @param {string} vyucBlokDenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVyucBlokDenVyucujiciV3(vyucBlokDenId: string, options?: any): AxiosPromise<GetVyucBlokDenVyucujiciV3200Response> {
            return localVarFp.getVyucBlokDenVyucujiciV3(vyucBlokDenId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/vyucovani_blok/__vyucovani_blok_id__/mistnosti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam mistnosti daneho bloku vyucovani
         * @param {string} vyucovaniBlokId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVyucovaniBlokMistnostiV3(vyucovaniBlokId: string, options?: any): AxiosPromise<GetVyucovaniBlokMistnostiV3200Response> {
            return localVarFp.getVyucovaniBlokMistnostiV3(vyucovaniBlokId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/vyucovani_blok/__vyucovani_blok_id__/vyucujici/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam vyucujicich daneho bloku vyucovani
         * @param {string} vyucovaniBlokId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVyucovaniBlokVyucujiciV3(vyucovaniBlokId: string, options?: any): AxiosPromise<GetVyucovaniBlokVyucujiciV3200Response> {
            return localVarFp.getVyucovaniBlokVyucujiciV3(vyucovaniBlokId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/vyucovani/__vyucovani_id__/mistnosti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam mistnosti daneho vyucovani
         * @param {string} vyucovaniId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVyucovaniMistnostiV3(vyucovaniId: string, options?: any): AxiosPromise<GetVyucovaniMistnostiV3200Response> {
            return localVarFp.getVyucovaniMistnostiV3(vyucovaniId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/vyucovani/__vyucovani_id__/skupiny/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam prednaskovych skupin, pro ktere je dane vyucovani
         * @param {string} vyucovaniId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVyucovaniSkupinyV3(vyucovaniId: string, options?: any): AxiosPromise<GetVyucovaniSkupinyV3200Response> {
            return localVarFp.getVyucovaniSkupinyV3(vyucovaniId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/vyucovani/__vyucovani_id__/vyucujici/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam vyucujicich daneho vyucovani
         * @param {string} vyucovaniId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVyucovaniVyucujiciV3(vyucovaniId: string, options?: any): AxiosPromise<GetVyucovaniVyucujiciV3200Response> {
            return localVarFp.getVyucovaniVyucujiciV3(vyucovaniId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RozvrhApi - object-oriented interface
 * @export
 * @class RozvrhApi
 * @extends {BaseAPI}
 */
export class RozvrhApi extends BaseAPI {
    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/rozvrhove-jednotky/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Rozvrhove jednotky (typy vyucovani, tedy rozvrhovych oken)
     * @param {string} aktualniPredmetId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RozvrhApi
     */
    public getAktualniPredmetRozvrhoveJednotkyV3(aktualniPredmetId: string, lang?: string, options?: AxiosRequestConfig) {
        return RozvrhApiFp(this.configuration).getAktualniPredmetRozvrhoveJednotkyV3(aktualniPredmetId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/terminy-zkousek/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Terminy v predmetu
     * @param {string} aktualniPredmetId 
     * @param {string} [lang] 
     * @param {string} [datumOd] 
     * @param {string} [datumDo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RozvrhApi
     */
    public getAktualniPredmetTerminyZkousekV3(aktualniPredmetId: string, lang?: string, datumOd?: string, datumDo?: string, options?: AxiosRequestConfig) {
        return RozvrhApiFp(this.configuration).getAktualniPredmetTerminyZkousekV3(aktualniPredmetId, lang, datumOd, datumDo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/terminy-zkousek/v4?lang=cs&datum_od=1.1.2022&datum_do=1.2.2022\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Terminy v predmetu
     * @param {string} aktualniPredmetId 
     * @param {string} [lang] 
     * @param {string} [datumOd] 
     * @param {string} [datumDo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RozvrhApi
     */
    public getAktualniPredmetTerminyZkousekV4(aktualniPredmetId: string, lang?: string, datumOd?: string, datumDo?: string, options?: AxiosRequestConfig) {
        return RozvrhApiFp(this.configuration).getAktualniPredmetTerminyZkousekV4(aktualniPredmetId, lang, datumOd, datumDo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/vyucovani/__vyucovani_id__/studenti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam studentu ve vyucovani
     * @param {string} aktualniPredmetId 
     * @param {string} vyucovaniId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RozvrhApi
     */
    public getAktualniPredmetVyucovaniStudentiV3(aktualniPredmetId: string, vyucovaniId: string, options?: AxiosRequestConfig) {
        return RozvrhApiFp(this.configuration).getAktualniPredmetVyucovaniStudentiV3(aktualniPredmetId, vyucovaniId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/vyucovani/v3?lang=cs&rok=2021&typ_semestru_id=2&datum_od=23.9.2021&datum_do=30.9.2021\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Vyucovani (rozvrhova okna predmetu)
     * @param {string} aktualniPredmetId 
     * @param {string} [lang] 
     * @param {number} [rok] 
     * @param {number} [typSemestruId] 
     * @param {string} [datumOd] 
     * @param {string} [datumDo] 
     * @param {string} [viditelne] 0/1/bez uvedeni nefiltrovano) - u mistnosti dalsi info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RozvrhApi
     */
    public getAktualniPredmetVyucovaniV3(aktualniPredmetId: string, lang?: string, rok?: number, typSemestruId?: number, datumOd?: string, datumDo?: string, viditelne?: string, options?: AxiosRequestConfig) {
        return RozvrhApiFp(this.configuration).getAktualniPredmetVyucovaniV3(aktualniPredmetId, lang, rok, typSemestruId, datumOd, datumDo, viditelne, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/vyuc_blok_den/__vyuc_blok_den_id__/mistnosti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam mistnosti daneho bloku vyucovani v konkretni den
     * @param {string} vyucBlokDenId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RozvrhApi
     */
    public getVyucBlokDenMistnostiV3(vyucBlokDenId: string, options?: AxiosRequestConfig) {
        return RozvrhApiFp(this.configuration).getVyucBlokDenMistnostiV3(vyucBlokDenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/vyuc_blok_den/__vyuc_blok_den_id__/vyucujici/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam vyucujicich daneho bloku vyucovani v konkretni den
     * @param {string} vyucBlokDenId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RozvrhApi
     */
    public getVyucBlokDenVyucujiciV3(vyucBlokDenId: string, options?: AxiosRequestConfig) {
        return RozvrhApiFp(this.configuration).getVyucBlokDenVyucujiciV3(vyucBlokDenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/vyucovani_blok/__vyucovani_blok_id__/mistnosti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam mistnosti daneho bloku vyucovani
     * @param {string} vyucovaniBlokId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RozvrhApi
     */
    public getVyucovaniBlokMistnostiV3(vyucovaniBlokId: string, options?: AxiosRequestConfig) {
        return RozvrhApiFp(this.configuration).getVyucovaniBlokMistnostiV3(vyucovaniBlokId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/vyucovani_blok/__vyucovani_blok_id__/vyucujici/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam vyucujicich daneho bloku vyucovani
     * @param {string} vyucovaniBlokId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RozvrhApi
     */
    public getVyucovaniBlokVyucujiciV3(vyucovaniBlokId: string, options?: AxiosRequestConfig) {
        return RozvrhApiFp(this.configuration).getVyucovaniBlokVyucujiciV3(vyucovaniBlokId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/vyucovani/__vyucovani_id__/mistnosti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam mistnosti daneho vyucovani
     * @param {string} vyucovaniId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RozvrhApi
     */
    public getVyucovaniMistnostiV3(vyucovaniId: string, options?: AxiosRequestConfig) {
        return RozvrhApiFp(this.configuration).getVyucovaniMistnostiV3(vyucovaniId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/vyucovani/__vyucovani_id__/skupiny/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam prednaskovych skupin, pro ktere je dane vyucovani
     * @param {string} vyucovaniId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RozvrhApi
     */
    public getVyucovaniSkupinyV3(vyucovaniId: string, options?: AxiosRequestConfig) {
        return RozvrhApiFp(this.configuration).getVyucovaniSkupinyV3(vyucovaniId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/vyucovani/__vyucovani_id__/vyucujici/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam vyucujicich daneho vyucovani
     * @param {string} vyucovaniId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RozvrhApi
     */
    public getVyucovaniVyucujiciV3(vyucovaniId: string, options?: AxiosRequestConfig) {
        return RozvrhApiFp(this.configuration).getVyucovaniVyucujiciV3(vyucovaniId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SeznamApi - axios parameter creator
 * @export
 */
export const SeznamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/predmety/v3?lang=cs&rok=2022&ustav_id=20045&typ_semestru_id=2\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam predmetu
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {number} [predmetId] 
         * @param {number} [aktualniPredmetId] 
         * @param {number} [ustavId] 
         * @param {number} [typSemestruId] 
         * @param {string} [zkratka] 
         * @param {number} [spravujiciUstavId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPredmetyV3: async (lang?: string, rok?: number, predmetId?: number, aktualniPredmetId?: number, ustavId?: number, typSemestruId?: number, zkratka?: string, spravujiciUstavId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/fit/predmety/v3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (rok !== undefined) {
                localVarQueryParameter['rok'] = rok;
            }

            if (predmetId !== undefined) {
                localVarQueryParameter['predmet_id'] = predmetId;
            }

            if (aktualniPredmetId !== undefined) {
                localVarQueryParameter['aktualni_predmet_id'] = aktualniPredmetId;
            }

            if (ustavId !== undefined) {
                localVarQueryParameter['ustav_id'] = ustavId;
            }

            if (typSemestruId !== undefined) {
                localVarQueryParameter['typ_semestru_id'] = typSemestruId;
            }

            if (zkratka !== undefined) {
                localVarQueryParameter['zkratka'] = zkratka;
            }

            if (spravujiciUstavId !== undefined) {
                localVarQueryParameter['spravujici_ustav_id'] = spravujiciUstavId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SeznamApi - functional programming interface
 * @export
 */
export const SeznamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SeznamApiAxiosParamCreator(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/predmety/v3?lang=cs&rok=2022&ustav_id=20045&typ_semestru_id=2\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam predmetu
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {number} [predmetId] 
         * @param {number} [aktualniPredmetId] 
         * @param {number} [ustavId] 
         * @param {number} [typSemestruId] 
         * @param {string} [zkratka] 
         * @param {number} [spravujiciUstavId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPredmetyV3(lang?: string, rok?: number, predmetId?: number, aktualniPredmetId?: number, ustavId?: number, typSemestruId?: number, zkratka?: string, spravujiciUstavId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPredmetyV3(lang, rok, predmetId, aktualniPredmetId, ustavId, typSemestruId, zkratka, spravujiciUstavId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SeznamApi - factory interface
 * @export
 */
export const SeznamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SeznamApiFp(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/predmety/v3?lang=cs&rok=2022&ustav_id=20045&typ_semestru_id=2\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam predmetu
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {number} [predmetId] 
         * @param {number} [aktualniPredmetId] 
         * @param {number} [ustavId] 
         * @param {number} [typSemestruId] 
         * @param {string} [zkratka] 
         * @param {number} [spravujiciUstavId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPredmetyV3(lang?: string, rok?: number, predmetId?: number, aktualniPredmetId?: number, ustavId?: number, typSemestruId?: number, zkratka?: string, spravujiciUstavId?: number, options?: any): AxiosPromise<GetAktualniPredmetV3200Response> {
            return localVarFp.getPredmetyV3(lang, rok, predmetId, aktualniPredmetId, ustavId, typSemestruId, zkratka, spravujiciUstavId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SeznamApi - object-oriented interface
 * @export
 * @class SeznamApi
 * @extends {BaseAPI}
 */
export class SeznamApi extends BaseAPI {
    /**
     *  <a href=\"https://api.vut.cz/api/fit/predmety/v3?lang=cs&rok=2022&ustav_id=20045&typ_semestru_id=2\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam predmetu
     * @param {string} [lang] 
     * @param {number} [rok] 
     * @param {number} [predmetId] 
     * @param {number} [aktualniPredmetId] 
     * @param {number} [ustavId] 
     * @param {number} [typSemestruId] 
     * @param {string} [zkratka] 
     * @param {number} [spravujiciUstavId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeznamApi
     */
    public getPredmetyV3(lang?: string, rok?: number, predmetId?: number, aktualniPredmetId?: number, ustavId?: number, typSemestruId?: number, zkratka?: string, spravujiciUstavId?: number, options?: AxiosRequestConfig) {
        return SeznamApiFp(this.configuration).getPredmetyV3(lang, rok, predmetId, aktualniPredmetId, ustavId, typSemestruId, zkratka, spravujiciUstavId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StudentiApi - axios parameter creator
 * @export
 */
export const StudentiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/el_index/__el_index_id__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail studenta v predmetu
         * @param {string} aktualniPredmetId 
         * @param {string} elIndexId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetElIndexV3: async (aktualniPredmetId: string, elIndexId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetElIndexV3', 'aktualniPredmetId', aktualniPredmetId)
            // verify required parameter 'elIndexId' is not null or undefined
            assertParamExists('getAktualniPredmetElIndexV3', 'elIndexId', elIndexId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/el_index/{el_index_id}/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)))
                .replace(`{${"el_index_id"}}`, encodeURIComponent(String(elIndexId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/studenti/anon/zapsani/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Studenti zapsani v predmetu (jen ID beze jmen)
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetStudentiAnonZapsaniV3: async (aktualniPredmetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetStudentiAnonZapsaniV3', 'aktualniPredmetId', aktualniPredmetId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/studenti/anon/zapsani/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/studenti/anon/zaregistrovani/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Studenti zaregistrovani v predmetu (jen ID beze jmen)
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetStudentiAnonZaregistrovaniV3: async (aktualniPredmetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetStudentiAnonZaregistrovaniV3', 'aktualniPredmetId', aktualniPredmetId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/studenti/anon/zaregistrovani/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/studenti/zapsani/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Studenti zapsani v predmetu
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetStudentiZapsaniV3: async (aktualniPredmetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetStudentiZapsaniV3', 'aktualniPredmetId', aktualniPredmetId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/studenti/zapsani/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudentiApi - functional programming interface
 * @export
 */
export const StudentiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StudentiApiAxiosParamCreator(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/el_index/__el_index_id__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail studenta v predmetu
         * @param {string} aktualniPredmetId 
         * @param {string} elIndexId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetElIndexV3(aktualniPredmetId: string, elIndexId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetElIndexV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetElIndexV3(aktualniPredmetId, elIndexId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/studenti/anon/zapsani/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Studenti zapsani v predmetu (jen ID beze jmen)
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetStudentiAnonZapsaniV3(aktualniPredmetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetStudentiAnonZapsaniV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetStudentiAnonZapsaniV3(aktualniPredmetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/studenti/anon/zaregistrovani/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Studenti zaregistrovani v predmetu (jen ID beze jmen)
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetStudentiAnonZaregistrovaniV3(aktualniPredmetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetStudentiAnonZapsaniV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetStudentiAnonZaregistrovaniV3(aktualniPredmetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/studenti/zapsani/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Studenti zapsani v predmetu
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetStudentiZapsaniV3(aktualniPredmetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetStudentiZapsaniV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetStudentiZapsaniV3(aktualniPredmetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StudentiApi - factory interface
 * @export
 */
export const StudentiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StudentiApiFp(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/el_index/__el_index_id__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail studenta v predmetu
         * @param {string} aktualniPredmetId 
         * @param {string} elIndexId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetElIndexV3(aktualniPredmetId: string, elIndexId: string, options?: any): AxiosPromise<GetAktualniPredmetElIndexV3200Response> {
            return localVarFp.getAktualniPredmetElIndexV3(aktualniPredmetId, elIndexId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/studenti/anon/zapsani/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Studenti zapsani v predmetu (jen ID beze jmen)
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetStudentiAnonZapsaniV3(aktualniPredmetId: string, options?: any): AxiosPromise<GetAktualniPredmetStudentiAnonZapsaniV3200Response> {
            return localVarFp.getAktualniPredmetStudentiAnonZapsaniV3(aktualniPredmetId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/studenti/anon/zaregistrovani/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Studenti zaregistrovani v predmetu (jen ID beze jmen)
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetStudentiAnonZaregistrovaniV3(aktualniPredmetId: string, options?: any): AxiosPromise<GetAktualniPredmetStudentiAnonZapsaniV3200Response> {
            return localVarFp.getAktualniPredmetStudentiAnonZaregistrovaniV3(aktualniPredmetId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/studenti/zapsani/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Studenti zapsani v predmetu
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetStudentiZapsaniV3(aktualniPredmetId: string, options?: any): AxiosPromise<GetAktualniPredmetStudentiZapsaniV3200Response> {
            return localVarFp.getAktualniPredmetStudentiZapsaniV3(aktualniPredmetId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StudentiApi - object-oriented interface
 * @export
 * @class StudentiApi
 * @extends {BaseAPI}
 */
export class StudentiApi extends BaseAPI {
    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/el_index/__el_index_id__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Detail studenta v predmetu
     * @param {string} aktualniPredmetId 
     * @param {string} elIndexId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentiApi
     */
    public getAktualniPredmetElIndexV3(aktualniPredmetId: string, elIndexId: string, options?: AxiosRequestConfig) {
        return StudentiApiFp(this.configuration).getAktualniPredmetElIndexV3(aktualniPredmetId, elIndexId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/studenti/anon/zapsani/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Studenti zapsani v predmetu (jen ID beze jmen)
     * @param {string} aktualniPredmetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentiApi
     */
    public getAktualniPredmetStudentiAnonZapsaniV3(aktualniPredmetId: string, options?: AxiosRequestConfig) {
        return StudentiApiFp(this.configuration).getAktualniPredmetStudentiAnonZapsaniV3(aktualniPredmetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/studenti/anon/zaregistrovani/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Studenti zaregistrovani v predmetu (jen ID beze jmen)
     * @param {string} aktualniPredmetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentiApi
     */
    public getAktualniPredmetStudentiAnonZaregistrovaniV3(aktualniPredmetId: string, options?: AxiosRequestConfig) {
        return StudentiApiFp(this.configuration).getAktualniPredmetStudentiAnonZaregistrovaniV3(aktualniPredmetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/studenti/zapsani/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Studenti zapsani v predmetu
     * @param {string} aktualniPredmetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentiApi
     */
    public getAktualniPredmetStudentiZapsaniV3(aktualniPredmetId: string, options?: AxiosRequestConfig) {
        return StudentiApiFp(this.configuration).getAktualniPredmetStudentiZapsaniV3(aktualniPredmetId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StudijniPlanApi - axios parameter creator
 * @export
 */
export const StudijniPlanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/plan/__plan_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail studijniho planu
         * @param {string} planId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanV3: async (planId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('getPlanV3', 'planId', planId)
            const localVarPath = `/api/fit/plan/{plan_id}/v3`
                .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (rok !== undefined) {
                localVarQueryParameter['rok'] = rok;
            }

            if (erasmus !== undefined) {
                localVarQueryParameter['erasmus'] = erasmus;
            }

            if (typ !== undefined) {
                localVarQueryParameter['typ'] = typ;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudijniPlanApi - functional programming interface
 * @export
 */
export const StudijniPlanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StudijniPlanApiAxiosParamCreator(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/plan/__plan_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail studijniho planu
         * @param {string} planId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlanV3(planId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOborPlanV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlanV3(planId, lang, rok, erasmus, typ, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StudijniPlanApi - factory interface
 * @export
 */
export const StudijniPlanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StudijniPlanApiFp(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/plan/__plan_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail studijniho planu
         * @param {string} planId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanV3(planId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options?: any): AxiosPromise<GetOborPlanV3200Response> {
            return localVarFp.getPlanV3(planId, lang, rok, erasmus, typ, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StudijniPlanApi - object-oriented interface
 * @export
 * @class StudijniPlanApi
 * @extends {BaseAPI}
 */
export class StudijniPlanApi extends BaseAPI {
    /**
     *  <a href=\"https://api.vut.cz/api/fit/plan/__plan_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Detail studijniho planu
     * @param {string} planId 
     * @param {string} [lang] 
     * @param {number} [rok] 
     * @param {string} [erasmus] 1
     * @param {string} [typ] M/N/D/B
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudijniPlanApi
     */
    public getPlanV3(planId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options?: AxiosRequestConfig) {
        return StudijniPlanApiFp(this.configuration).getPlanV3(planId, lang, rok, erasmus, typ, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StudijniProgramyApi - axios parameter creator
 * @export
 */
export const StudijniProgramyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/plan/__plan_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail studijniho planu v programu
         * @param {string} programId 
         * @param {string} planId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramPlanV3: async (programId: string, planId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('getProgramPlanV3', 'programId', programId)
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('getProgramPlanV3', 'planId', planId)
            const localVarPath = `/api/fit/program/{program_id}/plan/{plan_id}/v3`
                .replace(`{${"program_id"}}`, encodeURIComponent(String(programId)))
                .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (rok !== undefined) {
                localVarQueryParameter['rok'] = rok;
            }

            if (erasmus !== undefined) {
                localVarQueryParameter['erasmus'] = erasmus;
            }

            if (typ !== undefined) {
                localVarQueryParameter['typ'] = typ;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/plany/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam studijnich planu v programu
         * @param {string} programId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramPlanyV3: async (programId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('getProgramPlanyV3', 'programId', programId)
            const localVarPath = `/api/fit/program/{program_id}/plany/v3`
                .replace(`{${"program_id"}}`, encodeURIComponent(String(programId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (rok !== undefined) {
                localVarQueryParameter['rok'] = rok;
            }

            if (erasmus !== undefined) {
                localVarQueryParameter['erasmus'] = erasmus;
            }

            if (typ !== undefined) {
                localVarQueryParameter['typ'] = typ;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/rada/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Rada studijniho programu
         * @param {string} programId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramRadaV3: async (programId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('getProgramRadaV3', 'programId', programId)
            const localVarPath = `/api/fit/program/{program_id}/rada/v3`
                .replace(`{${"program_id"}}`, encodeURIComponent(String(programId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail studijniho programu
         * @param {string} programId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramV3: async (programId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('getProgramV3', 'programId', programId)
            const localVarPath = `/api/fit/program/{program_id}/v3`
                .replace(`{${"program_id"}}`, encodeURIComponent(String(programId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/programy/v3?lang=cs&erasmus=1&typ=N&rok=2020\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam studijnich programu
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {string} [iPripravaAkreditace] &#x3D;1
         * @param {string} [iNevykazovatDoMatriky] &#x3D;1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramyV3: async (lang?: string, rok?: number, erasmus?: string, typ?: string, iPripravaAkreditace?: string, iNevykazovatDoMatriky?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/fit/programy/v3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (rok !== undefined) {
                localVarQueryParameter['rok'] = rok;
            }

            if (erasmus !== undefined) {
                localVarQueryParameter['erasmus'] = erasmus;
            }

            if (typ !== undefined) {
                localVarQueryParameter['typ'] = typ;
            }

            if (iPripravaAkreditace !== undefined) {
                localVarQueryParameter['i_priprava_akreditace'] = iPripravaAkreditace;
            }

            if (iNevykazovatDoMatriky !== undefined) {
                localVarQueryParameter['i_nevykazovat_do_matriky'] = iNevykazovatDoMatriky;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudijniProgramyApi - functional programming interface
 * @export
 */
export const StudijniProgramyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StudijniProgramyApiAxiosParamCreator(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/plan/__plan_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail studijniho planu v programu
         * @param {string} programId 
         * @param {string} planId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramPlanV3(programId: string, planId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOborPlanV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramPlanV3(programId, planId, lang, rok, erasmus, typ, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/plany/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam studijnich planu v programu
         * @param {string} programId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramPlanyV3(programId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOborPlanyV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramPlanyV3(programId, lang, rok, erasmus, typ, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/rada/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Rada studijniho programu
         * @param {string} programId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramRadaV3(programId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProgramRadaV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramRadaV3(programId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail studijniho programu
         * @param {string} programId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramV3(programId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProgramV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramV3(programId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/programy/v3?lang=cs&erasmus=1&typ=N&rok=2020\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam studijnich programu
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {string} [iPripravaAkreditace] &#x3D;1
         * @param {string} [iNevykazovatDoMatriky] &#x3D;1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramyV3(lang?: string, rok?: number, erasmus?: string, typ?: string, iPripravaAkreditace?: string, iNevykazovatDoMatriky?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProgramyV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramyV3(lang, rok, erasmus, typ, iPripravaAkreditace, iNevykazovatDoMatriky, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StudijniProgramyApi - factory interface
 * @export
 */
export const StudijniProgramyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StudijniProgramyApiFp(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/plan/__plan_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail studijniho planu v programu
         * @param {string} programId 
         * @param {string} planId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramPlanV3(programId: string, planId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options?: any): AxiosPromise<GetOborPlanV3200Response> {
            return localVarFp.getProgramPlanV3(programId, planId, lang, rok, erasmus, typ, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/plany/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam studijnich planu v programu
         * @param {string} programId 
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramPlanyV3(programId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options?: any): AxiosPromise<GetOborPlanyV3200Response> {
            return localVarFp.getProgramPlanyV3(programId, lang, rok, erasmus, typ, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/rada/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Rada studijniho programu
         * @param {string} programId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramRadaV3(programId: string, lang?: string, options?: any): AxiosPromise<GetProgramRadaV3200Response> {
            return localVarFp.getProgramRadaV3(programId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail studijniho programu
         * @param {string} programId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramV3(programId: string, lang?: string, options?: any): AxiosPromise<GetProgramV3200Response> {
            return localVarFp.getProgramV3(programId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/programy/v3?lang=cs&erasmus=1&typ=N&rok=2020\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam studijnich programu
         * @param {string} [lang] 
         * @param {number} [rok] 
         * @param {string} [erasmus] 1
         * @param {string} [typ] M/N/D/B
         * @param {string} [iPripravaAkreditace] &#x3D;1
         * @param {string} [iNevykazovatDoMatriky] &#x3D;1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramyV3(lang?: string, rok?: number, erasmus?: string, typ?: string, iPripravaAkreditace?: string, iNevykazovatDoMatriky?: string, options?: any): AxiosPromise<GetProgramyV3200Response> {
            return localVarFp.getProgramyV3(lang, rok, erasmus, typ, iPripravaAkreditace, iNevykazovatDoMatriky, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StudijniProgramyApi - object-oriented interface
 * @export
 * @class StudijniProgramyApi
 * @extends {BaseAPI}
 */
export class StudijniProgramyApi extends BaseAPI {
    /**
     *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/plan/__plan_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Detail studijniho planu v programu
     * @param {string} programId 
     * @param {string} planId 
     * @param {string} [lang] 
     * @param {number} [rok] 
     * @param {string} [erasmus] 1
     * @param {string} [typ] M/N/D/B
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudijniProgramyApi
     */
    public getProgramPlanV3(programId: string, planId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options?: AxiosRequestConfig) {
        return StudijniProgramyApiFp(this.configuration).getProgramPlanV3(programId, planId, lang, rok, erasmus, typ, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/plany/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam studijnich planu v programu
     * @param {string} programId 
     * @param {string} [lang] 
     * @param {number} [rok] 
     * @param {string} [erasmus] 1
     * @param {string} [typ] M/N/D/B
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudijniProgramyApi
     */
    public getProgramPlanyV3(programId: string, lang?: string, rok?: number, erasmus?: string, typ?: string, options?: AxiosRequestConfig) {
        return StudijniProgramyApiFp(this.configuration).getProgramPlanyV3(programId, lang, rok, erasmus, typ, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/rada/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Rada studijniho programu
     * @param {string} programId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudijniProgramyApi
     */
    public getProgramRadaV3(programId: string, lang?: string, options?: AxiosRequestConfig) {
        return StudijniProgramyApiFp(this.configuration).getProgramRadaV3(programId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/program/__program_id__/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Detail studijniho programu
     * @param {string} programId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudijniProgramyApi
     */
    public getProgramV3(programId: string, lang?: string, options?: AxiosRequestConfig) {
        return StudijniProgramyApiFp(this.configuration).getProgramV3(programId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/programy/v3?lang=cs&erasmus=1&typ=N&rok=2020\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam studijnich programu
     * @param {string} [lang] 
     * @param {number} [rok] 
     * @param {string} [erasmus] 1
     * @param {string} [typ] M/N/D/B
     * @param {string} [iPripravaAkreditace] &#x3D;1
     * @param {string} [iNevykazovatDoMatriky] &#x3D;1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudijniProgramyApi
     */
    public getProgramyV3(lang?: string, rok?: number, erasmus?: string, typ?: string, iPripravaAkreditace?: string, iNevykazovatDoMatriky?: string, options?: AxiosRequestConfig) {
        return StudijniProgramyApiFp(this.configuration).getProgramyV3(lang, rok, erasmus, typ, iPripravaAkreditace, iNevykazovatDoMatriky, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TerminyApi - axios parameter creator
 * @export
 */
export const TerminyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Prihlaseni studenta na termin
         * @param {number} aktualniPredmetId 
         * @param {number} terminId 
         * @param {number} zkouskaTerminId 
         * @param {number} elIndexId 
         * @param {CreateAktualniPredmetTerminZkouskaTerminElIndexV4Request} createAktualniPredmetTerminZkouskaTerminElIndexV4Request Request body content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAktualniPredmetTerminZkouskaTerminElIndexV4: async (aktualniPredmetId: number, terminId: number, zkouskaTerminId: number, elIndexId: number, createAktualniPredmetTerminZkouskaTerminElIndexV4Request: CreateAktualniPredmetTerminZkouskaTerminElIndexV4Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('createAktualniPredmetTerminZkouskaTerminElIndexV4', 'aktualniPredmetId', aktualniPredmetId)
            // verify required parameter 'terminId' is not null or undefined
            assertParamExists('createAktualniPredmetTerminZkouskaTerminElIndexV4', 'terminId', terminId)
            // verify required parameter 'zkouskaTerminId' is not null or undefined
            assertParamExists('createAktualniPredmetTerminZkouskaTerminElIndexV4', 'zkouskaTerminId', zkouskaTerminId)
            // verify required parameter 'elIndexId' is not null or undefined
            assertParamExists('createAktualniPredmetTerminZkouskaTerminElIndexV4', 'elIndexId', elIndexId)
            // verify required parameter 'createAktualniPredmetTerminZkouskaTerminElIndexV4Request' is not null or undefined
            assertParamExists('createAktualniPredmetTerminZkouskaTerminElIndexV4', 'createAktualniPredmetTerminZkouskaTerminElIndexV4Request', createAktualniPredmetTerminZkouskaTerminElIndexV4Request)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/termin/{termin_id}/zkouska_termin/{zkouska_termin_id}/el_index/{el_index_id}/v4`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)))
                .replace(`{${"termin_id"}}`, encodeURIComponent(String(terminId)))
                .replace(`{${"zkouska_termin_id"}}`, encodeURIComponent(String(zkouskaTerminId)))
                .replace(`{${"el_index_id"}}`, encodeURIComponent(String(elIndexId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAktualniPredmetTerminZkouskaTerminElIndexV4Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Odhlaseni studenta z terminu
         * @param {number} aktualniPredmetId 
         * @param {number} terminId 
         * @param {number} zkouskaTerminId 
         * @param {number} elIndexId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAktualniPredmetTerminZkouskaTerminElIndexV4: async (aktualniPredmetId: number, terminId: number, zkouskaTerminId: number, elIndexId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('deleteAktualniPredmetTerminZkouskaTerminElIndexV4', 'aktualniPredmetId', aktualniPredmetId)
            // verify required parameter 'terminId' is not null or undefined
            assertParamExists('deleteAktualniPredmetTerminZkouskaTerminElIndexV4', 'terminId', terminId)
            // verify required parameter 'zkouskaTerminId' is not null or undefined
            assertParamExists('deleteAktualniPredmetTerminZkouskaTerminElIndexV4', 'zkouskaTerminId', zkouskaTerminId)
            // verify required parameter 'elIndexId' is not null or undefined
            assertParamExists('deleteAktualniPredmetTerminZkouskaTerminElIndexV4', 'elIndexId', elIndexId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/termin/{termin_id}/zkouska_termin/{zkouska_termin_id}/el_index/{el_index_id}/v4`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)))
                .replace(`{${"termin_id"}}`, encodeURIComponent(String(terminId)))
                .replace(`{${"zkouska_termin_id"}}`, encodeURIComponent(String(zkouskaTerminId)))
                .replace(`{${"el_index_id"}}`, encodeURIComponent(String(elIndexId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/termin/__termin_id__/el_index/__el_index_id__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Hodnoceni studenta na terminu
         * @param {string} aktualniPredmetId 
         * @param {string} terminId 
         * @param {string} elIndexId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetTerminElIndexV3: async (aktualniPredmetId: string, terminId: string, elIndexId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetTerminElIndexV3', 'aktualniPredmetId', aktualniPredmetId)
            // verify required parameter 'terminId' is not null or undefined
            assertParamExists('getAktualniPredmetTerminElIndexV3', 'terminId', terminId)
            // verify required parameter 'elIndexId' is not null or undefined
            assertParamExists('getAktualniPredmetTerminElIndexV3', 'elIndexId', elIndexId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/termin/{termin_id}/el_index/{el_index_id}/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)))
                .replace(`{${"termin_id"}}`, encodeURIComponent(String(terminId)))
                .replace(`{${"el_index_id"}}`, encodeURIComponent(String(elIndexId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/termin/__termin_id__/mistnosti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Mistnosti k terminu
         * @param {string} aktualniPredmetId 
         * @param {string} terminId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetTerminMistnostiV3: async (aktualniPredmetId: string, terminId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetTerminMistnostiV3', 'aktualniPredmetId', aktualniPredmetId)
            // verify required parameter 'terminId' is not null or undefined
            assertParamExists('getAktualniPredmetTerminMistnostiV3', 'terminId', terminId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/termin/{termin_id}/mistnosti/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)))
                .replace(`{${"termin_id"}}`, encodeURIComponent(String(terminId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/termin/__termin_id__/mistnosti/v4\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam mistnosti na termin
         * @param {string} aktualniPredmetId 
         * @param {string} terminId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetTerminMistnostiV4: async (aktualniPredmetId: string, terminId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetTerminMistnostiV4', 'aktualniPredmetId', aktualniPredmetId)
            // verify required parameter 'terminId' is not null or undefined
            assertParamExists('getAktualniPredmetTerminMistnostiV4', 'terminId', terminId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/termin/{termin_id}/mistnosti/v4`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)))
                .replace(`{${"termin_id"}}`, encodeURIComponent(String(terminId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/termin/__termin_id__/studenti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam studentu na terminu
         * @param {string} aktualniPredmetId 
         * @param {string} terminId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetTerminStudentiV3: async (aktualniPredmetId: string, terminId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetTerminStudentiV3', 'aktualniPredmetId', aktualniPredmetId)
            // verify required parameter 'terminId' is not null or undefined
            assertParamExists('getAktualniPredmetTerminStudentiV3', 'terminId', terminId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/termin/{termin_id}/studenti/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)))
                .replace(`{${"termin_id"}}`, encodeURIComponent(String(terminId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/termin/__termin_id__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Editace terminu
         * @param {string} aktualniPredmetId 
         * @param {string} terminId 
         * @param {string} [patch] 
         * @param {string} [parametry] stejne jako u vlozeni terminu - vyjma povinneho ZKOUSKA_PROJEKT_ID
         * @param {string} [ten] tu neni...
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetTerminV3: async (aktualniPredmetId: string, terminId: string, patch?: string, parametry?: string, ten?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetTerminV3', 'aktualniPredmetId', aktualniPredmetId)
            // verify required parameter 'terminId' is not null or undefined
            assertParamExists('getAktualniPredmetTerminV3', 'terminId', terminId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/termin/{termin_id}/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)))
                .replace(`{${"termin_id"}}`, encodeURIComponent(String(terminId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (patch !== undefined) {
                localVarQueryParameter['patch'] = patch;
            }

            if (parametry !== undefined) {
                localVarQueryParameter['parametry'] = parametry;
            }

            if (ten !== undefined) {
                localVarQueryParameter['ten'] = ten;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/terminy/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam terminu (zkousky, obhajoby, ...) v predmetu
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetTerminyV3: async (aktualniPredmetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetTerminyV3', 'aktualniPredmetId', aktualniPredmetId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/terminy/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/termin/__termin_id__/mistnosti/v4\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam mistnosti na termin
         * @param {string} terminId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminMistnostiV4: async (terminId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'terminId' is not null or undefined
            assertParamExists('getTerminMistnostiV4', 'terminId', terminId)
            const localVarPath = `/api/fit/termin/{termin_id}/mistnosti/v4`
                .replace(`{${"termin_id"}}`, encodeURIComponent(String(terminId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Je nutne uvest pocet bodu a datum, aby se hodnoceni ulozilo. Pokud se pocet bodu a datum neuvede, tak se hodnoceni smaze.
         * @summary Zapis hodnoceni studenta na terminu
         * @param {number} aktualniPredmetId 
         * @param {number} terminId 
         * @param {number} zkouskaTerminId 
         * @param {number} elIndexId 
         * @param {PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request} patchAktualniPredmetTerminZkouskaTerminElIndexV4Request Request body content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAktualniPredmetTerminZkouskaTerminElIndexV4: async (aktualniPredmetId: number, terminId: number, zkouskaTerminId: number, elIndexId: number, patchAktualniPredmetTerminZkouskaTerminElIndexV4Request: PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('patchAktualniPredmetTerminZkouskaTerminElIndexV4', 'aktualniPredmetId', aktualniPredmetId)
            // verify required parameter 'terminId' is not null or undefined
            assertParamExists('patchAktualniPredmetTerminZkouskaTerminElIndexV4', 'terminId', terminId)
            // verify required parameter 'zkouskaTerminId' is not null or undefined
            assertParamExists('patchAktualniPredmetTerminZkouskaTerminElIndexV4', 'zkouskaTerminId', zkouskaTerminId)
            // verify required parameter 'elIndexId' is not null or undefined
            assertParamExists('patchAktualniPredmetTerminZkouskaTerminElIndexV4', 'elIndexId', elIndexId)
            // verify required parameter 'patchAktualniPredmetTerminZkouskaTerminElIndexV4Request' is not null or undefined
            assertParamExists('patchAktualniPredmetTerminZkouskaTerminElIndexV4', 'patchAktualniPredmetTerminZkouskaTerminElIndexV4Request', patchAktualniPredmetTerminZkouskaTerminElIndexV4Request)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/termin/{termin_id}/zkouska_termin/{zkouska_termin_id}/el_index/{el_index_id}/v4`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)))
                .replace(`{${"termin_id"}}`, encodeURIComponent(String(terminId)))
                .replace(`{${"zkouska_termin_id"}}`, encodeURIComponent(String(zkouskaTerminId)))
                .replace(`{${"el_index_id"}}`, encodeURIComponent(String(elIndexId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchAktualniPredmetTerminZkouskaTerminElIndexV4Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TerminyApi - functional programming interface
 * @export
 */
export const TerminyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TerminyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Prihlaseni studenta na termin
         * @param {number} aktualniPredmetId 
         * @param {number} terminId 
         * @param {number} zkouskaTerminId 
         * @param {number} elIndexId 
         * @param {CreateAktualniPredmetTerminZkouskaTerminElIndexV4Request} createAktualniPredmetTerminZkouskaTerminElIndexV4Request Request body content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAktualniPredmetTerminZkouskaTerminElIndexV4(aktualniPredmetId: number, terminId: number, zkouskaTerminId: number, elIndexId: number, createAktualniPredmetTerminZkouskaTerminElIndexV4Request: CreateAktualniPredmetTerminZkouskaTerminElIndexV4Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAktualniPredmetTerminZkouskaTerminElIndexV4(aktualniPredmetId, terminId, zkouskaTerminId, elIndexId, createAktualniPredmetTerminZkouskaTerminElIndexV4Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Odhlaseni studenta z terminu
         * @param {number} aktualniPredmetId 
         * @param {number} terminId 
         * @param {number} zkouskaTerminId 
         * @param {number} elIndexId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAktualniPredmetTerminZkouskaTerminElIndexV4(aktualniPredmetId: number, terminId: number, zkouskaTerminId: number, elIndexId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAktualniPredmetTerminZkouskaTerminElIndexV4(aktualniPredmetId, terminId, zkouskaTerminId, elIndexId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/termin/__termin_id__/el_index/__el_index_id__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Hodnoceni studenta na terminu
         * @param {string} aktualniPredmetId 
         * @param {string} terminId 
         * @param {string} elIndexId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetTerminElIndexV3(aktualniPredmetId: string, terminId: string, elIndexId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetTerminElIndexV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetTerminElIndexV3(aktualniPredmetId, terminId, elIndexId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/termin/__termin_id__/mistnosti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Mistnosti k terminu
         * @param {string} aktualniPredmetId 
         * @param {string} terminId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetTerminMistnostiV3(aktualniPredmetId: string, terminId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetTerminMistnostiV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetTerminMistnostiV3(aktualniPredmetId, terminId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/termin/__termin_id__/mistnosti/v4\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam mistnosti na termin
         * @param {string} aktualniPredmetId 
         * @param {string} terminId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetTerminMistnostiV4(aktualniPredmetId: string, terminId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetTerminMistnostiV4200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetTerminMistnostiV4(aktualniPredmetId, terminId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/termin/__termin_id__/studenti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam studentu na terminu
         * @param {string} aktualniPredmetId 
         * @param {string} terminId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetTerminStudentiV3(aktualniPredmetId: string, terminId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetTerminStudentiV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetTerminStudentiV3(aktualniPredmetId, terminId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/termin/__termin_id__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Editace terminu
         * @param {string} aktualniPredmetId 
         * @param {string} terminId 
         * @param {string} [patch] 
         * @param {string} [parametry] stejne jako u vlozeni terminu - vyjma povinneho ZKOUSKA_PROJEKT_ID
         * @param {string} [ten] tu neni...
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetTerminV3(aktualniPredmetId: string, terminId: string, patch?: string, parametry?: string, ten?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetTerminV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetTerminV3(aktualniPredmetId, terminId, patch, parametry, ten, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/terminy/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam terminu (zkousky, obhajoby, ...) v predmetu
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetTerminyV3(aktualniPredmetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetTerminV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetTerminyV3(aktualniPredmetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/termin/__termin_id__/mistnosti/v4\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam mistnosti na termin
         * @param {string} terminId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTerminMistnostiV4(terminId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetTerminMistnostiV4200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTerminMistnostiV4(terminId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Je nutne uvest pocet bodu a datum, aby se hodnoceni ulozilo. Pokud se pocet bodu a datum neuvede, tak se hodnoceni smaze.
         * @summary Zapis hodnoceni studenta na terminu
         * @param {number} aktualniPredmetId 
         * @param {number} terminId 
         * @param {number} zkouskaTerminId 
         * @param {number} elIndexId 
         * @param {PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request} patchAktualniPredmetTerminZkouskaTerminElIndexV4Request Request body content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchAktualniPredmetTerminZkouskaTerminElIndexV4(aktualniPredmetId: number, terminId: number, zkouskaTerminId: number, elIndexId: number, patchAktualniPredmetTerminZkouskaTerminElIndexV4Request: PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchAktualniPredmetTerminZkouskaTerminElIndexV4(aktualniPredmetId, terminId, zkouskaTerminId, elIndexId, patchAktualniPredmetTerminZkouskaTerminElIndexV4Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TerminyApi - factory interface
 * @export
 */
export const TerminyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TerminyApiFp(configuration)
    return {
        /**
         * 
         * @summary Prihlaseni studenta na termin
         * @param {number} aktualniPredmetId 
         * @param {number} terminId 
         * @param {number} zkouskaTerminId 
         * @param {number} elIndexId 
         * @param {CreateAktualniPredmetTerminZkouskaTerminElIndexV4Request} createAktualniPredmetTerminZkouskaTerminElIndexV4Request Request body content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAktualniPredmetTerminZkouskaTerminElIndexV4(aktualniPredmetId: number, terminId: number, zkouskaTerminId: number, elIndexId: number, createAktualniPredmetTerminZkouskaTerminElIndexV4Request: CreateAktualniPredmetTerminZkouskaTerminElIndexV4Request, options?: any): AxiosPromise<any> {
            return localVarFp.createAktualniPredmetTerminZkouskaTerminElIndexV4(aktualniPredmetId, terminId, zkouskaTerminId, elIndexId, createAktualniPredmetTerminZkouskaTerminElIndexV4Request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Odhlaseni studenta z terminu
         * @param {number} aktualniPredmetId 
         * @param {number} terminId 
         * @param {number} zkouskaTerminId 
         * @param {number} elIndexId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAktualniPredmetTerminZkouskaTerminElIndexV4(aktualniPredmetId: number, terminId: number, zkouskaTerminId: number, elIndexId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteAktualniPredmetTerminZkouskaTerminElIndexV4(aktualniPredmetId, terminId, zkouskaTerminId, elIndexId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/termin/__termin_id__/el_index/__el_index_id__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Hodnoceni studenta na terminu
         * @param {string} aktualniPredmetId 
         * @param {string} terminId 
         * @param {string} elIndexId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetTerminElIndexV3(aktualniPredmetId: string, terminId: string, elIndexId: string, options?: any): AxiosPromise<GetAktualniPredmetTerminElIndexV3200Response> {
            return localVarFp.getAktualniPredmetTerminElIndexV3(aktualniPredmetId, terminId, elIndexId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/termin/__termin_id__/mistnosti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Mistnosti k terminu
         * @param {string} aktualniPredmetId 
         * @param {string} terminId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetTerminMistnostiV3(aktualniPredmetId: string, terminId: string, options?: any): AxiosPromise<GetAktualniPredmetTerminMistnostiV3200Response> {
            return localVarFp.getAktualniPredmetTerminMistnostiV3(aktualniPredmetId, terminId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/termin/__termin_id__/mistnosti/v4\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam mistnosti na termin
         * @param {string} aktualniPredmetId 
         * @param {string} terminId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetTerminMistnostiV4(aktualniPredmetId: string, terminId: string, options?: any): AxiosPromise<GetAktualniPredmetTerminMistnostiV4200Response> {
            return localVarFp.getAktualniPredmetTerminMistnostiV4(aktualniPredmetId, terminId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/termin/__termin_id__/studenti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam studentu na terminu
         * @param {string} aktualniPredmetId 
         * @param {string} terminId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetTerminStudentiV3(aktualniPredmetId: string, terminId: string, options?: any): AxiosPromise<GetAktualniPredmetTerminStudentiV3200Response> {
            return localVarFp.getAktualniPredmetTerminStudentiV3(aktualniPredmetId, terminId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/termin/__termin_id__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Editace terminu
         * @param {string} aktualniPredmetId 
         * @param {string} terminId 
         * @param {string} [patch] 
         * @param {string} [parametry] stejne jako u vlozeni terminu - vyjma povinneho ZKOUSKA_PROJEKT_ID
         * @param {string} [ten] tu neni...
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetTerminV3(aktualniPredmetId: string, terminId: string, patch?: string, parametry?: string, ten?: string, options?: any): AxiosPromise<GetAktualniPredmetTerminV3200Response> {
            return localVarFp.getAktualniPredmetTerminV3(aktualniPredmetId, terminId, patch, parametry, ten, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/terminy/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam terminu (zkousky, obhajoby, ...) v predmetu
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetTerminyV3(aktualniPredmetId: string, options?: any): AxiosPromise<GetAktualniPredmetTerminV3200Response> {
            return localVarFp.getAktualniPredmetTerminyV3(aktualniPredmetId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/termin/__termin_id__/mistnosti/v4\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam mistnosti na termin
         * @param {string} terminId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminMistnostiV4(terminId: string, options?: any): AxiosPromise<GetAktualniPredmetTerminMistnostiV4200Response> {
            return localVarFp.getTerminMistnostiV4(terminId, options).then((request) => request(axios, basePath));
        },
        /**
         * Je nutne uvest pocet bodu a datum, aby se hodnoceni ulozilo. Pokud se pocet bodu a datum neuvede, tak se hodnoceni smaze.
         * @summary Zapis hodnoceni studenta na terminu
         * @param {number} aktualniPredmetId 
         * @param {number} terminId 
         * @param {number} zkouskaTerminId 
         * @param {number} elIndexId 
         * @param {PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request} patchAktualniPredmetTerminZkouskaTerminElIndexV4Request Request body content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAktualniPredmetTerminZkouskaTerminElIndexV4(aktualniPredmetId: number, terminId: number, zkouskaTerminId: number, elIndexId: number, patchAktualniPredmetTerminZkouskaTerminElIndexV4Request: PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request, options?: any): AxiosPromise<any> {
            return localVarFp.patchAktualniPredmetTerminZkouskaTerminElIndexV4(aktualniPredmetId, terminId, zkouskaTerminId, elIndexId, patchAktualniPredmetTerminZkouskaTerminElIndexV4Request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TerminyApi - object-oriented interface
 * @export
 * @class TerminyApi
 * @extends {BaseAPI}
 */
export class TerminyApi extends BaseAPI {
    /**
     * 
     * @summary Prihlaseni studenta na termin
     * @param {number} aktualniPredmetId 
     * @param {number} terminId 
     * @param {number} zkouskaTerminId 
     * @param {number} elIndexId 
     * @param {CreateAktualniPredmetTerminZkouskaTerminElIndexV4Request} createAktualniPredmetTerminZkouskaTerminElIndexV4Request Request body content
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminyApi
     */
    public createAktualniPredmetTerminZkouskaTerminElIndexV4(aktualniPredmetId: number, terminId: number, zkouskaTerminId: number, elIndexId: number, createAktualniPredmetTerminZkouskaTerminElIndexV4Request: CreateAktualniPredmetTerminZkouskaTerminElIndexV4Request, options?: AxiosRequestConfig) {
        return TerminyApiFp(this.configuration).createAktualniPredmetTerminZkouskaTerminElIndexV4(aktualniPredmetId, terminId, zkouskaTerminId, elIndexId, createAktualniPredmetTerminZkouskaTerminElIndexV4Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Odhlaseni studenta z terminu
     * @param {number} aktualniPredmetId 
     * @param {number} terminId 
     * @param {number} zkouskaTerminId 
     * @param {number} elIndexId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminyApi
     */
    public deleteAktualniPredmetTerminZkouskaTerminElIndexV4(aktualniPredmetId: number, terminId: number, zkouskaTerminId: number, elIndexId: number, options?: AxiosRequestConfig) {
        return TerminyApiFp(this.configuration).deleteAktualniPredmetTerminZkouskaTerminElIndexV4(aktualniPredmetId, terminId, zkouskaTerminId, elIndexId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/termin/__termin_id__/el_index/__el_index_id__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Hodnoceni studenta na terminu
     * @param {string} aktualniPredmetId 
     * @param {string} terminId 
     * @param {string} elIndexId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminyApi
     */
    public getAktualniPredmetTerminElIndexV3(aktualniPredmetId: string, terminId: string, elIndexId: string, options?: AxiosRequestConfig) {
        return TerminyApiFp(this.configuration).getAktualniPredmetTerminElIndexV3(aktualniPredmetId, terminId, elIndexId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/termin/__termin_id__/mistnosti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Mistnosti k terminu
     * @param {string} aktualniPredmetId 
     * @param {string} terminId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminyApi
     */
    public getAktualniPredmetTerminMistnostiV3(aktualniPredmetId: string, terminId: string, options?: AxiosRequestConfig) {
        return TerminyApiFp(this.configuration).getAktualniPredmetTerminMistnostiV3(aktualniPredmetId, terminId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/termin/__termin_id__/mistnosti/v4\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam mistnosti na termin
     * @param {string} aktualniPredmetId 
     * @param {string} terminId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminyApi
     */
    public getAktualniPredmetTerminMistnostiV4(aktualniPredmetId: string, terminId: string, options?: AxiosRequestConfig) {
        return TerminyApiFp(this.configuration).getAktualniPredmetTerminMistnostiV4(aktualniPredmetId, terminId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/termin/__termin_id__/studenti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam studentu na terminu
     * @param {string} aktualniPredmetId 
     * @param {string} terminId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminyApi
     */
    public getAktualniPredmetTerminStudentiV3(aktualniPredmetId: string, terminId: string, options?: AxiosRequestConfig) {
        return TerminyApiFp(this.configuration).getAktualniPredmetTerminStudentiV3(aktualniPredmetId, terminId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/termin/__termin_id__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Editace terminu
     * @param {string} aktualniPredmetId 
     * @param {string} terminId 
     * @param {string} [patch] 
     * @param {string} [parametry] stejne jako u vlozeni terminu - vyjma povinneho ZKOUSKA_PROJEKT_ID
     * @param {string} [ten] tu neni...
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminyApi
     */
    public getAktualniPredmetTerminV3(aktualniPredmetId: string, terminId: string, patch?: string, parametry?: string, ten?: string, options?: AxiosRequestConfig) {
        return TerminyApiFp(this.configuration).getAktualniPredmetTerminV3(aktualniPredmetId, terminId, patch, parametry, ten, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/terminy/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam terminu (zkousky, obhajoby, ...) v predmetu
     * @param {string} aktualniPredmetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminyApi
     */
    public getAktualniPredmetTerminyV3(aktualniPredmetId: string, options?: AxiosRequestConfig) {
        return TerminyApiFp(this.configuration).getAktualniPredmetTerminyV3(aktualniPredmetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/termin/__termin_id__/mistnosti/v4\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam mistnosti na termin
     * @param {string} terminId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminyApi
     */
    public getTerminMistnostiV4(terminId: string, options?: AxiosRequestConfig) {
        return TerminyApiFp(this.configuration).getTerminMistnostiV4(terminId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Je nutne uvest pocet bodu a datum, aby se hodnoceni ulozilo. Pokud se pocet bodu a datum neuvede, tak se hodnoceni smaze.
     * @summary Zapis hodnoceni studenta na terminu
     * @param {number} aktualniPredmetId 
     * @param {number} terminId 
     * @param {number} zkouskaTerminId 
     * @param {number} elIndexId 
     * @param {PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request} patchAktualniPredmetTerminZkouskaTerminElIndexV4Request Request body content
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminyApi
     */
    public patchAktualniPredmetTerminZkouskaTerminElIndexV4(aktualniPredmetId: number, terminId: number, zkouskaTerminId: number, elIndexId: number, patchAktualniPredmetTerminZkouskaTerminElIndexV4Request: PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request, options?: AxiosRequestConfig) {
        return TerminyApiFp(this.configuration).patchAktualniPredmetTerminZkouskaTerminElIndexV4(aktualniPredmetId, terminId, zkouskaTerminId, elIndexId, patchAktualniPredmetTerminZkouskaTerminElIndexV4Request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TymyApi - axios parameter creator
 * @export
 */
export const TymyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/skupiny-tymu/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Skupiny tymu
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetSkupinyTymuV3: async (aktualniPredmetId: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetSkupinyTymuV3', 'aktualniPredmetId', aktualniPredmetId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/skupiny-tymu/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TymyApi - functional programming interface
 * @export
 */
export const TymyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TymyApiAxiosParamCreator(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/skupiny-tymu/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Skupiny tymu
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetSkupinyTymuV3(aktualniPredmetId: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetSkupinyTymuV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetSkupinyTymuV3(aktualniPredmetId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TymyApi - factory interface
 * @export
 */
export const TymyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TymyApiFp(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/skupiny-tymu/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Skupiny tymu
         * @param {string} aktualniPredmetId 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetSkupinyTymuV3(aktualniPredmetId: string, lang?: string, options?: any): AxiosPromise<GetAktualniPredmetSkupinyTymuV3200Response> {
            return localVarFp.getAktualniPredmetSkupinyTymuV3(aktualniPredmetId, lang, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TymyApi - object-oriented interface
 * @export
 * @class TymyApi
 * @extends {BaseAPI}
 */
export class TymyApi extends BaseAPI {
    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/skupiny-tymu/v3?lang=cs\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Skupiny tymu
     * @param {string} aktualniPredmetId 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TymyApi
     */
    public getAktualniPredmetSkupinyTymuV3(aktualniPredmetId: string, lang?: string, options?: AxiosRequestConfig) {
        return TymyApiFp(this.configuration).getAktualniPredmetSkupinyTymuV3(aktualniPredmetId, lang, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UvazkyApi - axios parameter creator
 * @export
 */
export const UvazkyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/uvazky/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Uvazky v predmetu
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetUvazkyV3: async (aktualniPredmetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetUvazkyV3', 'aktualniPredmetId', aktualniPredmetId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/uvazky/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UvazkyApi - functional programming interface
 * @export
 */
export const UvazkyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UvazkyApiAxiosParamCreator(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/uvazky/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Uvazky v predmetu
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetUvazkyV3(aktualniPredmetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetUvazkyV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetUvazkyV3(aktualniPredmetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UvazkyApi - factory interface
 * @export
 */
export const UvazkyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UvazkyApiFp(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/uvazky/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Uvazky v predmetu
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetUvazkyV3(aktualniPredmetId: string, options?: any): AxiosPromise<GetAktualniPredmetUvazkyV3200Response> {
            return localVarFp.getAktualniPredmetUvazkyV3(aktualniPredmetId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UvazkyApi - object-oriented interface
 * @export
 * @class UvazkyApi
 * @extends {BaseAPI}
 */
export class UvazkyApi extends BaseAPI {
    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/uvazky/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Uvazky v predmetu
     * @param {string} aktualniPredmetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UvazkyApi
     */
    public getAktualniPredmetUvazkyV3(aktualniPredmetId: string, options?: AxiosRequestConfig) {
        return UvazkyApiFp(this.configuration).getAktualniPredmetUvazkyV3(aktualniPredmetId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ZadaniApi - axios parameter creator
 * @export
 */
export const ZadaniApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Prihlaseni studenta na zadani
         * @param {number} aktualniPredmetId 
         * @param {number} zadaniId 
         * @param {number} elIndexId 
         * @param {CreateAktualniPredmetTerminZkouskaTerminElIndexV4Request} createAktualniPredmetTerminZkouskaTerminElIndexV4Request Request body content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAktualniPredmetZadaniElIndexV4: async (aktualniPredmetId: number, zadaniId: number, elIndexId: number, createAktualniPredmetTerminZkouskaTerminElIndexV4Request: CreateAktualniPredmetTerminZkouskaTerminElIndexV4Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('createAktualniPredmetZadaniElIndexV4', 'aktualniPredmetId', aktualniPredmetId)
            // verify required parameter 'zadaniId' is not null or undefined
            assertParamExists('createAktualniPredmetZadaniElIndexV4', 'zadaniId', zadaniId)
            // verify required parameter 'elIndexId' is not null or undefined
            assertParamExists('createAktualniPredmetZadaniElIndexV4', 'elIndexId', elIndexId)
            // verify required parameter 'createAktualniPredmetTerminZkouskaTerminElIndexV4Request' is not null or undefined
            assertParamExists('createAktualniPredmetZadaniElIndexV4', 'createAktualniPredmetTerminZkouskaTerminElIndexV4Request', createAktualniPredmetTerminZkouskaTerminElIndexV4Request)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/zadani/{zadani_id}/el_index/{el_index_id}/v4`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)))
                .replace(`{${"zadani_id"}}`, encodeURIComponent(String(zadaniId)))
                .replace(`{${"el_index_id"}}`, encodeURIComponent(String(elIndexId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAktualniPredmetTerminZkouskaTerminElIndexV4Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Odhlaseni studenta ze zadani
         * @param {number} aktualniPredmetId 
         * @param {number} zadaniId 
         * @param {number} elIndexId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAktualniPredmetZadaniElIndexV4: async (aktualniPredmetId: number, zadaniId: number, elIndexId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('deleteAktualniPredmetZadaniElIndexV4', 'aktualniPredmetId', aktualniPredmetId)
            // verify required parameter 'zadaniId' is not null or undefined
            assertParamExists('deleteAktualniPredmetZadaniElIndexV4', 'zadaniId', zadaniId)
            // verify required parameter 'elIndexId' is not null or undefined
            assertParamExists('deleteAktualniPredmetZadaniElIndexV4', 'elIndexId', elIndexId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/zadani/{zadani_id}/el_index/{el_index_id}/v4`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)))
                .replace(`{${"zadani_id"}}`, encodeURIComponent(String(zadaniId)))
                .replace(`{${"el_index_id"}}`, encodeURIComponent(String(elIndexId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/zadani/__zadani_id__/studenti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam studentu na zadani
         * @param {string} aktualniPredmetId 
         * @param {string} zadaniId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetZadaniStudentiV3: async (aktualniPredmetId: string, zadaniId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetZadaniStudentiV3', 'aktualniPredmetId', aktualniPredmetId)
            // verify required parameter 'zadaniId' is not null or undefined
            assertParamExists('getAktualniPredmetZadaniStudentiV3', 'zadaniId', zadaniId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/zadani/{zadani_id}/studenti/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)))
                .replace(`{${"zadani_id"}}`, encodeURIComponent(String(zadaniId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/zadani/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam zadani (napr. projektu) v predmetu
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetZadaniV3: async (aktualniPredmetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetZadaniV3', 'aktualniPredmetId', aktualniPredmetId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/zadani/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/zadani/__zadani_id__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail zadani
         * @param {string} aktualniPredmetId 
         * @param {string} zadaniId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetZadaniV31: async (aktualniPredmetId: string, zadaniId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetZadaniV31', 'aktualniPredmetId', aktualniPredmetId)
            // verify required parameter 'zadaniId' is not null or undefined
            assertParamExists('getAktualniPredmetZadaniV31', 'zadaniId', zadaniId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/zadani/{zadani_id}/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)))
                .replace(`{${"zadani_id"}}`, encodeURIComponent(String(zadaniId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Je nutne uvest pocet bodu a datum, aby se hodnoceni ulozilo. Pokud se pocet bodu a datum neuvede, tak se hodnoceni smaze.
         * @summary Zapis hodnoceni studenta na zadani
         * @param {number} aktualniPredmetId 
         * @param {number} zadaniId 
         * @param {number} elIndexId 
         * @param {PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request} patchAktualniPredmetTerminZkouskaTerminElIndexV4Request Request body content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAktualniPredmetZadaniElIndexV4: async (aktualniPredmetId: number, zadaniId: number, elIndexId: number, patchAktualniPredmetTerminZkouskaTerminElIndexV4Request: PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('patchAktualniPredmetZadaniElIndexV4', 'aktualniPredmetId', aktualniPredmetId)
            // verify required parameter 'zadaniId' is not null or undefined
            assertParamExists('patchAktualniPredmetZadaniElIndexV4', 'zadaniId', zadaniId)
            // verify required parameter 'elIndexId' is not null or undefined
            assertParamExists('patchAktualniPredmetZadaniElIndexV4', 'elIndexId', elIndexId)
            // verify required parameter 'patchAktualniPredmetTerminZkouskaTerminElIndexV4Request' is not null or undefined
            assertParamExists('patchAktualniPredmetZadaniElIndexV4', 'patchAktualniPredmetTerminZkouskaTerminElIndexV4Request', patchAktualniPredmetTerminZkouskaTerminElIndexV4Request)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/zadani/{zadani_id}/el_index/{el_index_id}/v4`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)))
                .replace(`{${"zadani_id"}}`, encodeURIComponent(String(zadaniId)))
                .replace(`{${"el_index_id"}}`, encodeURIComponent(String(elIndexId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchAktualniPredmetTerminZkouskaTerminElIndexV4Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ZadaniApi - functional programming interface
 * @export
 */
export const ZadaniApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ZadaniApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Prihlaseni studenta na zadani
         * @param {number} aktualniPredmetId 
         * @param {number} zadaniId 
         * @param {number} elIndexId 
         * @param {CreateAktualniPredmetTerminZkouskaTerminElIndexV4Request} createAktualniPredmetTerminZkouskaTerminElIndexV4Request Request body content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAktualniPredmetZadaniElIndexV4(aktualniPredmetId: number, zadaniId: number, elIndexId: number, createAktualniPredmetTerminZkouskaTerminElIndexV4Request: CreateAktualniPredmetTerminZkouskaTerminElIndexV4Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAktualniPredmetZadaniElIndexV4(aktualniPredmetId, zadaniId, elIndexId, createAktualniPredmetTerminZkouskaTerminElIndexV4Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Odhlaseni studenta ze zadani
         * @param {number} aktualniPredmetId 
         * @param {number} zadaniId 
         * @param {number} elIndexId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAktualniPredmetZadaniElIndexV4(aktualniPredmetId: number, zadaniId: number, elIndexId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAktualniPredmetZadaniElIndexV4(aktualniPredmetId, zadaniId, elIndexId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/zadani/__zadani_id__/studenti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam studentu na zadani
         * @param {string} aktualniPredmetId 
         * @param {string} zadaniId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetZadaniStudentiV3(aktualniPredmetId: string, zadaniId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetZadaniStudentiV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetZadaniStudentiV3(aktualniPredmetId, zadaniId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/zadani/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam zadani (napr. projektu) v predmetu
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetZadaniV3(aktualniPredmetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetZadaniV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetZadaniV3(aktualniPredmetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/zadani/__zadani_id__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail zadani
         * @param {string} aktualniPredmetId 
         * @param {string} zadaniId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetZadaniV31(aktualniPredmetId: string, zadaniId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetZadaniV31200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetZadaniV31(aktualniPredmetId, zadaniId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Je nutne uvest pocet bodu a datum, aby se hodnoceni ulozilo. Pokud se pocet bodu a datum neuvede, tak se hodnoceni smaze.
         * @summary Zapis hodnoceni studenta na zadani
         * @param {number} aktualniPredmetId 
         * @param {number} zadaniId 
         * @param {number} elIndexId 
         * @param {PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request} patchAktualniPredmetTerminZkouskaTerminElIndexV4Request Request body content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchAktualniPredmetZadaniElIndexV4(aktualniPredmetId: number, zadaniId: number, elIndexId: number, patchAktualniPredmetTerminZkouskaTerminElIndexV4Request: PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchAktualniPredmetZadaniElIndexV4(aktualniPredmetId, zadaniId, elIndexId, patchAktualniPredmetTerminZkouskaTerminElIndexV4Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ZadaniApi - factory interface
 * @export
 */
export const ZadaniApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ZadaniApiFp(configuration)
    return {
        /**
         * 
         * @summary Prihlaseni studenta na zadani
         * @param {number} aktualniPredmetId 
         * @param {number} zadaniId 
         * @param {number} elIndexId 
         * @param {CreateAktualniPredmetTerminZkouskaTerminElIndexV4Request} createAktualniPredmetTerminZkouskaTerminElIndexV4Request Request body content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAktualniPredmetZadaniElIndexV4(aktualniPredmetId: number, zadaniId: number, elIndexId: number, createAktualniPredmetTerminZkouskaTerminElIndexV4Request: CreateAktualniPredmetTerminZkouskaTerminElIndexV4Request, options?: any): AxiosPromise<any> {
            return localVarFp.createAktualniPredmetZadaniElIndexV4(aktualniPredmetId, zadaniId, elIndexId, createAktualniPredmetTerminZkouskaTerminElIndexV4Request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Odhlaseni studenta ze zadani
         * @param {number} aktualniPredmetId 
         * @param {number} zadaniId 
         * @param {number} elIndexId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAktualniPredmetZadaniElIndexV4(aktualniPredmetId: number, zadaniId: number, elIndexId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteAktualniPredmetZadaniElIndexV4(aktualniPredmetId, zadaniId, elIndexId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/zadani/__zadani_id__/studenti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam studentu na zadani
         * @param {string} aktualniPredmetId 
         * @param {string} zadaniId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetZadaniStudentiV3(aktualniPredmetId: string, zadaniId: string, options?: any): AxiosPromise<GetAktualniPredmetZadaniStudentiV3200Response> {
            return localVarFp.getAktualniPredmetZadaniStudentiV3(aktualniPredmetId, zadaniId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/zadani/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam zadani (napr. projektu) v predmetu
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetZadaniV3(aktualniPredmetId: string, options?: any): AxiosPromise<GetAktualniPredmetZadaniV3200Response> {
            return localVarFp.getAktualniPredmetZadaniV3(aktualniPredmetId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/zadani/__zadani_id__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Detail zadani
         * @param {string} aktualniPredmetId 
         * @param {string} zadaniId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetZadaniV31(aktualniPredmetId: string, zadaniId: string, options?: any): AxiosPromise<GetAktualniPredmetZadaniV31200Response> {
            return localVarFp.getAktualniPredmetZadaniV31(aktualniPredmetId, zadaniId, options).then((request) => request(axios, basePath));
        },
        /**
         * Je nutne uvest pocet bodu a datum, aby se hodnoceni ulozilo. Pokud se pocet bodu a datum neuvede, tak se hodnoceni smaze.
         * @summary Zapis hodnoceni studenta na zadani
         * @param {number} aktualniPredmetId 
         * @param {number} zadaniId 
         * @param {number} elIndexId 
         * @param {PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request} patchAktualniPredmetTerminZkouskaTerminElIndexV4Request Request body content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAktualniPredmetZadaniElIndexV4(aktualniPredmetId: number, zadaniId: number, elIndexId: number, patchAktualniPredmetTerminZkouskaTerminElIndexV4Request: PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request, options?: any): AxiosPromise<any> {
            return localVarFp.patchAktualniPredmetZadaniElIndexV4(aktualniPredmetId, zadaniId, elIndexId, patchAktualniPredmetTerminZkouskaTerminElIndexV4Request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ZadaniApi - object-oriented interface
 * @export
 * @class ZadaniApi
 * @extends {BaseAPI}
 */
export class ZadaniApi extends BaseAPI {
    /**
     * 
     * @summary Prihlaseni studenta na zadani
     * @param {number} aktualniPredmetId 
     * @param {number} zadaniId 
     * @param {number} elIndexId 
     * @param {CreateAktualniPredmetTerminZkouskaTerminElIndexV4Request} createAktualniPredmetTerminZkouskaTerminElIndexV4Request Request body content
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZadaniApi
     */
    public createAktualniPredmetZadaniElIndexV4(aktualniPredmetId: number, zadaniId: number, elIndexId: number, createAktualniPredmetTerminZkouskaTerminElIndexV4Request: CreateAktualniPredmetTerminZkouskaTerminElIndexV4Request, options?: AxiosRequestConfig) {
        return ZadaniApiFp(this.configuration).createAktualniPredmetZadaniElIndexV4(aktualniPredmetId, zadaniId, elIndexId, createAktualniPredmetTerminZkouskaTerminElIndexV4Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Odhlaseni studenta ze zadani
     * @param {number} aktualniPredmetId 
     * @param {number} zadaniId 
     * @param {number} elIndexId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZadaniApi
     */
    public deleteAktualniPredmetZadaniElIndexV4(aktualniPredmetId: number, zadaniId: number, elIndexId: number, options?: AxiosRequestConfig) {
        return ZadaniApiFp(this.configuration).deleteAktualniPredmetZadaniElIndexV4(aktualniPredmetId, zadaniId, elIndexId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/zadani/__zadani_id__/studenti/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam studentu na zadani
     * @param {string} aktualniPredmetId 
     * @param {string} zadaniId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZadaniApi
     */
    public getAktualniPredmetZadaniStudentiV3(aktualniPredmetId: string, zadaniId: string, options?: AxiosRequestConfig) {
        return ZadaniApiFp(this.configuration).getAktualniPredmetZadaniStudentiV3(aktualniPredmetId, zadaniId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/zadani/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam zadani (napr. projektu) v predmetu
     * @param {string} aktualniPredmetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZadaniApi
     */
    public getAktualniPredmetZadaniV3(aktualniPredmetId: string, options?: AxiosRequestConfig) {
        return ZadaniApiFp(this.configuration).getAktualniPredmetZadaniV3(aktualniPredmetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/zadani/__zadani_id__/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Detail zadani
     * @param {string} aktualniPredmetId 
     * @param {string} zadaniId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZadaniApi
     */
    public getAktualniPredmetZadaniV31(aktualniPredmetId: string, zadaniId: string, options?: AxiosRequestConfig) {
        return ZadaniApiFp(this.configuration).getAktualniPredmetZadaniV31(aktualniPredmetId, zadaniId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Je nutne uvest pocet bodu a datum, aby se hodnoceni ulozilo. Pokud se pocet bodu a datum neuvede, tak se hodnoceni smaze.
     * @summary Zapis hodnoceni studenta na zadani
     * @param {number} aktualniPredmetId 
     * @param {number} zadaniId 
     * @param {number} elIndexId 
     * @param {PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request} patchAktualniPredmetTerminZkouskaTerminElIndexV4Request Request body content
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZadaniApi
     */
    public patchAktualniPredmetZadaniElIndexV4(aktualniPredmetId: number, zadaniId: number, elIndexId: number, patchAktualniPredmetTerminZkouskaTerminElIndexV4Request: PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request, options?: AxiosRequestConfig) {
        return ZadaniApiFp(this.configuration).patchAktualniPredmetZadaniElIndexV4(aktualniPredmetId, zadaniId, elIndexId, patchAktualniPredmetTerminZkouskaTerminElIndexV4Request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ZkouskyProjektyApi - axios parameter creator
 * @export
 */
export const ZkouskyProjektyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/zkouska-projekt/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam \"zkousek, projektu\", pro ktere se vypisuji terminy nebo zadani
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetZkouskaProjektV3: async (aktualniPredmetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aktualniPredmetId' is not null or undefined
            assertParamExists('getAktualniPredmetZkouskaProjektV3', 'aktualniPredmetId', aktualniPredmetId)
            const localVarPath = `/api/fit/aktualni_predmet/{aktualni_predmet_id}/zkouska-projekt/v3`
                .replace(`{${"aktualni_predmet_id"}}`, encodeURIComponent(String(aktualniPredmetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ZkouskyProjektyApi - functional programming interface
 * @export
 */
export const ZkouskyProjektyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ZkouskyProjektyApiAxiosParamCreator(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/zkouska-projekt/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam \"zkousek, projektu\", pro ktere se vypisuji terminy nebo zadani
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAktualniPredmetZkouskaProjektV3(aktualniPredmetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAktualniPredmetZkouskaProjektV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAktualniPredmetZkouskaProjektV3(aktualniPredmetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ZkouskyProjektyApi - factory interface
 * @export
 */
export const ZkouskyProjektyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ZkouskyProjektyApiFp(configuration)
    return {
        /**
         *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/zkouska-projekt/v3\" target=\"_blank\">Adresa endpointu na API</a> 
         * @summary Seznam \"zkousek, projektu\", pro ktere se vypisuji terminy nebo zadani
         * @param {string} aktualniPredmetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAktualniPredmetZkouskaProjektV3(aktualniPredmetId: string, options?: any): AxiosPromise<GetAktualniPredmetZkouskaProjektV3200Response> {
            return localVarFp.getAktualniPredmetZkouskaProjektV3(aktualniPredmetId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ZkouskyProjektyApi - object-oriented interface
 * @export
 * @class ZkouskyProjektyApi
 * @extends {BaseAPI}
 */
export class ZkouskyProjektyApi extends BaseAPI {
    /**
     *  <a href=\"https://api.vut.cz/api/fit/aktualni_predmet/__aktualni_predmet_id__/zkouska-projekt/v3\" target=\"_blank\">Adresa endpointu na API</a> 
     * @summary Seznam \"zkousek, projektu\", pro ktere se vypisuji terminy nebo zadani
     * @param {string} aktualniPredmetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZkouskyProjektyApi
     */
    public getAktualniPredmetZkouskaProjektV3(aktualniPredmetId: string, options?: AxiosRequestConfig) {
        return ZkouskyProjektyApiFp(this.configuration).getAktualniPredmetZkouskaProjektV3(aktualniPredmetId, options).then((request) => request(this.axios, this.basePath));
    }
}


