import {
    DataGrid,
    GridActionsCellItem,
    GridActionsColDef,
    GridColDef,
    GridCsvExportOptions,
    GridRowParams,
    GridToolbarExport
} from "@mui/x-data-grid";
import {Box, Button, IconButton, Tooltip} from "@mui/material";
import {
    Add,
    Backspace,
    Check,
    Close,
    CloudDownload,
    DoneAll,
    Remove,
    Send,
    Settings,
    Star,
    StarOutline
} from "@mui/icons-material";
import React, {ReactElement, useCallback} from "react";
import {StudentGradingState} from "../helpers/taskGrading";
import {StudentGradingStateIcon} from "./StudentGradingStateIcon";
import {ICourseTaskSubtask} from "common/types/VutApi";
import {useUserPreferences, useUserPreferencesManager} from "common/hooks/useUserPreferences";
import ErrorBoundary from "./ErrorBoundary";

export interface IStudentEntry {
    per_id?: number;
    family_names?: string;
    first_names?: string;
    login?: string;
    el_index_id?: number;
    otazky?: ICourseTaskSubtask[];
    isPresent: boolean;
    isValid: boolean;
    points: number | null;
    pointsLessonCurrent: number | null;
    pointsLessonNew: number | null;
    pointsTotalCurrent: number | null;
    pointsTotalNew: number | null;
    gradingState: StudentGradingState;
}

export interface IStudentGradingTableToolbarProps {
    classId?: number;
    isLoading?: boolean;
    csvOptions?: GridCsvExportOptions;
    onImport?: () => void | undefined;
    onSubmit?: () => void | undefined;
    onConfigure?: () => void | undefined;
}

export interface ILabOverviewProps {
    lessonId?: number;
    classId?: number;
    students: IStudentEntry[];
    setPoints: (id: number, points: number | null) => void;
    error?: Error | null;
    isLoading?: boolean;
    toolbarProps?: IStudentGradingTableToolbarProps;
}

interface IMakeColumnDefsParams {
    setPoints: (id: number, points: number | null) => void;
}

const makeColumnDefs: (params: IMakeColumnDefsParams) => (GridColDef | GridActionsColDef)[]
    = ({setPoints}) => [
    {
        field: "per_id",
        headerName: "VUT ID",
        cellClassName: "private",
        width: 80,
        renderCell: params => <Box sx={{fontFamily: 'Monospace'}}>{params.value}</Box>,
    },
    {
        field: "login",
        headerName: "Login",
        type: "string",
        cellClassName: "private",
        width: 105,
        renderCell: params => <Box sx={{fontFamily: 'Monospace'}}>{params.value}</Box>,
    },
    {
        field: "family_names",
        headerName: "Family names",
        minWidth: 140,
    },
    {
        field: "first_names",
        headerName: "First names",
        minWidth: 120,
    },
    {
        field: "isPresent",
        headerName: "Present",
        type: "boolean",
        cellClassName: "private",
        width: 80,
        renderCell: params =>
            <Tooltip title={params.value ? "Student has logged in to a PC in the lab" : "Student login to any lab PC was not detected"}>
                {params.value
                    ? <Check sx={{color: "success.main"}} fontSize={"small"}/>
                    : <Close sx={{color: "error.main"}} fontSize={"small"}/>}
            </Tooltip>,
    },
    {
        field: "isValid",
        headerName: "Valid",
        type: "boolean",
        width: 80,
        renderCell: params =>
            <Tooltip title={params.value ? "Student can be graded" : "Student is not present in selected graded task"}>
                {params.value
                    ? <Check sx={{color: "success.main"}} fontSize={"small"}/>
                    : <Close sx={{color: "error.main"}} fontSize={"small"}/>}
            </Tooltip>,
    },
    {
        field: "pointsLessonNew",
        headerName: "Lesson",
        type: "number",
        cellClassName: "private",
        editable: true,
        width: 75,
        renderCell: params => {
            const valueChanged = params.row.gradingState !== StudentGradingState.NotGraded
                && params.row.gradingState !== StudentGradingState.Graded
                && params.row.gradingState !== StudentGradingState.Impossible;

            return <span style={{fontWeight: valueChanged ? "bold" : "normal"}}>
                {params.value ?? "A"}
            </span>;
        },
    },
    {
        field: "gradingState",
        headerName: "State",
        type: "string",
        headerAlign: "center",
        align: "center",
        renderCell: params => <StudentGradingStateIcon state={params.value}/>,
    },
    {
        field: "actions",
        type: "actions",
        minWidth: 120,
        getActions: ({id, row}: GridRowParams) => {
            const actions: ReactElement[] = [];
            const numericId = typeof id === "string" ? parseInt(id) : id;

            const handleAddPoints = () => setPoints(numericId, (row.pointsLessonNew ?? 0) + 1);
            const handleRemovePoints = () => setPoints(numericId, Math.max((row.pointsLessonNew ?? 0) - 1, 0));
            const handleClearPoints = () => setPoints(numericId, null);

            actions.push(
                <Tooltip title={"Add 1 point"}>
                    <GridActionsCellItem icon={<Add/>} onClick={handleAddPoints} sx={{color: "success.main"}}
                                         label="Add 1 point"/>
                </Tooltip>
            );
            actions.push(
                <Tooltip title={"Remove 1 point"}>
                    <GridActionsCellItem icon={<Remove/>} onClick={handleRemovePoints} sx={{color: "error.main"}}
                                         disabled={row.pointsLessonNew === 0}
                                         label="Remove 1 point"/>
                </Tooltip>
            );
            actions.push(
                <Tooltip title={"Clear points"}>
                    <GridActionsCellItem icon={<Backspace/>} onClick={handleClearPoints} label="Clear"
                                         disabled={row.pointsLessonNew === null}/>
                </Tooltip>
            );

            //actions.push(<GridActionsCellItem label="Print" showInMenu/>);
            return actions;
        },
    }
];

function CustomToolbar({
                           classId,
                           isLoading,
                           csvOptions,
                           onImport,
                           onSubmit,
                           onConfigure
                       }: IStudentGradingTableToolbarProps) {
    const preferences = useUserPreferences();
    const preferencesManager = useUserPreferencesManager();
    const isFavourite = preferences.favouriteClasses.indexOf(classId!) >= 0;

    function handleToggleFavourite() {
        if (isFavourite) {
            preferencesManager.removeFavouriteClass(classId!);
        } else if (classId) {
            preferencesManager.addFavouriteClass(classId);
        }
    }

    return (
        <Box style={{display: "flex", justifyContent: "space-between"}}>
            <div>
                <Button size={"small"} endIcon={<DoneAll/>} disabled>Present Done</Button>
                <Tooltip
                    title={onImport ? "Load points from IS VUT (overwrites local data)" : "Import unavailable, please configure this lesson first"}>
                    <span>
                    <Button size={"small"} endIcon={<CloudDownload/>}
                            onClick={onImport} disabled={isLoading || !onImport}>
                        Import Points
                    </Button>
                    </span>
                </Tooltip>
            </div>
            <div>
                <Tooltip title={!isFavourite
                    ? "Save this class in favourites (all lessons)"
                    : "Remove this class from favourites (all lessons)"}
                >
                    <IconButton onClick={handleToggleFavourite} color={"primary"} disabled={!classId}>
                        {!isFavourite && <StarOutline fontSize="small"/>}
                        {isFavourite && <Star fontSize="small"/>}
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Open settings for this lesson"}>
                    <Button size={"small"} startIcon={<Settings/>}
                            onClick={onConfigure} disabled={isLoading || !onConfigure}>
                        Configure
                    </Button>
                </Tooltip>
                <Tooltip title={"Export table data"}>
                    <GridToolbarExport csvOptions={{...csvOptions, fileName: `class-${classId}-${Date.now()}.csv`}}/>
                </Tooltip>
                <Tooltip title={"Open IS VUT submission wizard"}>
                    <Button size={"small"} startIcon={<Send/>} onClick={onSubmit} disabled={isLoading || !onSubmit}>
                        Submit
                    </Button>
                </Tooltip>
            </div>
        </Box>
    )
}

export function ClassGradingTable({
                                      classId,
                                      isLoading,
                                      error,
                                      students,
                                      setPoints,
                                      toolbarProps = {}
                                  }: ILabOverviewProps) {
    const getRowId = useCallback((row: IStudentEntry) => row.per_id!, []);
    const {privacyMode} = useUserPreferences();

    function handleRowUpdate(oldRow: IStudentEntry, newRow: IStudentEntry): IStudentEntry {
        if (oldRow.pointsLessonNew !== newRow.pointsLessonNew) {
            setPoints(oldRow.per_id!, newRow.pointsLessonNew);
        }

        return newRow;
    }

    const slotProps = {
        toolbar: {
            isLoading: isLoading,
            classId: classId,
            ...toolbarProps
        }
    };

    return (
        <div style={{height: '100%'}} className={privacyMode ? "privacyModeOn" : ""}>
            <ErrorBoundary error={error}>
                <DataGrid
                    density={"compact"}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                    slotProps={slotProps}
                    columns={makeColumnDefs({setPoints: setPoints})}
                    rows={students}
                    loading={isLoading}
                    getRowId={getRowId}
                    processRowUpdate={handleRowUpdate}
                    checkboxSelection
                />
            </ErrorBoundary>
        </div>
    );
}
