import {useQuery} from "@tanstack/react-query";
import {getLessonsForCurrentCourse} from "common/data/getLessonsForCurrentCourse";
import {decorateCourseLessonsWithLessonTeachers} from "common/data/decorateCourseLessons";
import {useVutApiSession} from "contexts/VutApiSession";
import {Alert, AlertTitle, Box, LinearProgress} from "@mui/material";
import {useNavigate} from "react-router-dom";
import React from "react";
import {ErrorAlert} from "common/components/ErrorAlert";
import {VutApi} from "common/helpers/VutApi";
import {flatAwait} from "common/helpers/promises";
import {getCoursesForPerson} from "common/data/getCoursesForPerson";
import {ClassLessonTable} from "../common/components/ClassLessonTable";
import {PageContentContainer} from "../common/components/PageContentContainer";
import {useDocumentTitle} from "../common/hooks/useDocumentTitle";
import {useForceLoginOn401} from "../common/hooks/useForceLoginOn401";
import {useLessonFilterByPerson} from "../common/hooks/useLessonFilterByPerson";
import {useLessonFilterByDate} from "../common/hooks/useLessonFilterByDate";


export interface ICurrentPersonCurrentClassesLoader {
    personId?: number | undefined,
    dateAt?: Date | undefined,
}

export function PersonClassesLoaderPage({personId, dateAt}: ICurrentPersonCurrentClassesLoader) {
    useDocumentTitle("FIT LOKI - Current classes");
    const vutSession = useVutApiSession();
    const api = new VutApi(vutSession)
    const osobyApi = api.osoby()
    const rozvrhApi = api.rozvrh()
    const navigate = useNavigate();

    const personIdFinal = personId ?? vutSession.per_id ?? 0;
    const filterByDate = useLessonFilterByDate(dateAt ?? new Date(Date.now()), false);
    const filterByPerson = useLessonFilterByPerson(personIdFinal, false);

    const {data, isError, error, isLoading} = useQuery([personIdFinal, "vyuka"], async () => {
        const vyucovanePredmety = await getCoursesForPerson(osobyApi, personIdFinal);

        const relevantniVyuka = await flatAwait(vyucovanePredmety.map(
            p => getLessonsForCurrentCourse(rozvrhApi, p.aktualni_predmet_id!)
                .then(vyuka => vyuka.filter(filterByDate))
                .then(vyuka => decorateCourseLessonsWithLessonTeachers(rozvrhApi, vyuka))
                .then(vyuka => vyuka.filter(filterByPerson))
        ));

        const aktualniVyuka = relevantniVyuka[0];
        if (aktualniVyuka) {
            const courseId = aktualniVyuka.vyuka.v_aktualni_predmet_id;
            const classId = aktualniVyuka.vyuka.v_vyucovani_id;
            const lessonid = aktualniVyuka.id;
            navigate(`/course/${courseId}/class/${classId}/lesson/${lessonid}`);
        }

        return [];
    });

    useForceLoginOn401(error as Error);

    return (
        <div>
            {isLoading && <LinearProgress/>}
            <PageContentContainer>
                <ErrorAlert error={error as Error} isError={isError}/>
                {!isLoading && data?.length === 0 &&
                    <Alert severity={"info"} sx={{mb: 2}}>
                        <AlertTitle>No currently running lesson found</AlertTitle>
                        <p>
                            No current lesson could be found for the current user account.
                            Most likely there are no running lessons at the moment.
                            Please try again later.
                        </p>
                        <p style={{marginBottom: 0}}>
                            For now, you can view the lessons that are scheduled for the future in the table below.
                            If you think this is an error, please contact the administrator.
                        </p>
                    </Alert>}
                <Box sx={{display: "flex", flexGrow: 1, maxHeight: "100%"}}>
                    {personIdFinal === vutSession.per_id && !isLoading
                        && <ClassLessonTable favouriteOnly futureOnly ownOnly/>}
                </Box>
            </PageContentContainer>
        </div>
    );
}
