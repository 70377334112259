import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import {VutApiSessionContext} from "contexts/VutApiSession";

export function useLogout() {
    const navigate = useNavigate();
    const {setSession} = useContext(VutApiSessionContext);

    return () => {
        setSession({});
        navigate("/sign-in");
    }
}