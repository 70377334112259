import React from "react";
import {Route, Routes} from "react-router-dom";
import {PersonClassesLoaderPage} from "pages/PersonClassesLoaderPage";
import {LessonsBrowsePage} from "pages/LessonsBrowsePage";

export function PersonClassesPageRoutes() {
    return (
        <Routes>
            <Route
                path={"/"}
                element={<PersonClassesLoaderPage />}
            />
            <Route
                path={"/person/:osobaId"}
                action={({params}) => <PersonClassesLoaderPage personId={parseInt(params.osobaId!)}/>}
            />
            <Route
                path={"/when/:kdy"}
                action={({params}) => {
                    const dateAt = new Date(params.kdy!);
                    return <PersonClassesLoaderPage dateAt={dateAt}/>
                }}
            />
            <Route
                path={"/person/:osobaId/when/:kdy"}
                action={({params}) => {
                    const dateAt = new Date(params.kdy!);
                    return <PersonClassesLoaderPage personId={parseInt(params.osobaId!)} dateAt={dateAt}/>
                }}
            />
            <Route
                path={"/favourite"}
                element={<LessonsBrowsePage favouriteOnly/>}
            />
            <Route
                path={"/browse"}
                element={<LessonsBrowsePage/>}
                />
        </Routes>
    );
}