import {useVutApiSession} from "contexts/VutApiSession";
import {
    AppBar,
    Box,
    Button,
    Container,
    Hidden,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem, styled,
    Toolbar,
    Typography
} from "@mui/material";
import {Link} from "react-router-dom";
import React, {useContext, useState} from "react";
import {Logout, MenuOpen, Menu as MenuDefault} from "@mui/icons-material";
import {Breakpoint} from "@mui/system";
import {useLogout} from "../common/hooks/useLogout";
import {MainNavContext} from "contexts/MainNavContext";

import {LoggedInAccountAvatar} from "../common/components/LoggedInAccountAvatar";
import {DrawerWidth} from "core/MainNav";

const SideMenuSpacer = styled('div', {shouldForwardProp: (prop) => prop !== 'open'})<{
    open?: boolean;
}>(({theme, open}) => ({
    width: `${open ? `${DrawerWidth}px` : theme.spacing(8)}`,
    [theme.breakpoints.down('md')]: {
        display: "none",
    },
}));

export interface IMainMenuProps {
    maxWidthInner?: Breakpoint | false;
}

export function MainMenu({maxWidthInner = "xl"}: IMainMenuProps) {
    const {desktopOpen, mobileOpen, setMobileOpen} = useContext(MainNavContext);

    const vutSession = useVutApiSession();
    const [menuAnchorElement, setMenuAnchorElement] = useState<null | HTMLElement>(null);

    const handleAccountMenuOpen = (e: React.MouseEvent<HTMLElement>) => setMenuAnchorElement(e.currentTarget);
    const handleAccountMenuClose = () => setMenuAnchorElement(null);

    const isMenuOpen = Boolean(menuAnchorElement);
    const menuId = "account-menu";

    const logInSubmenu = (
        <>
            <Button component={Link} to={"/sign-in"} sx={{color: "white", mx: ".5em"}}>
                Sign in
            </Button>
        </>
    );

    const logout = useLogout();
    const handleLogout = () => {
        logout();
        handleAccountMenuClose();
    }

    const handleMobileNavToggle = () => {
        setMobileOpen(!mobileOpen);
    }

    const accountDropdownMenu = (
        <Menu
            anchorEl={menuAnchorElement}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={isMenuOpen}
            onClose={handleAccountMenuClose}
        >
            <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                    <Logout fontSize="small"/>
                </ListItemIcon>
                <ListItemText>Sign out</ListItemText>
            </MenuItem>
        </Menu>
    );

    const accountSubmenu = (
        <>
            <Hidden smDown>
                <Typography variant={"overline"} textAlign="center" component={"a"} sx={{mx: "1em", lineHeight: "1.2"}}>
                    {vutSession.per_label}
                </Typography>
            </Hidden>
            <IconButton onClick={handleAccountMenuOpen}>
                <LoggedInAccountAvatar/>
            </IconButton>
            {accountDropdownMenu}
        </>
    );

    return (
        <AppBar position={"fixed"} sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
            <Toolbar variant={"dense"} sx={{padding: "0 !important"}}>
                <SideMenuSpacer open={desktopOpen}/>
                <Container maxWidth={"xl"} sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <Box sx={{display: "flex", alignItems: "center"}}>
                        <Hidden mdUp>
                            <Box style={{
                                display: "flex",
                                alignItems: "center",
                                width: "48px"
                            }}>
                                <IconButton onClick={handleMobileNavToggle} sx={{color: "white"}}>
                                    {mobileOpen ? <MenuOpen/> : <MenuDefault/>}
                                </IconButton>
                            </Box>
                        </Hidden>
                        <Typography variant="h6" component={Link} to={"/"}
                                    sx={{textDecoration: "none", color: "white"}}>
                            LOKI
                            <Typography variant={"caption"}
                                        sx={{marginLeft: ".35em", color: "rgba(255, 255, 255, 0.5)"}} gutterBottom>
                                {process.env.REACT_APP_VERSION}
                            </Typography>
                        </Typography>
                    </Box>
                    <Box>
                        {vutSession.per_id ? accountSubmenu : logInSubmenu}
                    </Box>
                </Container>
            </Toolbar>
        </AppBar>
    );
}
