import {Alert} from "@mui/material";
import {ReactNode} from "react";

interface IQueryException {
    getAlert(): ReactNode;
}

export abstract class QueryExceptionBase extends Error implements IQueryException
{
    public getAlert() {
        return <Alert severity={"error"} sx={{my: "1em"}}>{this.message}</Alert>
    }
}

export class QueryInformationException extends QueryExceptionBase
{
    public getAlert() {
        return <Alert severity={"info"} sx={{my: "1em"}}>{this.message}</Alert>
    }
}

export class QueryWarningException extends QueryExceptionBase
{
    public getAlert() {
        return <Alert severity={"warning"} sx={{my: "1em"}}>{this.message}</Alert>
    }
}

export class QueryException extends QueryExceptionBase {}
