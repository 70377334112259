import React, {useCallback, useState} from "react";
import {PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request} from "libs/vut-api";
import {
    Alert,
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Step,
    StepButton,
    Stepper,
    Tooltip
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useMutation} from "@tanstack/react-query";
import {IStudentEntry} from "common/components/ClassGradingTable";
import {DataGrid, GridActionsColDef, GridColDef, GridRowId} from "@mui/x-data-grid";
import {ICourseLesson} from "common/data/getLessonsForCurrentCourse";
import {StudentGradingState} from "../common/helpers/taskGrading";
import {ICourseTaskWithExamInfo} from "common/types/VutApi";
import Grid from "@mui/material/Unstable_Grid2";
import {CourseTaskAutocomplete} from "common/components/CourseTaskAutocomplete";
import {StudentGradingStateIcon} from "common/components/StudentGradingStateIcon";
import {useVutApiSession} from "contexts/VutApiSession";
import {VutApi} from "common/helpers/VutApi";
import {ClassLessonNumberInput} from "dialogs/ClassLessonNumberInput";
import {CourseClassLessonDescription} from "../common/components/CourseClassLessonDescription";
import {Check, Close} from "@mui/icons-material";


export interface ISubmitLessonGradesDialogProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    students: IStudentEntry[];
    setStudents: (students: IStudentEntry[]) => void;
    lessonNumber: number | undefined;
    setLessonNumber: (lessonNumber: number | undefined) => void;
    task: ICourseTaskWithExamInfo | undefined;
    setTask: (task: ICourseTaskWithExamInfo | undefined) => void;
    onSubmit: () => Promise<void>;
    lesson: ICourseLesson;
}

const reviewColumns: (GridColDef | GridActionsColDef)[] = [
    {
        field: "per_id",
        headerName: "VUT ID",
        cellClassName: "private",
        width: 80,
        renderCell: params => <Box sx={{fontFamily: 'Monospace'}}>{params.value}</Box>,
    },
    {
        field: "family_names",
        headerName: "Family names",
        minWidth: 140,
    },
    {
        field: "first_names",
        headerName: "First names",
        minWidth: 120,
    },
    {
        field: "isPresent",
        headerName: "Present",
        description: "Shows whether the student actually logged in to a computer during the lesson",
        type: "boolean",
        cellClassName: "private",
        width: 80,
        renderCell: params =>
            <Tooltip
                title={params.value ? "Student has logged in to a PC in lab. during this class" : "Student login to any lab PC was not detected"}>
                {params.value
                    ? <Check sx={{color: "success.main"}} fontSize={"small"}/>
                    : <Close sx={{color: "error.main"}}/>}
            </Tooltip>,
    },
    {
        field: "isValid",
        headerName: "Valid",
        description: "Shows whether the lesson student is actually registered to the graded task",
        type: "boolean",
        width: 80,
        renderCell: params =>
            <Tooltip title={params.value ? "Student can be graded" : "Student is not present in selected graded task"}>
                {params.value
                    ? <Check sx={{color: "success.main"}} fontSize={"small"}/>
                    : <Close sx={{color: "error.main"}}/>}
            </Tooltip>,
    },
    {
        field: "pointsTotalCurrent",
        headerName: "Sum",
        description: "Student's current summary grading in this task",
        type: "number",
        cellClassName: "private",
        width: 75,
    },
    {
        field: "pointsLessonCurrent",
        headerName: "Current",
        description: "Student's current lesson grading",
        type: "number",
        cellClassName: "private",
        width: 75,
        renderCell: params => params.value ?? "A",
    },
    {
        field: "pointsLessonNew",
        headerName: "New",
        description: "Student's new lesson grading (after submission)",
        type: "number",
        cellClassName: "private",
        width: 75,
        renderCell: params => {
            const valueChanged = params.row.gradingState !== StudentGradingState.NotGraded
                && params.row.gradingState !== StudentGradingState.Graded;
            const gradingPossible = params.row.gradingState !== StudentGradingState.Impossible;

            return <Box component={"span"} sx={{
                fontWeight: valueChanged && gradingPossible ? "bold" : "normal",
                color: !gradingPossible ? "error.main" : ""
            }}>
                {params.value ?? "A"}
            </Box>;
        },
    },
    {
        field: "pointsTotalNew",
        headerName: "New Sum",
        description: "Student's new summary grading in this task (after submission)",
        type: "number",
        cellClassName: "private",
        width: 75,
    },
    {
        field: "gradingState",
        headerName: "State",
        type: "string",
        headerAlign: "center",
        align: "center",
        renderCell: params => <StudentGradingStateIcon state={params.value}/>,
    },
];

export function SubmitLessonGradesDialog({
                                             isOpen, setIsOpen,
                                             students, setStudents,
                                             lessonNumber, setLessonNumber,
                                             task, setTask,
                                             onSubmit,
                                             lesson,
                                         }: ISubmitLessonGradesDialogProps) {
    const vutSession = useVutApiSession();
    const api = new VutApi(vutSession)
    const zadaniApi = api.zadani()

    const [error] = useState<Error | undefined>();
    const [stepCurrent, setStepCurrent] = useState<number>(0);
    const [canSubmit, setCanSubmit] = useState<Boolean>(false);

    const getRowId = (row: IStudentEntry): GridRowId => row.per_id!;
    const aktualniPredmetId = lesson.vyuka.v_aktualni_predmet_id!;

    const handleClose = async () => {
        if (isSubmitted) {
            await onSubmit();
        }
        setIsOpen(false);
        setCanSubmit(false);
        setStepCurrent(0);
        reset();
    }

    const date = new Date(Date.now());
    const requestContentDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    const createRequestContent = useCallback((s: IStudentEntry): PatchAktualniPredmetTerminZkouskaTerminElIndexV4Request => {
        let subTaskValues = {};
        if (task?.pocet_otazek) {
            subTaskValues = {
                ...s.otazky?.map(otazka => ({
                    [`otazka_${otazka.otazka_poradi}`]: otazka.otazka_pocet_bodu,
                })).reduce((previous, current) => ({...previous, ...current}), {}),
                [`otazka_${lessonNumber}`]: s.pointsLessonNew,
                pocet_bodu: s.pointsTotalNew!,
            }
        }

        return {
            datum: requestContentDate,
            pocet_bodu: s.pointsLessonNew!,
            poznamky: `graded by: ${vutSession.per_id}@fit-loki v${process.env.REACT_APP_VERSION}`,
            ...subTaskValues,
        };
    }, [lessonNumber, requestContentDate, task?.pocet_otazek, vutSession.per_id]);

    const sendGradingUpdate = useCallback(async (s: IStudentEntry) => {
        const requestContent = createRequestContent(s)
        // console.log(s, aktualniPredmetId, task!.zadani_id!, s.el_index_id!, requestContent, zadaniApi);
        // return new Promise(r => setTimeout(r, Math.random() * 4000))
        return await zadaniApi.patchAktualniPredmetZadaniElIndexV4(aktualniPredmetId, task!.zadani_id!, s.el_index_id!, requestContent);
    }, [aktualniPredmetId, createRequestContent, task, zadaniApi]);

    const {
        mutate,
        reset,
        isLoading: isSubmitting,
        isSuccess: isSubmitted,
    } = useMutation([aktualniPredmetId, "zadani", task?.zadani_id, "submit"], async () => {
        let localStudents = [...students];
        const changeStudent = (rowId: GridRowId, row: IStudentEntry) => setStudents(localStudents = localStudents.map(ls => getRowId(ls) === rowId ? row : ls));
        const gradingPromises = students
            .map(student => {
                if (student.gradingState !== StudentGradingState.NotGraded && student.gradingState !== StudentGradingState.Impossible) {
                    changeStudent(getRowId(student), {...student, gradingState: StudentGradingState.Processing});

                    return sendGradingUpdate(student)
                        .then(() => {
                            changeStudent(getRowId(student), {
                                ...student,
                                pointsLessonCurrent: student.pointsLessonNew,
                                pointsTotalCurrent: student.pointsTotalNew,
                                gradingState: StudentGradingState.Graded,
                            });
                        })
                        .catch(error => {
                            changeStudent(getRowId(student), {...student, gradingState: StudentGradingState.Failed});
                            console.error(student, error);
                        })
                }

                return null;
            })
            .filter(promise => promise !== null)

        return await Promise.all(gradingPromises);
    });

    const handleSubmit = () => {
        mutate();
    }

    const handleNextStep = () => {
        setStepCurrent(stepCurrent + 1);
        if (stepCurrent === steps.length - 2) {
            setCanSubmit(students.some(s => s.gradingState !== StudentGradingState.NotGraded
                && s.gradingState !== StudentGradingState.Impossible));
        }
    }

    const handleSelectStep = (id: number) => () => {
        setStepCurrent(id);
        if (id < steps.length - 1) {
            reset();
            setCanSubmit(false);
        }
    }

    const steps = [
        {
            stepId: 0,
            title: "Select task",
        },
        {
            stepId: 1,
            title: "Review submission",
        },
        {
            stepId: 2,
            title: "Submit data",
        },
    ];

    const setupStep = (
        <Container sx={{overflow: "hidden"}}>
            <CourseClassLessonDescription lesson={lesson} includeClassDescription/>
            <Grid container columns={8} spacing={4}>
                <Grid md={6} xs={8}>
                    <CourseTaskAutocomplete currentCourseId={aktualniPredmetId} setTask={setTask} task={task}/>
                </Grid>
                <Grid md={2} xs={8}>
                    <ClassLessonNumberInput value={lessonNumber} setValue={setLessonNumber}
                                            subTaskCount={task?.pocet_otazek}/>
                </Grid>
            </Grid>
        </Container>

    );

    const reviewStep = (
        <Box>
            <DataGrid
                columns={reviewColumns}
                rows={students}
                getRowId={getRowId}
                density={"compact"}
                autoHeight
            />
        </Box>
    );

    const submitStep = (
        <Box>
            <DataGrid
                columns={reviewColumns}
                rows={students.filter(s => s.gradingState !== StudentGradingState.NotGraded && s.gradingState !== StudentGradingState.Impossible)}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            per_id: false,
                        },
                    },
                }}
                getRowId={getRowId}
                density={"compact"}
                autoHeight
            />
        </Box>
    );

    const stepElements = [
        setupStep,
        reviewStep,
        submitStep,
        <Box>
            FINALIZE
        </Box>,
    ];

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={"lg"}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DialogTitle>Submit grades to IS VUT</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {error && <Alert severity="error">{error?.message}</Alert>}
                    </DialogContentText>
                    <Stepper activeStep={stepCurrent} sx={{mt: "1em", mb: "2em"}}>
                        {steps.map(({stepId, title}) => (
                            <Step key={stepId} completed={stepId < stepCurrent}>
                                <StepButton onClick={handleSelectStep(stepId)}>
                                    {title}
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                    <Box sx={{maxHeight: "60vh", overflow: "scroll"}}>
                        {stepElements[stepCurrent]}
                    </Box>
                </DialogContent>
                <DialogActions>
                    {stepCurrent < stepElements.length - 2 &&
                        <Button disabled={!task || (!lessonNumber && !!task?.pocet_otazek)}
                                onClick={handleNextStep}>Continue</Button>}
                    <Button onClick={handleClose}>Close</Button>
                    {!isSubmitted && canSubmit && <LoadingButton
                        disabled={stepCurrent < stepElements.length - 2}
                        loading={isSubmitting}
                        loadingIndicator="Submitting…"
                        onClick={handleSubmit}
                        color={"success"}
                    >
                        Submit
                    </LoadingButton>}
                </DialogActions>
            </Dialog>
        </div>
    );
}