import React, {ErrorInfo} from "react";
import {Alert, AlertTitle} from "@mui/material";

interface ErrorBoundaryProps {
    fallback?: (error: Error) => React.ReactNode;
    error?: Error | null;
}

interface ErrorBoundaryState {
    error?: Error | null;
}

export default class ErrorBoundary extends React.Component<React.PropsWithChildren<ErrorBoundaryProps>, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            error: props.error,
        };
    }

    static getDerivedStateFromError(error: Error): Partial<ErrorBoundaryState> {
        return {
            error: error
        };
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        console.error(error, info);
    }

    render() {
        if (this.state.error) {
            // You can render any custom fallback UI
            if (this.props.fallback) {
                return this.props.fallback(this.state.error);
            }

            return <Alert severity="error">
                <AlertTitle>An {this.state.error.name ?? "error"} occurred</AlertTitle>
                {this.state.error.message}
            </Alert>;
        }

        return this.props.children;
    }
}
