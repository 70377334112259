import React from 'react';
import AppConfigurator from "core/AppConfigurator";
import AppRouter from "core/AppRouter";

function App() {
    return (
        <AppConfigurator>
            <AppRouter/>
        </AppConfigurator>
    );
}

export default App;
