import {useVutApiSession} from "contexts/VutApiSession";
import {useQuery} from "@tanstack/react-query";
import {VutApi} from "../helpers/VutApi";
import {Avatar} from "@mui/material";
import React from "react";

export function LoggedInAccountAvatar() {
    const vutSession = useVutApiSession();

    const {data: vutPhoto} = useQuery(["person", vutSession.per_id, "photo"], async () => {
        const vutApi = new VutApi(vutSession);
        return await vutApi.getVutPhoto(vutSession.per_id!);
    }, {enabled: Boolean(vutSession.per_id), staleTime: 7 * 24 * 60 * 60 * 1000});

    return <Avatar src={vutPhoto} alt={vutSession.per_label_pr} sx={{fontSize: ".5em", width: 24, height: 24}}>
        {vutSession.per_label_pr?.split(" ")[0][0]}
        {vutSession.per_label_pr?.split(" ")[1][0]}
    </Avatar>;
}