import ErrorBoundary from "../common/components/ErrorBoundary";
import {ErrorPage} from "pages/ErrorPage";
import {Route, Routes} from "react-router-dom";
import {PersonClassesPageRoutes} from "pages/PersonClassesPageRoutes";
import {LessonsBrowsePage} from "pages/LessonsBrowsePage";
import LessonOverviewPage from "pages/LessonOverviewPage";
import {LogInPage} from "pages/LogInPage";
import {HomePage} from "pages/HomePage";
import React, {useContext} from "react";
import {styled} from "@mui/material";
import {MainNavContext} from "contexts/MainNavContext";
import {DrawerWidth} from "./MainNav";


const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})<{
    open?: boolean;
}>(({theme, open}) => ({
    flexGrow: 1,
    marginTop: "40px",
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `calc(${theme.spacing(8)} - ${DrawerWidth}px)`,
    width: `calc(100vw - ${open ? `${DrawerWidth}px` : theme.spacing(8)})`,
    [theme.breakpoints.down('md')]: {
        marginLeft: 0,
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    ...(open && {
        marginLeft: 0,
    }),
}));

export function MainContent() {
    const {desktopOpen} = useContext(MainNavContext);

    return <Main open={desktopOpen}>
        <ErrorBoundary fallback={error => <ErrorPage error={error}/>}>
            <Routes>
                <Route
                    path={"/teaching/*"}
                    element={<PersonClassesPageRoutes/>}
                />
                <Route
                    path={"/browse"}
                    element={<LessonsBrowsePage/>}
                />
                <Route
                    path={"/course/:aktualniPredmetId/class/:vyucovaniId/lesson/:vyucBlokDenId"}
                    element={<LessonOverviewPage/>}
                />
                <Route
                    path={"/sign-in"}
                    element={<LogInPage/>}
                />
                <Route
                    path={"*"}
                    element={<HomePage/>}
                />
            </Routes>
        </ErrorBoundary>
    </Main>;
}