import {Box, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import {useDocumentTitle} from "../common/hooks/useDocumentTitle";
import {PageContentContainer} from "../common/components/PageContentContainer";

export function HomePage() {
    useDocumentTitle("FIT LOKI");

    return (
        <PageContentContainer>
            <Typography variant={"h1"} sx={{textAlign: "center"}}>L<small style={{fontSize: 28}}>ab</small>O<small
                style={{fontSize: 28}}>pen</small>K<small style={{fontSize: 28}}>nowledge</small>I<small
                style={{fontSize: 28}}>nterface</small></Typography>
            <Grid container columns={6} sx={{my: "2em", alignItems: "center"}}>
                <Grid md={3} xs={6}>
                    <Box sx={{boxShadow: 3, borderRadius: "8px", overflow: "hidden"}}>
                        <img alt={"Automated grading submission to central IS"}
                             src={"screenshot/classroom_seating.png"}
                             style={{width: "100%", marginBottom: "-8px"}}/>
                    </Box>
                </Grid>
                <Grid md={3} xs={6} sx={{
                    padding: "1em",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-end"
                }}>
                    <Typography variant={"h3"}>Live seating map</Typography>
                    <Typography variant={"body1"} sx={{textAlign: "right"}}>
                        Grading students and validating their presence in a classroom is a breeze with live seating map.
                        Just click on a student to grade them instantly.
                        Talking to them is also enhanced, as you can see their name and photo.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container columns={6} sx={{my: "2em", alignItems: "center"}}>
                <Grid md={3} xs={6}
                       sx={{padding: "1em", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    <Typography variant={"h3"}>Individual grading</Typography>
                    <Typography variant={"body1"}>
                        Overview of all students and their current grades in the lesson is available at a glance.
                        Filtering and sorting is available to help you find the student you are looking for.
                    </Typography>
                </Grid>
                <Grid md={3} xs={6}>
                    <Box sx={{boxShadow: 3, borderRadius: "8px", overflow: "hidden"}}>
                        <img alt={"Automated grading submission to central IS"}
                             src={"screenshot/lesson_overview.png"}
                             style={{width: "100%", marginBottom: "-8px"}}/>
                    </Box>
                </Grid>
            </Grid>
            <Grid container columns={6} sx={{my: "2em", alignItems: "center"}}>
                <Grid md={3} xs={6}>
                    <Box sx={{boxShadow: 3, borderRadius: "8px", overflow: "hidden"}}>
                        <img alt={"Automated grading submission to central IS"}
                             src={"screenshot/automated_submission.png"}
                             style={{width: "100%", marginBottom: "-8px"}}/>
                    </Box>
                </Grid>
                <Grid md={3} xs={6} sx={{
                    padding: "1em",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-end"
                }}>
                    <Typography variant={"h3"}>Grading in CIS</Typography>
                    <Typography variant={"body1"} sx={{textAlign: "right"}}>
                        All grades can be automatically submitted to the central IS with just a few clicks.
                        No more manual typing of grades!
                        Grades are stored locally in your browser until you submit them.
                        Data can also be exported to CSV.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container columns={6} sx={{my: "2em", alignItems: "center"}}>
                <Grid md={3} xs={6}
                       sx={{padding: "1em", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    <Typography variant={"h3"}>Simple overviews</Typography>
                    <Typography variant={"body1"}>
                        Find all your lessons and students in one place.
                        Filter by year, subject, or even class category.
                        Pick favorite lessons to have them at your fingertips.
                    </Typography>
                </Grid>
                <Grid md={3} xs={6}>
                    <Box sx={{boxShadow: 3, borderRadius: "8px", overflow: "hidden"}}>
                        <img alt={"Automated grading submission to central IS"}
                             src={"screenshot/class_overview.png"}
                             style={{width: "100%", marginBottom: "-8px"}}/>
                    </Box>
                </Grid>
            </Grid>
        </PageContentContainer>
    );
}