import React from "react";
import {Box, Container} from "@mui/material";

export function PageContentContainer({children}: React.PropsWithChildren) {
    const innerMargin = 2;

    return <Container maxWidth={"xl"} sx={{my: innerMargin}}>
        <Box sx={{
            display: "flex",
            flexGrow: 1,
            alignItems: "stretch",
            flexDirection: "column",
            width: "100%",
            height: `calc(100vh - 40px - ${2 * innerMargin * 8}px)`,
        }}>
            {children}
        </Box>
    </Container>
}