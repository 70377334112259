import {IApiSession, VutApiConfiguration} from "common/types/VutApi";
import {AutentizaceApi, OsobyApi, RozvrhApi, ZadaniApi} from "libs/vut-api";
import globalAxios, {ResponseType} from 'axios';
import { Buffer } from 'buffer';

export class VutApi {
    static BASE_PATH = "https://proxy.vut.api.bazar.nesad.fit.vutbr.cz";

    private _session: IApiSession;

    constructor(session: IApiSession) {
        this._session = session;
    }

    private createConfiguration() {
        return new VutApiConfiguration({
            basePath: VutApi.BASE_PATH,
            // baseOptions: {
            //     headers: {
            //         "X-APP-NAME": "fit-loki",
            //         "X-APP-VERSION": process.env.REACT_APP_VERSION,
            //     },
            // },
            accessToken: this._session.http_session_token,
        })
    }

    public autentizace() {
        return new AutentizaceApi(this.createConfiguration());
    }

    public osoby() {
        return new OsobyApi(this.createConfiguration());
    }

    public rozvrh() {
        return new RozvrhApi(this.createConfiguration());
    }

    public zadani() {
        return new ZadaniApi(this.createConfiguration());
    }

    public async getVutPhoto(vutId: number | string): Promise<string> {
        const link = `${VutApi.BASE_PATH}/api/osoby/osoba/${vutId}/info/fotografie/pasova/v1?dest_height=400`;
        const config = {
            headers: {
                "Authorization": `Bearer ${this._session.http_session_token}`,
            },
            responseType: "arraybuffer" as ResponseType,
        };
        const photoData = await globalAxios.get(link, config);
        const photoBase64 = Buffer.from(photoData.data, "binary").toString("base64");
        return `data:image/jpeg;base64,${photoBase64}`;
    }
}