import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {ICourseLesson} from "common/data/getLessonsForCurrentCourse";
import {ICourseTaskWithExamInfo} from "common/types/VutApi";
import Grid from "@mui/material/Unstable_Grid2";
import {CourseTaskAutocomplete} from "common/components/CourseTaskAutocomplete";
import {ClassLessonNumberInput} from "dialogs/ClassLessonNumberInput";
import {getClassDisplayName} from "../common/helpers/getLessonDisplayName";
import {CourseClassLessonDescription} from "../common/components/CourseClassLessonDescription";


interface IConfigureLessonDialogProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    task: ICourseTaskWithExamInfo | undefined;
    setTask: (task: ICourseTaskWithExamInfo | undefined) => void;
    lessonNumber: number | undefined;
    setLessonNumber: (lessonNumber: number | undefined) => void;
    lesson: ICourseLesson;
}

export function ConfigureLessonDialog({
                                          isOpen, setIsOpen,
                                          task, setTask,
                                          lessonNumber, setLessonNumber,
                                          lesson,
                                      }: IConfigureLessonDialogProps) {
    const aktualniPredmetId = lesson.vyuka.v_aktualni_predmet_id!;

    const handleClose = () => {
        setIsOpen(false);
    }

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={"md"}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DialogTitle>Configure
                    class &#8211; {lesson.vyuka.v_p_zkratka}: {getClassDisplayName(lesson.vyuka)}</DialogTitle>
                <DialogContent>
                    <CourseClassLessonDescription lesson={lesson} includeClassDescription/>
                    <Grid container columns={8} spacing={4}>
                        <Grid md={6} xs={8}>
                            <CourseTaskAutocomplete currentCourseId={aktualniPredmetId} setTask={setTask} task={task}/>
                        </Grid>
                        <Grid md={2} xs={8}>
                            <ClassLessonNumberInput value={lessonNumber} setValue={setLessonNumber}
                                                    subTaskCount={task?.pocet_otazek}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
