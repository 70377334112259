import React, {createContext, useState} from "react";
import {useUserPreferences, useUserPreferencesManager} from "../common/hooks/useUserPreferences";

export interface IMainNavContextContext {
    isDarkMode: boolean,
    toggleThemeMode: () => void,
    isPrivacyMode: boolean,
    togglePrivacyMode: () => void,
    mobileOpen: boolean,
    setMobileOpen: (mobileOpen: boolean) => void,
    desktopOpen: boolean,
    setDesktopOpen: (desktopOpen: boolean) => void,
}

export const MainNavContext = createContext<IMainNavContextContext>({
    isPrivacyMode: false,
    togglePrivacyMode: () => {},
    isDarkMode: false,
    toggleThemeMode: () => {},
    mobileOpen: false,
    setMobileOpen: (_) => {},
    desktopOpen: false,
    setDesktopOpen: (_) => {},
});

function MainNavContextConfigurator({children}: React.PropsWithChildren) {
    const preferences = useUserPreferences();
    const preferencesManager = useUserPreferencesManager();

    const [mobileOpen, setMobileOpen] = useState(false)

    const context: IMainNavContextContext = {
        isPrivacyMode: preferences.privacyMode,
        togglePrivacyMode: preferencesManager.togglePrivacyMode,
        isDarkMode: preferences.themeMode === "dark",
        toggleThemeMode: preferencesManager.toggleThemeMode,
        mobileOpen: mobileOpen,
        setMobileOpen: setMobileOpen,
        desktopOpen: preferences.navOpen,
        setDesktopOpen: preferencesManager.setNavOpen,
    };

    return <>
        <MainNavContext.Provider value={context}>
            {children}
        </MainNavContext.Provider>
    </>
}

export default MainNavContextConfigurator;
