import {Dispatch, SetStateAction, useEffect, useState} from "react";

export function useDocumentTitle(title: string):  [string, Dispatch<SetStateAction<string>>] {
    const [documentTitle, setDocumentTitle] = useState(title);

    useEffect(() => {
        document.title = documentTitle;
    },[documentTitle]);

    return [documentTitle, setDocumentTitle];
}
