import React, {useMemo, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {grey} from "@mui/material/colors";
import {FitCvtApi} from "common/helpers/FitCvtApi";
import {useQuery} from "@tanstack/react-query";
import {
    Box,
    Card,
    CardActions,
    CardContent,
    FormControlLabel,
    FormGroup,
    LinearProgress,
    Switch,
    Toolbar,
    Typography
} from "@mui/material";
import {CvtActivitySummary} from "libs/fit-cvt-api";

export interface ILessonLabSeatingOverviewProps {
    lessonId: number;
    room: string;
    whenFrom: Date;
    whenTo: Date;
    renderContent?: (data: CvtActivitySummary | undefined) => React.ReactNode;
    renderActions?: (data: CvtActivitySummary | undefined) => React.ReactNode;
}

export function LessonLabSeatingOverview({
                                             lessonId,
                                             room,
                                             whenFrom,
                                             whenTo,
                                             renderContent,
                                             renderActions
                                         }: ILessonLabSeatingOverviewProps) {
    const [reverse, setReverse] = useState(true);
    const [showActions, setShowActions] = useState(false);
    const fitCvtApi = new FitCvtApi()
    const laboratoryLogsApi = fitCvtApi.labLogs();

    const itemContentMinWidth = 136;
    const itemImageMinWidth = 0;
    const itemMinWidth = itemContentMinWidth + itemImageMinWidth;
    const columnCount = 5;

    const {
        data: labPresence = {},
        isLoading: labPresenceIsLoading
    } = useQuery(["lesson", lessonId, "lab", room, "from", whenFrom, "to", whenTo, "presence-summary"], async () => {
        return await laboratoryLogsApi.getLoginPresenceSummaryInLab(room, whenFrom.toISOString(), whenTo.toISOString())
            .then(response => response.data)
    });

    const renderSeating: React.ReactNode[] = useMemo(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        renderContent ??= (data) => (
            <Typography sx={{fontFamily: "Monospace"}} color={!data ? "text.secondary" : "default"}>
                {data?.login ?? "-"}
            </Typography>
        )

        // sort lab activity descending by length of activity
        const presence = Object.keys(labPresence)
            .flatMap(login => labPresence[login])
            .sort(presence => Date.parse(presence.when_from) - Date.parse(presence.when_to));

        const presenceRenders: React.ReactNode[] = []
        for (let pcId = 0; pcId < 20; pcId++) {
            const presenceData = presence.find(p => p.pc_id === pcId);
            presenceRenders.push(
                <Grid xs={1} key={`pc-${pcId}`}>
                    <Card sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        flexGrow: 1,
                    }} variant={"outlined"}>
                        <Box>
                            <CardContent sx={{
                                display: "flex",
                                flexDirection: "column",
                                minWidth: 120,
                                p: 1,
                                pr: 2,
                                pb: "8px !important",
                            }}>
                                <Typography component={"span"} sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                    #{pcId.toString().padStart(2, "0")}
                                </Typography>
                                {renderContent(presenceData)}
                            </CardContent>
                            {showActions && renderActions && <CardActions sx={{
                                backgroundColor: theme => theme.palette.mode === "light"
                                    ? grey[50]
                                    : "rgb(0 0 0 / 25%)"
                            }} disableSpacing>
                                {renderActions(presenceData)}
                            </CardActions>}
                        </Box>
                        {/*<CardMedia component="img" image={`https://api.vut.cz/api/osoby/osoba/250504/info/fotografie/pasova/v1?dest_width=100&dest_height=100`} sx={{display: "none", width: itemImageMinWidth}}/>*/}
                    </Card>
                </Grid>
            )
        }

        return reverse ? presenceRenders.reverse() : presenceRenders;
    }, [labPresence, renderContent, renderActions, showActions, reverse]);


    const handleToggleReverse = () => setReverse(!reverse);
    const handleToggleShowActions = () => setShowActions(!showActions);

    return <>
        {labPresenceIsLoading && <LinearProgress/>}
        <Toolbar sx={{justifyContent: "flex-end"}} variant={"dense"}>
            <FormGroup>
                <FormControlLabel
                    label="Reverse order"
                    control={<Switch size={"small"} value={reverse} onChange={handleToggleReverse}/>}/>
            </FormGroup>
            <FormGroup>
                <FormControlLabel
                    label="Show actions"
                    control={<Switch size={"small"} value={showActions} onChange={handleToggleShowActions}/>}/>
            </FormGroup>
        </Toolbar>
        <Box sx={{flexGrow: "1", display: "flex", overflow: "hidden", m: "-4px"}}>
            <Box sx={{overflowY: "hidden", overflowX: "scroll", p: "4px"}}>
                <Grid container columns={columnCount} columnSpacing={1} rowSpacing={2} sx={{
                    minWidth: `calc(${itemMinWidth}px * ${columnCount})`,
                    alignItems: "center",
                    alignContent: "center",
                }}>
                    {renderSeating}
                </Grid>
            </Box>
        </Box>
    </>
}