import {useVutApiSession} from "contexts/VutApiSession";
import {VutApi} from "common/helpers/VutApi";
import React, {ReactElement, useState, useCallback} from "react";
import {useUserPreferences, useUserPreferencesManager} from "common/hooks/useUserPreferences";
import {useQuery} from "@tanstack/react-query";
import {getCoursesForPerson} from "common/data/getCoursesForPerson";
import {flatAwait} from "common/helpers/promises";
import {getLessonsForCurrentCourse, ICourseLesson} from "common/data/getLessonsForCurrentCourse";
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Typography
} from "@mui/material";
import {DataGrid, GridActionsCellItem, GridActionsColDef, GridColDef, GridRowParams} from "@mui/x-data-grid";
import {getClassDisplayName} from "common/helpers/getLessonDisplayName";
import Grid from "@mui/material/Unstable_Grid2";
import {getLessonStartDayTime} from "common/helpers/getLessonStartDayTime";
import {OpenInNew} from "@mui/icons-material";
import {getClassTeachersForClassLesson} from "../data/getClassTeachersForClassLesson";
import {GetVyucovaniVyucujiciV3200ResponseDataVyucovaniInnerVyucujiciInner} from "libs/vut-api";
import {useForceLoginOn401} from "../hooks/useForceLoginOn401";
import ErrorBoundary from "./ErrorBoundary";


const now = new Date();

const lessonsColumns: (GridColDef<ICourseLesson> | GridActionsColDef)[] = [
    {
        field: "courseAbbr",
        headerName: "Course",
        valueGetter: params => params.row.vyuka.v_p_zkratka,
    },
    {
        field: "courseName",
        headerName: "Course Name",
        minWidth: 280,
        valueGetter: params => params.row.vyuka.v_ap_nazev,
    },
    {
        field: "classCategory",
        headerName: "Class Category",
        minWidth: 220,
        valueGetter: params => params.row.vyuka.v_trj_popis,
    },
    {
        field: "displayName",
        headerName: "Class Name",
        minWidth: 220,
    },
    {
        field: "indexWithoutCancelled",
        headerName: "Nth",
        minWidth: 30,
        valueGetter: params => params.row.indexWithoutCancelled + 1,
    },
    {
        field: "lessonDayTime",
        headerName: "Lesson Day",
        type: "string",
        minWidth: 220,
        valueGetter: params => params.row.blok,
        renderCell: ({value, row}) => <Typography
            sx={{color: row.date_from < now || row.is_cancelled ? "text.secondary" : "secondary.dark"}}>
            {getLessonStartDayTime(value)}
        </Typography>,
    },
    {
        field: "lessonDateTime",
        headerName: "Lesson Date",
        type: "dateTime",
        minWidth: 140,
        valueGetter: params => params.row.date_from,
        renderCell: ({value, row}) => <Typography
            sx={{color: value < now || row.is_cancelled ? "text.secondary" : "secondary.dark"}}>
            {value.toLocaleDateString()}
        </Typography>,
    },
    {
        field: "is_cancelled",
        headerName: "Planned",
        type: "boolean",
        valueGetter: params => !params.value,
    },
    {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        getActions: () => {
            const actions: ReactElement[] = [];
            actions.push(<GridActionsCellItem icon={<OpenInNew/>} label="Open"/>);
            return actions;
        },
    },
];

export interface IClassLessonTableProps {
    favouriteOnly?: boolean;
    futureOnly?: boolean;
    ownOnly?: boolean;
}

export function ClassLessonTable({favouriteOnly, futureOnly, ownOnly}: IClassLessonTableProps) {
    const vutSession = useVutApiSession();
    const api = new VutApi(vutSession);
    const osobyApi = api.osoby();
    const rozvrhApi = api.rozvrh();
    const preferencesManager = useUserPreferencesManager();

    const [error, setError] = useState<Error | undefined>();
    const [includeCancelled, setIncludeCancelled] = useState<boolean>(false);
    const [showOnlyOwn, setShowOnlyOwn] = useState<boolean>(ownOnly ?? false);
    const preferences = useUserPreferences();
    const year = preferences.year;
    const courseId = preferences.selectedCourseId;
    const filteredLessonCategories = preferences.filteredClassCategories;
    useForceLoginOn401(error);

    const personIdFinal = vutSession.per_id!;

    const {
        data: coursesTeaching = [],
        isLoading: coursesTeachingIsLoading,
    } = useQuery([year, personIdFinal, "courses"], async () => {
        return await getCoursesForPerson(osobyApi, personIdFinal, year);
    }, {onError: setError});

    const {
        data: lessons = [],
        isLoading: lessonsAreLoading,
        error: lessonsError,
    } = useQuery([showOnlyOwn, courseId, personIdFinal, "courses", coursesTeaching.map(c => c.aktualni_predmet_id), "lessons"], async (): Promise<ICourseLesson[]> => {
        const lessons = courseId
            ? await getLessonsForCurrentCourse(rozvrhApi, courseId)
            : await flatAwait(coursesTeaching.map(course => getLessonsForCurrentCourse(rozvrhApi, course.aktualni_predmet_id!)));

        if (!showOnlyOwn) {
            return lessons;
        }

        const vyucovaniBlokIds = [...new Set(lessons.map(lesson => lesson.blok.vb_vyucovani_blok_id))];
        const vyucovaniBlokTeachers = await Promise.all(vyucovaniBlokIds.map(async vyucovaniBlokId => {
            return {
                vyucovaniBlokId: vyucovaniBlokId!,
                teachers: await getClassTeachersForClassLesson(rozvrhApi, vyucovaniBlokId!.toString()),
            };
        }));

        const vyucovaniTeachersMap = new Map<number, GetVyucovaniVyucujiciV3200ResponseDataVyucovaniInnerVyucujiciInner[]>();
        vyucovaniBlokTeachers.forEach(x => vyucovaniTeachersMap.set(x.vyucovaniBlokId, x.teachers));

        return lessons.filter(lesson => {
            const vyucovaniTeachers = vyucovaniTeachersMap.get(lesson.blok.vb_vyucovani_blok_id ?? 0);
            return vyucovaniTeachers?.some(teacher => teacher.osoba_id === personIdFinal);
        });
    }, {onError: setError, enabled: !coursesTeachingIsLoading, staleTime: 60 * 60 * 1000});

    function handleCourseIdChange(event: SelectChangeEvent) {
        preferencesManager.setSelectedCourseId(parseInt(event.target.value) ?? undefined);
    }

    const handleOnRowClick = ({row}: GridRowParams<ICourseLesson>) => {
        const url = `/course/${row.vyuka.v_aktualni_predmet_id}/class/${row.vyuka.v_vyucovani_id}/lesson/${row.id}`;
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const filterLessons = useCallback(() => {
        return lessons
            .map(lesson => ({...lesson, displayName: getClassDisplayName(lesson.vyuka)}))
            .filter(lesson => !lesson.is_cancelled || includeCancelled)
            .filter(lesson => !favouriteOnly || preferences.favouriteClasses.find(classId => classId === lesson?.vyuka?.v_vyucovani_id))
            .filter(lesson => !futureOnly || lesson.date_from > now)
            .filter(lesson => filteredLessonCategories.length === 0 || filteredLessonCategories.indexOf(lesson.vyuka.v_trj_popis!) > -1)
            .filter(lesson => !courseId || lesson.vyuka.v_aktualni_predmet_id === courseId);
    }, [lessons, preferences.favouriteClasses, includeCancelled, favouriteOnly, futureOnly, filteredLessonCategories, courseId]);

    const filteredLessons = filterLessons();
    const lessonCategories = [...new Set(lessons.map(lesson => lesson.vyuka.v_trj_popis))];

    const handleYearChange = (event: SelectChangeEvent) => {
        const {
            target: {value},
        } = event;

        // noinspection SuspiciousTypeOfGuard
        preferencesManager.setYear(parseInt(value));
    };

    const handleFilteredLessonCategoriesChange = (event: SelectChangeEvent) => {
        const {
            target: {value},
        } = event;

        // noinspection SuspiciousTypeOfGuard
        preferencesManager.setClassCategoryFilter(typeof value === "string" ? value.split(',') : value);
    };

    function handleIncludeCancelledChange() {
        setIncludeCancelled(!includeCancelled);
    }

    function handleShowOnlyOwnChange() {
        setShowOnlyOwn(!showOnlyOwn);
    }

    return <>
        <Box sx={{
            display: "flex",
            flexGrow: 1,
            alignItems: "stretch",
            flexDirection: "column",
            width: "100%",
            height: "100%",
        }}>
            <Grid container columns={8} spacing={2} sx={{my: ".5em"}}>
                <Grid md={2} sm={8} xs={8}>
                    <FormControl size={"small"} fullWidth>
                        <InputLabel id={"year-label"}>Year</InputLabel>
                        <Select
                            label={"Course"}
                            labelId={"year-label"}
                            id={"year"}
                            value={year?.toString() ?? ""}
                            onChange={handleYearChange}
                            autoWidth
                        >
                            <MenuItem key={"default"} value={""}>
                                Current default
                            </MenuItem>
                            {Array.from(new Array(1 + new Date().getFullYear() - 2022), (x, i) => i + 2022).map(yearNumber => (
                                <MenuItem key={yearNumber} value={yearNumber}>
                                    {yearNumber}/{yearNumber + 1}
                                </MenuItem>
                            )).reverse()}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid md={3} sm={8} xs={8}>
                    <FormControl size={"small"} fullWidth>
                        <InputLabel id={"courseId-label"}>Course</InputLabel>
                        <Select
                            label={"Course"}
                            labelId={"courseId-label"}
                            id={"courseId"}
                            value={courseId?.toString() ?? ""}
                            onChange={handleCourseIdChange}
                            autoWidth
                        >
                            {coursesTeaching.map(course => (
                                <MenuItem key={course.aktualni_predmet_id} value={course.aktualni_predmet_id}>
                                    {course.zkratka}: {course.ap_nazev}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid md={3} sm={8} xs={8}>
                    <FormControl size={"small"} fullWidth>
                        <InputLabel id={"classCategory-label"}>Class categories</InputLabel>
                        <Select
                            labelId={"classCategory-label"}
                            id={"classCategory"}
                            onChange={handleFilteredLessonCategoriesChange}
                            // @ts-ignore
                            value={filteredLessonCategories}
                            // @ts-ignore
                            renderValue={(selected) => selected.join(', ')}
                            input={<OutlinedInput label="Tag"/>}
                            multiple
                            autoWidth
                        >
                            {lessonCategories.map(classCategory => (
                                <MenuItem key={classCategory} value={classCategory}>
                                    <Checkbox checked={filteredLessonCategories.indexOf(classCategory!) > -1}/>
                                    <ListItemText primary={classCategory}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid md={2} sx={{display: {xs: 'none', md: 'block'}}}/>
                <Grid md={3} sm={4} xs={8}>
                    <FormControl size={"small"} fullWidth>
                        <FormControlLabel
                            control={<Checkbox checked={includeCancelled} onChange={handleIncludeCancelledChange}/>}
                            label="Include cancelled"/>
                    </FormControl>
                </Grid>
                <Grid md={3} sm={4} xs={8}>
                    <FormControl size={"small"} fullWidth>
                        <FormControlLabel
                            disabled={ownOnly}
                            control={<Checkbox checked={showOnlyOwn} onChange={handleShowOnlyOwnChange}/>}
                            label="Show only own"/>
                    </FormControl>
                </Grid>
            </Grid>
            <Box sx={{
                mt: "1em",
                flexGrow: 1,
                minHeight: 300,
                height: "100%",
            }}>
                <ErrorBoundary error={lessonsError as Error}>
                    <DataGrid
                        sx={{
                            "& .MuiDataGrid-cell": {
                                cursor: "pointer",
                            },
                        }}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    courseName: false,
                                    actions: false,
                                }
                            },
                            sorting: {
                                sortModel: futureOnly
                                    ? [{field: "lessonDateTime", sort: "asc",}]
                                    : [],
                            },
                        }}
                        columns={lessonsColumns}
                        rows={filteredLessons}
                        loading={coursesTeachingIsLoading || lessonsAreLoading}
                        density={"compact"}
                        onRowClick={handleOnRowClick}
                    />
                </ErrorBoundary>
            </Box>
        </Box>
    </>;
}