import React from "react";
import {ClassLessonTable, IClassLessonTableProps} from "../common/components/ClassLessonTable";
import {PageContentContainer} from "common/components/PageContentContainer";
import {useDocumentTitle} from "../common/hooks/useDocumentTitle";
import {Box} from "@mui/material";


export interface ILessonBrowsePageProps extends IClassLessonTableProps {
    favouriteOnly?: boolean;
    futureOnly?: boolean;
}

export function LessonsBrowsePage({favouriteOnly = false, futureOnly = false}: ILessonBrowsePageProps) {
    useDocumentTitle("FIT LOKI - Browse Lessons");

    return (
        <PageContentContainer>
            <Box sx={{display: "flex", flexGrow: 1, maxHeight: "100%"}}>
                <ClassLessonTable favouriteOnly={favouriteOnly} futureOnly={futureOnly}/>
            </Box>
        </PageContentContainer>
    );
}
