import React, {createContext, useContext, useMemo} from "react";
import {CreateAuthLogin200Response} from "libs/vut-api";
import {useLocalStorageState} from "../common/hooks/useLocalStorageState";
import {IApiSession} from "../common/types/VutApi";

export interface IVutApiSessionContext {
    session: CreateAuthLogin200Response,
    setSession: (session: CreateAuthLogin200Response) => void,
}

export const VutApiSessionContext = createContext<IVutApiSessionContext>({
    session: {},
    setSession: (_) => {},
});

export const useVutApiSession = (): CreateAuthLogin200Response => useContext(VutApiSessionContext).session;

function VutApiSessionContextConfigurator({children}: React.PropsWithChildren) {
    const [session, setSession] = useLocalStorageState<IApiSession>("/user/session", {});

    const vutApiSessionContext: IVutApiSessionContext = useMemo(() => ({
        session: session ?? {},
        setSession: setSession,
    }), [session, setSession]);

    return <>
        <VutApiSessionContext.Provider value={vutApiSessionContext}>
            {children}
        </VutApiSessionContext.Provider>
    </>
}

export default VutApiSessionContextConfigurator;
