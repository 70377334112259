import {useNavigate} from "react-router-dom";
import {useVutApiSession} from "contexts/VutApiSession";
import {useEffect} from "react";

export function useForceRedirectWhenSignedIn(redirectTo: string) {
    const navigate = useNavigate();
    const vutSession = useVutApiSession();

    useEffect(() => {
        if (vutSession.per_id) {
            navigate(redirectTo, {replace: true});
        }
    }, [vutSession, navigate, redirectTo]);
}