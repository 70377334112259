import {BaseAPI} from "libs/vut-api/base";
import {
    Configuration,
    ConfigurationParameters,
    CreateAuthLogin200Response,
    GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner,
    GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInnerBlokyInner,
    GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner,
    GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInnerOtazkyInner,
    GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner,
    GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner
} from "libs/vut-api";


export type VutApiBase = BaseAPI;
export class VutApiConfiguration extends Configuration {}

export type VutApiConfigParams = ConfigurationParameters;

export type IApiSession = CreateAuthLogin200Response;

export type ICourseTask = GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInnerZadaniInner;
export type ICourseExam = GetAktualniPredmetZadaniV3200ResponseDataPredmetyInnerAktualniPredmetyInnerZkouskyInner;

export type ICourseClassType = GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner;
export type ICourseClassTypeVariant = GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInnerBlokyInner;

export type ICourseTaskStudent = GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner;
export type ICourseTaskSubtask = GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInnerOtazkyInner;

export interface ICourseTaskWithExamInfo extends ICourseTask {
    zkouska?: ICourseExam;
}
