import {ZadaniApi} from "libs/vut-api";
import {getFirstOrThrow} from "./getFirstOrThrow";

export async function getCourseTasks(api: ZadaniApi, courseId: number) {
    try {
        return await api.getAktualniPredmetZadaniV3(courseId.toString())
            .then(response => getFirstOrThrow(response.data.data?.predmety, `Course with ID ${courseId} not found.`))
            .then(predmet => getFirstOrThrow(predmet.aktualni_predmety))
            .then(aktualniPredmet => aktualniPredmet.zkousky ?? [])
            .then(zkousky => zkousky.flatMap(zkouska => zkouska.zadani?.map(
                zadani => ({...zadani, zkouska: zkouska}))));
    } catch {
        return [];
    }
}