import React, {ChangeEvent, FormEvent, useContext, useState} from "react";
import {CreateAuthLoginRequest} from "libs/vut-api";
import {VutApiSessionContext} from "contexts/VutApiSession";
import {useMutation} from "@tanstack/react-query";
import {
    Alert,
    Container,
    InputAdornment,
    TextField, Typography
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {AccountCircle, Lock} from "@mui/icons-material";
import {AxiosError} from "axios";
import {QueryException} from "common/types/exceptions";
import {useNavigate, useSearchParams} from "react-router-dom";
import {VutApi} from "common/helpers/VutApi";
import {useDocumentTitle} from "../common/hooks/useDocumentTitle";
import {useForceRedirectWhenSignedIn} from "../common/hooks/useForceRedirectWhenSignedIn";

export function LogInPage() {
    useDocumentTitle("FIT LOKI - Sign in");
    const {session, setSession} = useContext(VutApiSessionContext);
    const api = new VutApi(session)
    const autentizaceApi = api.autentizace()
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [credentials, setCredentials] = useState<CreateAuthLoginRequest>({});

    useForceRedirectWhenSignedIn("/");

    const {mutate: logIn, error, isError, isLoading} = useMutation<unknown, string>(() =>
        autentizaceApi.createAuthLogin(credentials)
            .then(response => {
                setSession(response.data);
                navigate(searchParams.get("returnTo") ?? "/");
            })
            .catch(error => {
                console.error(error);
                if (error instanceof AxiosError && error.response?.status === 401) {
                    let message = "Autentizace selhala.";
                    try {
                        message = error.response!.data.message_cz;
                    } catch (e) {
                        console.error(e);
                    }

                    throw new QueryException(message);
                }

                throw new QueryException(error.message);
            })
    );

    const handleUsernameChange = (e: ChangeEvent<HTMLInputElement>) => setCredentials({
        ...credentials,
        username: e.target.value
    });

    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => setCredentials({
        ...credentials,
        password: e.target.value
    });

    const handleLogIn = (event: FormEvent) => {
        event?.preventDefault();
        logIn();
    }

    return (
        <div>
            <Container maxWidth={"sm"} sx={{my: "2em"}}>
                <Typography variant={"h4"}>Sign in</Typography>
                <Typography variant={"body1"} sx={{my: 1}}>
                    To continue using the application please provide your VUT API credentials.
                    The provided credentials are only used for one-time authentication to the API and are not locally
                    stored.
                </Typography>
                <>{isError && <Alert severity="error" sx={{my: "1em"}}>{error.toString()}</Alert>}</>
                <form onSubmit={handleLogIn}>
                    <TextField
                        autoFocus
                        disabled={isLoading}
                        onChange={handleUsernameChange}
                        margin="normal"
                        id="username"
                        label="VUT login"
                        type="text"
                        variant="outlined"
                        error={isError}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle/>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        disabled={isLoading}
                        onChange={handlePasswordChange}
                        margin="normal"
                        id="password"
                        label="VUT password"
                        type="password"
                        variant="outlined"
                        error={isError}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Lock/>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <LoadingButton
                        variant={"contained"}
                        loading={isLoading}
                        loadingIndicator="Signing in..."
                        type={"submit"}
                        sx={{width: "100%", my: 1}}
                    >
                        Sign in
                    </LoadingButton>
                </form>
            </Container>
        </div>
    );
}
