import {ICourseClass} from "../data/getLessonsForCurrentCourse";
import {Days} from "./getLessonStartDayTime";

export function getClassDisplayName(courseClass: ICourseClass): string {
    if (courseClass.v_doplnujici_text) {
        return courseClass.v_doplnujici_text;
    }

    const courseBlock = courseClass.bloky?.[0];
    if (courseBlock) {
        const dateFrom = new Date(courseBlock?.vb_datum_od!);
        const timeFrom = new Date(courseBlock?.vb_cas_od!);
        const dayFrom = Days[(dateFrom.getDay() + 6) % 7];

        return `${dayFrom}, ${timeFrom.toLocaleTimeString()}`;
    }

    return courseClass.v_trj_popis ?? "-";
}