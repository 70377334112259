import {
    GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInnerBlokyInnerDnyInnerVyucujiciInner,
    GetVyucovaniVyucujiciV3200ResponseDataVyucovaniInnerVyucujiciInner,
    RozvrhApi
} from "libs/vut-api";
import {ICourseLesson} from "./getLessonsForCurrentCourse";
import {getClassTeachersForClassLesson} from "./getClassTeachersForClassLesson";
import {getLessonTeachersForClassLesson} from "./getLessonTeachersForClassLesson";
import {getRoomsForClassLesson} from "./getRoomsForClassLesson";

export interface ICourseLessonWithTeachers extends ICourseLesson {
    teachers: GetVyucBlokDenVyucujiciV3200ResponseDataVyucovaniInnerBlokyInnerDnyInnerVyucujiciInner[],
}

export interface ICourseLessonWithRooms extends ICourseLesson {
    rooms: string[],
}

export interface ICourseLessonWithDetails extends ICourseLessonWithTeachers, ICourseLessonWithRooms {
}

export async function decorateCourseLessonsWithCourseTeachers(api: RozvrhApi, lessons: ICourseLesson[]): Promise<ICourseLessonWithTeachers[]> {
    const vyucovaniVyucujici: Record<string, GetVyucovaniVyucujiciV3200ResponseDataVyucovaniInnerVyucujiciInner[]> = {};
    const dataPromises = lessons.map(async lesson => {
        let vyucovaniBlokId = lesson.blok.vb_vyucovani_blok_id!.toString();
        vyucovaniVyucujici[vyucovaniBlokId] ??= await getClassTeachersForClassLesson(api, vyucovaniBlokId);

        return {
            ...lesson,
            teachers: vyucovaniVyucujici[vyucovaniBlokId],
        }
    });

    return await Promise.all(dataPromises);
}


export async function decorateCourseLessonsWithLessonTeachers(api: RozvrhApi, lessons: ICourseLesson[]): Promise<ICourseLessonWithTeachers[]> {
    const dataPromises = lessons.map(async lesson => ({
        ...lesson,
        teachers: await getLessonTeachersForClassLesson(api, lesson),
    }));

    return Promise.all(dataPromises);
}


export async function decorateCourseLessonsWithRooms(api: RozvrhApi, lessons: ICourseLesson[]): Promise<ICourseLessonWithRooms[]> {
    const dataPromises = lessons.map(async lesson => ({
        ...lesson,
        rooms: await getRoomsForClassLesson(api, lesson.id)
            .then(mistnosti => mistnosti.map(m => m.mistnost_label!)),
    }));

    return await Promise.all(dataPromises);
}


export async function decorateCourseLessons(api: RozvrhApi, lessons: ICourseLesson[]): Promise<ICourseLessonWithDetails[]> {
    // @ts-ignore
    return await decorateCourseLessonsWithRooms(api, lessons)
        .then(lessons => decorateCourseLessonsWithLessonTeachers(api, lessons))
}
