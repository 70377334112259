import {VutApiConfiguration} from "common/types/VutApi";
import {LaboratoryLogsApi} from "libs/fit-cvt-api";

export class FitCvtApi {

    private createConfiguration() {
        return new VutApiConfiguration({
            basePath: "https://cvt.api.bazar.nesad.fit.vutbr.cz",
        })
    }

    public labLogs() {
        return new LaboratoryLogsApi(this.createConfiguration());
    }
}