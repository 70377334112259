import {RozvrhApi} from "libs/vut-api";
import {getFirstOrThrow} from "./getFirstOrThrow";

export async function getCourseClassStudents(api: RozvrhApi, currentCourseId: number, vyucovaniId: number) {
    try {
        return await api.getAktualniPredmetVyucovaniStudentiV3(currentCourseId.toString(), vyucovaniId.toString())
            .then(response => getFirstOrThrow(response.data.data?.predmety, `Course with ID ${currentCourseId} not found.`))
            .then(predmet => getFirstOrThrow(predmet.aktualni_predmety))
            .then(aktualniPredmet => getFirstOrThrow(aktualniPredmet.vyucovani, `Class with ID ${vyucovaniId} not found.`))
            .then(courseClass => courseClass.studenti ?? []);
    } catch {
        return [];
    }
}