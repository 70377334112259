

export default function createDateFromDifferentSources(date_source: Date | string, time_source: Date | string): Date {
    date_source = date_source instanceof Date ? date_source : new Date(date_source);
    time_source = time_source instanceof Date ? time_source : new Date(time_source);

    const result_date = new Date(date_source);
    result_date.setHours(
        time_source.getHours(),
        time_source.getMinutes(),
        time_source.getSeconds(),
        time_source.getMilliseconds()
    );

    return result_date;
}
