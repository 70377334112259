import {Alert, AlertTitle, Button, Link} from "@mui/material";
import React from "react";
import {useDocumentTitle} from "../common/hooks/useDocumentTitle";
import {PageContentContainer} from "../common/components/PageContentContainer";

interface IErrorPageProps {
    error?: Error | null;
}

export function ErrorPage({error}: IErrorPageProps) {
    useDocumentTitle("FIT LOKI");

    return <PageContentContainer>
        <Alert severity={"error"} action={
            <Button color={"error"} size={"small"} onClick={() => localStorage.clear()}>
                Reset State
            </Button>
        }>
            <AlertTitle>An error occurred</AlertTitle>
            <p>
                There was an error rendering the page.
                You can try checking the logs in the console but this is most probably a minified
                version of
                the application so the logs are not very useful.
                You can also try resetting the state of the application by clicking the "Reset
                State"
                button; however, this will result in loss of any and all data (favourite classes,
                existing
                unsubmitted grading and other preferences).
            </p>
            <p>
                If this issue persists, please contact <Link
                href={"mailto:dolejska@fit.vut.cz?subject=[LOKI] Application error"}>Daniel
                Dolejška</Link>.
                Try to include the following information:
            </p>
            <ul>
                <li>What were you doing when the error occurred?</li>
                <li>What browser are you using?</li>
                <li>What are the contents in the browser console log?</li>
            </ul>
        </Alert>
    </PageContentContainer>
}