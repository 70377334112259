import {
    GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner,
    GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInnerBlokyInner,
    RozvrhApi
} from "libs/vut-api";
import createDateFromDifferentSources from "common/helpers/createDateFromDifferentSources";


export type ICourseClass = GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInner;

export interface ICourseLesson {
    vyuka: ICourseClass;
    blok: GetAktualniPredmetVyucovaniV3200ResponseDataVyucovaniInnerBlokyInner;
    id: number;
    index: number;
    indexWithoutCancelled: number;
    date_from: Date;
    date_to: Date;
    is_cancelled: boolean;
}

export async function getLessonsForCurrentCourse(api: RozvrhApi, currentCourseId: number): Promise<ICourseLesson[]> {
    const vyucovani = await api.getAktualniPredmetVyucovaniV3(currentCourseId.toString())
        .then(response => response.data.data?.vyucovani ?? []);

    return vyucovani.flatMap(
        v => v.bloky?.flatMap(
            b => {
                const dnyBezZrusenych = b.dny?.filter(d => !d.vbd_zruseno) ?? [];
                return b.dny?.map(d => ({
                    vyuka: v,
                    blok: b,
                    id: d.vbd_vyuc_blok_den_id!,
                    index: b.dny!.indexOf(d),
                    indexWithoutCancelled: dnyBezZrusenych.indexOf(d),
                    date_from: createDateFromDifferentSources(d.vbd_datum!, b.vb_cas_od!),
                    date_to: createDateFromDifferentSources(d.vbd_datum!, b.vb_cas_do!),
                    is_cancelled: Boolean(d.vbd_zruseno),
                })) ?? []
            }
        ) ?? []
    );
}
