import {Alert} from "@mui/material";
import {QueryExceptionBase} from "common/types/exceptions";

export interface IErrorToAlertParams {
    error: Error | undefined;
    isError?: boolean;
}

export function ErrorAlert({error, isError}: IErrorToAlertParams) {
    isError ??= Boolean(error);

    if (isError && error instanceof QueryExceptionBase)
    {
        return error.getAlert();
    }

    return <>
        {isError && <Alert severity={"error"} sx={{mb: ".5em"}}>{error?.message ?? "An error has occured."}</Alert>}
    </>
}
