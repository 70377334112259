import {CvtActivitySummary} from "libs/fit-cvt-api";
import {
    GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInnerStudentiInner
} from "libs/vut-api";
import {useVutApiSession} from "contexts/VutApiSession";
import {useQuery} from "@tanstack/react-query";
import {VutApi} from "../helpers/VutApi";
import {Avatar, Badge, Box, Typography} from "@mui/material";
import React from "react";

export function StudentSeating(data: {
    activity: CvtActivitySummary | undefined,
    student?: GetAktualniPredmetVyucovaniStudentiV3200ResponseDataPredmetyInnerAktualniPredmetyInnerVyucovaniInnerStudentiInner | null,
    grading: number | null,
}) {
    const {activity, student, grading} = data;
    const vutSession = useVutApiSession();

    const {data: vutPhoto} = useQuery(["person", student?.per_id, "photo"], async () => {
        if (!student) {
            return null;
        }

        const vutApi = new VutApi(vutSession);
        return await vutApi.getVutPhoto(student.per_id!);
    }, {staleTime: 7 * 24 * 60 * 60 * 1000});

    const studentName = student ? `${student.family_names} ${student.first_names}` : activity?.login ?? "-";
    return <>
        {/* @ts-ignore slotProps={{badge:{sx:{right: 20}}}} */}
        <Box sx={{display: "flex", alignItems: "center"}}>
            <Box sx={{mr: ".5em"}}>
                <Avatar src={vutPhoto ?? undefined} alt={studentName} className={student ? "private" : ""} sx={{
                    width: {
                        xs: 32,
                        md: 48,
                        xl: 64,
                    },
                    height: {
                        xs: 32,
                        md: 48,
                        xl: 64,
                    },
                }}>
                    {student ? `${student.family_names?.split(" ").map(n => n[0])}${student.first_names![0]}` : null}
                </Avatar>
            </Box>
            <Box sx={{flexGrow: 1}}>
                <Badge badgeContent={grading}
                       slotProps={{badge: {className: "private"}}}
                       component={"div"}
                       sx={{width: "100%"}}
                       color={grading === 0 ? "error" : typeof grading === "number" ? "success" : "default"} showZero>
                    <div>
                        <Typography component={"div"} color={!activity ? "text.secondary" : "default"}>
                            {student
                                ? student.family_names
                                : <Typography
                                    sx={{fontFamily: "Monospace", fontSize: 14}}>{activity?.login ?? "-"}</Typography>
                            }
                        </Typography>
                        <Typography sx={{fontSize: 12}} component={"div"}
                                    color={!activity ? "text.secondary" : "default"}>
                            {student ? student.first_names : ""}
                        </Typography>
                    </div>
                </Badge>
            </Box>
        </Box>
    </>;
}