import React, {useMemo} from "react";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {useUserPreferences} from "../common/hooks/useUserPreferences";

function AppThemeConfigurator({children}: React.PropsWithChildren) {
    const preferences = useUserPreferences()

    const currentTheme = useMemo(() => {
        return createTheme({
            palette: {
                mode: preferences.themeMode,
            },
        });
    }, [preferences]);

    return <>
        <ThemeProvider theme={currentTheme}>
            <CssBaseline/>
            {children}
        </ThemeProvider>
    </>
}

export default AppThemeConfigurator;
