import React from "react";
import {TextField} from "@mui/material";


export interface IClassLessonNumberInputParams {
    value: number | undefined;
    setValue: (value: number | undefined) => void;
    subTaskCount: number | undefined;
}

export function ClassLessonNumberInput({
                                           value,
                                           setValue,
                                           subTaskCount
                                       }: IClassLessonNumberInputParams) {
    const noSubTasksAvailable = !subTaskCount;

    const handleLessonNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(parseInt(event.currentTarget.value));
    }

    return <TextField
        label={"Lesson number"}
        helperText={noSubTasksAvailable ? "Graded task does not allow any subtasks" : ""}
        value={value ?? ""}
        type={"number"}
        inputProps={{min: 1, max: subTaskCount ?? 1}}
        InputLabelProps={{ shrink: Boolean(value) }}
        onChange={handleLessonNumberChange}
        disabled={noSubTasksAvailable}
        fullWidth
    />;
}