import React, {useContext} from "react";
import {MainNavContext} from "contexts/MainNavContext";
import {useVutApiSession, VutApiSessionContext} from "contexts/VutApiSession";
import {
    CalendarMonth,
    DarkMode,
    KeyboardDoubleArrowLeft,
    KeyboardDoubleArrowRight,
    LightMode,
    Login,
    Logout,
    Star,
    Today, Visibility, VisibilityOff
} from "@mui/icons-material";
import {Link, useNavigate} from "react-router-dom";
import {
    Box,
    CSSObject,
    Divider,
    Hidden,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled,
    Theme,
    Toolbar,
    Typography
} from "@mui/material";
import {LoggedInAccountAvatar} from "../common/components/LoggedInAccountAvatar";
import MuiDrawer from "@mui/material/Drawer";


export const DrawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: DrawerWidth,
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: DrawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export function MainNav() {
    const {
        isDarkMode, toggleThemeMode,
        isPrivacyMode, togglePrivacyMode,
        mobileOpen, setMobileOpen,
        desktopOpen, setDesktopOpen,
    } = useContext(MainNavContext);

    const handleMobileNavToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleNavToggle = () => {
        setDesktopOpen(!desktopOpen);
    };

    const vutSession = useVutApiSession();

    const mainNavItems = [
        {
            text: "Current Lesson",
            icon: <Today/>,
            link: "/teaching",
            disabled: !Boolean(vutSession.per_id),
        },
        {
            text: "Favourite Lessons",
            icon: <Star/>,
            link: "/teaching/favourite",
            disabled: !Boolean(vutSession.per_id),
        },
        {
            text: "Browse Lessons",
            icon: <CalendarMonth/>,
            link: "/teaching/browse",
            disabled: !Boolean(vutSession.per_id),
        },
    ];

    const handleToggleTheme = () => {
        toggleThemeMode();
    }

    const handleTogglePrivacyMode = () => {
        togglePrivacyMode();
    }

    const navigate = useNavigate();
    const {setSession} = useContext(VutApiSessionContext);
    const handleLogout = () => {
        setSession({});
        navigate("/sign-in");
    }

    const getSignInNav = (isMobile: boolean) => (<>
        <ListItem disablePadding>
            <ListItemButton to={"/sign-in"} component={Link}>
                <ListItemIcon>
                    <Login/>
                </ListItemIcon>
                <ListItemText primary={"Sign in"}/>
            </ListItemButton>
        </ListItem>
    </>);

    const getAccountNav = (isMobile: boolean) => (<>
        <ListItem sx={{height: "64px"}}>
            <ListItemText>
                {!isMobile && !desktopOpen &&
                    <ListItemIcon>
                        <LoggedInAccountAvatar/>
                    </ListItemIcon>}
                {(desktopOpen || isMobile) &&
                    <Typography variant={"overline"} textAlign={"center"} component={"div"}
                                sx={{lineHeight: "1.2", whiteSpace: "normal"}}>
                        {vutSession.per_label}
                    </Typography>}
            </ListItemText>
        </ListItem>
        <ListItem onClick={handleLogout} disablePadding>
            <ListItemButton onClick={handleToggleTheme}>
                <ListItemIcon>
                    <Logout/>
                </ListItemIcon>
                <ListItemText>Sign out</ListItemText>
            </ListItemButton>
        </ListItem>
    </>);

    const getDrawerContent = (isMobile: boolean) => (<>
        <Toolbar variant={"dense"}/>
        <Box sx={{overflow: 'auto'}}>
            <List>
                {mainNavItems.map((content, index) => (
                    <ListItem key={index} disablePadding>
                        <ListItemButton disabled={content.disabled} to={content.link} component={Link}>
                            <ListItemIcon>
                                {content.icon}
                            </ListItemIcon>
                            <ListItemText primary={content.text}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider/>
            <List>
                {Boolean(vutSession?.per_id) ? getAccountNav(isMobile) : getSignInNav(isMobile)}
            </List>
            <Divider/>
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={handleToggleTheme}>
                        <ListItemIcon>
                            {isDarkMode ? <LightMode/> : <DarkMode/>}
                        </ListItemIcon>
                        <ListItemText>
                            {isDarkMode ? "Set Light Mode" : "Set Dark Mode"}
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={handleTogglePrivacyMode}>
                        <ListItemIcon>
                            {isPrivacyMode ? <Visibility/> : <VisibilityOff/>}
                        </ListItemIcon>
                        <ListItemText>
                            {isPrivacyMode ? "Disable Privacy" : "Enable Privacy"}
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
                <Hidden mdDown>
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleNavToggle}>
                            <ListItemIcon>
                                {desktopOpen ? <KeyboardDoubleArrowLeft/> : <KeyboardDoubleArrowRight/>}
                            </ListItemIcon>
                            <ListItemText primary={desktopOpen ? "Collapse menu" : "Open menu"}/>
                        </ListItemButton>
                    </ListItem>
                </Hidden>
            </List>
        </Box>
    </>);

    // @ts-ignore
    const container = window !== undefined ? () => window.document.body : undefined;

    return <Box
        component="nav"
        sx={{width: {md: DrawerWidth}, flexShrink: {md: 0}}}
    >
        <MuiDrawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleMobileNavToggle}
            ModalProps={{
                keepMounted: true,
            }}
            sx={{
                display: {xs: 'block', md: 'none'},
                '& .MuiDrawer-paper': {boxSizing: 'border-box'},
            }}
        >
            {getDrawerContent(true)}
        </MuiDrawer>
        <Drawer
            variant="permanent"
            sx={{
                display: {xs: 'none', md: 'block'},
                '& .MuiDrawer-paper': {boxSizing: 'border-box'},
            }}
            open={desktopOpen}
        >
            {getDrawerContent(false)}
        </Drawer>
    </Box>
}