import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Typography} from "@mui/material";
import {ICourseClassType, ICourseClassTypeVariant} from "common/types/VutApi";
import {getLessonStartDayTime} from "common/helpers/getLessonStartDayTime";
import {getClassDisplayName} from "common/helpers/getLessonDisplayName";


interface CourseTaskDescriptionParams {
    courseClass: ICourseClassType;
    blok: ICourseClassTypeVariant;
    noMargin?: boolean
}

export function CourseClassDescription({courseClass, blok, noMargin = false}: CourseTaskDescriptionParams) {
    return <>
        <Grid container columns={5} rowSpacing={1} columnSpacing={3}
               sx={{mb: noMargin ? 0 : "1.5em", display: "flex", alignContent: "center", alignItems: "center"}}>
            <Grid md={1} xs={5}>
                <Typography variant={"subtitle1"} sx={{textAlign: {md: "right"}}}>Course</Typography>
            </Grid>
            <Grid md={4} xs={5}>
                <Typography
                    variant={"body2"}>[{courseClass.v_p_zkratka}] {courseClass.v_ap_nazev}</Typography>
            </Grid>
            <Grid md={1} xs={5}>
                <Typography variant={"subtitle1"} sx={{textAlign: {md: "right"}}}>Name</Typography>
            </Grid>
            <Grid md={4} xs={5}>
                <Typography variant={"body2"}>{getClassDisplayName(courseClass)}</Typography>
            </Grid>
            <Grid md={1} xs={5}>
                <Typography variant={"subtitle1"} sx={{textAlign: {md: "right"}}}>Description</Typography>
            </Grid>
            <Grid md={4} xs={5}>
                <Typography variant={"body2"}>{courseClass.v_trj_popis}</Typography>
            </Grid>
            <Grid md={1} xs={5}>
                <Typography variant={"subtitle1"} sx={{textAlign: {md: "right"}}}>Class Date</Typography>
            </Grid>
            <Grid md={4} xs={5}>
                <Typography variant={"body2"}>
                    {getLessonStartDayTime(blok)}
                </Typography>
            </Grid>
        </Grid>
    </>;
}
