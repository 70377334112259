import {useCallback, useEffect, useState} from "react";


export type LocalStorageState<T> = [T, (data: T) => void];

function getValueFromLocalStorage<T>(storageKey: string): T | undefined {
    const storageContent = localStorage.getItem(storageKey);
    if (storageContent === null) {
        return undefined;
    }

    try {
        return JSON.parse(storageContent) as T;
    } catch (e) {
        return undefined;
    }
}

export function useLocalStorageState<T>(storageKey: string, defaults: T): LocalStorageState<T>;
export function useLocalStorageState<T = undefined>(storageKey: string): LocalStorageState<T | undefined> {
    const defaults = arguments[1] as T | undefined;
    const [data, setData] = useState(getValueFromLocalStorage<T>(storageKey) ?? defaults);

    useEffect(() => {
        const newValue = getValueFromLocalStorage<T>(storageKey) ?? defaults;
        if (data !== newValue) {
            setData(newValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storageKey]);

    const updateStoredData = useCallback((value: T | undefined) => {
        setData(value);
        localStorage.setItem(storageKey, JSON.stringify(value));
    }, [storageKey]);

    const onStorageUpdate = useCallback((event: StorageEvent) => {
        const {key, newValue} = event;
        if (key !== storageKey) {
            return;
        }

        setData(newValue ? JSON.parse(newValue) : undefined);
    }, [storageKey]);

    useEffect(() => {
        window.addEventListener("storage", onStorageUpdate);
        return () => {
            window.removeEventListener("storage", onStorageUpdate);
        };
    }, [onStorageUpdate]);

    return [data, updateStoredData];
}
