import React, {createContext, useMemo} from "react";
import {useLocalStorageState} from "../common/hooks/useLocalStorageState";
import {useVutApiSession} from "contexts/VutApiSession";


export interface IUserPreferences {
    year?: number,
    selectedCourseId?: number,
    filteredClassCategories: string[],
    favouriteClasses: number[],
    themeMode: "light" | "dark",
    navOpen: boolean,
    privacyMode: boolean,
}

export interface IUserPreferencesManager {
    setYear: (year: number) => void,
    setSelectedCourseId: (courseId: number) => void,
    setClassCategoryFilter: (categories: string[]) => void,
    addFavouriteClass: (classId: number) => void,
    removeFavouriteClass: (classId: number) => void,
    toggleThemeMode: () => void,
    setNavOpen: (open: boolean) => void,
    togglePrivacyMode: () => void,
}

const userPreferencesDefaults: IUserPreferences = {
    year: undefined,
    selectedCourseId: undefined,
    filteredClassCategories: [],
    favouriteClasses: [],
    themeMode: "light",
    navOpen: true,
    privacyMode: false,
};

const userPreferencesManagerDefaults: IUserPreferencesManager = {
    setYear: () => {},
    setSelectedCourseId: () => {},
    setClassCategoryFilter: () => {},
    addFavouriteClass: () => {},
    removeFavouriteClass: () => {},
    toggleThemeMode: () => {},
    setNavOpen: () => {},
    togglePrivacyMode: () => {},
};

export const UserPreferencesContext = createContext<IUserPreferences>(userPreferencesDefaults)

export const UserPreferencesManagerContext = createContext<IUserPreferencesManager>(userPreferencesManagerDefaults)

function UserPreferencesContextConfigurator({children}: React.PropsWithChildren) {
    const vutSession = useVutApiSession();
    const [preferences, setPreferences] = useLocalStorageState<IUserPreferences>(`/user/${vutSession.per_id}/preferences`, userPreferencesDefaults);

    const manager: IUserPreferencesManager = useMemo(() => ({
        setYear: (year) => setPreferences({
            ...preferences,
            selectedCourseId: undefined,
            year: year,
        }),
        setSelectedCourseId: (courseId) => setPreferences({
            ...preferences,
            selectedCourseId: courseId,
        }),
        setClassCategoryFilter: (categories: string[]) => setPreferences({
            ...preferences,
            filteredClassCategories: [...categories],
        }),
        addFavouriteClass: (classId) => setPreferences({
            ...preferences,
            favouriteClasses: Array.from(new Set([...preferences.favouriteClasses, classId])),
        }),
        removeFavouriteClass: (classId) =>  setPreferences({
            ...preferences,
            favouriteClasses: preferences.favouriteClasses.filter(_classId => _classId !== classId),
        }),
        toggleThemeMode: () => setPreferences({
            ...preferences,
            themeMode: preferences.themeMode === "dark" ? "light" : "dark",
        }),
        setNavOpen: (open) => setPreferences({
            ...preferences,
            navOpen: open,
        }),
        togglePrivacyMode: () => setPreferences({
            ...preferences,
            privacyMode: !preferences.privacyMode,
        }),
    }), [preferences, setPreferences])

    return <>
        <UserPreferencesContext.Provider value={preferences}>
            <UserPreferencesManagerContext.Provider value={manager}>
                {children}
            </UserPreferencesManagerContext.Provider>
        </UserPreferencesContext.Provider>
    </>
}

export default UserPreferencesContextConfigurator;
