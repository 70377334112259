import {useContext} from "react";
import {
    IUserPreferences,
    IUserPreferencesManager,
    UserPreferencesContext,
    UserPreferencesManagerContext
} from "contexts/UserPreferences";

export function useUserPreferences(): IUserPreferences {
    return useContext(UserPreferencesContext);
}

export function useUserPreferencesManager(): IUserPreferencesManager {
    return useContext(UserPreferencesManagerContext);
}
