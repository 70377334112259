import {StudentGradingState} from "../helpers/taskGrading";
import {Block, CheckCircle, CheckCircleOutline, RemoveCircle, Error, HourglassEmpty} from "@mui/icons-material";
import {CircularProgress, Tooltip} from "@mui/material";
import React from "react";


export interface IStudentGradingStateIconProps {
    state: StudentGradingState;
}

export function StudentGradingStateIcon({state}: IStudentGradingStateIconProps) {
    return <>
        {state === StudentGradingState.NotGraded &&
            <Tooltip title={"No changes against IS VUT"}>
                <Block sx={{color: "text.secondary"}}/>
            </Tooltip>}
        {state === StudentGradingState.Impossible &&
            <Tooltip title={"Record is not valid and cannot be graded"}>
                <RemoveCircle sx={{color: "error.main"}}/>
            </Tooltip>}
        {state === StudentGradingState.Possible &&
            <Tooltip title={"Grade will be submitted"}>
                <CheckCircleOutline sx={{color: "info.main"}}/>
            </Tooltip>}
        {state === StudentGradingState.Waiting &&
            <Tooltip title={"Waiting..."}>
                <HourglassEmpty sx={{color: "warning.main"}}/>
            </Tooltip>}
        {state === StudentGradingState.Processing &&
            <Tooltip title={"Processing..."}>
                <CircularProgress size={18} sx={{color: "info.main"}}/>
            </Tooltip>}
        {state === StudentGradingState.Graded &&
            <Tooltip title={"Export table data"}>
                <CheckCircle sx={{color: "success.main"}}/>
            </Tooltip>}
        {state === StudentGradingState.Failed &&
            <Tooltip title={"Submission to IS VUT has failed"}>
                <Error sx={{color: "error.main"}}/>
            </Tooltip>}
    </>;
}
