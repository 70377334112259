import {GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner} from "libs/vut-api";
import {ICourseTaskWithExamInfo} from "common/types/VutApi";



export enum StudentGradingState {
    NotGraded,
    Impossible,
    Possible,
    Waiting,
    Processing,
    Graded,
    Failed,
}

export class TaskGrading {
    private task: ICourseTaskWithExamInfo | undefined;
    private lessonNumber: number | undefined;

    constructor(task: ICourseTaskWithExamInfo | undefined, lessonNumber: number | undefined) {
        this.task = task;
        this.lessonNumber = lessonNumber;
    }

    public getCurrentLessonGrade(grading: GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner | undefined): number | null {
        if ((grading?.otazky?.length ?? 0) > 0)
        {
            return grading?.otazky?.find(o => o.otazka_poradi === this.lessonNumber)?.otazka_pocet_bodu ?? null;
        }

        return grading?.pocet_bodu ?? null;
    }

    public getPreviousStudentGrading(grading: GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner | undefined): number | null {
        if ((this.task?.pocet_otazek ?? 0) > 0) {
            // zadani ma vice casti k hodnoceni
            return grading?.otazky?.filter(o => o.otazka_poradi !== this.lessonNumber)
                .reduce((previous, current) => previous + (current.otazka_pocet_bodu ?? 0), 0) ?? null
        } else {
            // zadani nema vice casti k hodnoceni
            return grading?.pocet_bodu ?? null;
        }
    }

    public getCurrentStudentGrading(grading: GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner | undefined): number | null {
        const previousGrading = this.getPreviousStudentGrading(grading);
        const currentGrading = this.getCurrentLessonGrade(grading);

        // hodnoceni studenta nyni neexistuje
        return previousGrading === null
            ? currentGrading
            : previousGrading + (currentGrading ?? 0)
    }

    public getNewStudentGrading(grading: GetAktualniPredmetZadaniStudentiV3200ResponseDataStudentiInner | undefined, points: number | null): number | null {
        const previousGrading = this.getPreviousStudentGrading(grading);
        if (points === null) {
            return previousGrading;
        } else {
            if (grading?.otazky?.length ?? 0) {
                return (previousGrading ?? 0) + points;
            }

            return points;
        }
    }

}
