/* tslint:disable */
/* eslint-disable */
/**
 * FIT CVT API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CvtActivitySummary
 */
export interface CvtActivitySummary {
    /**
     * 
     * @type {string}
     * @memberof CvtActivitySummary
     */
    'lab_name': string;
    /**
     * 
     * @type {number}
     * @memberof CvtActivitySummary
     */
    'pc_id': number;
    /**
     * 
     * @type {string}
     * @memberof CvtActivitySummary
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof CvtActivitySummary
     */
    'when_from': string;
    /**
     * 
     * @type {string}
     * @memberof CvtActivitySummary
     */
    'when_to': string;
}
/**
 * 
 * @export
 * @interface CvtStateLog
 */
export interface CvtStateLog {
    /**
     * 
     * @type {string}
     * @memberof CvtStateLog
     */
    'lab_name': string;
    /**
     * 
     * @type {number}
     * @memberof CvtStateLog
     */
    'pc_id': number;
    /**
     * 
     * @type {string}
     * @memberof CvtStateLog
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof CvtStateLog
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof CvtStateLog
     */
    'system'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CvtStateLog
     */
    'is_allowed': boolean;
    /**
     * 
     * @type {number}
     * @memberof CvtStateLog
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface LocationInner
 */
export interface LocationInner {
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<LocationInner>}
     * @memberof ValidationError
     */
    'loc': Array<LocationInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}

/**
 * LaboratoryLogsApi - axios parameter creator
 * @export
 */
export const LaboratoryLogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get current state of computers in the given laboratory
         * @param {string} name A name of PC laboratory room
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentLabState: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getCurrentLabState', 'name', name)
            const localVarPath = `/v1/lab/{name}/now`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get state of computers in the given laboratory at the given time and day
         * @param {string} name A name of PC laboratory room
         * @param {string} when Datetime string in ISO-8601
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabStateOnDay: async (name: string, when: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getLabStateOnDay', 'name', name)
            // verify required parameter 'when' is not null or undefined
            assertParamExists('getLabStateOnDay', 'when', when)
            const localVarPath = `/v1/lab/{name}/at/{when}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"when"}}`, encodeURIComponent(String(when)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get student presence summary in the given laboratory from and to the given time and day grouped by logins
         * @param {string} name A name of PC laboratory room
         * @param {string} whenFrom Datetime string in ISO-8601
         * @param {string} whenTo Datetime string in ISO-8601
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginPresenceSummaryInLab: async (name: string, whenFrom: string, whenTo: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getLoginPresenceSummaryInLab', 'name', name)
            // verify required parameter 'whenFrom' is not null or undefined
            assertParamExists('getLoginPresenceSummaryInLab', 'whenFrom', whenFrom)
            // verify required parameter 'whenTo' is not null or undefined
            assertParamExists('getLoginPresenceSummaryInLab', 'whenTo', whenTo)
            const localVarPath = `/v1/lab/{name}/from/{when_from}/to/{when_to}/logins/summary`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"when_from"}}`, encodeURIComponent(String(whenFrom)))
                .replace(`{${"when_to"}}`, encodeURIComponent(String(whenTo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of logins present in the given laboratory from and to the given time and day
         * @param {string} name A name of PC laboratory room
         * @param {string} whenFrom Datetime string in ISO-8601
         * @param {string} whenTo Datetime string in ISO-8601
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginsPresentInLab: async (name: string, whenFrom: string, whenTo: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getLoginsPresentInLab', 'name', name)
            // verify required parameter 'whenFrom' is not null or undefined
            assertParamExists('getLoginsPresentInLab', 'whenFrom', whenFrom)
            // verify required parameter 'whenTo' is not null or undefined
            assertParamExists('getLoginsPresentInLab', 'whenTo', whenTo)
            const localVarPath = `/v1/lab/{name}/from/{when_from}/to/{when_to}/logins`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"when_from"}}`, encodeURIComponent(String(whenFrom)))
                .replace(`{${"when_to"}}`, encodeURIComponent(String(whenTo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List names of available laboratory rooms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLabs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/lab`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LaboratoryLogsApi - functional programming interface
 * @export
 */
export const LaboratoryLogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LaboratoryLogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get current state of computers in the given laboratory
         * @param {string} name A name of PC laboratory room
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentLabState(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CvtStateLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentLabState(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get state of computers in the given laboratory at the given time and day
         * @param {string} name A name of PC laboratory room
         * @param {string} when Datetime string in ISO-8601
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLabStateOnDay(name: string, when: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CvtStateLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLabStateOnDay(name, when, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get student presence summary in the given laboratory from and to the given time and day grouped by logins
         * @param {string} name A name of PC laboratory room
         * @param {string} whenFrom Datetime string in ISO-8601
         * @param {string} whenTo Datetime string in ISO-8601
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoginPresenceSummaryInLab(name: string, whenFrom: string, whenTo: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<CvtActivitySummary>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoginPresenceSummaryInLab(name, whenFrom, whenTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of logins present in the given laboratory from and to the given time and day
         * @param {string} name A name of PC laboratory room
         * @param {string} whenFrom Datetime string in ISO-8601
         * @param {string} whenTo Datetime string in ISO-8601
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoginsPresentInLab(name: string, whenFrom: string, whenTo: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoginsPresentInLab(name, whenFrom, whenTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List names of available laboratory rooms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listLabs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listLabs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LaboratoryLogsApi - factory interface
 * @export
 */
export const LaboratoryLogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LaboratoryLogsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get current state of computers in the given laboratory
         * @param {string} name A name of PC laboratory room
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentLabState(name: string, options?: any): AxiosPromise<Array<CvtStateLog>> {
            return localVarFp.getCurrentLabState(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get state of computers in the given laboratory at the given time and day
         * @param {string} name A name of PC laboratory room
         * @param {string} when Datetime string in ISO-8601
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabStateOnDay(name: string, when: string, options?: any): AxiosPromise<Array<CvtStateLog>> {
            return localVarFp.getLabStateOnDay(name, when, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get student presence summary in the given laboratory from and to the given time and day grouped by logins
         * @param {string} name A name of PC laboratory room
         * @param {string} whenFrom Datetime string in ISO-8601
         * @param {string} whenTo Datetime string in ISO-8601
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginPresenceSummaryInLab(name: string, whenFrom: string, whenTo: string, options?: any): AxiosPromise<{ [key: string]: Array<CvtActivitySummary>; }> {
            return localVarFp.getLoginPresenceSummaryInLab(name, whenFrom, whenTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of logins present in the given laboratory from and to the given time and day
         * @param {string} name A name of PC laboratory room
         * @param {string} whenFrom Datetime string in ISO-8601
         * @param {string} whenTo Datetime string in ISO-8601
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginsPresentInLab(name: string, whenFrom: string, whenTo: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getLoginsPresentInLab(name, whenFrom, whenTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List names of available laboratory rooms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLabs(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.listLabs(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LaboratoryLogsApi - object-oriented interface
 * @export
 * @class LaboratoryLogsApi
 * @extends {BaseAPI}
 */
export class LaboratoryLogsApi extends BaseAPI {
    /**
     * 
     * @summary Get current state of computers in the given laboratory
     * @param {string} name A name of PC laboratory room
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LaboratoryLogsApi
     */
    public getCurrentLabState(name: string, options?: AxiosRequestConfig) {
        return LaboratoryLogsApiFp(this.configuration).getCurrentLabState(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get state of computers in the given laboratory at the given time and day
     * @param {string} name A name of PC laboratory room
     * @param {string} when Datetime string in ISO-8601
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LaboratoryLogsApi
     */
    public getLabStateOnDay(name: string, when: string, options?: AxiosRequestConfig) {
        return LaboratoryLogsApiFp(this.configuration).getLabStateOnDay(name, when, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get student presence summary in the given laboratory from and to the given time and day grouped by logins
     * @param {string} name A name of PC laboratory room
     * @param {string} whenFrom Datetime string in ISO-8601
     * @param {string} whenTo Datetime string in ISO-8601
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LaboratoryLogsApi
     */
    public getLoginPresenceSummaryInLab(name: string, whenFrom: string, whenTo: string, options?: AxiosRequestConfig) {
        return LaboratoryLogsApiFp(this.configuration).getLoginPresenceSummaryInLab(name, whenFrom, whenTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of logins present in the given laboratory from and to the given time and day
     * @param {string} name A name of PC laboratory room
     * @param {string} whenFrom Datetime string in ISO-8601
     * @param {string} whenTo Datetime string in ISO-8601
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LaboratoryLogsApi
     */
    public getLoginsPresentInLab(name: string, whenFrom: string, whenTo: string, options?: AxiosRequestConfig) {
        return LaboratoryLogsApiFp(this.configuration).getLoginsPresentInLab(name, whenFrom, whenTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List names of available laboratory rooms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LaboratoryLogsApi
     */
    public listLabs(options?: AxiosRequestConfig) {
        return LaboratoryLogsApiFp(this.configuration).listLabs(options).then((request) => request(this.axios, this.basePath));
    }
}


