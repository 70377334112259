import React, {useMemo} from "react";
import AppThemeContextConfigurator from "contexts/AppTheme";
import VutApiSessionContextConfigurator from "contexts/VutApiSession";
import UserPreferencesContextConfigurator from "contexts/UserPreferences";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import MainNavContextConfigurator from "contexts/MainNavContext";


function AppConfigurator({children}: React.PropsWithChildren) {
    const queryClient = useMemo(() => new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: 60 * 1000,
                cacheTime: 5 * 60 * 1000,
                retry: 0,
                retryDelay: 1000,
                retryOnMount: true,
                refetchOnMount: true,
                refetchOnReconnect: false,
                refetchOnWindowFocus: false,
            },
        },
    }), []);

    return (
        <QueryClientProvider client={queryClient}>
            <VutApiSessionContextConfigurator>
                <UserPreferencesContextConfigurator>
                    <MainNavContextConfigurator>
                        <AppThemeContextConfigurator>
                            {children}
                        </AppThemeContextConfigurator>
                    </MainNavContextConfigurator>
                </UserPreferencesContextConfigurator>
            </VutApiSessionContextConfigurator>
            <ReactQueryDevtools/>
        </QueryClientProvider>
    )
}

export default AppConfigurator;
