import {useQuery} from "@tanstack/react-query";
import {ICourseTaskWithExamInfo} from "common/types/VutApi";
import {Autocomplete, MenuItem, TextField} from "@mui/material";
import React from "react";
import {useVutApiSession} from "contexts/VutApiSession";
import {VutApi} from "common/helpers/VutApi";
import {getCourseTasks} from "common/data/getCourseTasks";

export interface ICourseTaskAutocompleteProps {
    currentCourseId: number | undefined;
    task: ICourseTaskWithExamInfo | undefined;
    setTask: (task: ICourseTaskWithExamInfo | undefined) => void;
}

export function CourseTaskAutocomplete({currentCourseId, task, setTask}: ICourseTaskAutocompleteProps) {
    const vutSession = useVutApiSession();
    const api = new VutApi(vutSession)
    const zadaniApi = api.zadani()

    const {
        data: courseTaskList = [],
        error: courseTaskListError,
        isError: courseTaskListHasError,
        isLoading: courseTaskListIsLoading
    } = useQuery([currentCourseId, "zadani"], async () => {
        return await getCourseTasks(zadaniApi, currentCourseId!)
    });

    const handleCourseTaskChange = (courseTask: ICourseTaskWithExamInfo | null | undefined) => {
        setTask(courseTask ?? undefined);
    }

    const inputParams = {
        error: courseTaskListHasError,
        helperText: courseTaskListError?.toString(),
    }

    return <Autocomplete
        options={courseTaskList}
        loading={courseTaskListIsLoading}
        disabled={courseTaskListHasError}
        groupBy={(option) => option?.zkouska!.zkouska_projekt_nazev!}
        getOptionLabel={(option) => `${option?.zkouska!.zkouska_projekt_nazev}: ${option?.zadani_nazev!}`}
        onChange={(event, value) => handleCourseTaskChange(value)}
        renderInput={(params) => <TextField {...inputParams} {...params} label="Graded task"/>}
        renderOption={(props, option) => <MenuItem {...props}>{option!.zadani_nazev}</MenuItem>}
        isOptionEqualToValue={(option, value) => option?.zadani_id === value?.zadani_id}
        value={task ?? null}
        fullWidth
    />
}