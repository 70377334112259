import {useLocation, useNavigate} from "react-router-dom";
import {useContext, useEffect} from "react";
import {VutApiSessionContext} from "contexts/VutApiSession";
import {AxiosError} from "axios";

export function useForceLoginOn401(error: Error | null | undefined) {
    const navigate = useNavigate();
    const location = useLocation();
    const {setSession} = useContext(VutApiSessionContext);

    useEffect(() => {
        if (error instanceof AxiosError && error.response?.status === 401) {
            setSession({});
            navigate(`/sign-in?returnTo=${location.pathname}`);
        }
    }, [error, location.pathname, navigate, setSession]);
}