import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Typography} from "@mui/material";
import {ICourseLesson} from "common/data/getLessonsForCurrentCourse";
import {CourseClassDescription} from "dialogs/CourseClassDescription";


export interface ICourseClassLessonDescriptionParams {
    lesson: ICourseLesson;
    includeClassDescription: boolean;
}

export function CourseClassLessonDescription({lesson, includeClassDescription}: ICourseClassLessonDescriptionParams) {
    return <>
        {includeClassDescription && <CourseClassDescription courseClass={lesson.vyuka} blok={lesson.blok} noMargin/>}
        <Grid container columns={5} rowSpacing={1} columnSpacing={3}
               sx={{mb: "1.5em", display: "flex", alignContent: "center", alignItems: "center"}}>
            <Grid md={1} xs={5}>
                <Typography variant={"subtitle1"} sx={{textAlign: {md: "right"}}}>Lesson Date</Typography>
            </Grid>
            <Grid md={4} xs={5}>
                <Typography variant={"body2"}>
                    {lesson.date_from.toLocaleString()}
                </Typography>
            </Grid>
        </Grid>
    </>
}